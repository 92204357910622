import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";

import CustomDropdown from "../../../components/common/CustomDropdown";

const textToNumberDaysMapping = {
    "1dayago": 1,
    "3daysago": 3,
    "1weekago": 7,
    "1monthago": 30,
    "1yearago": 365,
}

const DocumentStatusBarGraph = (props) => {
    const { t } = useTranslation('documents');

    const {
        statusMapping,
        barSeries,
        selectedTimeDuration,
        handleTimeDurationChange,
        handleSetSelectedStatus
    } = props;

    const [createdDocumentsFilterOptions, setCreatedDocumentsFilterOptions] = useState([]);
    const [barGraphOptions, setBarGraphOptions] = useState({
        grid: {
            show: false,
        },
        chart: {
            height: 350,
            type: "bar",
            events: {
                legendClick: (a, seriesIndex, c) => {
                    handleSetSelectedStatus(seriesIndex);
                },
                dataPointSelection: (event, chartContext, config) => {
                    handleSetSelectedStatus(config?.seriesIndex);
                }
            },
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                //   borderRadius: 10,
                dataLabels: {
                    position: "top", // top, center, bottom
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val;
            },
            offsetY: -20,
            style: {
                fontSize: "16px",
                colors: ["#3498db", "#f39c12", "#2ecc71", "#FF0000"],
            },
        },

        xaxis: {
            categories: [""],
            position: "bottom",
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                formatter: function (val) {
                    return val;
                },
            },
        },
        title: {
            text: "",
        },
        legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            onItemClick: {
                toggleDataSeries: false,
            },
            fontSize: '14px',
            colors: ["#3498db", "#f39c12", "#2ecc71", "#FF0000"],
            labels: {
                colors: ["#3498db", "#f39c12", "#2ecc71", "#FF0000"],
                useSeriesColors: false
            },
            // offsetY: 10,
        },
        colors: ["#3498db", "#f39c12", "#2ecc71", "#FF0000"]

    });

    const getBarSeries = () => {
        const newBarSeries = []
        Object.keys(statusMapping).forEach((status, index) => {
            newBarSeries.push({
                name: statusMapping[status],
                data: [barSeries[index]],
            })
        })
        return newBarSeries || [0, 0, 0, 0];

    }

    useEffect(() => {
        const dropdownOptions = Object.keys(textToNumberDaysMapping).map(key => ({ text: t(key), value: textToNumberDaysMapping[key] }));
        setCreatedDocumentsFilterOptions(dropdownOptions);
    }, [t])

    const selectedOptionText = Object.keys(textToNumberDaysMapping).find(option => textToNumberDaysMapping[option] === selectedTimeDuration) || '1dayago';

    return (
        <>
            <Row>
                <Col md={6}></Col>
                {/* For days counting*/}
                <Col md={6} className="d-flex justify-content-center statusFilterByDaysTour">
                    <CustomDropdown
                        selectedOption={{ text: t(selectedOptionText), value: selectedTimeDuration || 1 }}
                        options={createdDocumentsFilterOptions}
                        optionChangeEventHandler={handleTimeDurationChange}
                        className
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} id="chart">
                    <ReactApexChart
                        options={barGraphOptions}
                        series={getBarSeries()}
                        type="bar"
                        height={350}
                    />
                </Col>
            </Row>
        </>
    )
}

export default DocumentStatusBarGraph;