import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AddRemoveOptions = (props) => {
    const { annotationData, handleUpdateAnnotation, themeColor } = props;
    const [options, setOptions] = useState([]);
    const [newOption, setNewOption] = useState('');

    const { t } = useTranslation('templates');

    const handleAddOption = () => {
        if (newOption.trim() !== '') {
            setOptions([...options,  newOption]);
            setNewOption('');
        }
    };

    useEffect(() => {
        setOptions(annotationData?.options || []);
    }, [annotationData?.options])

    const handleSave = () => {
        handleUpdateAnnotation({ ...annotationData, options });
    }

    const handleRemoveOption = (index) => {
        const updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        setOptions(updatedOptions);
    };

    return (
        <>
            <Row className="mb-3 justify-content-between">
                <h6 className='ps-0'>{t('UPDATE_DROPDOWN_OPTIONS')}</h6>
                <Col sm={7} className='p-0'>
                    <Form.Group controlId="newOption">
                        <Form.Control
                            type="text"
                            value={newOption}
                            onChange={(e) => setNewOption(e.target.value)}
                            placeholder={t('NEW_OPTION')}
                        />
                    </Form.Group>
                </Col>
                <Col className='p-0 d-flex justify-content-end'>
                    <Button variant="primary" style={{background: themeColor}} onClick={handleAddOption}>
                        {t('ADD_OPTION')}
                    </Button>
                </Col>
            </Row>
            <Row>

            {options.map((option, index) => (
                <Row key={index} className="mb-2" style={{ backgroundColor: 'lightgray', padding: '5px', borderRadius: '5px' }}>
                    <Col sm={10}>
                        <Form.Control plaintext readOnly value={option} />
                    </Col>
                    <Col sm={2} className="d-flex align-items-center">
                        <i className="bi bi-trash" onClick={() => handleRemoveOption(index)} style={{ cursor: 'pointer', color: 'red' }}></i>
                    </Col>
                </Row>
            ))}
            {options.length > 0 && <Button style={{background: themeColor}} onClick={handleSave}>Save</Button>}
            </Row>
        </>
    );
};

export default AddRemoveOptions;
