import React, { useContext, useState } from 'react';
import './ForgotPassword.css';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthServices from '../../services/auth.services';
import { LoaderContext } from '../../store/Loader';
import translateLanguageImage from '../../assets/Icon material-translate.svg'
import LanguageSwitcher from '../../LanguageSwitcher';
import { useTranslation } from 'react-i18next';
// import AuthFooter from '../../components/common/AuthFooter';
import { DocumentContext } from '../../store/document';
import { bgBeforeLogin, textChangeApp, emailPattern } from '../../utils/constants';
import Loader from '../../components/ui/Loader';
import TawqiiaVactorEn from "../../assets/sign_in_vector_tawqiia_en.png"
import TawqiiaVactorAr from "../../assets/sign_in_vector_tawqiia_ar.png"
import TawqiiaLogoEn from "../../assets/Tawqiia_english.png"
import TawqiiaLogoAr from "../../assets/Tawqiia_arabic.png"
import DastkhatVactorEn from "../../assets/dastkat-vactor-img.png"
import DastkhatLogoEnFinalBlue from "../../assets/DastkhatLogoEnFinalBlue.png"

import { generateSignBanner } from '../../utils/helper';

const authServices = new AuthServices();

const ForgotPassword = () => {
  const params = useParams();
  const location = useLocation();
  const orgName = location.pathname.split('/')[1];
  const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");

  const { getOrgApp, documentState } = useContext(DocumentContext);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    email: '',
  });
  const { loaderDispatch } = useContext(LoaderContext);
  const navigate = useNavigate();

  const handleOnChange = (name) => (e) => {
    setValues((prevState) => {
      return { ...prevState, [name]: e.target.value };
    });
  };
  const { t } = useTranslation('forgot_password');
  const { t: ts } = useTranslation('apiMessage');
  const { i18n: { language } } = useTranslation();

  const dataBg = bgBeforeLogin(documentState)
  const textChangeAppData = textChangeApp(documentState)

  const handleForgotPasswordClick = async () => {
    const { email } = values;
    const enteredEmail = email;
    if (!enteredEmail) {
      return toast.warn(ts('Please_enter_email'));
    }
    const isValidEmail = emailPattern.test(enteredEmail);
    if (!isValidEmail) {
      return toast.warn(ts('ENTER_VALID_EMAIL'));
    }
    loaderDispatch({ type: 'SET_LOADING', payload: true });
    setIsLoading(true);
    const myHostName = window.location.hostname;
    let host;
    if (myHostName === 'localhost') {
      host = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    } else {
      host = `${window.location.protocol}//${window.location.hostname}`;
    }
    try {
      const res = await authServices.forgotPassword({
        email,
        host,
        subDomain: params?.orgName
      });
      if (res?.status === 'success') {
        toast.success(ts(res?.code || res?.message));
        navigate(`/${orgName}`);
      }
    } catch (error) {
      toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`));
    } finally {
      setValues({
        email: '',
      });
      loaderDispatch({ type: 'SET_LOADING', payload: false });
      setIsLoading(false);
    }
  };
  const logo = documentState?.orgAppData;
  let hostname = process.env.REACT_APP_DOMAIN;

  return (
    <div className='login-body' style={{
      backgroundImage: dataBg
    }}>
      <div className='login-page'>
        <div className='form-submitted'>
          <div className='dastkhat-logo px-5 mx-5'>
            <Link to={`/${orgName}`} className={`pointer mt-5 ${!logo?.logo && 'visibility-hidden'}`}>
              <img className="pointer mt-5" src={logo?.logo} alt="#" width={130} height={70} />
            </Link>
          </div>
          <div className='sign-vector-section'>
            <div>
              <div className="sign-vector">
                <img
                  src={generateSignBanner(textChangeAppData, language)}
                  alt="#" />
              </div>
              <div>
              <img className='dasthkath-logo-login' width={100}  
                src={(['ar'].includes(language)&& hostname === 'TAWQIA') ?
                  TawqiiaLogoAr :
                  (['en'].includes(language)&& hostname === 'TAWQIA') ?
                  TawqiiaLogoEn :
                  DastkhatLogoEnFinalBlue}
                  alt="#" />             
                   </div>
            </div>
            <div className='login-form'>
              <div className='form-here'>
                {/*  */}
                <div className='d-flex justify-content-between'>
                  <h5 className="bold-text mt-3"><strong>{t('heading')}</strong></h5>
                  {hostname === 'TAWQIA' && <div className='d-flex justify-content-between align-items-center'><img className='me-2' width={20} src={translateLanguageImage} alt='' />
                    < LanguageSwitcher />
                  </div>}
                </div>
                {/*  */}
                <div className='mt-4'>
                  <div className='mb-3'>
                    <p className='mb-1'>{t('email')}<span className='text-danger'>*</span></p>
                    <div className='d-flex justify-content-center align-items-center form-input-field'>
                      <input
                        type='text'
                        name='email'
                        placeholder= {ts( "EmailIdPlaceholder" )}
                        value={values.email}
                        onChange={handleOnChange('email')}
                      />

                    </div>
                  </div>

                  <div className="otp-input-container">

                  </div>
                  <div className='login-forgetpassword'>
                    <div className='login-btn mt-4 mx-0'>
                      <button
                        style={{
                          backgroundColor: textChangeAppData
                        }}

                        className='btnlogin me-4'
                        onClick={() => handleForgotPasswordClick()}
                      >
                        {t('btn')} - &rarr;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AuthFooter /> */}
      {isLoading && (
        <Loader />
      )}
    </div>
  );
};

export default ForgotPassword;
