import React, { useContext, useState } from 'react';
import { Popover } from 'react-bootstrap';
import SubscriptionServices from '../../../services/subscription.services';
import { toast } from 'react-toastify';
import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Modal as bootstrapModal } from 'bootstrap';
import { LoaderContext } from '../../../store/Loader';
import { SubscriptionContext } from '../../../store/subscription';

const subscriptionServices = new SubscriptionServices();

const useSubscriptions = () => {
    const [subscriptionState, setSubscriptionState] = useState({});
    const [highlightedIndex, setHighlightedIndex] = useState(null);
    const [addOnsData, setAddOnsData] = useState([]);
    const [myAddOnsData, setMyAddOnsData] = useState([]);
    // const [subscriptionLoading, setSubscriptionLoading] = useState(false);
    const [selectedStorage, setSelectedStorage] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [myAddOnspopoverVisible, setMyAddOnsPopoverVisible] = useState(false);
    const [isChecked, setIsChecked] = useState(null);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [retrievePlan, setRetrievePlan] = useState(false);
    const { loaderDispatch, loaderState: { isLoading = false } } = useContext(LoaderContext);
    const { subscriptionDispatch } = useContext(SubscriptionContext);

    const navigate = useNavigate();
    const stripe = useStripe();
    const { t } = useTranslation('admin');
    const handleToggleChange = async ({ subscriptionId, cancelAtPeriodEnd }) => {
        if (cancelAtPeriodEnd) {
            toast.warn(t('subscriptionPlan.renewalWarning'));
            return;
        }
        setSubscriptionLoading(true);
        try {
            const data = await subscriptionServices.autoDebit({ subscriptionId });
            if (data?.status === 'success') {
                setIsChecked(data?.data);
                // toast.success(t(`API.${data?.code}`))
                toast.success(t(`API.${data.code}`));
            }
        } catch (error) {
            toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
        } finally {
            setSubscriptionLoading(false);
        }
    };

    const handleDivClick = (index, id) => {
        setHighlightedIndex(index);
        setSelectedStorage(id);
    };

    function setSubscriptionLoading(payload) {
        loaderDispatch({ type: 'SET_LOADING', payload });
    }

    const handleAddOnsDivClick = (index, id) => {
        setHighlightedIndex(index);
        setSelectedId(id);
    };

    const handlePopoverToggle = () => {
        setPopoverVisible(!popoverVisible);
    };

    const handleMyAddOnsPopoverToggle = () => {
        setMyAddOnsPopoverVisible(!myAddOnspopoverVisible);
    };

    const handleAddOnsStorageClick = async () => {
        setSubscriptionLoading(true);
        try {
            const data = await subscriptionServices.addOnsStorage({ priceId: selectedStorage });
            /* This code is used to pay from stripe via invoices 
            let url = data?.url;
            if (url) {
                openNewTab(url);
            }
            
           console.log({data});
           return 
           */
            const { error } = await stripe.redirectToCheckout({
                sessionId: data.sessionID,
            });
            if (error) {
                toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
            }
        } catch (error) {
            toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
        } finally {
            setSubscriptionLoading(false);
            setPopoverVisible(false);
        }
    };

    const handleDeleteAddOnsStorageClick = async () => {
        setSubscriptionLoading(true);
        try {
            const data = await subscriptionServices.deleteAddOnsStorage({ addOnsId: selectedId });
            if (data?.status === 'success') {
                toast.success(t(`API.${data?.code}`) || data?.message);
            }
            setMyAddOnsPopoverVisible(false);
            getMySubscription();
        } catch (error) {
            toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
        } finally {
            setSubscriptionLoading(false);
        }
        setPopoverVisible(false);
    };

    const handlePopoverHide = () => {
        setPopoverVisible(false);
    };

    const handleMyAddOnsPopoverHide = () => {
        setMyAddOnsPopoverVisible(false);
    }

    const getMySubscription = async () => {
        setSubscriptionLoading(true);
        try {
            const { data } = await subscriptionServices.getSubscriptions();
            if (data?.status) {
                getAddOnsPlans();
            }
            subscriptionDispatch({type: 'SET_ACTIVE_PLAN', payload: data});
            setSubscriptionState(data);
            setMyAddOnsData(data?.addOns);
        } catch (error) {
            toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
        } finally {
            setSubscriptionLoading(false);
        }
    };

    const getAddOnsPlans = async () => {
        setSubscriptionLoading(true);
        try {
            const { data } = await subscriptionServices.getAddOnsPlans();
            setAddOnsData(data);
        } catch (error) {
            toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
        } finally {
            setSubscriptionLoading(false);
        }
    };

    const handleCancelSubscription = async ({ subscriptionId }) => {
        setSubscriptionLoading(true);
        try {
            const res = await subscriptionServices.cancelSubscription({ subscriptionId });
            if (res.status === 'success') {
                toast.success(t(`API.${res?.code}`) || res.message);
            }
            await getMySubscription();
            // toast.success(res);
        } catch (error) {
            toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
        } finally {
            setSubscriptionLoading(false);
        }
    };

    const handleResumeSubscription = async ({ subscriptionId }) => {
        setSubscriptionLoading(true);
        try {
            const res = await subscriptionServices.resumeSubscription({ subscriptionId });
            if (res.status === 'success') {
                toast.success(t(`API.${res?.code}`) || res?.message);
            }
            await getMySubscription();
            // toast.success(res);
        } catch (error) {
            toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
        } finally {
            setSubscriptionLoading(false);
        }
    };

    const getSubscriptionPlan = async () => {
        try {
            let { data } = await subscriptionServices.getSubscriptionPlans();
            if (data?.data?.length) {
                data = data?.data?.slice(1);
            } else {
                data = data?.data
            }
            setSubscriptionPlans(data);
        } catch (error) {
            toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
        }
    }
    
    const openNewTab = async (url, modalRef) => {
        window.open(url, '_blank', 'noopener,noreferrer');
        hideModal(modalRef)
    };

    const hideModal = (modalRef) => {
        // setSelectedUserInfo(null);
        const modalEle = modalRef.current;
        const bsModal = bootstrapModal.getInstance(modalEle);
        bsModal.hide();
    };

    const handlePayNowClick = async (orgName) => {
        setSubscriptionLoading(true);
        try {
            const res = await subscriptionServices?.payManually();
            if (res.status === 'success') {
                const { error } = await stripe.redirectToCheckout({
                    sessionId: res?.paymentUrl,
                });
                if (error) {
                    toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
                }
            }
        } catch (error) {
            toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
        } finally {
            setSubscriptionLoading(false);
        }
    };

    const handleUpgrade = async ({ planId, orgName, modalRef }) => {
        if (!planId) {
            return toast.warn(t('subscriptionPlan.subscriptionValidationWarning'))
        }
        setSubscriptionLoading(true);
        try {
            const { data: status } = await subscriptionServices.isCancelledSubscription();
            let sessionID;
            //checking the subscription status if it is cancelled.
            if (status?.subscriptionStatus) {
                const session = await subscriptionServices.upgradeMySubscription({ priceId: planId });
                if (session?.status === 'success') {
                    toast.success(t(`API.${session.code}`));
                    hideModal(modalRef)
                    // setRetrievePlan(!retrievePlan);
                    if (session?.data) {
                        const { data } = await subscriptionServices.getSubscriptions();
                        subscriptionDispatch({type: 'SET_ACTIVE_PLAN', payload: data});
                        setSubscriptionState(data);
                     }
                    return;
                }
                sessionID = session?.sessionID
            } else {
                const session = await subscriptionServices.createNewSubscription({ planId, subdomain: orgName });
                sessionID = session?.sessionID;
            }
            const { error } = await stripe.redirectToCheckout({
                sessionId: sessionID,
            });
            if (error) {
                toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message);
            }
        } catch (error) {
            toast.error(t(`API.${error?.response?.data?.code}`) || error?.response?.data?.message)
        } finally {
            setSubscriptionLoading(false);
        }
    };

    const AddOnsPopover = ({ selectedColor, textChangeAppData, isSelectButtonEnabled }) => (
        <Popover id="popover-basic" style={{ width: '20rem' }} className="responsive-popover">
            <Popover.Header as="h3"
                style={{ color: selectedColor ? selectedColor : textChangeAppData }}
                className="text-center fs-5 popover-header"
            >
                {t('subscriptionPlan.popoverHeading')}
            </Popover.Header>
            <Popover.Body className="popover-body">
                {addOnsData?.map((plans, index) => (
                    <div
                        key={index}
                        className={`d-flex flex-column px-1 py-2 justify-content-between align-items-center rounded-3 cursor-pointer storage-div mb-2 ${highlightedIndex === index ? 'highlighted' : ''}`}
                        onClick={() => handleDivClick(index, plans?.id)}
                    >
                        <p className="mb-0">{t(plans?.nickname)}</p>
                        {plans?.amount && <div className='d-flex'>
                            <p className="mb-0">${plans?.amount / 100}/{t(plans?.interval)}</p>
                        </div>}
                    </div>
                ))}
                <div className="text-center">
                    <button type="button" className='btn py-1 shadow select-btn px-3'
                        style={{ background: selectedColor ? selectedColor : textChangeAppData, color: '#fff' }}
                        onClick={handleAddOnsStorageClick}
                        disabled={!isSelectButtonEnabled}
                    >
                        {t('subscriptionPlan.select')}
                    </button>
                </div>
            </Popover.Body>
        </Popover>
    );

    const myAddOnsPopover = ({ selectedColor, textChangeAppData, isSelectButtonEnabled }) => (
        <Popover id="popover-basic" style={{ width: '30rem' }} className="responsive-popover">
            {myAddOnsData?.length ? <> <Popover.Header as="h3"
                style={{ color: selectedColor ? selectedColor : textChangeAppData }}
                className="text-center fs-5 popover-header"
            >
                {t('subscriptionPlan.popoverHeading')}
            </Popover.Header>
                <Popover.Body className="popover-body">
                    {myAddOnsData?.map((plans, index) => (
                        <div
                            key={index}
                            className={`d-flex flex-column px-1 py-2 justify-content-between align-items-center rounded-3 cursor-pointer storage-div mb-2 ${highlightedIndex === index ? 'highlighted' : ''}`}
                            onClick={() => handleAddOnsDivClick(index, plans?.id)}
                        >
                            <p className="mb-0">{plans?.name}</p>
                            {plans?.amount && <div className='d-flex'>
                                <p className="mb-0">{plans?.quantity + 'x $' + plans?.amount} = ${plans?.quantity * plans?.amount}/{plans?.recurring}</p>
                            </div>}
                        </div>
                    ))}
                    <div className="text-center">
                        <button type="button" className='btn py-1 shadow select-btn px-3'
                            style={{ background: selectedColor ? selectedColor : textChangeAppData, color: '#fff' }}
                            onClick={handleDeleteAddOnsStorageClick}
                            disabled={!isSelectButtonEnabled}
                        >
                            {t('subscriptionPlan.delete')}
                        </button>
                    </div>
                </Popover.Body>
            </> : <p className='text-center d-flex justify-content-center align-items-center mt-3'>{t('ADD_ONS_NOT_FOUND')}</p>}
        </Popover>
    );
    return {
        subscriptionState,
        highlightedIndex,
        addOnsData,
        subscriptionLoading: isLoading,
        selectedStorage,
        popoverVisible,
        isChecked,
        setIsChecked,
        handleToggleChange,
        handleDivClick,
        handlePopoverToggle,
        handleMyAddOnsPopoverToggle,
        handleAddOnsStorageClick,
        handlePopoverHide,
        handleMyAddOnsPopoverHide,
        myAddOnspopoverVisible,
        getMySubscription,
        handleCancelSubscription,
        handleResumeSubscription,
        handleDeleteAddOnsStorageClick,
        AddOnsPopover,
        myAddOnsPopover,
        selectedId,
        subscriptionPlans,
        getSubscriptionPlan,
        handleUpgrade,
        hideModal,
        handlePayNowClick,
        retrievePlan
    };
};

export default useSubscriptions;
