import bgImage from '../assets/login-bg.jpg'
import bgImagePink from '../assets/login_bg-7C3E66.png'
import bgImageGreen from '../assets/login_bg-159895.png'
import bgImageVilot from '../assets/colo-3C486B.png'
import bg_903749 from '../assets/903749.png';
import bg_C996CC from '../assets/C996CC.png';
import bg_ACBDC5 from '../assets/ACBDC5.png';
import bg_678983 from '../assets/678983.png';
import bg_060930 from '../assets/060930.png';
import bg_4f4557 from '../assets/4f4557.png';
import bg_4F200D from '../assets/4F200D.png';
import bg_0C134F from '../assets/0C134F.png';
// 
import bg_903749_nav from '../assets/903749pannel6.png';
import bg_C996CC_nav from '../assets/C996CCpannel4.png';
import bg_ACBDC5_nav from '../assets/ACBDC5pannel5.png';
import bg_678983_nav from '../assets/678983pannel3.png';
import bg_060930_nav from '../assets/060930pannel7.png';
import bg_4f4557_nav from '../assets/4f4557pannel2.png';
import bg_4F200D_nav from '../assets/4F200Dpannel8.png';
import bg_0C134F_nav from '../assets/0C134Fpannel1.png';
// 
import bgImagePinkNav from "../assets/bgm2-7c3e66.png";
import bgImage_nav from "../assets/navbar-bg.png";
import bgImageVilotNav from "../assets/bgm2-3C486B.png";
import bgImageGreenNav from "../assets/bgm2-159895.png";

export const navLinks = {
    dashboard: 'dashboard',
    templates: 'templates',
    documents: 'documents',
    settings: 'settings',
};

export const documentsTabs = {
    MY_DOCUMENTS: 'my-documents',
    SHARED_WITH_ME: 'shared-with-me',
    TEMPLATES: 'templates'
}

export const settingsTabs = {
    PROFILE: 'profile',
    PASSWORD: 'password',
    SIGNATURE: 'signature'
}

export const recipientSignatureColor = [
    '#C69EF2',
    '#FFA8A8',
    '#A6C9F0',
    '#FDE9A7',
    '#FFF9E6',
    '#E6F2FF',
    '#FFE6E6',
    '#F2E6FF',
    '#b5aebd',
    '#c575b9',
    '#75a2c5',
    '#75c5b4',
    '#e2ebba',
    '#e5b7a8',
    '#a8e5d2',
    '#b0b2cf',
    '#b9bb78',
    '#dfdfdf',
    '#83d0dd',
    '#5c57a9'
]

export const statusArabicMaping = {
    AWAITING: 'في انتظار',
    DRAFT: 'مسودة',
    PENDING: 'قيد الانتظار',
    COMPLETED: 'مكتمل',
    REJECTED: 'مرفوض'
}

export const statusEnglishMaping = {
    AWAITING: 'Awaiting',
    DRAFT: 'Draft',
    PENDING: 'Pending',
    COMPLETED: 'Completed',
    REJECTED: 'Rejected'
}

export const subscriptionArabic = {
    Trial: 'ابدأ تجربتك المجانية',
    Basic: 'أساسي',
    Enterprise: 'مَشرُوع',
    Premium: 'غالي',
    Month:"شهر"
}

export const subscriptionEnglish = {
    Trial: 'Trial',
    Basic: 'Basic',
    Enterprise: 'Enterprise',
    Premium: 'Premium',
    Month:"Month"
}

export const tourContent = (e) => (
    <>
      <p className="bold tour-title">{e?.title}</p>
      <div className="mt-3">{e?.description}</div>
    </>
  );

export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const nameRegex = /^[A-Za-z ]+$/;
export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export const bgBeforeLogin = (documentState) => {
    return documentState?.orgAppData?.primary_color === '#7c3e66' ? `url(${bgImagePink})` : documentState?.orgAppData?.primary_color === '#159895' ?
        `url(${bgImageGreen})` : documentState?.orgAppData?.primary_color === '#3c486b' ? `url(${bgImageVilot})` : documentState?.orgAppData?.primary_color === '#206888' ? `url(${bgImage})` :
            documentState?.orgAppData?.primary_color === '#903749' ? `url(${bg_903749})` : documentState?.orgAppData?.primary_color === '#C996CC' ? `url(${bg_C996CC})` : documentState?.orgAppData?.primary_color === '#ACBDC5' ? `url(${bg_ACBDC5})` : documentState?.orgAppData?.primary_color === '#678983' ? `url(${bg_678983})` :
                documentState?.orgAppData?.primary_color === '#060930' ? `url(${bg_060930})` : documentState?.orgAppData?.primary_color === '#4f4557' ? `url(${bg_4f4557})` : documentState?.orgAppData?.primary_color === '#4F200D' ? `url(${bg_4F200D})` : documentState?.orgAppData?.primary_color === '#0C134F' ? `url(${bg_0C134F})` : ''
}

export const navBgAfterLogin = (documentState) => {
    return documentState?.orgAppData?.primary_color === "#7c3e66"
        ? `url(${bgImagePinkNav})` : documentState?.orgAppData?.primary_color === "#3c486b" ? `url(${bgImageVilotNav})` : documentState?.orgAppData?.primary_color === "#159895"
         ? `url(${bgImageGreenNav})` : documentState?.orgAppData?.primary_color === "#206888" ? `url(${bgImage_nav})`: documentState?.orgAppData?.primary_color === '#903749' ? `url(${bg_903749_nav})` : documentState?.orgAppData?.primary_color === '#C996CC' ? `url(${bg_C996CC_nav})` : documentState?.orgAppData?.primary_color === '#ACBDC5' ? `url(${bg_ACBDC5_nav})` : documentState?.orgAppData?.primary_color === '#678983' ? `url(${bg_678983_nav})` :
         documentState?.orgAppData?.primary_color === '#060930' ? `url(${bg_060930_nav})` : documentState?.orgAppData?.primary_color === '#4f4557' ? `url(${bg_4f4557_nav})` : documentState?.orgAppData?.primary_color === '#4F200D' ? `url(${bg_4F200D_nav})` : documentState?.orgAppData?.primary_color === '#0C134F' ? `url(${bg_0C134F_nav})` : ''
}

const textColors = ['#7c3e66', '#3c486b', '#159895', '#206888', '#903749', '#C996CC', '#ACBDC5', '#678983', '#060930', '#4f4557', '#4F200D', '#0C134F']
export const textChangeApp = (documentState) => {
    const currentColor = documentState?.orgAppData?.primary_color;
    if (currentColor && textColors.includes(currentColor)) {
        return currentColor;
    }
    return "";
}

export const documentSignSteps = {
    SIGNATURE: "SIGNATURE",
    PHOTO: "IMAGE",
    STAMP: "STAMP",
    TEXT: "TEXTAREA",
    COMPLETED: "COMPLETED",
}

// Constants

export const NAME = 'NAME';
export const EMAIL_ID = 'EMAIL';
export const COMPANY = 'COMPANY';
export const TITLE = 'TITLE';
export const TEXT = 'TEXTAREA';
export const CHECKBOX = 'CHECKBOX';
export const DROPDOWN = 'DROPDOWN';
export const RADIO_BUTTON = 'RADIO_BUTTON';
export const SIGNATURE = "SIGNATURE";
export const PHOTO = "IMAGE";
export const STAMP = "STAMP";

export const SENDER = 'SENDER';
export const SIGNER = 'SIGNER';