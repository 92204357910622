import React, { Fragment, useState, useContext, useRef, createRef } from 'react';
import DocumentUpdate from '../../pages/Document/DocumentUpdate';
import { Document, Page, pdfjs } from 'react-pdf';
import ContentLoader from "react-content-loader";
import short from 'short-uuid';
import './UploadDoc.css';
import { useEffect } from 'react';
import { DocumentContext } from '../../store/document';
import { textChangeApp } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import DocumentTemplateServices from '../../services/documentTemplateServices';

import signatureIcon from "../../assets/icons8-signature-64.png"
import photoIcon from "../../assets/icons8-photo-50.png"
import stampIcon from "../../assets/icons8-stamp-66.png"

import {
    SIGNATURE,
    PHOTO,
    STAMP,
} from '../../utils/constants';

const documentTemplateServices = new DocumentTemplateServices();

const getAnnotationWidth = () => 150;

export function UploadDoc({ selectedDocInfo }) {
    const pdfDocumentRef = useRef(null);
    const { t } = useTranslation('documents');
    const { t: td } = useTranslation('documentsUpdate');
    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/',
    };
    const refPages = useRef([]);
    const [numPages, setNumPages] = useState(null);
    const [docZoom, setDocZoom] = useState(1);
    const [pageHeight, setPageHeight] = useState(0);
    const { getOrgApp, documentState } = useContext(DocumentContext);
    const textChangeAppData = textChangeApp(documentState);
    const [annotations, setAnnotations] = useState([]);

    const [selectedColor, setSelectedColor] = useState(documentState?.orgAppData?.primary_color ? documentState?.orgAppData?.primary_color : '');

    useEffect(() => {
        if (selectedDocInfo?.documentTemplateId) {
            getTemplateData();
        }
    }, [selectedDocInfo])

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        refPages.current = new Array(nextNumPages).fill().map((_, index) => refPages.current[index] || createRef());
        setNumPages(nextNumPages);
    }

    const getTemplateData = async () => {
        const document = await documentTemplateServices.getTemplate(selectedDocInfo?.documentTemplateId);
        if (document) {
            const responseAnnotations = document.documentTemplateFields.filter(annotation => annotation.fulfiller === 'SIGNER') || [];
            const formattedAnnotations = responseAnnotations.map(annotation => ({
                ...annotation,
                annId: annotation.id,
                x: Number(annotation?.positionX),
                y: Number(annotation?.positionY),
                value: annotation.type === 'CHECKBOX' ? false : ''
            }))
            setAnnotations(formattedAnnotations);
        }
    };

    const renderAnnotationTemplate = () => annotations.map((annotation, index) => {
        return <div
            className={`annotation_${index}`}
            style={{
                position: 'absolute',
                left: parseFloat(annotation?.positionX),
                top: parseFloat(annotation?.positionY),
                maxWidth: `${getAnnotationWidth()}rem`,
                backgroundColor: 'rgb(255 255 255)',
                opacity: 0.8,
                cursor: 'move',
            }}
        >
            <div className="annotation-text d-flex flex-column align-items-center">
                {annotation.type === SIGNATURE && (
                    <p className='StampPhotoSignIconsdoc justify-content-center gap-2' style={{ width: '8rem', height: '2.7rem' }}>
                        <img src={signatureIcon} alt='' style={{ height: '1.5rem' }} />
                        <div className='' style={{ color: 'black', fontSize: '14px' }}>{td('SIGNATURE_LABEL')}</div>
                    </p>
                )}
                {annotation.type === PHOTO && (
                    <p className='StampPhotoSignIconsdoc flex-column'>
                        <img className='mt-1' src={photoIcon} alt='' style={{ height: '1.7rem' }} />
                        <div className='' style={{ color: 'black', fontSize: '14px' }}>{td('PHOTO_LABEL')}</div>
                    </p>
                )}
                {annotation.type === STAMP && (
                    <p className='StampPhotoSignIconsdoc flex-column'>
                        <img className='mt-1' src={stampIcon} alt='' style={{ height: '1.7rem' }} />
                        <div className='' style={{ color: 'black', fontSize: '14px' }}>{td('STAMP_LABEL')}</div>
                    </p>
                )}
            </div>
        </div>
    })

    const renderPlaceholder = (props) => {
        const length = 30;
        const lines = Array.from({ length }, (_, index) => index);
        return (
            <div className='row'>
                <div className='col-sm-12' style={{ height: '600px' }}>
                    <ContentLoader
                        speed={1}
                        // width={'auto'}
                        // height={'auto'}
                        viewBox="0 0 auto auto"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        style={{ width: '100%', height: '100%' }}
                        {...props}
                    >
                        <rect x="10%" y="5" rx="3" ry="3" width="80%" height="6" />
                        {
                            lines.map((item, index) => {
                                return (
                                    <rect x="0" y={(15 * (index + 1)) + (6 * (index + 1))} rx="3" ry="3" width="100%" height="6" />
                                )
                            })
                        }
                        <rect x="10%" y={(15 * (10 + 1)) + (6 * (10 + 1))} rx="3" ry="3" width="80%" height="6" />
                    </ContentLoader>
                </div>
            </div>
        )
    }

    return (
        <div>
            <>
                <div className='row col-12'>
                    <div className='col-lg-8 col-md-10 col-xs-6 overflow-scroll' style={{ height: "510px", overflowY: 'auto' }}>
                        <Document
                            inputRef={pdfDocumentRef}
                            options={options}
                            onLoadSuccess={onDocumentLoadSuccess}
                            renderMode="canvas"
                            file={selectedDocInfo?.url}
                            renderAnnotationLayer={false}
                            className="upload-document"
                            style={{ zoom: '1.5', overflowY: 'scroll' }}
                            loading={renderPlaceholder}
                        >
                            {Array.from({ length: numPages }, (_, index) => (
                                <Fragment key={short.generate().toString()}>
                                    <div
                                        key={short.generate().toString()}
                                        style={{
                                            position: 'relative',
                                        }}
                                        className={`mx-auto w-fit page-${index} m-0`}
                                    >
                                        <Page
                                            inputRef={(pa) => (refPages.current[index] = pa)}
                                            className={`${selectedDocInfo && selectedDocInfo?.status !== 'DRAFT' ? 'emailSent' : ''}`}
                                            style={{ margin: 'auto' }}
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}
                                            loading={renderPlaceholder}
                                            onLoadSuccess={(e) => {
                                                if (e.height) setPageHeight(e.height);
                                            }}
                                            onRenderError={() => console.log('error occurred')}
                                        >
                                            {selectedDocInfo?.documentTemplateId && renderAnnotationTemplate()}
                                        </Page>
                                    </div>
                                </Fragment>
                            ))}
                        </Document>
                    </div>
                    <div className='col-lg-4 col-md-2 col-xs-6'>
                        <b style={{ color: selectedColor ? selectedColor : textChangeAppData }}>{t('File_Name')}:</b><br />
                        <p>{selectedDocInfo?.title}</p>
                    </div>
                </div>
            </>
        </div>
    )
}

export default UploadDoc