import Modal from "./Modal";
import { LineWave } from "react-loader-spinner";
import { textChangeApp } from "../../utils/constants";
import { useContext } from "react";
import { DocumentContext } from "../../store/document";

function Loader(props) {
    const { documentState } = useContext(DocumentContext);
    const textChangeAppData = textChangeApp(documentState)

    const defaultLoaderProps = {
        height: "200",
        width: "200",
        color: textChangeAppData,
        ariaLabel: "line-wave",
        wrapperStyle: {},
        wrapperClass: "",
        visible: true,
        firstLineColor: "",
        middleLineColor: "",
        lastLineColor: "",
    }

    const loaderProps ={
        ...defaultLoaderProps, 
        ...props,
    }

    return (
        <Modal>
            <LineWave { ...loaderProps } />
        </Modal>
    )
}

export default Loader;