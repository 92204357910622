import React, { createContext, useReducer } from 'react';

const initialState = {
   activePlan: [],
};

const reducer = (subscriptionState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_PLAN':
      return { ...subscriptionState, activePlan: {...subscriptionState?.activePlan, ...action.payload} };
    default:
      return subscriptionState;
  }
};

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const [subscriptionState, subscriptionDispatch] = useReducer(reducer, initialState);

  return (
    <SubscriptionContext.Provider value={{ subscriptionState, subscriptionDispatch }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
