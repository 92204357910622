import React, { useState } from 'react';
import Stepper from 'react-stepper-horizontal'
import UploadDoc from './UploadDoc';
import { DocumentContext } from '../../store/document';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AddSignPosition from './AddSignPosition';
import AddSignersForDoc from './AddSignersForDoc';
import { useEffect } from 'react';
import { LoaderContext } from '../../store/Loader';
import { toast } from 'react-toastify';
import SignerServices from '../../services/signer.services';
import { textChangeApp } from '../../utils/constants';
import './DocModal.css';
import moment from 'moment';

const signerServices = new SignerServices();

const DocModal = ({ newDoc,selectedDocInfo, selectedColumn, onModalClose }) => {
    const { t } = useTranslation('documents');
    const { t: ts } = useTranslation('apiMessage');
    const [showModal, setShowModal] = useState(true);
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const steps = [
        { title: t('UPLOAD_DOCUMENTS') },
        { title: t('ADD_SIGNERS') },
        { title: t('ADD_POSITION') },
        { title: t('SEND_DOCUMENTS') },
    ];
    const [activeStep, setActiveStep] = useState(0);
    const { fetchDocuments, documentDispatch, getOrgApp, documentState } = useContext(DocumentContext);
    const { loaderDispatch } = useContext(LoaderContext);
    const textChangeAppData = textChangeApp(documentState)
    const [nxtdisable, setnxtdisable] = useState(false)

    useEffect(() => {
        switch (selectedColumn) {
            case 'Title':
                setnxtdisable(false)
                break;
            case 'Add Signers':
                setActiveStep(1);
                break;
            case 'View Signers':
                setActiveStep(1);
                break;
            default:
                setActiveStep(0);
                setnxtdisable(false)
                break;
        }
    }, [selectedColumn,selectedDocInfo])

    useEffect(() => {
        if (!newDoc) setActiveStep(selectedDocInfo?.recipients?.length ? 2 : 0);
    }, [])
    
    const showModalHandler = () => {
        setShowModal(true);
    };

    const hideModalHandler = () => {
        onModalClose();
        setnxtdisable(false)
        setShowModal(false);
    };

    const callNext = (data) => {
        if (data) {
            const { status, recipients, signers } = data;
            const isPositionAdded = documentState?.selectedDocument?.recipients?.some((e) => e?.fields?.length)
            let isSignatureAdded = false
            if (signers?.length && recipients?.length) {
                isSignatureAdded = signers.map(signer => signer.email).every(signerEmail => [...new Set(recipients.filter(ann => ann.type === 'SIGNATURE').map(ann => ann?.recipientEmail))].includes(signerEmail)) ? false : true
            }
            if (recipients?.length === 0) {
                isSignatureAdded = true
            }
            switch (activeStep) {
                case 0:
                    return setnxtdisable(false)
                case 1:
                    return setnxtdisable(data?.recipients?.length ? false : true)
                case 2:
                    return setnxtdisable(status === 'DRAFT' && isSignatureAdded)
                case 3:
                    return setnxtdisable(true);
                default:
                    return
            }
        }
    };

    const handleSendMailToAllRecipient = async (id) => {
        try {
            loaderDispatch({ type: 'SET_LOADING', payload: true });
            const document = documentState?.documents?.find(doc => doc?.id === id);
            const IDs = document?.recipients?.map(signer => {
                return signer?.id
            });
            if (IDs?.length < 1) {
                return toast.warn(ts('SIGNER_NOT_FOUND'));
            }
            const res = await signerServices.sendRecipientEmail({ recipientIds: IDs });
            if (res) {
                toast.success(ts(res?.code || res?.message));
                fetchDocuments();
            }
            hideModalHandler();
        } catch (error) {
            toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`));
        } finally {
            loaderDispatch({ type: 'SET_LOADING', payload: false });
        }
    }

    const columnsToShow = ['email', 'phoneNumber', 'dueDate'];

    window.addEventListener("resize", function() {
        setDeviceWidth(window.innerWidth)
    });
    
    const onPrevClick = () => {
        const val = activeStep - 1;
        setActiveStep(val)
        if (val === 0) {
            setnxtdisable(false)
        }
    }

    return (
        <div className='doc-modal'>
            <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog" style={{ maxWidth: "1400px" }}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-end'>
                                <button type="button" className="close custom-close-button" onClick={hideModalHandler}><span>&times;</span></button>
                            </div>
                            <div>
                                <Stepper
                                    steps={steps}
                                    activeStep={activeStep}
                                    completeColor={textChangeAppData}
                                    activeColor={textChangeAppData}
                                    circleFontSize="1"
                                    titleFontSize={deviceWidth > 410 ? 16 : 12}
                                />
                            </div>
                            <hr />
                            <div style={{ height: '500px', overflowY: 'hidden' }}>
                                {activeStep === 0 &&
                                    <><UploadDoc selectedDocInfo={selectedDocInfo} /></>
                                }
                                {activeStep === 1 &&

                                    <div className='d-flex flex-column'>
                                        <AddSignersForDoc callNext={callNext} selectedDocInfo={selectedDocInfo} />
                                    </div>
                                }
                                {activeStep === 2 &&
                                    <>
                                        <AddSignPosition callNext={callNext} selectedDocInfo={selectedDocInfo} />
                                    </>
                                }
                                {activeStep === 3 &&
                                    <>
                                    <div className='doc-table'>
                                    <table className="table">
                                            <thead>
                                                <tr>
                                                    {columnsToShow.map((column, index) => (
                                                        <th key={index}>{t(`${ column }`)}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {documentState?.selectedDocument?.recipients?.map((item, index) => (
                                                    <tr key={index}>
                                                        {columnsToShow.map((column, columnIndex) => {
                                                            return (
                                                                <td key={columnIndex}>{ column === 'dueDate' ? moment(item[column]).format('DD-MM-YYYY') : item[column]}</td>
                                                            )
                                                        })}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='d-flex justify-content-center m-4'>
                                        <button
                                            disabled={!selectedDocInfo?.recipients?.length || selectedDocInfo?.status === "COMPLETED"}
                                            onClick={() => handleSendMailToAllRecipient(selectedDocInfo?.id)}
                                            style={{ backgroundColor: textChangeAppData }}
                                            className={`${selectedDocInfo?.recipients?.length ? 'send-btn' : 'send-btn-disabled'} px-3 ${selectedDocInfo?.status === "COMPLETED" ? 'send-btn-disabled' : 'send-btn'}`}>
                                            <i className="me-2 bi bi-send"></i>
                                            {selectedDocInfo?.status === "DRAFT" ? t('sendBtn') : t('resSendBtn')}
                                        </button>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" style={{backgroundColor: textChangeAppData }} className='btn btn-primary' onClick={() => onPrevClick()} disabled={activeStep === 0}>
                                {t('PREV')}
                            </button>
                            <button type="button" style={{backgroundColor: textChangeAppData, display: activeStep === 3 ? "none" : "" }} className="btn btn-primary" onClick={() => setActiveStep(activeStep + 1)} disabled={nxtdisable}>
                                {t('NEXT')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Modal */}
        </div>
    );
};

export default DocModal;
