const debounce = (func, delay) => {
    let debounceTimer;
    // eslint-disable-next-line func-names
    return function () {
        const context = this;
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
};

export default debounce;