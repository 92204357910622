import React, { useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Paymentcard from "../../../assets/Paymentcard.png"
import cvvcvc from "../../../assets/cvvcvc.png"
import dateiconn from "../../../assets/dateiconn.png"
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';


function AddNewPaymentModal({ show, handleClose }) {
    const [inputValue, setInputValue] = useState('');
    const [expiryDate, setexpiryDate] = useState('');
    const [errorCardNumber, seterrorCardNumber] = useState({ error: false, message: '' });
    const [errorExpiry, seterrorExpiry] = useState({ error: false, message: '' })
    const [cvc, setcvc] = useState('')
    const [errorcvc, seterrorcvc] = useState({ error: false, message: '' })

    const formattedCreditCardNumber = inputValue.replace(/(\d{4})/g, '$1 ');
    const { meta, getCardNumberProps, getCardImageProps } = usePaymentInputs();

    const handleChange = (e) => {
        const value = e.target.value.replace(/\s/g, '');
        if (meta?.erroredInputs?.cardNumber) {
            seterrorCardNumber({ error: true, message: meta?.erroredInputs?.cardNumber })
        }
        if (value.length <= 19) {
            setInputValue(value);
        }
    };

    function clearNumber(value = "") {
        return value.replace(/\D+/g, "");
    }

    function formatExpirationDate(value) {
        const clearValue = clearNumber(value);

        if (clearValue.length >= 3) {
            return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
        }

        return clearValue;
    }

    const handleChangeExpiryDate = (e) => {
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        const enteredYear = parseInt(e.target.value.slice(3), 10);
        const enteredMonth = parseInt(e.target.value.slice(0, 2), 10);
        if (
            enteredYear < currentYear ||
            (enteredYear === currentYear && enteredMonth < currentMonth) ||
            enteredMonth < 1 ||
            enteredMonth > 12
        ) {
            seterrorExpiry({ error: true, message: 'Enter Valid  Date' })
        } else {
            seterrorExpiry({ error: false, message: '' })
        }
        const expiryinputt = formatExpirationDate(e.target.value)
        setexpiryDate(expiryinputt);
    };

    const handleChangeCardNumber = (e) => {
        console.log(e);
    }

    const handleChangeCVC = (e) => {
        if (!e.target.value.length) {
            seterrorcvc({ error: true, message: 'Enter CVC' })
        }
        setcvc(e.target.value);
    }
    const handlesubmitcard = () => {

    }

    return (
        <div>
            <Modal
                show={show}
                centered={true}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: 'rgb(32, 104, 136)' }}>Add New Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Card Number"
                        className="mb-2 px-2 d-flex"
                    >
                        <Form.Control
                            {...getCardNumberProps({ onChange: handleChange })} value={formattedCreditCardNumber}
                            placeholder="name@example.com" />
                        {!inputValue?.length ?
                            <img src={Paymentcard} className='payment-icons' />
                            :
                            <svg {...getCardImageProps({ images })} className='payment-card-icons' value={inputValue} />
                        }
                    </FloatingLabel>
                    {errorCardNumber?.error ? <p className='mx-2' style={{ color: 'red' }}>{meta.erroredInputs.cardNumber}</p> : ''}
                    <div className='d-flex gap-2'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Expiry Date"
                            className="mb-1 px-2 w-50"
                        >
                            <Form.Control className='w-40' type="tel" pattern="\d\d/\d\d" value={expiryDate} onChange={handleChangeExpiryDate}
                                placeholder="Date" />
                            <img src={dateiconn} className='payment-icons px-1' />
                            {errorExpiry?.error ? <p className='mt-1' style={{ color: 'red' }}>{errorExpiry?.message}</p> : ''}
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="CVC/CVV"
                            className="mb-2 px-2"
                        >
                            <Form.Control placeholder='CVC/CVV' onChange={handleChangeCVC} value={cvc} />
                            <img src={cvvcvc} className='payment-icons px-1' />
                            {errorcvc?.error ? <p className='mt-1' style={{ color: 'red' }}>Enter cvc</p> : ''}
                        </FloatingLabel>
                    </div>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Cardholder Name"
                        className="mb-3 px-2"
                    >
                        <Form.Control type="email" placeholder="name@example.com" />
                    </FloatingLabel>
                    <Form.Check
                        rounded
                        label="Save card"
                        className="mx-2"
                        type='checkbox'
                    />
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <Button variant="secondary" style={{ backgroundColor: '#fff', color: 'rgb(32, 104, 136)', border: '1px solid rgb(32, 104, 136)' }} className='w-40' onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handlesubmitcard} variant="primary" className='w-40' style={{ backgroundColor: 'rgb(32, 104, 136)' }} >Add Card</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddNewPaymentModal