import React, { useContext, useState } from 'react'
import DocumentServices from '../../services/document.services';
import { isUser } from '../../utils/helper';
import './RejectionModal.css'
import { textChangeApp } from '../../utils/constants';
import { DocumentContext } from '../../store/document';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Row, Col, Modal } from 'react-bootstrap';


function RejectionModal({ selectedDocument, guestDocument, onReject, setShowRejectionModal }) {
  const { t } = useTranslation('documentsUpdate');
  const { documentState } = useContext(DocumentContext);
  const textChangeAppData = textChangeApp(documentState)
  const documentServices = new DocumentServices();
  const [rejectText, setRejectText] = useState('');
  const params = useParams();

  const rejectapi = async () => {
    if (!guestDocument) {
      var { id } = selectedDocument.recipients?.find(recipient => recipient.email === isUser().email)
    }
    const payload = {
      documentId: selectedDocument.id,
      recipientId: selectedDocument?.recipient_id ? selectedDocument.recipient_id : id,
      reasonForRejection: rejectText,
    }
    try {
      setRejectText('');
      let rejectionResponse;
      if (guestDocument) {
        rejectionResponse = await documentServices.rejectGuestDocument({ ...payload, token: params?.token || '' });
      } else {
        rejectionResponse = await documentServices.rejectDocument(payload);
      }
      onReject(rejectionResponse?.data);
      setShowRejectionModal(false);
      toast.success(t('rejectionSuccess'))
      return;
    } catch (error) {
      toast.error(t(`${error?.response?.data?.message}`));
      return { status: 'fail' }
    }
  }

  return (<div className="modal modal-container-top">
    <Modal show={true} size="lg">
      <Modal.Header className='rejection-modal-title' style={{ backgroundColor: textChangeAppData }}>
        <Modal.Title className='rejection-modal-title-div'>
          {t('reasonForRejection')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea value={rejectText} placeholder='Type Reason For Rejection' onChange={(e) => { setRejectText(e.target.value) }} className='reject-textarea-box' />
      </Modal.Body>
      <Modal.Footer className='reject-modal-footer'>
        <Row>
          <Col>
            <Button className='reject-modal-buttons' onClick={() => { setRejectText(''); setShowRejectionModal(false) }}>
              {t('CLOSE_BTN')}
            </Button>
          </Col>
          <Col>
            <Button className='reject-modal-buttons-reject' onClick={rejectapi}>
              {t('reject')}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  </div>)
}

export default RejectionModal