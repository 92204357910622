import React, { useContext } from 'react';
import API from '../../../api';
import './SubscriptionPlans.css';
import './SubscriptionPlans.css';
import SubscriptionPlan from './SubscriptionPlan';
import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from "react-i18next";
import SubscriptionPlansHeading from "../../../assets/subscription_plans_heading.png"
import SubscriptionPlansArabic from '../../../assets/Subscription-Plans-ar.png'
import SubscriptionPlansBorder from "../../../assets/subscription_plans_border_img.png"
import { useNavigate } from 'react-router';
import { LoaderContext } from '../../../store/Loader';

const SubscriptionPlans = ({ subscriptionPlans, subscribtionPlanshere, orgData }) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const { i18n: { language } } = useTranslation();
  const { t } = useTranslation('landingPage');
  const { loaderDispatch, loaderState: { isLoading = false } } = useContext(LoaderContext);

  function setSubscriptionLoading(payload) {
    loaderDispatch({ type: 'SET_LOADING', payload });
}
  const handleGuestCheckout = async (id) => {
    try {
      setSubscriptionLoading(true);
      const { data } = await API.post('/payment/create-checkout-session', {
        orgData: { ...orgData, planId: id }
      });
      // check if trail subscription
      if (data?.trail) {
        setSubscriptionLoading(true);
        navigate(`/${orgData?.subDomain}/login`);
        window.location.reload();
        setSubscriptionLoading(false);
        return;
      }
      //new subscription
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionID,
      });
  
      if (error) {
        console.log(error);
      }
    } catch (error) {
      console.log({error});
    } finally{
      setSubscriptionLoading(false);
    }
  };
  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'column', backgroundImage: `url(${SubscriptionPlansBorder})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}
        className='subscription-plans container text-center mb-5'
        ref={subscribtionPlanshere}
      >
        <div className='row' style={{ width: '110%' }}>
          <div class="col-sm-6">
          </div>
          <div class="col-sm d-flex justify-content-end"
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-anchor-placement="top-center"
          >
            {/* <img
              style={{ marginTop: "-2rem" }}
              className="img-fluid req-for-demo-img"
              src={language === 'en' ? RequestForDemoImg : RequestRorDemoArabic}
              alt="Dastkhat logo" /> */}
          </div>
          <div class="col-sm-1">
          </div>
        </div>
        <div className="row d-flex justify-content-center pt-5">
          <img
            className="img-fluid"
            style={{ width: '75%' }}
            src={language === 'en' ? SubscriptionPlansHeading : SubscriptionPlansArabic}
            alt="subscription plans logo" />
        </div>
        <div className='row container-fluid bg-gradient p-5 subscription-plan'>
            {subscriptionPlans?.map((plan, index) => (
              <SubscriptionPlan
                key={plan.id}
                handleGuestCheckout={handleGuestCheckout}
                plan={plan}
              />
            ))}
        </div>
      </div>
   
    </>
  );
};

export default SubscriptionPlans;