import { useContext } from "react";
import AddTemplatePosition from "./AddTemplatePosition";
import { useTranslation } from 'react-i18next';
import { textChangeApp } from "../../utils/constants";
import { DocumentContext } from "../../store/document";

const TemplateUpdateModal = (props) => {
    const { selectedDocInfo, closeUpdateModal } = props;
    const { documentState } = useContext(DocumentContext);
    const textColor = textChangeApp(documentState);

    const { t } = useTranslation('templates');

    const hideModalHandler = () => {
        closeUpdateModal();
    }

    return (
        <div className='doc-modal'>
            <div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
                <div className="modal-dialog" style={{ maxWidth: "1400px" }}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between'>
                                <h5 style={{ fontWeight: '600', color: textColor }}>{t('UPDATE_TEMPLATE')}</h5>
                                <button type="button" className="close custom-close-button" onClick={hideModalHandler}><span>&times;</span></button>
                            </div>
                            <hr />
                            <div style={{ height: '650px', overflowY: 'hidden' }}>
                                <AddTemplatePosition selectedDocInfo={selectedDocInfo} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Modal */}
        </div>
    )
}

export default TemplateUpdateModal;