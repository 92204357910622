import React, { useContext, useEffect, useState } from 'react';
import logoHere from '../../assets/dastkhat-logo.svg';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthServices from '../../services/auth.services';
import { LoaderContext } from '../../store/Loader';
import translateLanguageImage from '../../assets/Icon material-translate.svg'
import LanguageSwitcher from '../../LanguageSwitcher';
import { useTranslation } from 'react-i18next';
// import AuthFooter from '../../components/common/AuthFooter';
import { bgBeforeLogin, textChangeApp } from '../../../src/utils/constants'
import './UserRegSignup.css'
import { DocumentContext } from '../../store/document';
import Loader from '../ui/Loader';
import { generateSignBanner } from '../../utils/helper';

import TawqiiaVactorEn from "../../assets/sign_in_vector_tawqiia_en.png"
import TawqiiaVactorAr from "../../assets/sign_in_vector_tawqiia_ar.png"
import TawqiiaLogoEn from "../../assets/Tawqiia_english.png"
import TawqiiaLogoAr from "../../assets/Tawqiia_arabic.png"

const authServices = new AuthServices();

const UserRegSignup = () => {
    const location = useLocation();
    const orgName = location.pathname.split('/')[1];
    const { getOrgApp, documentState } = useContext(DocumentContext);
    const { token } = useParams();
    const { loaderDispatch } = useContext(LoaderContext);
    const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const [isValidPwd, setIsValidPwd] = useState(false);
    const [isPwdMatched, setIsPwdMatched] = useState(false);

    const [inputValues, setInputValues] = useState({
        firstName: '',
        lastName: ''
    });
    const textChangeAppData = textChangeApp(documentState)

    const [passwordValues, setPasswordValues] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // useEffect(() => { getOrgApp() },
    //     [])

    const { i18n: { language } } = useTranslation();
    const { t } = useTranslation('login');
    const { t: ts } = useTranslation('apiMessage');


    const handleOnChange = (name) => (e) => {
        if (name === "newPassword") {
            setIsValidPwd(passwordRegex.test(e.target.value));
            setIsPwdMatched(e.target.value === passwordValues.confirmPassword);
        }
        if (name === "confirmPassword") {
            setIsPwdMatched(passwordValues.newPassword === e.target.value);
        }
        setInputValues((prevState) => {
            return { ...prevState, [name]: e.target.value };
        });
        setPasswordValues((prevState) => {
            return { ...prevState, [name]: e.target.value };
        });
    };

    const dataBg = bgBeforeLogin(documentState)


    const simpleTextregex = /^[A-Za-z]+$/;
    const isValidFirstName = simpleTextregex.test(inputValues.firstName);
    const isValidLastName = simpleTextregex.test(inputValues.lastName);

    const handleResetPasswordClick = async () => {
        if (!inputValues.firstName) {
            toast.error(t('Enter_First_Name'))
        } else if (!isValidFirstName) {
            toast.error(t('Enter_valid_First_Name'))
        } else if (!inputValues.lastName) {
            toast.error(t('Enter_Last_Name'))
        } else if (!isValidLastName) {
            toast.error(t('Enter_valid_Last_Name'))
        } else if (!passwordValues.newPassword) {
            toast.error(t('Enter_New_password'))
        } else if (!passwordValues.confirmPassword) {
            toast.error(t('Conform_the_password'))
        } else if (passwordValues.newPassword !== passwordValues.confirmPassword) {
            toast.error(t('Passwords_are_not_Equald'))
        }
        else {
            try {
                loaderDispatch({ type: 'SET_LOADING', payload: true });
                setIsLoading(true);
                await authServices.resetUserDetails({
                    "firstName": inputValues.firstName,
                    "lastName": inputValues.lastName,
                    "password": passwordValues.newPassword,
                    "confirmPassword": passwordValues.confirmPassword,
                }, token
                );
                loaderDispatch({ type: 'SET_LOADING', payload: false });
                setIsLoading(false);
                navigate(`/${orgName}/login`);
            } catch (error) {
                loaderDispatch({ type: 'SET_LOADING', payload: false });
                setIsLoading(false);
                toast.error('Token Expired');
            }
        }
    }
    const logo = documentState?.orgAppData;

    return (
        <div className='signup-body' style={{
            backgroundImage: dataBg
        }}>
            <div className='login-page'>
                <div className='form-submitted'>
                    <div className='dastkhat-logo px-5 mx-5'>
                        <Link to={`/${orgName}`} className={`pointer mt-5 ${!logo?.logo && 'visibility-hidden'}`}>
                            <img className="pointer mt-5" src={logo?.logo} alt="#" width={130} height={70} />
                        </Link>
                    </div>
                    <div className='signup-vector-section'>
                        <div>
                            <div className="signup-vector">
                                <img
                                    src={generateSignBanner(textChangeAppData, language)}
                                    alt="#" />
                            </div>
                            <div>
                                <img className='dasthkath-logo-signup' width={100} src={language === 'en' ? TawqiiaLogoEn : TawqiiaLogoAr} alt='' />
                            </div>
                        </div>
                        <div className='signup-form'>
                            <div className='form-here'>
                                <div className='d-flex justify-content-between'>
                                    <h3 className="bold-text"><strong>{t('SignUp')}</strong></h3>
                                    <div className='d-flex justify-content-between align-items-center'><img className='me-2' width={20} src={translateLanguageImage} alt='' />
                                        < LanguageSwitcher />
                                    </div>
                                </div>

                                <div className='mt-2 responsive-form'>
                                    <div className='mb-2'>
                                        <p className='mb-1 ms-2 responsive-fields-names'>{t('firstName')} </p>
                                        <div className='d-flex justify-content-center align-items-center signup-form-input-field'>
                                            <input
                                                type='text'
                                                placeholder={ts("fnamePlaceholder")}
                                                name='firstName'
                                                value={inputValues.firstName}
                                                onChange={handleOnChange('firstName')}
                                            />
                                        </div>
                                    </div>

                                    <div className='mb-2'>
                                        <p className='mb-1 ms-2 responsive-fields-names'>{t('lastName')}</p>
                                        <div className='d-flex justify-content-center align-items-center signup-form-input-field'>
                                            <input
                                                type='text'
                                                name='lastName'
                                                placeholder={ts("lnamePlaceholder")}

                                                value={inputValues.lastName}
                                                onChange={handleOnChange('lastName')}
                                            />
                                        </div>
                                    </div>

                                    <div className='mb-2'>
                                        <p className='mb-1 ms-2 responsive-fields-names'>{t('NewPaswd')}</p>
                                        <div className='d-flex justify-content-center align-items-center signup-form-input-field'>
                                            <input
                                                type='password'
                                                name='newPassword'
                                                placeholder={ts("password")}
                                                value={passwordValues.newPassword}
                                                onChange={handleOnChange('newPassword')}
                                            />
                                        </div>
                                        {
                                            passwordValues.newPassword && <div class={`mt-1 ${isValidPwd ? "valid validation-msg" : "invalid validation-msg"}`}>{`${isValidPwd ? (t("Password_matched_the_requirements")) : (t("PasswordValidation_Instruction_msg"))}`}</div>
                                        }
                                    </div>

                                    <div className='mb-2 cnfpaswrd'>
                                        <p className='mb-1 ms-2 responsive-fields-names'>{t('cnfPswd')}</p>
                                        <div className='d-flex justify-content-center align-items-center signup-form-input-field'>
                                            <input
                                            placeholder={ts("confirmPasswordPlaceholder")}
                                                type='password'
                                                name='confirmPassword'
                                                value={passwordValues.confirmPassword}
                                                onChange={handleOnChange('confirmPassword')}
                                            />
                                        </div>
                                        {
                                            passwordValues.confirmPassword && <div class={`mt-1 ${isPwdMatched ? "valid validation-msg" : "invalid validation-msg"}`}>{`${isPwdMatched ? (t("Password_matched")) : (t("Confirm_Password_should_be_matched_with_new_password"))}`}</div>
                                        }
                                    </div>

                                    <div className="otp-input-container">

                                    </div>
                                    <div className='signup-button'>
                                        <div className='signup-btn mt-2 mx-0'>
                                            <button
                                                style={{
                                                    backgroundColor: textChangeAppData
                                                }}

                                                className='btnsignup me-4'
                                                onClick={() => handleResetPasswordClick()}
                                            >
                                                {t('Submit')} - &rarr;
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <AuthFooter /> */}
            {isLoading && (
                <Loader />
            )}
        </div>
    );
};

export default UserRegSignup;
