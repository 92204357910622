import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { DocumentContext } from './store/document';
import { textChangeApp } from './utils/constants';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isChecked, setIsChecked] = useState(localStorage.getItem('language') === 'ar');
  const { documentState } = useContext(DocumentContext);
  const textChangeAppData = textChangeApp(documentState)
  const storedLocalLanguage = localStorage.getItem('language');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setIsChecked(storedLanguage === 'ar');
      i18n.changeLanguage(storedLanguage);
    }
  }, [storedLocalLanguage]);

  const changeLanguage = (language) => {
    setIsChecked(language === 'ar');
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  const handleToggleChange = (event) => {
    const value = event.target.checked ? 'ar' : 'en';
    changeLanguage(value);
  };

  return (
    <div className="onoffswitch">
      <input
        onChange={handleToggleChange}
        checked={isChecked}
        type="checkbox"
        name="onoffswitch"
        className="onoffswitch-checkbox"
        id="myonoffswitch"
      />
      <label className="onoffswitch-label" htmlFor="myonoffswitch">
        <span className="onoffswitch-inner"></span>
        <span className="onoffswitch-switch" style={{backgroundColor: textChangeAppData}}></span>
      </label>
    </div>
  );
};

export default LanguageSwitcher;

