import React from "react";
import DastkhatLogoEn from '../assets/Dastkhat_logo_English_white.png'

// Sign Banner logos
import dastkhatVectorImg from '../assets/dastkat-vactor-img.png'
import logo_3c486b_ar  from '../assets/sign_logos/3c486b_arabic.svg'
import logo_3c486b_en from '../assets/sign_logos/3c486b.svg'

export const isAuthenticated = () => {
    if (localStorage.getItem('jwtToken')) {
        return JSON.parse(localStorage.getItem('jwtToken'))
    }
    return false
}


export const domainBasedImages = (hostnames, language) => {
    let hostname = process.env.REACT_APP_DOMAIN;
    switch (hostname) {
    case 'TAWQIA':
        return 'tawqia'
            case 'DASTKHAT':
        return DastkhatLogoEn;
      default:
        return '';
    }
  };

export const hideEmail = (email) => {
    const [username, domain] = email.split('@');
    const firstChar = username.charAt(0);
    const hiddenUsername = firstChar + username.slice(1).replace(/./g, '*');
    const formattedEmail = `${hiddenUsername}@${domain}`;
    return formattedEmail;
}

export function hidePhoneNumber(phoneNumber) {
    const visiblePart = phoneNumber.slice(-4);
    const hiddenPart = '**'.repeat(phoneNumber.length - 4);
    return hiddenPart + visiblePart;
}

export const isUser = () => {
    if (localStorage.getItem('user')) {
        return JSON.parse(localStorage.getItem('user'))
    }
    return false
}

export const mapAnnotations = (input) => {
    const result = [];

    input && input.forEach((recipient) => {
        const { id: recipientId, email: recipientEmail, fields, emailSent } = recipient;

        fields.forEach((field) => {
            const { positionX: x, positionY: y, height, width, label, value, page, id: annId, type } = field;
            result.push({
                x: Number(x),
                y: Number(y),
                height: Number(height),
                width: Number(width),
                label,
                value,
                type,
                page: Number(page),
                annId: Number(annId),
                recipientId: Number(recipientId),
                recipientEmail,
                emailSent,
                signingStatus: field?.signingStatus ? field?.signingStatus : "NOT_SIGNED"
            });
        });
    });
    return result;
}

export const base64ToBinary = (dataurl) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    const randomNumber = Math.floor(Math.random() * 100000) + 100
    return new File([u8arr], randomNumber + '.png', { type: mime });
}

export const useOutsideClick = (callback) => {
    const ref = React.useRef();

    React.useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref]);

    return ref;
};

export function getDayDifferenceFromCurrent(date) {
    const inputDate = new Date(date);
    const currentDate = new Date();
    const timeDifference = currentDate - inputDate;
    const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return dayDifference;
}

const colors = ["#7c3e66", "#3c486b", "#159895", "#206888", "#903749", "#C996CC", "#ACBDC5", "#678983", "#060930", "#4f4557", "#4F200D", "#0C134F" ];
const logos = {'en': {}, 'ar': {}}

async function generateImports() {
    await Promise.all(colors.map(async (color) => {
        const importName = color.slice(1);
        logos['en'][color] = await import(`../assets/sign_logos/${importName}.svg`);
        logos['ar'][color] = await import(`../assets/sign_logos/${importName}_arabic.svg`);
    }));
}

generateImports();

export const generateSignBanner = (primary_color, language) => {
    const hostName = process.env.REACT_APP_DOMAIN;
    if (hostName === 'TAWQIA') { 
        if (language && primary_color && logos[language] && logos[language][primary_color]) {
            return logos[language][primary_color]?.default || (language === 'en' ? logo_3c486b_en : logo_3c486b_ar) 
        }
        return language === 'en' ? logo_3c486b_en : logo_3c486b_ar;
    } else {
        return dastkhatVectorImg;
    }
}

export const getActiveImageBackground = (primary_color) => {
    // primary_color = primary_color.toLowerCase();
    /*generated filters From https://codepen.io/sosuke/pen/Pjoqqp , if colours are bit off consider re-adjust From website again*/
    let currentFilter = { filter: 'invert(40%) sepia(5%) saturate(7028%) hue-rotate(155deg) brightness(81%) contrast(82%)' };
    switch (primary_color) {
        case '#3c486b':
            currentFilter = { filter: 'invert(25%) sepia(53%) saturate(367%) hue-rotate(185deg) brightness(99%) contrast(95%)' };
            break;
        case '#7c3e66':
            currentFilter = { filter: 'invert(28%) sepia(19%) saturate(1396%) hue-rotate(268deg) brightness(97%) contrast(90%)' };
            break;
        case '#159895':
            currentFilter = { filter: 'invert(38%) sepia(59%) saturate(2760%) hue-rotate(153deg) brightness(94%) contrast(83%)' };
            break;
        case '#206888':
            currentFilter = { filter: 'invert(40%) sepia(5%) saturate(7028%) hue-rotate(155deg) brightness(81%) contrast(82%)' };
            break;
        case '#903749':
            currentFilter = { filter: 'invert(29%) sepia(14%) saturate(3348%) hue-rotate(303deg) brightness(88%) contrast(87%)' };
            break;
        case '#c996cc':
            currentFilter = { filter: 'invert(58%) sepia(6%) saturate(1532%) hue-rotate(249deg) brightness(107%) contrast(114%)' };
            break;
        case '#acbdc5':
            currentFilter = { filter: 'invert(85%) sepia(12%) saturate(283%) hue-rotate(154deg) brightness(87%) contrast(91%)' };
            break;
        case '#678983':
            currentFilter = { filter: 'invert(54%) sepia(14%) saturate(576%) hue-rotate(120deg) brightness(91%) contrast(83%)' };
            break;
        case '#060930':
            currentFilter = { filter: 'invert(12%) sepia(15%) saturate(2502%) hue-rotate(197deg) brightness(94%) contrast(117%)' };
            break;
        case '#4f4557':
            currentFilter = { filter: 'invert(26%) sepia(17%) saturate(508%) hue-rotate(230deg) brightness(97%) contrast(92%)' };
            break;
        case '#4f200d':
            currentFilter = { filter: 'invert(13%) sepia(12%) saturate(6726%) hue-rotate(348deg) brightness(97%) contrast(95%)' };
            break;
        case '#0c134f':
            currentFilter = { filter: 'invert(7%) sepia(99%) saturate(2340%) hue-rotate(230deg) brightness(100%) contrast(106%)' };
            break;
        default:
            currentFilter = { filter: 'invert(40%) sepia(5%) saturate(7028%) hue-rotate(155deg) brightness(81%) contrast(82%)' };
            break;
    }
    return currentFilter;
}