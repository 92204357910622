import { Card, Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Tab.css";
import upload from './../../../assets/upload.png';
import stampimg from "./../../../assets/stamp.png";

const StampTab = (props) => {
    const { isSavingSignature, stampfileRef, saveStampImage, handleStampSelection } = props
    const { t: tp } = useTranslation('settings');

    const handleSelectStampFromDOM = () => {
        const inputEl = document.getElementById('uploadStamp');
        if (inputEl) {
            inputEl.click();
        }
    }

    return (
        <Card>
            <Card.Title style={{ backgroundColor: '#bcffe1' }}>
                {tp('UPLOAD_STAMP')}:
            </Card.Title>
            <Card.Body style={{ backgroundColor: '#d5fbfb', fontWeight: '600', color: '#8f1657' }}>
                <Row className="d-flex justify-content-between gap-1">
                    <Col sm={6} className="photo-card-body">
                        <input
                            name="uploadStamp"
                            type="file"
                            id="uploadStamp"
                            accept="image/png, image/jpg, image/jpeg"
                            hidden
                            disabled={isSavingSignature}
                            onChange={handleStampSelection}
                            ref={stampfileRef}
                        />
                        <Button className="tab-button" onClick={handleSelectStampFromDOM}>
                            <img src={upload} width={16} height={16} alt='Upload Stamp Icon' />
                            {tp('UPLOAD_STAMP')}
                        </Button>
                    </Col>
                    <Col sm={5} className="photo-card-body">
                        <img src={saveStampImage ? saveStampImage : stampimg} alt='stamp' className='my-uploaded-signature mx-3 p-2' width={140} height={140} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default StampTab