import React, { useContext, useState, useRef } from 'react'
import SignerServices from '../../services/signer.services';
import { toast } from 'react-toastify';
import { SignerContext } from '../../store/signer';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/ui/Spinner';
import { useEffect } from 'react';
import { DocumentContext } from '../../store/document';
import { emailPattern, textChangeApp } from '../../utils/constants';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Modal as bootstrapModal } from 'bootstrap';

const signerServices = new SignerServices();

function Signer({ selectedDocInfo , callNext }) {
    const modalRef = React.useRef();
    const [sinnerLoading, setSignerLoading] = useState(false);
    const [selectedSigner, setSelectedSigner] = useState({});
    const [singersFormData, setSignersFormData] = useState([]);
    const [isInputDisabled, setIsInputDisabled] = useState({});
    const { signerState, signerDispatch } = useContext(SignerContext);
    const { t } = useTranslation('documentsAdd');
    const { t: ts } = useTranslation('apiMessage');
    const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
    const { getOrgApp, documentState, } = useContext(DocumentContext);
    const textChangeAppData = textChangeApp(documentState)

    useEffect(() => {
        const signersObject = signerState?.signers?.reduce((res, obj) => {
            res[obj?.id] = selectedDocInfo.status === 'DRAFT' ? true : false;
            return res;
        }, {});
        setIsInputDisabled(signersObject)
    }, [signerDispatch, signerState])

    useEffect(() => {
        console.log('signerState', signerState);
        setSignersFormData(signerState?.signers)
        callNext({state:'DRAFT',recipients:signerState?.signers})
    }, [signerState?.signers])

    const handleAddSigner = async () => {
        // setSignerLoading(true);
        // try {
        //     const response = await signerServices.createSigner({ documentId: selectedDocument?.id });
        //     signerDispatch({ type: 'ADD_SIGNER', payload: response });
        // } catch (error) {
        // } finally {
        //     setSignerLoading(false);
        // }
    }

    const showModal = () => {
        const modalEle = modalRef.current;
        const bsModal = new bootstrapModal(modalEle);
        bsModal.show();
    };

    const hideModal = () => {
        const modalEle = modalRef.current;
        const bsModal = bootstrapModal.getInstance(modalEle);
        bsModal.hide();
    };

    const handleUpdate = async (updatedData) => {
        try {
            const selectedDueDate = updatedData.dueDate;
            const currentDate = new Date().toISOString().slice(0, 10);

            if (new Date(selectedDueDate).toISOString().slice(0, 10) >= currentDate) {
                const formattedDueDate = moment(selectedDueDate).format("YYYY-MM-DD");

                const signer = await signerServices.updateSigners(updatedData.id, {
                    email: updatedData.email,
                    dueDate: formattedDueDate,
                    phoneNumber: updatedData.phoneNumber
                });

                signerDispatch({ type: 'UPDATE_SIGNERS', payload: { id: updatedData.id, signer } });
                toast.success(t('Signer_Updated'));
            } else {
                toast.error(t('future_due_date'));
            }
        } catch (error) {
            toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`));
        }
    };

    const handleDelete = async(id) => {
        if (!id) {
            return;
        }
        try {
            const res = await signerServices.deleteSigners(id);
            if (res) {
                signerDispatch({ type: 'DELETE_SIGNER', payload: id });
                toast.success(t('Signer_Deleted'));
            }
        } catch (error) {
            toast.error(ts(`${error?.response?.data?.code || error?.response?.data?.message}`));
        }
    };

    const RowForm = ({ id, signersListItem, onUpdate, onDelete }) => {
        const [email, setEmail] = useState(signersListItem.email);
        const [phone, setPhone] = useState(signersListItem.phoneNumber);
        const [date, setDate] = useState(signersListItem.dueDate);
        const [isValidEmail, setIsValidEmail] = useState(true);
        const [isValidPhone, setIsValidPhone] = useState(true);

        const prevRowDataRef = useRef({email: signersListItem.email, phone: signersListItem.phoneNumber, date: signersListItem?.dueDate});
        const currentData = {email, phone, date}

        const handleEmailChange = (e) => {
            const newEmail = e.target.value;

            setEmail(newEmail)
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            const isValidEmail = emailRegex.test(newEmail);
            setIsValidEmail(isValidEmail);
        };

        const handlePhoneChange = (val) => {
            const newPhone = val;
            setPhone(newPhone);
            setIsValidPhone(isValidPhoneNumber(String(newPhone)))
            console.log('isValidEmail', isValidPhoneNumber(String(newPhone)));
        };

        const handleDateChange = (e) => {
            const newDate = e.target.value;
            if (newDate > 10) {
                return;
            }
            setDate(newDate);
        };

        const handleUpdateClick = (val) => {
            prevRowDataRef.current = currentData;
            const updatedData = {
                id,
                email,
                phoneNumber: phone,
                dueDate: date,
            };
            onUpdate(updatedData);
        };

        return (
            <div className='profile-names-container d-flex mt-3 row' key={id}>
                <div className='col-md-3'>
                    <div className='m-2 input-label' style={{
                        color: textChangeAppData
                    }}>{t('email')}<span className='text-danger'>*</span></div>
                    <input className={`name-input ${id}`} type='email' disabled={!isInputDisabled[`${signersListItem?.id}`]}
                        placeholder='Email Id' onChange={handleEmailChange} value={email} />
                    {!isValidEmail && <span style={{ fontSize: "smaller", color: "red", marginLeft: "5px" }}>{t('validEmail')}</span>}
                </div>
                <div className='col-md-4'>
                    <div className='m-2 input-label' style={{
                        color: textChangeAppData
                    }}>{t('phone')}<span className='text-danger'>*</span></div>
                    <PhoneInput
                        disabled={!isInputDisabled[`${signersListItem?.id}`]}
                        className='name-input'
                        placeholder={t('phone_placeholder')}
                        value={phone}
                        rules = {{ validate: isValidPhoneNumber  }}
                        onChange={handlePhoneChange} />
                    {!isValidPhone && <span style={{ fontSize: "smaller", color: "red", marginLeft: "5px" }}>{t('ENTER_VALID_PHONE_NO')}</span>}
                </div>
                <div className='col-md-3'>
                    <div className='m-2 input-label' style={{
                        color: textChangeAppData
                    }}>{t('date')}<span className='text-danger'>*</span></div>
                    <input
                        onKeyDown={(e) => { e.preventDefault() }}
                        type='date' className='name-input' placeholder='Due Date' min={new Date().toISOString().split('T')[0]} onChange={handleDateChange} value={moment(date).format('YYYY-MM-DD')} />
                </div>
                <div className='col-md-2'>
                    <div className='m-2 input-label' style={{
                        color: textChangeAppData
                    }}><pre> </pre></div>
                    <div className='d-flex align-items-center'>
                        <button disabled={JSON.stringify(currentData) === JSON.stringify(prevRowDataRef?.current) || !isValidPhone || !isValidEmail || signersListItem?.email.length === 0 || signersListItem?.phoneNumber.length === 0}
                            onClick={() => {
                                handleUpdateClick(signersListItem)
                            }} className='add-signer-btn' style={{ backgroundColor: textChangeAppData, opacity: (JSON.stringify(currentData) === JSON.stringify(prevRowDataRef?.current) || !isValidPhone || !isValidEmail || signersListItem?.email.length === 0 || signersListItem?.phoneNumber.length === 0) ? 0.5 : 1 }}>
                            <div className='d-flex align-items-center'>
                                <span>{t('save')}</span>
                            </div>
                        </button>
                        <div onClick={() => showModal(signersListItem?.id)} className=''>
                            <i className="bi bi-trash-fill signer-delete-icon"></i>
                        </div>
                        <div
                            className="modal"
                            id="staticBackdrop"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                            ref={modalRef} tabIndex="-1"
                        >
                            <div
                                className="modal-dialog modal-dialog-centered modal-dashboard"
                                data-bs-backdrop="static"
                            >
                                <div className="modal-content">
                                    <div className="modal-heading-dash py-3 rounded-2" style={{ backgroundColor: textChangeAppData }}>
                                        <h4
                                            className="modal-titled d-flex justify-content-center mt-1 text-white"
                                            id="staticBackdropLabel"
                                        >
                                            {t('deleteDoc')}
                                        </h4>
                                    </div>
                                    <div className="mt-5">
                                        <label
                                            className="mx-3 text-center"
                                        >
                                            {t('deleteDescription')}
                                        </label>
                                        <div
                                            className="d-flex justify-content-center"
                                        >
                                            <div className="modal-footer mt-5">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary deleteBtn"
                                                    data-bs-dismiss="modal"
                                                    onClick={hideModal}
                                                >
                                                    {t('cancelBtn')}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary deleteBtn"
                                                    style={{ backgroundColor: textChangeAppData ? textChangeAppData : defaultPrimaryColorValue }}
                                                    onClick={() => {
                                                        hideModal();
                                                        onDelete(signersListItem?.id)
                                                    }}
                                                >
                                                    {t('deleteBtn')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <section className='my-2 signers-div-scroll' style={{overflowY: 'auto', maxHeight: '16rem'}}>
            <div>
                {
                    sinnerLoading ? <Spinner /> :
                        !!singersFormData && singersFormData.map((signersListItem, i) => (
                            <RowForm
                                id={signersListItem.id}
                                signersListItem={signersListItem}
                                onUpdate={handleUpdate}
                                onDelete={handleDelete}
                            />
                        ))}
            </div>
        </section>
    )
}

export default Signer