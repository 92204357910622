import axios from 'axios';
import { isAuthenticated } from '../utils/helper';
import API from '../api';

class SubscriptionServices {

    async getSubscriptions() {
        try {
            const { data } = await API.get(`/subscription`, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async getAddOnsPlans() {
        try {
            const { data } = await API.get(`/subscription/add-ons`, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async payManually() {
        try {
            const { data } = await API.get(`/subscription/pay-manually`, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async downloadSelectedPdfs(payload) {
        try {
            const { data } = await API.post(`/subscription/download-invoices`, payload, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async cancelSubscription(payload) {
        try {
            const { data } = await API.post(`/subscription/cancel`, payload, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async resumeSubscription(payload) {
        try {
            const { data } = await API.post(`/subscription/resume`, payload, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async getCancelledAddOns() {
        try {
            const { data } = await API.get(`/subscription/cancelled-add-ons`, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async createNewSubscription(payload) {
        try {
            const { data } = await API.post(`/subscription/new`, payload, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async upgradeMySubscription(payload) {
        try {
            const { data } = await API.post(`/subscription/upgarde`, payload, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async getSubscriptionPlans() {
        try {
          const data = await API.get(`/payment/plans`);
          return data;
        } catch (error) {
          return Promise.reject(error);
        }
      }

    async isCancelledSubscription() {
        try {
            const { data } = await API.get(`/subscription/is-cancelled`, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }


    async autoDebit(payload) {
        try {
            const { data } = await API.post(`/subscription/auto-debit`, payload, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }


    async addOnsStorage(payload) {
        try {
            const { data } = await API.post(`/subscription/add-ons`, payload, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async deleteAddOnsStorage(payload) {
        try {
            const { data } = await API.post(`/subscription/add-ons-delete`, payload, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` },
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export default SubscriptionServices;
