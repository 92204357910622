import { React, useContext } from 'react'
import './Footer.css';
import FissionLogo from '../../assets/fl.svg';
import FooterImg from '../../assets/powered_by_fission_img.png';
import { textChangeApp } from '../../utils/constants';
import { DocumentContext } from '../../store/document';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t: ts } = useTranslation('apiMessage');
  const {
    documentState,
  } = useContext(DocumentContext);
  const dataBgFooter = textChangeApp(documentState)

  const location = useLocation();
  const isHomepage = location.pathname === ('/');
  const isRegPage = location.pathname === ('/register-company')

  return (
    <>
      {
        isHomepage ? (
          <div className='footer-common d-flex align-items-center justify-content-center' style={{ backgroundColor: isHomepage || isRegPage ? '#337591' : dataBgFooter, marginTop: '-5rem' }}>
            <a className='d-flex align-items-center justify-content-center' target='_blank' href='https://www.fissionlabs.com/' rel="noreferrer">
              <p className='footer_para'>
                <span className='me-2 fs-4'>{ts ("POWERED_BY")}</span>
                <img src={FissionLogo} width={160} height={25} alt="Fission Logo" srcSet="" />
              </p></a>
          </div>
        )
          : (
            <div className='footer-common d-flex align-items-center justify-content-center footerHieght' style={{ backgroundColor: isHomepage || isRegPage ? '#1F5D7A' : dataBgFooter }}>
              <a className='d-flex align-items-center justify-content-center' target='_blank' href='https://www.fissionlabs.com/' rel="noreferrer">
                <p className='footer_para'>
                  <span className='me-2'>{ts ("POWERED_BY")} </span>
                  <img src={FissionLogo} width={160} height={25} alt="Fission Logo" srcSet="" />
                </p></a>
            </div>
          )
      }
    </>
  )
}

export default Footer;