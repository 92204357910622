import API from '../api';
import { isAuthenticated } from '../utils/helper';

class SignerServices {

  async createSigner(payload) {
    try {
      const { data } = await API.post(`/recipient`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getSigner(id) {
    try {
      const { data } = await API.get(`/recipient/${id}`,  {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteSigners(id) {
    try {
      const { data } = await API.delete(`/recipient/${id}`, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateSigners(id, payload) {
    try {
      const { data } = await API.put(`/recipient/update_recipient/${id}`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  //   sendRecipientEmail
  async sendRecipientEmail(payload) {
    try {
      const { data } = await API.post(`/recipient/sendmail_to_recipient`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async authenticate(token, refreshToken) {
    try {
      localStorage.setItem('jwtToken', JSON.stringify(token));
      localStorage.setItem('jwtRefreshToken', JSON.stringify(refreshToken));
    } catch (error) {
      return error;
    }
  }
}

export default SignerServices;