import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { SignerContext } from '../../store/signer';
import SignerServices from '../../services/signer.services';
import { toast } from 'react-toastify';
import { LoaderContext } from '../../store/Loader';
import { useTranslation } from 'react-i18next';
import { DocumentContext } from '../../store/document';
import { emailPattern, phoneNumberRegex, textChangeApp } from '../../utils/constants';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber  } from 'react-phone-number-input';
import { Modal as bootstrapModal } from 'bootstrap';

const signerServices = new SignerServices();

export default function AddDocumentSigner({ documentId, setShowAddSignerDiv, setAddDisabled }) {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const { loaderDispatch } = useContext(LoaderContext);
  const { fetchDocuments } = useContext(DocumentContext);
  const { getOrgApp, documentState, documentDispatch, getMyDocumentsByStatus, getSharedWithDocumentsByStatus } = useContext(DocumentContext);
  const modalRef = React.useRef();
  const [signerData, setSignerData] = useState({
    documentId,
    email: '',
    phoneNumber: '',
    dueDate: ''
  })
  const { documentId: signerDocumentId, email, dueDate, phoneNumber } = signerData;
  const { signerState, signerDispatch } = useContext(SignerContext);
  const { t } = useTranslation('documentsAdd');
  const { t: ts } = useTranslation('apiMessage');
  const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
  const textChangeAppData = textChangeApp(documentState)

  const handleOnChange = name => e => {
    if (name === "email") {
      const enteredEmail = e.target.value;
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      const isValidEmail = emailRegex.test(enteredEmail);
      setIsValidEmail(isValidEmail);
    }
    if (name === "phoneNumber") {
      setIsValidPhone(isValidPhoneNumber(String(e)));
    }
    if(name==='dueDate'){
      if(e.target.value.length>10){
        return;
      }
    }
    setSignerData(prevState => {
      return { ...prevState, [name]: name === 'phoneNumber' ? e : e.target.value }
    });
  }


  const handleAddSigner = async () => {
    switch (true) {
      case !email:
        toast.error(t('ENTER_EMAIL_ID'));
        return;

      case !emailPattern.test(email):
        toast.error(ts('INVALID_EMAIL'));
        return false;

      case !phoneNumber:
        toast.error(t('ENTER_PHONE_NO'));
        return;

      case !isValidPhoneNumber(String(phoneNumber)):
        toast.error(t('ENTER_VALID_PHONE_NO'));
        return;

      case !dueDate:
        toast.error(t('ENTER_DATE'));
        return;

      case !email || !phoneNumber || !signerDocumentId || !dueDate:
        toast.warn(ts('REQUIRED_ALL_FIELDS'));
        return;

      default:
    }

    try {
      loaderDispatch({ type: 'SET_LOADING', payload: true });
      const currentDate = new Date().toISOString().slice(0, 10);
      if (new Date(dueDate).toISOString().slice(0, 10) >= currentDate) {
        const response = await signerServices.createSigner({ documentId, email, dueDate, phoneNumber });
        if (response) {
          toast.success(t('Signers_Added'));
          fetchDocuments();
          setShowAddSignerDiv(false);
        }
        signerDispatch({ type: 'ADD_SIGNER', payload: response });
        setAddDisabled(false);
      } else {
        toast.error(t('future_due_date'));
      }
    } catch (error) {
      toast.error(ts(`${error?.response?.data?.code || error?.response?.data?.message}`));
      setAddDisabled(false);
    }
    finally {
      loaderDispatch({ type: 'SET_LOADING', payload: false });
    }
  }

  const handleDeleteRow = () => {
    setShowAddSignerDiv(false);
    setAddDisabled(false);
    setSignerData(prevState => {
      return { ...prevState, email: '', dueDate: '', phoneNumber: '' }
    })
  }

  const showModal = () => {
    const modalEle = modalRef.current;
    const bsModal = new bootstrapModal(modalEle);
    bsModal.show();
  };

  const hideModal = () => {
    const modalEle = modalRef.current;
    const bsModal = bootstrapModal.getInstance(modalEle);
    bsModal.hide();
  };


  return <div className='profile-names-container d-flex mt-3 row'>
    <div className='col-md-3'>
      <div className='m-2 input-label' style={{
        color: textChangeAppData
      }}>{t('email')}<span className='text-danger'>*</span></div>
      <input onChange={handleOnChange("email")} value={signerData?.email} className={`name-input`} type='email' />
      {!isValidEmail && <span style={{ fontSize: "smaller", color: "red", marginLeft: "5px" }}>{t('validEmail')}</span>}
    </div>
    <div className='col-md-4'>
      <div className='m-2 input-label' style={{
        color: textChangeAppData
      }}>{t('phone')}<span className='text-danger'>*</span></div>
      <PhoneInput
        className='name-input'
        placeholder={t('phone_placeholder')}
        value={signerData?.phoneNumber}
        rules = {{ validate: isValidPhoneNumber  }}
        onChange={handleOnChange("phoneNumber")} />

      {!isValidPhone && <span style={{ fontSize: "smaller", color: "red", marginLeft: "5px" }}>{t('ENTER_VALID_PHONE_NO')}</span>}
    </div>
    <div className='col-md-3'>
      <div className='m-2 input-label' style={{ color: textChangeAppData }}>{t('date')}<span className='text-danger'>*</span></div>
      <input onKeyDown={(e)=>{e.preventDefault()}} type='date' name="date" min={new Date().toISOString().split('T')[0]} className='name-input' onChange={handleOnChange("dueDate")} value={moment(signerData?.dueDate).format('YYYY-MM-DD')} />
    </div>
    <div className='col-md-2'>
      <div className='m-2 input-label'><pre> </pre></div>
      <div className='d-flex align-items-center'>
        <button onClick={() => handleAddSigner()} className='add-signer-btn' style={{ backgroundColor: textChangeAppData }}>
          <div className='d-flex align-items-center'>
            <span>{t('btnAdd')}</span>
          </div>
        </button>
        <div onClick={showModal} className='me-2 cursor-pointer'>
          <i className="bi bi-trash-fill signer-delete-icon"></i>
        </div>
        <div
          className="modal"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          ref={modalRef} tabIndex="-1"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dashboard"
            data-bs-backdrop="static"
          >
            <div className="modal-content">
              <div className="modal-heading-dash py-3 rounded-2" style={{ backgroundColor: textChangeAppData }}>
                <h4
                  className="modal-titled d-flex justify-content-center mt-1 text-white"
                  id="staticBackdropLabel"
                >
                  {t('deleteDoc')}
                </h4>
              </div>
              <div className="mt-5">
                <label
                  className="mx-3 text-center"
                >
                  {t('deleteDescription')}
                </label>
                <div
                  className="d-flex justify-content-center"
                >
                  <div className="modal-footer mt-5">
                    <button
                      type="button"
                      className="btn btn-secondary deleteBtn"
                      data-bs-dismiss="modal"
                      onClick={hideModal}
                    >
                      {t('cancelBtn')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary deleteBtn"
                      style={{ backgroundColor: textChangeAppData ? textChangeAppData : defaultPrimaryColorValue }}
                      onClick={() => {
                        hideModal();
                        handleDeleteRow()
                      }}
                    >
                      {t('deleteBtn')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>;
}
