import React, { Fragment, useEffect, useState, useRef, createRef, useContext } from 'react';
import short from 'short-uuid';
import Draggable from 'react-draggable';
import ContentLoader from "react-content-loader";
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { pdfjsWorker } from 'pdfjs-dist/build/pdf.worker.entry';
import { Button, Col, Dropdown, Form, FormControl, Modal, Row } from 'react-bootstrap';

import signatureIcon from "./../../assets/icons8-signature-64.png";
import photoIcon from "./../../assets/icons8-photo-50.png";
import stampIcon from "./../../assets/icons8-stamp-66.png"

import { EMAIL_ID, COMPANY, TITLE, TEXT, CHECKBOX, DROPDOWN, NAME, textChangeApp } from '../../utils/constants';
import DocumentTemplateServices from '../../services/documentTemplateServices';
import { toast } from 'react-toastify';
import Loader from '../../components/ui/Loader';
import { DocumentContext } from '../../store/document';

const getAnnotationWidth = () => 11;

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const documentTemplateServices = new DocumentTemplateServices();

function TemplateDocumentCreationModal(props) {
    const { templateType, templateData, handleCloseModal, isRejected, image, mySignature, isSigned, isSignedPhoto, PhotoSignedPlaces, AttachPhotoImage, isSignedStamp, StampSignedPlaces, AttachStampImage, activeStep } = props;
    const { documentState } = useContext(DocumentContext);

    const { id: documentId, url } = templateData;

    const { t } = useTranslation('documentsUpdate');
    const { t: tp } = useTranslation('templates');

    const [annotations, setAnnotations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [templateUrl, setTemplateUrl] = useState(url);
    const [documentName, setDocumentName] = useState('')

    const getTemplateData = async () => {
        const document = await documentTemplateServices.getTemplate(templateData?.id, templateType);
        if (document) {
            if (document?.data?.s3Location) {
                setTemplateUrl(document?.data?.s3Location);
            }
            // documentDispatch({ type: 'SET_SELECTED_DOCUMENT', payload: document });
            const responseAnnotations = document.data?.documentTemplateFields || [];
            const formattedAnnotations = responseAnnotations.map(annotation => ({
                ...annotation,
                annId: annotation.id,
                x: Number(annotation?.positionX),
                y: Number(annotation?.positionY),
                value: annotation.type === CHECKBOX ? false : ''
            }))
            setAnnotations(formattedAnnotations);
        }
    };

    const textAnnotationsRef = useRef({});
    let isDragging = false;

    const refPages = useRef([]);
    const pdfDocumentRef = useRef(null);

    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        getTemplateData()
    }, [templateData])

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    }, []);

    const handleAnnotationValueUpdate = (annotation, updateValue) => {
        const updatedAnnotations = annotations.map(currentAnnotation => {
            if (currentAnnotation?.annId === annotation.id) {
                return {
                    ...currentAnnotation,
                    value: updateValue
                }
            }
            return currentAnnotation;
        })
        setAnnotations(updatedAnnotations)
    }

    const handleCreateDocumentFromTemplate = async () => {
        try {
            setIsLoading(true)
            let createDocument = true;
            if (documentName === "") {
                createDocument = false;
                toast.error(tp('DOCUMENT_NAME_SHOULD_NOT_BE_EMPTY'));
            }
            annotations.forEach(currentAnnotation => {
                const currentAnnotationValue = currentAnnotation.value;
                if (currentAnnotation.is_required && ['', undefined].includes(currentAnnotation.value) && currentAnnotation?.fulfiller === "SENDER") {
                    createDocument = false;
                    toast.error(`${tp(currentAnnotation.type)} ${tp('NOT_FILLED')} ${currentAnnotation.page}`);
                } else if (currentAnnotation.value !== '') {
                    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                    switch (currentAnnotation.type) {
                        case EMAIL_ID:
                            if (!emailRegex.test(currentAnnotationValue)) {
                                createDocument = false
                                toast.error(tp('INVALID_EMAIL'));
                            }
                            break;
                        // case 
                        default:
                    }
                }
            })
            if (createDocument) {
                const response = await documentTemplateServices.createDocument(documentId, documentName, annotations.map((ann) => ({ id: ann.id, value: ann.value })));
                if (response.code === 'DOCUMENT_FROM_TEMPLATE_CREATED') {
                    toast.success(tp('DOCUMENT_FROM_TEMPLATE_CREATED'))
                    handleCloseModal();
                }
            }
        } catch (error) {
            toast.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const renderAnnotations = (pageIndex) => {
        return annotations && annotations.map((annotation, index) => {
            if (Number(annotation?.page) === pageIndex) {
                if (annotation?.type === 'SIGNATURE') {
                    return (
                        <Draggable disabled={true} defaultPosition={{ x: annotation.x, y: annotation.y }} bounds="parent" key={index}>
                            <div
                                className={`annotation_${index}`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    maxWidth: `${getAnnotationWidth()}rem`,
                                    backgroundColor: !isRejected ? isSigned ? 'transparent' : '#fff' : null,
                                    opacity: .8,
                                    cursor: 'context-menu',
                                    border: 'none',
                                    userSelect: 'none',
                                    paddingRight: '3px'
                                }}
                            >
                                {
                                    !isRejected ? annotations?.filter(annotation => annotation.type === 'SIGNATURE' && annotation?.signingStatus === "NOT_SIGNED").length && image && mySignature ? (
                                        <img className="recipient-sign-img" src={mySignature} alt='Recipient_Signature' />
                                    ) :
                                        (
                                            <div className="annotation-text d-flex flex-column align-items-center">
                                                <p
                                                    className='StampPhotoSignIconsdoc justify-content-center gap-2'
                                                    style={{ width: '8rem', height: '2.7rem' }}
                                                >
                                                    <img src={signatureIcon} alt='' style={{ height: '1.5rem' }} />
                                                    <div className='' style={{ color: 'black', fontSize: "14px" }}>{t('SIGNATURE_LABEL')}</div>
                                                </p>
                                                <div className='recipient-email'>{Boolean(annotation.recipientEmail) ? annotation.recipientEmail : '< >'}</div>
                                            </div>
                                        ) : null
                                }
                            </div>
                        </Draggable>
                    );
                } else if (annotation?.type === 'IMAGE') {
                    return (
                        <Draggable disabled={true} defaultPosition={{ x: annotation.x, y: annotation.y }} bounds="parent" key={index}>
                            <div
                                className={`annotation_${index}`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    maxWidth: `${getAnnotationWidth()}rem`,
                                    backgroundColor: !isRejected ? isSignedPhoto ? 'transparent' : '#fff' : null,
                                    opacity: .8,
                                    cursor: 'context-menu',
                                    border: 'none',
                                }}
                            >
                                {
                                    !isRejected ? isSignedPhoto ? (
                                        <img className="recipient-stamp-img" src={PhotoSignedPlaces?.some(item => item?.annId === annotation?.annId) && AttachPhotoImage} alt='Recipient_Photo' />
                                    ) :
                                        (
                                            <div className="annotation-text d-flex flex-column align-items-center">
                                                <p className='StampPhotoSignIconsdoc flex-column'>
                                                    <img className='mt-1' src={photoIcon} alt='' style={{ height: '1.7rem' }} />
                                                    <div className='' style={{ color: 'black', fontSize: "14px" }}>{t('PHOTO_LABEL')}</div>
                                                </p>
                                                <div className='recipient-email'>{Boolean(annotation.recipientEmail) ? annotation.recipientEmail : '< >'}</div>
                                            </div>
                                        ) : null
                                }
                            </div>
                        </Draggable>
                    );
                } else if (annotation?.type === 'STAMP') {
                    return (
                        <Draggable disabled={true} defaultPosition={{ x: annotation.x, y: annotation.y }} bounds="parent" key={index}>
                            <div
                                className={`annotation_${index}`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    maxWidth: `${getAnnotationWidth()}rem`,
                                    backgroundColor: !isRejected ? isSigned ? 'transparent' : '#fff' : null,
                                    opacity: .8,
                                    cursor: 'context-menu',
                                    border: 'none',
                                    userSelect: 'none',
                                    paddingRight: '3px'
                                }}
                            >
                                {
                                    !isRejected ? isSignedStamp ? (
                                        <img className="recipient-stamp-img" src={StampSignedPlaces?.some(item => item?.annId === annotation?.annId) && AttachStampImage} alt='Recipient_Stamp' />
                                    ) :
                                        (
                                            <div className="annotation-text d-flex flex-column align-items-center">
                                                <p className='StampPhotoSignIconsdoc flex-column'>
                                                    <img className='mt-1' src={stampIcon} alt='' style={{ height: '1.7rem' }} />
                                                    <div className='' style={{ color: 'black', fontSize: "14px" }}>{t('STAMP_LABEL')}</div>
                                                </p>
                                                <div className="recipient-email">
                                                    {Boolean(annotation.recipientEmail) ? annotation.recipientEmail : '< >'}
                                                </div>
                                            </div>
                                        ) : null
                                }
                            </div>
                        </Draggable>
                    );
                } else if ([TEXT, EMAIL_ID, COMPANY, TITLE, NAME].includes(annotation?.type)) {
                    return (
                        <Draggable disabled={true} defaultPosition={{ x: annotation.x, y: annotation.y }} bounds="parent" key={index}>
                            <div
                                className={`annotation_${index} pdf-textarea`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    backgroundColor: !isRejected ? isSigned ? 'transparent' : '#fff' : null,
                                    opacity: .8,
                                    cursor: 'context-menu',
                                    border: 'none',
                                    userSelect: 'none',
                                    paddingRight: '3px'
                                }}
                            >
                                <textarea
                                    style={{
                                        height: `${annotation.height}px`,
                                        width: `${annotation.width}px`,
                                        resize: 'none',
                                        backgroundColor: annotation?.fulfiller === "SIGNER" ? "grey" : '#80808042',
                                        border: "#0000ffa8 solid",
                                    }}
                                    disabled={annotation?.fulfiller === "SIGNER"}
                                    placeholder={t(`${annotation?.type}_PLACEHOLDER`)}
                                    defaultValue={annotation.value}
                                    onChange={(e) => handleTextFill(e, annotation)}
                                    onBlur={() => handleAnnotationValueUpdate(annotation, textAnnotationsRef.current)}
                                />
                            </div>
                        </Draggable>
                    )
                } else if (annotation.type === CHECKBOX) {
                    return (
                        <Draggable disabled={true} defaultPosition={{ x: annotation.x, y: annotation.y }} bounds="parent" key={index}>
                            <div
                                className={`annotation_${index} pdf-checkbox`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    backgroundColor: !isRejected ? isSigned ? 'transparent' : '#fff' : null,
                                    opacity: .8,
                                    cursor: 'context-menu',
                                    border: 'none',
                                    userSelect: 'none',
                                    paddingRight: '3px',
                                    height: annotation.height + "px",
                                    width: annotation.width + "px",
                                }}
                            >
                                <input type='checkbox' checked={annotation?.value || false} className='w-100 h-100' onChange={(e) => handleAnnotationValueUpdate(annotation, e.target.checked)} />
                            </div>
                        </Draggable>
                    )
                } else if (annotation.type === DROPDOWN) {
                    return (
                        <Draggable disabled={true} defaultPosition={{ x: annotation.x, y: annotation.y }} bounds="parent" key={index}>
                            <div
                                className={`annotation_${index} pdf-checkbox`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    backgroundColor: !isRejected ? isSigned ? 'transparent' : '#fff' : null,
                                    opacity: .8,
                                    cursor: 'context-menu',
                                    border: 'none',
                                    userSelect: 'none',
                                    paddingRight: '3px',
                                    height: annotation.height + "px",
                                    width: annotation.width + "px",
                                }}
                            >
                                <Dropdown onSelect={(selectedValue) => handleAnnotationValueUpdate(annotation, selectedValue)} style={{ height: annotation.height + "px", width: annotation.width + "px" }}>
                                    <Dropdown.Toggle variant="info" id="dropdown-basic">
                                        {annotation.value || tp('SELECT_OPTION')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                            annotation.options.map(option => (
                                                <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Draggable>
                    )
                }
            }
            return null;
        });
    };

    const handleTextFill = (e, selectedAnnotation) => {
        const element = e.target;
        if (element.scrollHeight > 30 && element.clientHeight < element.scrollHeight) {
            element.value = textAnnotationsRef.current || '';
        } else {
            const currentValue = element.value;
            textAnnotationsRef.current = currentValue;
        }
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        refPages.current = new Array(nextNumPages).fill().map((_, index) => refPages.current[index] || createRef());
        setNumPages(nextNumPages);
    }

    const renderPlaceholder = (props) => {
        const length = 30;
        const lines = Array.from({ length }, (_, index) => index);
        return (
            <div className='row'>
                <div className='col-sm-12' style={{ height: '600px' }}>
                    <ContentLoader
                        speed={1}
                        viewBox="0 0 auto auto"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        style={{ width: '100%', height: '100%' }}
                        {...props}
                    >
                        <rect x="10%" y="5" rx="3" ry="3" width="80%" height="6" />
                        {
                            lines.map((item, index) => {
                                return (
                                    <rect x="0" y={(15 * (index + 1)) + (6 * (index + 1))} rx="3" ry="3" width="100%" height="6" />
                                )
                            })
                        }
                        <rect x="10%" y={(15 * (10 + 1)) + (6 * (10 + 1))} rx="3" ry="3" width="80%" height="6" />
                    </ContentLoader>
                </div>
            </div>
        )
    }

    const handleAnnotationClick = (e, index) => {
        if (isDragging || e?.target?.className === 'annotation-text' || e?.target?.className === 'recipient-email' || e?.target?.className !== 'react-pdf__Page__canvas') {
            return null;
        }
    };

    const handleClose = () => {
        handleCloseModal();
    }

    const textChangeAppData = textChangeApp(documentState)


    return (
        <>
            {isLoading ? <Loader /> :
                <Modal show onHide={handleClose} style={{ fontFamily: documentState?.orgAppData?.font || '' }} className='template-document-creation-modal'>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: textChangeAppData }}>{tp('DOCUMENT_CREATION')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ width: "fit-content" }}>
                        <Row className='mb-1'>
                            <Col md={3} className='d-flex align-items-center'>
                                <Form.Label className='p-0 m-0' style={{ color: textChangeAppData }}>{tp('NAME_DOCUMENT')}</Form.Label>
                            </Col>
                            <Col>
                                <FormControl
                                    type="text"
                                    placeholder={tp('NAME_DOCUMENT')}
                                    value={documentName}
                                    onChange={(e) => setDocumentName(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: "65vh", overflow: "scroll" }}>
                            <Document
                                inputRef={pdfDocumentRef}
                                options={options}
                                onLoadSuccess={onDocumentLoadSuccess}
                                renderMode="canvas"
                                file={templateUrl}
                                renderAnnotationLayer={false}
                                // className="document-sign"
                                loading={renderPlaceholder}
                            >
                                {Array.from({ length: numPages }, (_, index) => (
                                    <Fragment key={short.generate().toString()}>
                                        <div
                                            key={short.generate().toString()}
                                            style={{ position: 'relative' }}
                                            className={`mx-auto w-fit page-${index}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleAnnotationClick(e, index)
                                            }}
                                        >
                                            <Page
                                                inputRef={(pa) => (refPages.current[index] = pa)}
                                                className="mt-5"
                                                style={{ margin: 'auto' }}
                                                key={`page_${index + 1}`}
                                                pageNumber={index + 1}
                                                renderAnnotationLayer={false}
                                                renderTextLayer={false}
                                                loading={renderPlaceholder}
                                                onRenderError={() => console.log('error occurred')}
                                            >
                                            </Page>
                                            {renderAnnotations(index)}
                                        </div>
                                    </Fragment>
                                ))}
                            </Document>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {tp('CLOSE')}
                        </Button>
                        <Button style={{ backgroundColor: textChangeAppData }} onClick={handleCreateDocumentFromTemplate}>
                            {tp('CREATE_DOCUMENT')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}

export default TemplateDocumentCreationModal;
