import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { textChangeApp } from '../../../utils/constants';
import { DocumentContext } from '../../../store/document';
import { hidePhoneNumber } from '../../../utils/helper';
import { Button, Container, Row, Col } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import './OTP.css';

function OTP({ validateOTP, phoneNumber }) {
    const { t } = useTranslation("registrationCompany");
    const { documentState } = useContext(DocumentContext)
    const textChangeAppData = textChangeApp(documentState)
    const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");

    const [hasOtp, setHasOtp] = useState('')
    const handleOTPValidation = () => {
        const otp = hasOtp;
        validateOTP(otp)
    }

    const clearOTP = () => {
        setHasOtp('')
    }
    return (
        <Container className="d-flex justify-content-center">
            <div className="w-100">
                <Row className="w-75 m-auto text-center otp-box-alignment">
                    <h5 className="my-4 code-sent-text">
                        <span>{t('sendCodeText')} </span> <br />
                        <span className="mt-2">{phoneNumber && hidePhoneNumber(phoneNumber || '')}</span>
                    </h5>
                </Row>
                <Row className="m-auto d-flex text-center otp-input-container">
                    <OtpInput
                        value={hasOtp}
                        onChange={setHasOtp}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={'input-box'}
                    />
                </Row>
                <Row className="mt-4 d-flex otp-input-container">
                    <Col>
                        <Button
                            variant="primary"
                            className="choose-plan-btn px-4 py-1.3 me-3"
                            onClick={clearOTP}
                            style={{ backgroundColor: textChangeAppData ? textChangeAppData : defaultPrimaryColorValue }}
                        >
                            {t('Clear')}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            variant="primary"
                            className="choose-plan-btn px-4 py-1.3"
                            onClick={handleOTPValidation}
                            style={{ backgroundColor: textChangeAppData ? textChangeAppData : defaultPrimaryColorValue }}
                        >
                            <span>{t('validateBtn')} -</span>
                            <span className="mx-1">&rarr;</span>
                        </Button>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default OTP;