import React, { useState, useContext } from 'react';
import Dropzone from 'react-dropzone';
import fileIcon from '../../assets/file-icon.svg';
import { LoaderContext } from '../../store/Loader';
import { getActiveImageBackground, isAuthenticated } from '../../utils/helper';
import DocumentServices from '../../services/document.services';
import { toast } from 'react-toastify';
import { DocumentContext } from '../../store/document';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { textChangeApp } from '../../utils/constants';
import { useParams } from 'react-router-dom';
import DocModal from '../../pages/Document/DocModal';
import Loader from '../ui/Loader';

const documentServices = new DocumentServices();

export default function FileUpload(props) {
  const { loaderDispatch } = useContext(LoaderContext);
  const { fetchDocuments, documentDispatch, getOrgApp, documentState } = useContext(DocumentContext);
  const { t } = useTranslation('dashboard');
  const { t: ts } = useTranslation('apiMessage');
  const [isLoading, setIsLoading] = useState(false);
  const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
  const [openDocModal, setOpenDocModal] = useState(false)
  const textChangeAppData = textChangeApp(documentState)
  // useEffect(()=>{
  //   getOrgApp()
  // },[])
  const { orgName } = useParams();
  const [selectedDocInfo, setSelectedDocInfo] = useState(null);
  useEffect(()=>{
    setSelectedDocInfo(documentState?.filteredDocuments?.data?.data[0])
  },[documentState, documentState?.filteredDocuments])

  async function getDocumentCount() {
    try {
      loaderDispatch({ type: 'SET_LOADING', payload: true });
      setIsLoading(true);
      const documentCount = await documentServices.getDocumentCount();
      if (documentCount) {
        documentDispatch({
          type: 'SET_DOCUMENT_COUNT',
          payload: documentCount?.countByStatus,
        });
      }
      const sharedDocumentCount =
        await documentServices.getSharedDocumentCount();
      if (sharedDocumentCount) {
        documentDispatch({
          type: 'SET_SHARED_DOCUMENT_COUNT',
          payload: sharedDocumentCount?.countByStatus,
        });
      }
    } catch (error) {
      // toast.error(ts(`${error.response.data.code || error?.response?.data?.message}`));
    } finally {
      loaderDispatch({ type: 'SET_LOADING', payload: false });
      setIsLoading(false);
    }
  }

  const onDrop = async (files) => {
    loaderDispatch({ type: 'SET_LOADING', payload: true });
    setIsLoading(true);
    if (isAuthenticated()) {
      if (files.length > 0) {
        const file = files[0];
        if (file.type === 'application/pdf') {
          if (file.size <= 10 * 1024 * 1024) {
          try {
            const formData = new FormData();
            formData.append('document', file);
            const response = await documentServices.createDocument(formData, orgName);
            if (response.message) {
              toast.success(ts('FILE_UPLOADED'));
              await fetchDocuments();
              getDocumentCount();
              setOpenDocModal(true)
            }
          } catch (error) {
            toast.error(ts(error?.response?.data?.code || 'SOMETHING_WENT_WRONG'));
          }
        }else {
          toast.error(ts('FILE_TOO_LARGE'));
        }
       } else {
          toast.warn(ts('FILE_NOT_PDF'));
        }
      } else {
        toast.warn(ts('FILE_NOT_SELECTED'));
      }
    } else {
      toast.error(ts('LOGIN_UPLOAD_DOC'));
    }
    loaderDispatch({ type: 'SET_LOADING', payload: false });
    setIsLoading(false);
  };

  const onModalClose = () => {
    //Refresh Documents on Modal Close
    fetchDocuments();
    // Update Dashboard cards
    if (props?.getDocumentCount) props.getDocumentCount();
    setSelectedDocInfo(null);
    setOpenDocModal(false);
   }

  return (
    <section style={{ margin: '25px 0px 0px 0px' }}>
      <div className='m-auto'>
        <div className='m-auto w-100'>
          <div
            className='h-50 d-flex align-items-center'>
            {/* <button type='button' onClick={showModal}>PopUp</button> */}
            <Dropzone onDrop={onDrop} multiple={false} accept="application/pdf">
              {({ getRootProps, getInputProps }) => (
                <section className='dropzone-section w-50'>
                  <div
                    style={{ border: `3px dashed ${textChangeAppData}` }}
                    {...getRootProps({
                      className: 'd-flex w-100 docUploadTour flex-column align-items-center justify-content-center dropzone',
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className='h-35'>
                      <div className='file-icon d-flex align-items-center justify-content-center'>
                        <img
                          src={fileIcon}
                          style={getActiveImageBackground(textChangeAppData)}
                          alt='File icon'
                          width={17}
                          height={17}
                        />
                      </div>
                    </div>
                    <div className='h-50 d-flex flex-column align-items-center justify-content-center'>
                      <span
                        style={{
                          fontWeight: 'bold',
                          color: textChangeAppData
                        }}>{t('uploadDocs')}</span>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
      {isLoading && (
        <Loader />
      )}
      {openDocModal && <DocModal newDoc={openDocModal} selectedDocInfo={selectedDocInfo} selectedColumn={'Title'} onModalClose={onModalClose}/>}
    </section>
  );
}
