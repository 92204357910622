import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function RemoveLogoWarningModal(props) {
    const { removeLogo, setShowRemoveLogoWarningModal, selectedColor, getLocalSelectedlanguage } = props
    return (
        <Modal
                show={setShowRemoveLogoWarningModal}
                centered={true}
                onHide={removeLogo}
                backdrop="static"
                keyboard={false}
                >
            <Modal.Body>
                <p style={{color: selectedColor, fontWeight: '600'}}>{getLocalSelectedlanguage('ARE_YOU_SURE_WANT_TO_REMOVE_LOGO')}</p>
                <p style={{color: selectedColor}}>{getLocalSelectedlanguage('IF_YES_REMOVE_AND_SUBMIT')}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setShowRemoveLogoWarningModal(false) }}>{getLocalSelectedlanguage('CLOSE_BTN')}</Button>
                <Button style={{backgroundColor: selectedColor}} onClick={() => { removeLogo() }}>{getLocalSelectedlanguage('REMOVE_BTN')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RemoveLogoWarningModal;