import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Link, useParams, useLocation } from "react-router-dom";
import Navbar from '../../components/Navbar/Navbar';
import './Document.css';
import { navLinks, textChangeApp } from '../../utils/constants';
import userPlusIcon from './../../assets/user-plus-icon.png';
import { DocumentContext } from '../../store/document';
import DocumentServices from '../../services/document.services';
import SignerServices from '../../services/signer.services';
import { SignerContext } from '../../store/signer';
import moment from 'moment/moment';
import Spinner from '../../components/ui/Spinner';
import Signer from '../Signers/Signer';
import AddDocumentSigner from '../Signers/AddDocumentSigner';
import { useTranslation } from 'react-i18next';
import bgImagePinkNav from "../../assets/bgm2-7c3e66.png";
import bgImage from "../../assets/navbar-bg.png";
import bgImageVilotNav from "../../assets/bgm2-3C486B.png";
import bgImageGreenNav from "../../assets/bgm2-159895.png";

const documentServices = new DocumentServices();
const signerServices = new SignerServices();

const Document = () => {
    const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
    const location = useLocation();
    const orgName = location.pathname.split('/')[1];
    const [documentTitle, setDocumentTitle] = useState('');
    const [sinnerLoading, setSignerLoading] = useState(false);
    const { getOrgApp, documentDispatch, documentState } = useContext(DocumentContext);
    const { signerState, signerDispatch } = useContext(SignerContext);
    const { selectedDocument } = documentState
    const [selectedSigner, setSelectedSigner] = useState({});
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [dueDate, setDueDate] = useState();
    const [showAddSignerDiv, setShowAddSignerDiv] = useState(false);
    const [signerData, setSignerData] = useState({
        email: '',
        dueDate: ''
    })
    const [addDisabled, setAddDisabled] = useState(false);
    const { t } = useTranslation('documentsAdd');
    const { t: ts } = useTranslation('apiMessage');
    const params = useParams();
    const textChangeAppData = textChangeApp(documentState)

    const getDocument = async () => {
        setSignerLoading(true);
        const { data: { document, recipients } } = await documentServices.getDocumentSigner(params?.document);
        setDocumentTitle(document?.title)
        signerDispatch({ type: 'SET_SIGNERS', payload: recipients });
        setSignerLoading(false);
    }

    useEffect(() => {
        getDocument();
    }, [params.document])

    const addNewSignerRow = async () => {
        setShowAddSignerDiv(true);
        setAddDisabled(true)
        // setSignerLoading(true);
        // try {
        //     const response = await signerServices.createSigner({ documentId: selectedDocument?.id });
        //     signerDispatch({ type: 'ADD_SIGNER', payload: response });
        // } catch (error) {
        // } finally {
        //     setSignerLoading(false);
        // }
    };
    /*
    const handleDeleteSigner = async (id) => {
        if(!id){
           return;
        }
        try {
            console.log("Signer Clicked")
            const res = await signerServices.deleteSigners(id);
            if (res) {
                signerDispatch({ type: 'DELETE_SIGNER', payload: id });
                toast.success("Signer Deleted");
            }
        } catch (error) {
            toast.success("Signer not deleted");
        }
    };
    */
    async function handleFocusOut(id, email, dueDate) {
        // Logic to handle focus-out event
        try {
            if (isValidEmail && id === signerData?.id && (selectedSigner?.email !== signerData?.email || selectedSigner?.dueDate !== signerData?.dueDate)) {
                const signer = await signerServices.updateSigners(id, { email: signerData?.email || email, dueDate: (signerData?.dueDate || dueDate) && moment(signerData?.dueDate || dueDate).format("YYYY-MM-DD") });
                signerDispatch({ type: 'UPDATE_SIGNERS', payload: { id, signer } });
                toast.success(ts('SIGNER_UPDATED'));
            }
        } catch (error) {
            // console.log({error})
            toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`));
        }
    }

    function handleSignerListClick({ id, email, dueDate }) {
        const signer = signerState?.signers?.find(signer => signer?.id === id);
        setSelectedSigner(signer);
        setSignerData({
            id,
            email: email || '',
            dueDate: dueDate || ''
        });
    }
    /*

    const handleOnChange = name => e => {
        if (name === "email") {
            const enteredEmail = e.target.value;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(enteredEmail);
            setIsValidEmail(isValidEmail);
        }
        setSignerData(prevState => {
            return { ...prevState, [name]: e.target.value }
        })
    }
    console.log({ signerData, signerState })
    const handleSingleEmailSend = async (id) => {
        try {
            const res = await signerServices.sendRecipientEmail({ recipientIds: [id] });
            if (res) {
                toast.success(res.message);
                getDocument();
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    }

    const handleBulkEmailSending = async () => {
        try {
            const IDs = signerState?.signers?.map(signer => {
                return signer?.id
            });
            const res = await signerServices.sendRecipientEmail({ recipientIds: IDs });
            if (res) {
                toast.success(res.message);
            }
            console.log({ handleSingleEmailSend: res });
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    }

    const defaultDueDateChangeHandler = async (e) => {
        try {  
            const res = await documentServices.updateMultipleDueDate({default_dueDate: e.target.value},  params?.document);
            setDueDate(moment(res.default_dueDate).format('YYYY-MM-DD'));
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    }
    */

    return (<div>
        <Navbar pageName={navLinks.documents} />
        <section className="my-4">
            <div className="container">
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <span>{t('DOCUMENTS')} <span className='mx-2'>{'>'}</span> </span>
                        <span> <Link to={`/${orgName}/documents`} style={{ textDecoration: 'none', color: '#484848' }}>{documentTitle}</Link> <span className='mx-2'>{'>'}</span> </span>
                        <span>{t('SIGNERS')}</span>
                    </div>
                    <div className='d-flex'>
                        {/*  <Link to={`/${orgName}/documents/${params?.document}/update`}>
                            <button className='btn btn-primary m-auto save-btn px-4'>
                                <div className='d-flex align-items-center'>
                                    <img  alt='send icon' className='me-2' width={13} height={13} />
                                    <span>{t('edtbtn')}</span>
                                </div>
                            </button>
                        </Link>
                       <button className='btn btn-primary m-auto save-btn px-4 mx-3'>
                            <div className='d-flex align-items-center'>
                                <img src={whiteDownloadIcon} alt='send icon' className='me-2' width={13} height={13} />
                                <span>Download</span>
                            </div>
                        </button>
                        <button onClick={() => handleBulkEmailSending()} className='btn btn-primary m-auto save-btn px-4 bg-warning'>
                            <div className='d-flex align-items-center'>
                                <img src={sendIcon} alt='send icon' className='me-1' width={13} height={13} />
                                <span>Send all</span>
                            </div>
                        </button> */}
                    </div>
                </div>
            </div>
        </section>
        <section className='my-2'>
            <div className="container settings-form-container p-4 px-5">
                <div className='container border-bottom'>
                    <div className='container border-bottom'>
                        <h2 style={{
                            color: textChangeAppData
                        }} >{t('signr')}</h2>
                        <p className='site-secondary-color'>{t('txt')}</p>
                    </div>
                </div>
                <Signer />
                {showAddSignerDiv && <AddDocumentSigner setAddDisabled={setAddDisabled} documentId={params?.document} setShowAddSignerDiv={setShowAddSignerDiv} />}
                <div className='container m-auto mb-4 mt-5'>
                    <button disabled={addDisabled} className='btn btn-primary m-auto save-btn px-4' onClick={addNewSignerRow} style={{
                        backgroundColor: textChangeAppData, borderRadius: '10px'
                    }}>
                        <div className='d-flex align-items-center'>
                            <img src={userPlusIcon} alt='User plus icon' className='me-1' width={15} height={13} />
                            <span>{t('addsign')}</span>
                        </div>
                    </button>
                </div>
            </div>
        </section>
    </div>);
};

export default Document;