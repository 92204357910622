import { useState, useEffect } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { isAuthenticated } from "../../../utils/helper";
import { useTranslation } from 'react-i18next';

const DocumentStatusPieChart = (props) => {
    const { t } = useTranslation('admin');
    const { i18n: { language } } = useTranslation();

    const statusMapping = {
      DRAFT: t('DRAFT'),
      AWAITING: t('AWAITING'),
      COMPLETED: t('COMPLETED'),
      REJECTED: t('REJECTED')
  }

    const { getStatusSeries } = props;

    const [chartData, setChartData] = useState({
      series: [0, 0, 0, 0],
      options: {
        chart: {
          height: 400,
          type: "radialBar",
        },
        plotOptions: {
          // height: 200,
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 360,
            hollow: {
              margin: 5,
              size: "30%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: true,
              },
              value: {
                show: true,
                formatter: function (val, otherData) {
                    const { config } = otherData;
                    const { series } = config;
                    const totalCount = series.reduce((curr, acc) => curr + acc);
                    const statusPercentage = (val * 100) / totalCount;
                    if (totalCount === 0) {
                      return "";
                    }
                    return (Math.round(statusPercentage * 100)) / 100 + "%";
                },
              },
            },
          },
        },
        colors: ["#3498db", "#f39c12", "#2ecc71", "#FF0000"],
        labels: [statusMapping.DRAFT, statusMapping.AWAITING, statusMapping.COMPLETED, statusMapping.REJECTED],
        legend: {
          show: true,
          height: 30,
          floating: true,
          fontSize: "14px",
          position: "bottom",
          // offsetX: -30,
          // offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          onItemClick: {
            toggleDataSeries: false, 
          },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
          {
              breakpoint: 240,
              options: {
                legend: {
                  show: false,
                },
              },
          },
        ],
      },
    })

    const handleSetChartData = async () => {
        const organization = window.location.href?.split('/')[3] || '';
        const { data: response } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/get-reports`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated()}`,
              'Organization': organization,
            },
        });
        if (response?.data?.countStatus) {
            const { data } = response;
            const { countStatus } = data;
            const newSeries = getStatusSeries(countStatus)
            setChartData({
              ...chartData,
              series: newSeries,
            });
        }
      }

    useEffect(() => {
      handleSetChartData();
    }, [])

    useEffect(() => {
      const options = {
        ...chartData.options,
        labels: [statusMapping.DRAFT, statusMapping.AWAITING, statusMapping.COMPLETED, statusMapping.REJECTED],
      }
      setChartData({
        ...chartData,
        options: options
      });
    }, [language])
  
    return (
      <div id="chart chartTour">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="radialBar"
          height={400}
        />
      </div>
    );
}  

export default DocumentStatusPieChart;