import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useLocation} from 'react-router-dom';
import { toast } from 'react-toastify';
import DocumentServices from '../services/document.services';
import { useTranslation } from 'react-i18next';
import OrgServices from '../services/org.services';
import DocumentTemplateServices from '../services/documentTemplateServices';

const initialState = {
  documents: [],
  templates: [],
  templateCategories: [],
  sharedWithMeDocuments: [],
  filteredDocuments: [],
  filteredSharedWithMeDocuments: [],
  selectedDocument: {},
  documentCountObj: {},
  sharedDocumentCountObj: {},
  sharedDocumentAwaitingCount: 0,
  orgAppData: null,
  selectedDashboardFilter: '',
};

const documentServices = new DocumentServices();
const documentTemplateServices = new DocumentTemplateServices();

const reducer = (documentState, action) => {
  switch (action.type) {
    case 'ADD_DOCUMENT':
      return { ...documentState, documents: [...documentState.documents, action.payload] };
    case 'DELETE_DOCUMENT':
      return {
        ...documentState,
        documents: documentState.documents.filter((doc) => doc.id !== action.payload),
      };
    case 'SET_DOCUMENTS':
      return { ...documentState, documents: action.payload };
    case 'SET_FILTERED_DOCUMENTS':
      return { ...documentState, filteredDocuments: action.payload };
    case 'SET_SHARED_WITH_ME_DOCUMENTS':
      return { ...documentState, sharedWithMeDocuments: action.payload };
    case 'SET_SHARED_WITH_ME_FILTERED_DOCUMENTS':
      return { ...documentState, filteredSharedWithMeDocuments: action.payload };
    case 'SET_SELECTED_DOCUMENT':
      return { ...documentState, selectedDocument: action.payload };
    case 'SET_DOCUMENT_COUNT':
      return { ...documentState, documentCountObj: action.payload };
    case 'SET_SHARED_DOCUMENT_COUNT':
      return { ...documentState, sharedDocumentCountObj: action.payload };
    case 'SET_SHARED_DOCUMENT_AWAITING_COUNT':
      return { ...documentState, sharedDocumentAwaitingCount: action.payload };
    case 'SET_ORG_APP_DATA':
      return { ...documentState, orgAppData: action.payload };
    case 'SET_SELECTED_DASHBOARD_FILTER':
      return { ...documentState, selectedDashboardFilter: action.payload,}
    case 'SET_TEMPLATE_CATEGORIES':
      return { ...documentState,  templateCategories: action.payload }
    case 'SET_TEMPLATES':
      return { ...documentState, templates: action.payload }
    default:
      return documentState;
  }
};

export const DocumentContext = createContext();

export const DocumentProvider = ({ children }) => {
  const [documentState, documentDispatch] = useReducer(reducer, initialState);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [orgAppData, setOrgAppData] = useState({})
  const location = useLocation();
  const orgName = location.pathname.split('/')[1];

  const getOrgApp = async () => {
    const orgServices = new OrgServices();
    let data = {};
    try{
      if (orgName) {
        data = await orgServices.organizationAppearance(orgName);
      }
    }catch(err){
      console.log(err);
      if(err?.response?.status === 404){
        data = {message: "organization not found"};
      }
    }
    documentDispatch({ type: 'SET_ORG_APP_DATA', payload: data });
  }

  // Simulating API call to fetch documents
  const fetchDocuments = async () => {
    setDocumentLoading(true);
    try {
      const response = await documentServices.getDocuments();
      const sharedWithMeDocs = await documentServices?.getSharedWithMeDocuments();
      const awaitingDocs = sharedWithMeDocs?.data?.filter(doc => doc?.document?.status === 'AWAITING')?.length;
      documentDispatch({ type: 'SET_FILTERED_DOCUMENTS', payload: { status: '', data: response } });
      documentDispatch({ type: 'SET_SHARED_WITH_ME_FILTERED_DOCUMENTS', payload: { status: '', data: sharedWithMeDocs } });
      documentDispatch({ type: 'SET_SHARED_DOCUMENT_AWAITING_COUNT', payload: awaitingDocs });
      documentDispatch({ type: 'SET_DOCUMENTS', payload: response?.data });
      documentDispatch({ type: 'SET_SHARED_WITH_ME_DOCUMENTS', payload: sharedWithMeDocs?.data });
    } catch (error) {
      // toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`));
    } finally {
      setDocumentLoading(false);
    }
  };

  const fetchTemplateCategories = async () => {
    setDocumentLoading(true);
    try {
      const response = await documentTemplateServices.getCategories();
      documentDispatch({ type: 'SET_TEMPLATE_CATEGORIES', payload: response?.data });
    } catch (error) {
      // toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`));
    } finally {
      setDocumentLoading(false);
    }
  }

  const fetchTemplates = async (category, searchTemplateQuery, page) => {
    setDocumentLoading(true);
    try {
      const response = await documentTemplateServices.getTemplates(category, searchTemplateQuery, page);
      documentDispatch({ type: 'SET_TEMPLATES', payload: response?.data });
      return response
    } catch (error) {
      // toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`));
    } finally {
      setDocumentLoading(false);
    }
  };

  const fetchDocumentDetails = async (documentId) => {
    setDocumentLoading(true);
    try {
      const response = await documentServices.getDocumentDetails(documentId);
      documentDispatch({ type: 'SET_SELECTED_DOCUMENT', payload: response });
    } catch (error) {
      toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`));
    } finally {
      setDocumentLoading(false);
    }
  };
  const { t: ts } = useTranslation('apiMessage');

  const fetchSharedWithMeDocuments = async () => {
    setDocumentLoading(true);
    try {
      const response = await documentServices.getSharedWithMeDocuments();
      documentDispatch({ type: 'SET_SHARED_WITH_ME_FILTERED_DOCUMENTS', payload: response?.data });
      documentDispatch({ type: 'SET_SHARED_WITH_ME_DOCUMENTS', payload: response?.data });
    } catch (error) {
      toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`));
    } finally {
      setDocumentLoading(false);
    }
  };

  const getMyDocumentsByStatus = async (payload) => {
    setDocumentLoading(true);
    try {
      const res = await documentServices.filterMyDocumentsBasedOnStatus(payload);
      documentDispatch({ type: 'SET_FILTERED_DOCUMENTS', payload: { status: payload?.status, data: res } });
    } catch (error) {
      console.log('Something went wrong.');
    } finally {
      setDocumentLoading(false);
    }
  }

  const getSharedWithDocumentsByStatus = async (status) => {
    setDocumentLoading(true);
    try {
      const res = await documentServices.filterSharedWithMeDocumentsBasedOnStatus(status);
      documentDispatch({ type: 'SET_SHARED_WITH_ME_FILTERED_DOCUMENTS', payload: { status: status, data: res } });
    } catch (error) {
      console.log('Something went wrong.');
    } finally {
      setDocumentLoading(false);
    }
  }

  return (
    <DocumentContext.Provider value={{ documentState, getOrgApp, documentDispatch, fetchDocuments, fetchTemplateCategories, fetchTemplates, fetchDocumentDetails, fetchSharedWithMeDocuments, documentLoading, setDocumentLoading, getMyDocumentsByStatus, getSharedWithDocumentsByStatus }}>
      {children}
    </DocumentContext.Provider>
  );
};
