import Dropdown from 'react-bootstrap/Dropdown';
import { DocumentContext } from '../../store/document';
import { useContext } from 'react';
import { textChangeApp } from '../../utils/constants';

function CustomDropdown(props) {
    const { selectedOption, options, optionChangeEventHandler } = props;
    const { documentState } = useContext(DocumentContext);
    const themeColor = textChangeApp(documentState)

    return (
        <Dropdown onSelect={optionChangeEventHandler}>
            <Dropdown.Toggle style={{ background: themeColor }} id="dropdown-basic">
                {selectedOption.text}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    options.map(option => <Dropdown.Item style={{ background: 'none', color: 'black' }} eventKey={option.value}>{option.text}</Dropdown.Item>)
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default CustomDropdown;