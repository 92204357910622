import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import Menwithpen from "./../../assets/Menwithpen.gif";
import MenwithpenEn from "./../../assets/MenwithpenEn.gif";
import DragAndDropAnimation from "./../../assets/DragAndDropAnimation.gif";
import SubscriptionPlans from "../../components/common/SubscriptionPlans/SubscriptionPlans";
import OrgServices from "../../services/org.services";
import { useTranslation } from "react-i18next";
import { DocumentContext } from "../../store/document";
import Men_with_pen_arabic from './../../assets/Men_with_pen_arabic.gif'
import easyToShareArabic from './../../assets/Drag_and_Drop_animation_arabic.gif'
import TawqiiaLogoAr from "../../assets/Tawqiia_white_arabic.png"
import TawqiiaLogoEn from "../../assets/Tawqiia_white_english.png"
import DastkhatLogoEn from "../../assets/Dastkhat_logo_English_white.png"
import SimpleToSignHeadingEn from "../../assets/simple_to_sign_text_heading_en.png"
import SimpleToSignArabicHeading from "../../assets/SimpleToSignArabicHeading.png"
import BgPattern from "../../assets/BG_Pattern.png"
import EasyToShareScreen from "../../assets/easy_to_share_screen.png"
import Drag_DropArabicImg from "../../assets/Drag_DropArabicImg.png"
import EasyToShareTextHeading from "../../assets/easy_to_share_heading.png"
import EasyToShareArabicHeading from "../../assets/EasyToShareArabicHeading.png"
import EasyToShareBg from "../../assets/easy_to_share_text_background.png"
import EasyToCertifyImg from "../../assets/easy_to_certify_img.png"
import EasyToCertifyHeading from "../../assets/easy_to_cetrify_heading.png"
import DocumentProcessHeading from "../../assets/document_process_heading.png"
import DocumentProcessArabicHeading from "../../assets/DocumentProcessArabicHeading.png"
import DocumentProcessHeadingResponsive from "../../assets/document_flow_process_withdot.gif"
import UploadDocumentImg from "../../assets/Upload_Document.png"
import UploadDocArabic from "../../assets/UploadDocArabic.png"
import DraftImg from "../../assets/Draft.png"
import DraftDocArabic from "../../assets/DraftDocArabic.png"
import AwaitingImg from "../../assets/Awaiting.png"
import AwaitingDocArabic from "../../assets/AwaitingDocArabic.png"
import CompletedImg from "../../assets/Completed.png"
import CompletedDocArabic from "../../assets/CompletedDocArabic.png"
import RejectedImg from "../../assets/Rejected.png"
import RejectedDocArabic from "../../assets/RejectedDocArabic.png"
import ArrowImg from "../../assets/right_Arrow.png"
import AboutDastkhat from "../../assets/About_dastkhat.png"
import AboutDastkhatHeading from "../../assets/About_Dastkhat_heading.png"
import AboutUsCompressedImg from "../../assets/AboutUsCompressedImg.png"
import AboutUS_Comp_Img_Arabic from "../../assets/AboutUS_Comp_Img_Arabic.png"
import WantToKnowText from "../../assets/want_to_know.png"
import WantToKnowTextArabic from "../../assets/WantToKnowTextArabic.png"
import DastkhatBlueText from "../../assets/dastkhat_blue_heading.png"
import LanguageSwitcher from "../../LanguageSwitcher";
import translateLanguageImage from '../../assets/Icon material-translate.svg'
import { domainBasedImages } from "../../utils/helper";
import TwaqiaArHeading from "../../assets/TwaqiaArHeading.png"
import TwaqiaEnHeading from "../../assets/TwaqiaEnHeading.png"
import TwaqiaEnHeadingWhite from "../../assets/TwaqiaEnHeadingWhite.png"
import TwaqiArWhiteHeading from "../../assets/TwaqiArWhiteHeading.png"
import Loader from "../../components/ui/Loader";
import SalesByFissionImg from "../../assets/sales_fission_labs_img.png"
import ForMoreDetailsImg from "../../assets/for_more_details_text.png"
import ContactUsArabic from "../../assets/ContactUsArabic.png"
import Carousel from "./carousel";

const Home = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [logoSrc, setLogoSrc] = useState('');
  const { documentState } = useContext(DocumentContext);

  const {
    i18n: { language },
  } = useTranslation();
  const { t } = useTranslation("landingPage");

  const getSubscriptionPlan = async () => {
    const { data } = await orgServices.getSubscriptionPlans();
    setSubscriptionPlans(data);
  };
  const orgServices = new OrgServices();

  useEffect(() => {
    // window.scrollTo(0, 0);
    getSubscriptionPlan();
  }, []);

  const homeRef = useRef(null);
  // const contactRef = useRef(null);
  const aboutRef = useRef(null);
  const subscribtionPlansRef = useRef(null);

  const handleScrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  //Scroll Functionality
  let mybutton = document.querySelector(".quick_scroll");
  //Hostname Functionality
  let hostname = process.env.REACT_APP_DOMAIN;
  useEffect(() => {
    // const language = 'en';
    const src = domainBasedImages(hostname, language);
    // setActiveDomain(src)
    setLogoSrc(src);
    setShouldDisplay(hostname === 'TAWQIA');
  }, [language, hostname]);
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (!mybutton) return
    if (document.body.scrollTop > (window.innerHeight - 300) || document.documentElement.scrollTop > (window.innerHeight - 300)) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const animationElements = useRef([]);

  useEffect(() => {
    animationElements.current = Array.from(
      document.querySelectorAll(".animation-element")
    );

    const checkIfInView = () => {
      const windowHeight = window.innerHeight;
      const windowTopPosition = window.scrollY;
      const windowBottomPosition = windowTopPosition + windowHeight;

      animationElements.current.forEach((element) => {
        const elementHeight = element.offsetHeight;
        const elementTopPosition = element.offsetTop;
        const elementBottomPosition = elementTopPosition + elementHeight;

        if (
          elementBottomPosition >= windowTopPosition &&
          elementTopPosition <= windowBottomPosition
        ) {
          element.classList.add("in-view");
        } else {
          element.classList.remove("in-view");
        }
      });
    };

    // Add event listener for scroll
    window.addEventListener("scroll", checkIfInView);
    checkIfInView();
    return () => {
      window.removeEventListener("scroll", checkIfInView);
    };
  }, []);
  return !documentState?.orgAppData ? (
    <div className="home-body" style={{ fontFamily: "Arial" }} ref={homeRef}>
      <Loader />
    </div>
  ) : (
    <div className="home-body" style={{ fontFamily: "Arial" }} ref={homeRef}>

      <div className="" style={{ backgroundColor: '#337591' }}>
        <nav className="navbar navbar-expand-lg ms-4">
          <div className="container-fluid">
            <a className="navbar-brand" href="/#">
              {logoSrc && (
                <img
                  src={
                    (['ar'].includes(language) && hostname === 'TAWQIA') ?
                      TawqiiaLogoAr :
                      (['en'].includes(language) && hostname === 'TAWQIA') ?
                        TawqiiaLogoEn :
                        DastkhatLogoEn
                  } alt="Dastkhat logo"
                  height={80}
                  width={80}
                />
              )}
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mb-2 mb-lg-0 ms-lg-4 text-center">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="#home"
                    onClick={() => handleScrollTo(homeRef)}
                  >
                    {t("homeBtn")}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="#subscription"
                    onClick={() => handleScrollTo(subscribtionPlansRef)}
                  >
                    {t("plans")}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="#about-us"
                    onClick={() => handleScrollTo(aboutRef)}
                  >
                    {t("aboutBtn")}
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/register-company`}
                    className="btn text-center d-flex text-white reg-btn"
                  >
                    <span className="text-dark" >{t("registerBtn")} - &rarr;</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              {shouldDisplay && (
                <div className="me-4 d-flex justify-content-between align-items-center">
                  <img className='me-2 text-white' width={20} src={translateLanguageImage} alt='' />
                  <LanguageSwitcher />
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
        {/* Carousel Section */}
        <div className="p-3">
        <Carousel hostname={hostname} language={language}/>
        </div>

        {/* simple to sign */}
        <div className=" mb-5">
          <div className="container" style={{ backgroundImage: `url(${BgPattern})`, backgroundSize: 'contain' }}>
            <div className="row mt-3" style={{ transition: 'all 800ms linear' }}>

              <div className="col-md-12 col-xl-5"
                data-aos="fade-right"
                data-aos-offset="50"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="sign-gif d-flex justify-content-center">
                  <img
                    src={
                      (['ar'].includes(language) && hostname === 'TAWQIA') ?
                        Men_with_pen_arabic :
                        (['en'].includes(language) && hostname === 'TAWQIA') ?
                          MenwithpenEn :
                          Menwithpen
                    }
                    alt="Dastkhat logo" />
                </div>
              </div>
              <div className="col-md-12 col-xl-7"
                data-aos="fade-up"
                data-aos-offset="50"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="mt-4 d-flex justify-content-center">
                  {/* {t('simpleToSignHeading')} */}
                  <img
                    className="img-fluid"
                    src={language === 'en' ? SimpleToSignHeadingEn : SimpleToSignArabicHeading}
                    alt="Dastkhat logo" />
                </div>
                <div className="mt-4 ms-1 d-flex justify-content-center"
                  data-aos="fade-up"
                  data-aos-offset="50"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-anchor-placement="top-bottom">
                  {
                    (['ar'].includes(language) && hostname === 'TAWQIA') ?
                      <span className="fs-5" style={{
                        textAlign: `${language === 'ar' ? 'right' : ''}`,
                        direction: `${language === 'ar' ? 'rtl' : ''}`,
                      }}>
                        {t('TAWQIA_SIMPLE_TO_SIGN__TEXT')}
                      </span> :
                      (['en'].includes(language) && hostname === 'TAWQIA') ?
                        <span className="fs-5" style={{
                          textAlign: `${language === 'ar' ? 'right' : ''}`,
                          direction: `${language === 'ar' ? 'rtl' : ''}`,
                        }}>
                          {t('TAWQIA_SIMPLE_TO_SIGN__TEXT')}
                        </span> :
                        <span className="fs-5" style={{
                          textAlign: `${language === 'ar' ? 'right' : ''}`,
                          direction: `${language === 'ar' ? 'rtl' : ''}`,
                        }}>
                          {t('simpleToSignText')}
                        </span>
                  }
                </div>
              </div>

            </div>
          </div>

          {/* easy to share */}

          <div className="container easy-to-share-height mt-5">
            <div className="row device-img-1">
              <img
                className="img-fluid"
                src={language.includes('en') ? DragAndDropAnimation : easyToShareArabic}
                alt="Dastkhat logo" style={{ width: '38rem' }} />
            </div>
            <div className="row">
              <div className="col-md-12 col-xl-5">
                <div className="sign-gif d-flex justify-content-center"
                  data-aos="fade-right"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-anchor-placement="top-bottom"
                >
                  <img
                    className="img-fluid"
                    src={language.includes('en') ? EasyToShareTextHeading : EasyToShareArabicHeading}
                    alt="Dastkhat logo" />
                </div>
              </div>
            </div>
            <div className="row mt-4"
            >
              {/* {t('simpleToSignHeading')} */}

              <div className="img-container">
                <div className="container">
                  {/* <div> */}
                  <div className="imageOne image w-100"
                    data-aos="fade-right"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-anchor-placement="top-bottom">
                    <img
                      className="img-fluid"
                      src={EasyToShareBg}
                      alt="easy to share" />

                  </div>
                  <div className="imageTwo image"
                    data-aos="fade-right"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="top-right" style={{
                      textAlign: `${language === 'ar' ? 'right' : ''}`,
                      direction: `${language === 'ar' ? 'rtl' : ''}`,
                    }}>
                      {hostname === 'TAWQIA' ? t("easyToShareTextNew") : t("easyToShareText")}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div className="device-img-2"
                  data-aos="fade-left"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-anchor-placement="top-bottom"
                >
                  <img
                    className="img-fluid mt-sm-4 ms-sm-2 mt-md-5 ms-md-5"
                    style={{
                      position: 'absolute',
                      right: '0',
                      top: '-10rem',
                      height: '350px',
                      width: '400px',
                    }}
                    src={language.includes('en') ? EasyToShareScreen : Drag_DropArabicImg}
                    alt="easy to share" />
                </div>
              </div>
            </div>
          </div>

          {/* Easy to certify */}

          <div className="container easy-to-certify-container" style={{ backgroundImage: `url(${BgPattern})`, backgroundSize: 'contain' }}>
            <div className="row easy-to-certify-section gap-5">
              <div className="col-xl-5 col-md-12 d-flex justify-content-center"
                data-aos="fade-right"
                data-aos-offset="50"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top-bottom"
              >
                <img src={EasyToCertifyImg}
                  alt="Dastkhat logo" className="easy-to-certify-img" style={{ width: '70vh', height: '58vh', marginTop: '5rem' }} />
              </div>

              <div className="col-xl-6 col-md-12"
                data-aos="fade-up"
                data-aos-offset="50"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="d-flex justify-content-center">
                  {/* {t('simpleToSignHeading')} */}
                  <img
                    className="img-fluid"
                    src={language.includes('en') ? EasyToCertifyHeading : EasyToShareArabicHeading}
                    alt="Dastkhat logo" />
                </div>
                <div className="mt-4 ms-1 d-flex justify-content-center"
                  data-aos="fade-up"
                  data-aos-offset="50"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-anchor-placement="top-bottom">
                  {/* {t('simpleToSignText')} */}
                  {
                    (['ar'].includes(language) && hostname === 'TAWQIA') ?
                      <span className="fs-5" style={{
                        textAlign: `${language === 'ar' ? 'right' : ''}`,
                        direction: `${language === 'ar' ? 'rtl' : ''}`,
                      }}>
                        {t('TAWQIA_EASY_TO_CERTIFY_TEXT1')}
                      </span> :
                      (['en'].includes(language) && hostname === 'TAWQIA') ?
                        <span className="fs-5" style={{
                          textAlign: `${language === 'ar' ? 'right' : ''}`,
                          direction: `${language === 'ar' ? 'rtl' : ''}`,
                        }}>
                          {t('TAWQIA_EASY_TO_CERTIFY_TEXT1')}
                        </span> :
                        <span className="fs-5" style={{
                          textAlign: `${language === 'ar' ? 'right' : ''}`,
                          direction: `${language === 'ar' ? 'rtl' : ''}`,
                        }}>
                          {t('easyToCertifyText1')}
                        </span>
                  }
                </div>
                <div className="mt-4 ms-1 d-flex justify-content-center"
                  data-aos="fade-up"
                  data-aos-offset="50"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-anchor-placement="top-bottom">
                  {/* {t('simpleToSignText')} */}
                  {
                    (['ar'].includes(language) && hostname === 'TAWQIA') ?
                      <span className="fs-5" style={{
                        textAlign: `${language === 'ar' ? 'right' : ''}`,
                        direction: `${language === 'ar' ? 'rtl' : ''}`,
                      }}>
                        {t('TAWQIA_EASY_TO_CERTIFY_TEXT2')}
                      </span> :
                      (['en'].includes(language) && hostname === 'TAWQIA') ?
                        <span className="fs-5" style={{
                          textAlign: `${language === 'ar' ? 'right' : ''}`,
                          direction: `${language === 'ar' ? 'rtl' : ''}`,
                        }}>
                          {t('TAWQIA_EASY_TO_CERTIFY_TEXT2')}
                        </span> :
                        <span className="fs-5" style={{
                          textAlign: `${language === 'ar' ? 'right' : ''}`,
                          direction: `${language === 'ar' ? 'rtl' : ''}`,
                        }}>
                          {t('easyToCertifyText2')}
                        </span>
                  }
                </div>
              </div>
            </div>
          </div>

          {/* Document Process */}

          <div className="container">
            <div className="" style={{ backgroundImage: `url(${BgPattern})`, backgroundSize: 'contain' }}>
              <div className="mt-4 row"
                data-aos="fade-right"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top-bottom"
              >
                <img
                  style={{ width: '43rem' }}
                  className="img-fluid"
                  src={language.includes('en') ? DocumentProcessHeading : DocumentProcessArabicHeading}
                  alt="Dastkhat logo" />
              </div>
              <div className="mt-5 responsive row">
                <div className="col-sm-3">
                  <div className="row"
                    data-aos="fade-right"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="col-sm-10"
                    >
                      <img
                        className="img-fluid"
                        src={language.includes('en') ? UploadDocumentImg : UploadDocArabic}
                        alt="Dastkhat logo" />
                    </div>
                    <div className="col-sm-2 m-auto">
                      <img
                        className="img-fluid"
                        src={ArrowImg}
                        alt="Dastkhat logo" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">

                  <div className="row" data-aos="fade-right"
                    data-aos-delay="200"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-anchor-placement="top-bottom">
                    <div className="col-sm-10">
                      <img
                        className="img-fluid"
                        src={language.includes('en') ? DraftImg : DraftDocArabic}
                        alt="Dastkhat logo" />
                    </div>
                    <div className="col-sm-2 m-auto">
                      <img
                        className="img-fluid"
                        src={ArrowImg}
                        alt="Dastkhat logo" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">

                  <div className="row" data-aos="fade-right"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-anchor-placement="top-bottom">
                    <div className="col-sm-10">
                      <img
                        className="img-fluid"
                        src={language.includes('en') ? AwaitingImg : AwaitingDocArabic}
                        alt="Dastkhat logo" />
                    </div>
                    <div className="col-sm-2 m-auto">
                      <img
                        className="img-fluid"
                        style={{ transform: 'rotate(330deg)' }}
                        src={ArrowImg}
                        alt="Dastkhat logo" />
                      <img
                        className="img-fluid"
                        style={{ transform: 'rotate(30deg)' }}
                        src={ArrowImg}
                        alt="Dastkhat logo" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-3"
                  data-aos="fade-right"
                  data-aos-delay="600"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-anchor-placement="top-bottom"
                >
                  <div className="container"
                  >
                    <img
                      style={{ paddingRight: '25px', paddingBottom: '5px' }}
                      className="img-fluid"
                      src={language.includes('en') ? CompletedImg : CompletedDocArabic}
                      alt="Dastkhat logo" />
                  </div>
                  <div className="container">
                    <img
                      style={{ paddingRight: '25px' }}
                      className="img-fluid"
                      src={language.includes('en') ? RejectedImg : RejectedDocArabic}
                      alt="Dastkhat logo" />
                  </div>
                </div>
              </div>
              <div className="mt-4 d-lg-none">
                <img
                  className="img-fluid"
                  src={language.includes('en') ? DocumentProcessHeadingResponsive : ''}
                  alt="Dastkhat logo" />
              </div>
              <div className="row mt-4"
                data-aos="fade-up"
                data-aos-offset="0"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="d-flex justify-content-center">
                  <span className="d-flex align-items-center">
                    <img
                      style={{ width: '25rem' }}
                      className="img-fluid"
                      src={language.includes('en') ? WantToKnowText : WantToKnowTextArabic}
                      alt="Dastkhat logo" />
                  </span>
                  {/* </div> */}
                  <span className="ps-2">
                    <img
                      style={{ width: '15rem' }}
                      className="img-fluid"
                      src={
                        (['ar'].includes(language) && hostname === 'TAWQIA') ?
                          TwaqiaArHeading :
                          (['en'].includes(language) && hostname === 'TAWQIA') ?
                            TwaqiaEnHeading :
                            DastkhatBlueText
                      }
                      alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>


        </div>
        <span id="boot-icon" onClick={scrollToTop} className="quick_scroll bi bi-arrow-up-circle-fill"></span>

        {/* Subscription Plans - starts */}
        <div className="mt-5">
          <SubscriptionPlans subscribtionPlanshere={subscribtionPlansRef} subscriptionPlans={subscriptionPlans} />
        </div>
        <div className="pt-0" id="contact-us"></div>
        {/* Subscription Plans - ends */}

        {/* About us */}
        <div className="background-pattern-img" style={{ marginBottom: '12rem', backgroundImage: `url(${BgPattern})`, backgroundSize: 'contain' }} ref={aboutRef}>
          <div className="row height-about aboutUs-background-img" style={{
            backgroundImage: `url(${AboutDastkhat})`
          }}>
            <div className="d-flex respnsive-flexDirection mt-5 pt-5">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="mt-1 d-flex justify-content-center"
                  data-aos="fade-right"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-anchor-placement="top-bottom"
                >
                  {/* {t('simpleToSignHeading')} */}
                  <img
                    className="img-fluid w-50-on-mobile"
                    src=
                    {
                      (['ar'].includes(language) && hostname === 'TAWQIA') ?
                        TwaqiArWhiteHeading :
                        (['en'].includes(language) && hostname === 'TAWQIA') ?
                          TwaqiaEnHeadingWhite :
                          AboutDastkhatHeading
                    }
                    alt="Dastkhat logo" />
                </div>
                <div className="mt-3 ms-1 d-flex justify-content-center"
                  data-aos="fade-right"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-anchor-placement="top-bottom"
                >
                  {/* {t('simpleToSignText')} */}
                  {
                    (['ar'].includes(language) && hostname === 'TAWQIA') ?
                      <span className=" aboutUsText" style={{
                        textAlign: `${language === 'ar' ? 'right' : ''}`,
                        direction: `${language === 'ar' ? 'rtl' : ''}`,
                      }}>
                        {t('TAWQIA_ABOUTUS_TEXT')}
                      </span> :
                      (['en'].includes(language) && hostname === 'TAWQIA') ?
                        <span className=" aboutUsText" style={{
                          textAlign: `${language === 'ar' ? 'right' : ''}`,
                          direction: `${language === 'ar' ? 'rtl' : ''}`,
                        }}>
                          {t('TAWQIA_ABOUTUS_TEXT')}
                        </span> :
                        <span className=" aboutUsText " style={{
                          textAlign: `${language === 'ar' ? 'right' : ''}`,
                          direction: `${language === 'ar' ? 'rtl' : ''}`,
                        }}>
                          {t('dastkhatText')}
                        </span>
                  }
                </div>
              </div>
              <div className="col-lg-6 custom-col-md-6"
              >
                <div className="mt-3 d-flex justify-content-center"
                // data-aos="fade-left"
                // data-aos-delay="50"
                // data-aos-duration="1000"
                // data-aos-easing="ease-in-out"
                // data-aos-mirror="true"
                // data-aos-once="false"
                // data-aos-anchor-placement="top-bottom"
                >
                  {/* {t('simpleToSignHeading')} */}
                  <img
                    className=" hide-img-on-mobile aboutus-logo"
                    src={language.includes('en') ? AboutUsCompressedImg : AboutUS_Comp_Img_Arabic}
                    alt="Dastkhat logo" />
                </div>
              </div>
              {/* <div className="col-sm-1"></div> */}
            </div>
            <div className="contanct-us-container">
              <div className='contact-us-images-div container d-flex justify-content-center row mx-auto contact-us-sec' style={{ width: '100%', marginTop: '-5.5rem' }}>
                <div className='col-sm-1 d-none'></div>
                <div
                  className='col-sm-4 d-flex align-items-center mt-3'
                  data-aos="fade-left"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  // // data-aos-mirror="true"
                  // // data-aos-once="false"
                  data-aos-anchor-placement="top-bottom"
                >
                  <img
                    className="img-fluid sales-fission-img"
                    src={language === 'en' ? ForMoreDetailsImg : ContactUsArabic}
                    alt="Dastkhat logo" />
                </div>
                <div
                  className="col-sm-7 d-flex justify-content-start"
                  data-aos="fade-right"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  // // data-aos-mirror="true"
                  // // data-aos-once="false"
                  data-aos-anchor-placement="top-bottom"
                >
                  <img
                    // style={{ marginBottom: "-2rem" }}
                    className="img-fluid sales-fission-img ms-5"
                    src={SalesByFissionImg}
                    alt="Dastkhat logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Home;
