import React from 'react';
import imageError from '../../assets/404 Error with a broken robot-rafiki.svg'
import { Link, useParams } from 'react-router-dom';
import { isAuthenticated, isUser } from '../../utils/helper';

const NotFound = () => {
  const { orgName } = useParams();

  return (<div className="container m-auto">
    <div className='d-flex p-5 mt-5' style={{ gap: '6rem' }}>
      <div className='m-5'> <h1 className='me-5 mt-5 mb-5 d-flex justify-content-center' style={{ fontSize: '3rem', fontWeight: '600', color: '#206888' }}>404 Page not found</h1>
        <Link to={`${isUser() && isAuthenticated() ? `/${orgName}/dashboard` : `/${orgName}/login`}`} className="navbar-brand text-white" style={{ fontSize: '20px', backgroundColor: '#206888', padding: '1.5rem', borderRadius: '17px' }} href="#">
          Back to Home - &rarr;</Link>
      </div>
      <div><img src={imageError} alt="error" width={700} /></div>
    </div>
  </div>);
};

export default NotFound;
