import { React, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import dashboardIcon from './../../assets/dashboard-icon.svg';
import settingsIcon from './../../assets/settings-icon.svg';
import documentIcon from './../../assets/document-icon.svg';
import selectedSettingIcon from './../../assets/selected-settings-icon.png';
import selectedDocumentIcon from './../../assets/selected-document-icon.svg';
import { navLinks } from '../../utils/constants';
import dashboardNonSelectedIcon from './../../assets/dashboard-nonselected.png';
import LanguageSwitcher from '../../LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import { isAuthenticated, isUser } from '../../utils/helper';
import AuthServices from '../../services/auth.services';
import { useContext } from 'react';
import { AuthContext } from '../../store/auth';
import { DocumentContext } from '../../store/document';
import { textChangeApp, navBgAfterLogin } from '../../utils/constants';
import Tour from 'reactour';
import {
  ApperenceTawqiatourConfigAdmin, activeTourConfigAdmin,
  dashboardConfig, dashboardtourConfigAdmin, documentsConfig, documentsSignConfig, sharedWithMeTabConfig,
  subscriptionHistoryTabConfigAdmin, subscriptionTabConfigAdmin, tourConfigAdmin,
  userTourConfigAdmin
} from '../Take_a_Tour/tourEn';
// import { tourConfigAdminAR } from '../Take_a_Tour/tourAr';
const authServices = new AuthServices();

const Navbar = ({ pageName, guest = false, activeModalTab }) => {
  const hostname = process.env.REACT_APP_DOMAIN;

  const { getOrgApp, documentState } = useContext(DocumentContext);
  const dataBgNav = navBgAfterLogin(documentState)
  const { userRole } = useContext(AuthContext);
  const navigate = useNavigate();
  const rolesData = localStorage.getItem('roleData');
  const { t } = useTranslation('dashboard');
  const { t: ts } = useTranslation('apiMessage');
  const { t: td } = useTranslation('documentsUpdate');
  const { i18n: { language } } = useTranslation();
  const textChangeAppData = textChangeApp(documentState)
  const [shouldDisplay, setShouldDisplay] = useState(hostname === 'TAWQIA');
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  // const {users} = props

  // useEffect(() => {
  //   getOrgApp();
  // }, []);

  const { tab } = useParams();
  const location = useLocation();
  const isAdminPage = location.pathname.includes('/admin');
  const logo = documentState?.orgAppData;
  const orgName = location.pathname.split('/')[1];
  const admin = () => {
    navigate(`/${orgName}/admin`);
  };
  const logout = () => {
    authServices.logOut();
    toast.success(ts('LOGOUT_SUCCESS'), {
      toastId: 'success1',
    });
    navigate(`/${orgName}/login`);
  };

  //take a tour.... 
  useEffect(() => {
    if (isTourOpen) {
      document.documentElement.style.overflowX = 'inherit';
      document.documentElement.style.scrollBehavior = 'inherit';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflowX = 'hidden';
      document.documentElement.style.scrollBehavior = 'smooth';
      document.documentElement.style.overflow = 'inherit';
    }
  }, [isTourOpen]);

  const params = useParams();

  const selectedLanguage = localStorage.getItem('language')
  const pathname = window?.location?.pathname;
  const isDashboardPage = location.pathname.includes('dashboard');
  const isSettingPage = location.pathname.includes('setting');
  const isUpdatePage = location.pathname.includes('update');

  useEffect(() => {
    setShouldDisplay(hostname === 'TAWQIA');
  }, [hostname, language]);

  let allHere = [];
  // if (pathname === `/${orgName}/admin` && hostname === 'TAWQIA') {
  //   allHere = selectedLanguage === 'en' ? ApperenceTawqiatourConfigAdmin : ApperenceTawqiatourConfigAdmin;
  // }
  if (pathname === `/${orgName}/admin` && activeModalTab === 0) {
    allHere = selectedLanguage === 'en' ? dashboardtourConfigAdmin : dashboardtourConfigAdmin;
  } else if (pathname === `/${orgName}/admin` && activeModalTab === 1) {
    allHere = selectedLanguage === 'en' ? ApperenceTawqiatourConfigAdmin : ApperenceTawqiatourConfigAdmin;
  } else if (pathname === `/${orgName}/admin` && activeModalTab === 2) {
    allHere = selectedLanguage === 'en' ? userTourConfigAdmin : userTourConfigAdmin;
  } else if (pathname === `/${orgName}/admin` && activeModalTab === 3) {
    allHere = selectedLanguage === 'en' ? activeTourConfigAdmin : activeTourConfigAdmin;
  } else if (pathname === `/${orgName}/admin` && activeModalTab === 4) {
    allHere = selectedLanguage === 'en' ? subscriptionTabConfigAdmin : subscriptionTabConfigAdmin;
  } else if (pathname === `/${orgName}/admin` && activeModalTab === 5) {
    allHere = selectedLanguage === 'en' ? subscriptionHistoryTabConfigAdmin : subscriptionHistoryTabConfigAdmin;
  } else if (pathname === `/${orgName}/dashboard`) {
    allHere = selectedLanguage === 'en' ? dashboardConfig : dashboardConfig;
  } else if (pathname === `/${orgName}/documents`) {
    allHere = selectedLanguage === 'en' ? documentsConfig : documentsConfig;
  } else if (pathname === `/${orgName}/documents/tab/shared-with-me`) {
    allHere = selectedLanguage === 'en' ? sharedWithMeTabConfig : sharedWithMeTabConfig;
  } else if (pathname === `/${orgName}/documents/${params?.document}/sign/${params?.recipientId}`) {
    allHere = selectedLanguage === 'en' ? documentsSignConfig : documentsSignConfig;
  }

  const closeTour = () => {
    setIsTourOpen(false);
    setCurrentStep(0);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const accentColor = '#5cb7b7';

  //take a tour end....
  return (
    <nav className="navbar navbar-expand-lg navbar-expand-md" style={{ backgroundImage: dataBgNav }}>
      <div className="container-fluid w-100 nav-container">
        <Link to={`${isUser() && isAuthenticated() ? `/${orgName}/dashboard` : `/${orgName}`}`} className="navbar-brand text-white" href="#">
          <img src={logo?.logo ? logo?.logo : ''} style={{ "visibility": logo?.logo ? "visible" : "hidden", objectFit: "contain" }} className='nav-logo' alt="dasthakat logo" />
        </Link>
        <div className='d-flex align-items-center d-lg-none d-md-none'>
          {shouldDisplay && <div onClick={(event) => { event.stopPropagation() }} className='language-switcher-nav mx-3'><LanguageSwitcher /></div>}
          {
            !guest ? <div className="dropdown">
              <button className="btn btn-secondary logout-btn" style={{ backgroundColor: textChangeAppData }} data-bs-toggle="dropdown" aria-expanded="false">
                {isUser().firstName ? isUser()?.firstName.slice(0, 1).toUpperCase() : 'R'}
              </button>

              <ul style={{ height: rolesData?.includes("Admin") ? '110px' : '75px' }} className="dropdown-menu dropdown-menu-end dropdown-menu-sm-center">
                {rolesData?.includes("Admin") && <li><span className="dropdown-item" href="#" onClick={admin} style={{ cursor: 'pointer' }}><span><i className="bi bi-person-fill me-1"></i></span>{t('Admin')}</span></li>}
                <li><span className="dropdown-item" href="#" style={{ cursor: 'pointer' }}><span><i className="bi bi-file-earmark-pdf-fill me-1"></i><a target='_blank' style={{ textDecoration: 'none', color: 'black' }} href='https://dastkhat-userguide.s3.eu-north-1.amazonaws.com/Dastkhat-+Document+Owner+User+Guide.pdf' rel='noreferrer'>
                  {t('UserGuide')} </a></span></span></li>
                <li><span className="dropdown-item" href="#" onClick={logout} style={{ cursor: 'pointer' }}><span><i className="bi bi-box-arrow-left me-2 exit-logo"></i></span>{t('logout')}</span></li>
              </ul>
            </div> : null
          }
        </div>
        {
          !guest ?
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            : null
        }
        {
          !guest ?
            <div
              className={`collapse navbar-collapse text-white text-center ${isAdminPage ? 'm-0' : ''}`}
              id="navbarNavAltMarkup"
            >
              <ul className="navbar-nav mx-auto">
                <li
                  style={{
                    backgroundColor: `${pageName === navLinks.dashboard && (documentState?.orgAppData?.primary_color === '#7c3e66' ? '#91587d' :
                      documentState?.orgAppData?.primary_color === '#3c486b' ? '#6b7797' : documentState?.orgAppData?.primary_color === '#159895' ? '#4a9392' : '')}`
                  }}
                  className={`nav-item px-3 ${pageName === navLinks.dashboard && 'active-nav-item'
                    }`}
                >
                  <Link
                    className="nav-link text-white d-flex align-items-center nav-bar-links"
                    to={`/${orgName}/dashboard`}
                  >
                    <img src={pageName === navLinks.dashboard
                      ? dashboardIcon
                      : dashboardNonSelectedIcon
                    }
                      alt="document icon" width={20} height={20} />
                    <span className="mx-1">{t('Dboard')}</span>
                  </Link>
                </li>
                <li
                  style={{
                    backgroundColor: `${pageName === navLinks.documents && (documentState?.orgAppData?.primary_color === '#7c3e66' ? '#91587d' :
                      documentState?.orgAppData?.primary_color === '#3c486b' ? '#6b7797' : documentState?.orgAppData?.primary_color === '#159895' ? '#4a9392' : '')}`
                  }}
                  className={`nav-item px-3 ${pageName === navLinks.documents && 'active-nav-item'
                    }`}
                >
                  <Link
                    className="nav-link text-white d-flex align-items-center nav-bar-links"
                    to={`/${orgName}/documents`}
                  >
                    <img
                      src={
                        pageName === navLinks.documents
                          ? selectedDocumentIcon
                          : documentIcon
                      }
                      alt="document icon"
                    />
                    <span className="mx-1">{t('Docs')}</span>
                  </Link>
                </li>
                <li
                  style={{
                    backgroundColor: `${pageName === navLinks.settings && (documentState?.orgAppData?.primary_color === '#7c3e66' ? '#91587d' :
                      documentState?.orgAppData?.primary_color === '#3c486b' ? '#6b7797' : documentState?.orgAppData?.primary_color === '#159895' ? '#4a9392' : '')}`
                  }}

                  className={`nav-item px-3 ${pageName === navLinks.settings && 'active-nav-item'
                    }`}
                >
                  <Link
                    className="nav-link text-white d-flex align-items-center nav-bar-links"
                    to={`/${orgName}/settings`}
                  >
                    <img
                      src={
                        pageName === navLinks.settings
                          ? selectedSettingIcon
                          : settingsIcon
                      }
                      alt="document icon"
                    />
                    <span className="mx-1">{t('Settings')}</span>
                  </Link>
                </li>
              </ul>
            </div> : null
        }
        <div className='d-flex align-items-center me-4 profile'>
          <div className='tourBtn' style={{position: 'fixed', right: '12rem'}}>
            {isDashboardPage && (
              <button className={`navbar-brand hide-on-mobile flex-fill pointer take-tour`}
                onClick={() => setIsTourOpen(true)}>
                {t('TAKE_A_TOUR')}
              </button>
            )}
          </div>
          {shouldDisplay && <div onClick={(event) => { event.stopPropagation() }} className='language-switcher-nav mx-3'><LanguageSwitcher /></div>}
          {
            !guest ? <div className="dropdown">
              <button className="btn btn-secondary logout-btn" style={{ backgroundColor: textChangeAppData }} data-bs-toggle="dropdown" aria-expanded="false">
                {isUser().firstName ? isUser()?.firstName.slice(0, 1).toUpperCase() : 'R'}
              </button>

              <ul style={{ height: rolesData?.includes("Admin") ? '110px' : '75px' }} className="dropdown-menu dropdown-menu-end dropdown-menu-sm-center">
                {rolesData?.includes("Admin") && <li><span className="dropdown-item" href="#" onClick={admin} style={{ cursor: 'pointer' }}><span><i className="bi bi-person-fill me-1"></i></span>{t('Admin')}</span></li>}
                <li><span className="dropdown-item" href="#" style={{ cursor: 'pointer' }}><span><i className="bi bi-file-earmark-pdf-fill me-1"></i><a target='_blank' style={{ textDecoration: 'none', color: 'black' }} href='https://tawqia-userguide.s3.ap-south-1.amazonaws.com/Tawqia-+Document+Owner+User+Guide.pdf' rel='noreferrer'>
                  {t('UserGuide')} </a></span></span></li>
                <li><span className="dropdown-item" href="#" onClick={logout} style={{ cursor: 'pointer' }}><span><i className="bi bi-box-arrow-left me-2 exit-logo"></i></span>{t('logout')}</span></li>
              </ul>
            </div> : null
          }

          <Tour
            onRequestClose={closeTour}
            steps={allHere}
            isOpen={isTourOpen}
            maskClassName="mask"
            className="helper"
            rounded={5}
            startAt={0}
            accentColor={accentColor}
            showButtons={true}
            prevButton={
              <button
                onClick={() => {
                  if (currentStep > 0) setCurrentStep(currentStep - 1);
                }}
                disabled={currentStep === 0}
                className="btn bold font-size-14px"
              >
                {td("previous")}
              </button>
            }
            nextButton={
              <button
                onClick={() => {
                  if (currentStep < allHere.length - 1) setCurrentStep(currentStep + 1);
                }}
                disabled={currentStep === allHere?.length - 1}
                className="btn bold"
              >
                {td("next")}
              </button>
            }
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
