import { Card, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Tab.css";

import uploadSignatureIcon from './../../../assets/arrow-up-from-bracket-solid.svg';
import fileSignatureIcon from './../../../assets/file-signature-solid.svg';

const SignatureTab = (props) => {
    const { handleShowCreateSignatureModal, fileRef, image, mySignature, isSavingSignature, handleFileSelection } = props
    const { t } = useTranslation('documentsUpdate');

    const handleFileSelectionFromDOM = () => {
        const inputEl = document.getElementById('uploadImage');
        if (inputEl) {
            inputEl.click();
        }
    }

    return (
        <Card style={{ backgroundColor: '#f1d4d8' }}>
            <Card.Title className="card-title" style={{ color: '#8f1657', backgroundColor: '#FFC0CB' }}>{t('SIGN_DOCUMENT')}:</Card.Title>
            <Card.Body className="signature-tab-card-body">
                <Row style={!(image && mySignature) ? { display: 'flex', justifyContent: 'center' } : {}}>
                    <Col sm={6} className='buttons-col'>
                        <input name="uploadImage"
                            type="file"
                            id="uploadImage"
                            accept="image/png, image/jpg, image/jpeg"
                            hidden
                            disabled={isSavingSignature}
                            onChange={handleFileSelection}
                            ref={fileRef}
                        />
                        <Button className="tab-button" onClick={handleFileSelectionFromDOM}>
                            <img src={uploadSignatureIcon} width={16} height={16} alt='upload Signature Icon' />
                            {t('uploadSig')}
                        </Button>
                        <Button className="tab-button" onClick={handleShowCreateSignatureModal}>
                            <img src={fileSignatureIcon} width={16} height={16} alt='create Signature Icon' />
                            {t('CREATE_SIGNATURE')}
                        </Button>
                    </Col>
                    <Col sm={6} className='buttons-col' style={{ display: !(image && mySignature) && 'none' }}>
                        {image && mySignature &&
                            <div className='d-flex mb-1 align-items-center gap-2 flex-column'>
                                <img src={mySignature} alt='Signature photo' className='my-uploaded-signature mx-3 p-2' width={160} height={80} />
                            </div>}
                    </Col>
                </Row>
            </Card.Body>
        </Card >
    )
}

export default SignatureTab