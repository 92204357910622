import './StaticSigner.css'
import Navbar from '../../components/Navbar/Navbar';
import { navLinks, textChangeApp } from '../../utils/constants';
import { useParams } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import DocumentServices from '../../services/document.services';
import moment from 'moment';
import Spinner from '../../components/ui/Spinner';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { DocumentContext } from '../../store/document';
import bgImagePinkNav from "../../assets/bgm2-7c3e66.png";
import bgImage from "../../assets/navbar-bg.png";
import bgImageVilotNav from "../../assets/bgm2-3C486B.png";
import bgImageGreenNav from "../../assets/bgm2-159895.png";


const documentServices = new DocumentServices();

const StaticSigners = () => {
    const location = useLocation();
    const orgName = location.pathname.split('/')[1];
    const params = useParams();
    const [signers, setSigners] = useState([]);
    const [documentName, setDocumentName] = useState([]);
    const [signerLoading, setSignerLoading] = useState(false);
    const { t } = useTranslation('documentsAdd');
    const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
    const { getOrgApp, documentState } = useContext(DocumentContext);
    const textChangeAppData = textChangeApp(documentState)

    const getSigners = async () => {
        setSignerLoading(true);
        const { data: { document, recipients } } = await documentServices.getDocumentSigner(params?.document);
        setDocumentName(document?.title)
        if (recipients.length) {
            setSigners(recipients);
        }
        setSignerLoading(false);
    }
    useEffect(() => {
        getSigners();
    }, [params])

    // useEffect(() => {
    //     getOrgApp();
    // },[]);

    return (
        <>
            <div>
               <Navbar pageName={navLinks.documents} />
            </div>
            <div className='container'>
                <section className="my-4">
                    <div className="container">
                        <div>
                            <span><Link to={`/${orgName}/documents`} style={{ textDecoration: 'none', color: '#484848' }}>{t('DOCUMENTS')}</Link> <span className='mx-2'>{'>'}</span> </span>
                            <span> {documentName}<span className='mx-2'>{'>'}</span> </span>
                            <span>{t('signr')}</span>
                        </div>
                    </div>
                </section>
                <div className='signers-body' style={{
                    color: textChangeAppData }}>
                    <h4 className='signers-heading'>{t('signr')}</h4>
                    <hr />
                    <div className='container'>
                        {signerLoading ? <Spinner /> : <table style={{ minWidth: '50rem' }} className="table">
                            <thead style={{ textAlign: 'start' }}>
                                <tr style={{
                                    color: textChangeAppData }}>
                                    <th scope="col" >{t('email')}</th>
                                    <th scope="col" >{t('phone')}</th>
                                    <th scope="col">{t('date')}</th>
                                </tr>
                            </thead>
                            <tbody className='body-data w-100'>
                                {
                                    signers.length > 0 ?
                                        signers?.map(signer => (
                                            <tr>
                                                <td className='w-30'>{signer?.email}</td>
                                                <td className='w-30'>{signer?.phoneNumber || 'NA'}</td>
                                                <td  className='w-30'>{moment(signer?.dueDate).format('DD-MM-YYYY')}</td>
                                            </tr>
                                        )) : <p>{t('NoSignerFound')}</p>
                                }
                            </tbody>
                        </table>}
                    </div>
                </div>
            </div>
        </>
    )
}
export default StaticSigners;