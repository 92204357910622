// Carousel.js
import React from 'react';
import QrcodeA from './../../assets/1Qrcode.jpg'
import EaseofIntegrationB from './../../assets/2EaseofIntegration.jpg'
import SignUsingTheQR_CodeC from './../../assets/3SignUsingTheQR_Code.jpg'
import FreeTrialD from './../../assets/4FreeTrial.jpg'
import SSOE from './../../assets/5SSO.jpg'
import DasthkatMobileapp from './../../assets/6DasthkatMobileapp.jpg'
import TawqiaQrcodeA from './../../assets/1TawqiaArabicQrCode.jpg'
import TawqiaEaseofIntegrationB from './../../assets/2TawqiaArabicEaseOfIntegration.jpg'
import TawqiaSignUsingTheQR_CodeC from './../../assets/3TawqiaArabicSignUsingTheQR_Code.jpg'
import TawqiaFreeTrialD from './../../assets/4TawqiaArabicFreeTrial.jpg'
import TawqiaSSOE from './../../assets/5TawqiaArabicSSO.jpg'
import TawqiaDasthkatMobileapp from './../../assets/6TawqiaArabicMobileApp.jpg'

const Carousel = ( {hostname, language} ) => {
    const carouselItemsTawqia = [
        { src: TawqiaQrcodeA, alt: 'Tawqia QR Code A' },
        { src: TawqiaEaseofIntegrationB, alt: 'Tawqia Ease of Integration B' },
        { src: TawqiaSignUsingTheQR_CodeC, alt: 'Tawqia Sign Using the QR Code C' },
        { src: TawqiaFreeTrialD, alt: 'Tawqia Free Trial D' },
        { src: TawqiaSSOE, alt: 'Tawqia SSOE' },
        { src: TawqiaDasthkatMobileapp, alt: 'Tawqia Dasthkat Mobile App' },
    ];

    const carouselItemsOther = [
        { src: QrcodeA, alt: 'QR Code A' },
        { src: EaseofIntegrationB, alt: 'Ease of Integration B' },
        { src: SignUsingTheQR_CodeC, alt: 'Sign Using the QR Code C' },
        { src: FreeTrialD, alt: 'Free Trial D' },
        { src: SSOE, alt: 'SSOE' },
        { src: DasthkatMobileapp, alt: 'Dasthkat Mobile App' },
    ];
    const carouselItems = (hostname.includes('TAWQIA') && language === 'en') || language == 'en' ? carouselItemsOther : carouselItemsTawqia;
    return (
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
          <div className="carousel-indicators">
            {carouselItems.map((_, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className={index === 0 ? 'active' : ''}
                aria-current={index === 0 ? 'true' : undefined}
                aria-label={`Slide ${index + 1}`}
              ></button>
            ))}
          </div>
          <div className="carousel-inner">
            {carouselItems.map((item, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img src={item.src} className="d-block w-100" alt={item.alt} />
              </div>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"><span className="carousel-control-prev-icon" aria-hidden="true"></span><span className="visually-hidden">Previous</span></button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next"><span className="carousel-control-next-icon" aria-hidden="true"></span><span className="visually-hidden">Next</span></button>
        </div>
    );
}

export default Carousel;
