import React, { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { toast } from 'react-toastify';
import '../common/CameraModal.css'
import { useTranslation } from 'react-i18next';

function CameraModal({ openCamera, handleTakePhoto, handleCameraError }) {
  const { t } = useTranslation('documentsUpdate');
  const webcamRef = useRef(null);
  const [isCameraOpened, setIsCameraOpened] = useState(false);
  const [error, setError] = useState('');

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      handleTakePhoto(imageSrc);
    } else {
      toast.info(t('PLEASE_WAIT'))
    }
  }, [webcamRef, handleTakePhoto]);

  const handleUserMedia = () => {
    if (!isCameraOpened) {
      setIsCameraOpened(true);
    }
  };

  const cameraError = (error) => {
    setError(String(error))
    handleCameraError(error)
  }

  return (
    <div className="modal show" tabIndex="-1" style={{ display: 'block', background: 'rgba(0,0,0,0.5)' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            {!isCameraOpened && error === '' && <div style={{ textAlign: 'center', fontWeight: '600' }}>{t("Please_Wait_Opening_Camera")}</div>}
            {error !== '' && <div style={{ color:'red', textAlign: 'center', fontWeight: '600' }}>{error}</div>}
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              onUserMediaError={cameraError}
              className="w-100"
              onUserMedia={handleUserMedia}
            />
          </div>
          <div className="modal-footer" style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <button type="button" className="btn btn-secondary" onClick={openCamera}>
              {t("CLOSE_BTN")}
            </button>
            <button type="button" className="btn btn-primary" onClick={capture}>
              {t("CAPTURE")}
            </button>
          </div>
        </div>
      </div>
    </div>)
}

export default CameraModal