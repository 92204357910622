import API from '../api';
import { isAuthenticated } from '../utils/helper';
const baseUrl = `${process.env.REACT_APP_SERVER_URL}`;


class CompRegisServices {
  get path() {
    return `organization/register-tenant`;
  }

  async registerCompany(payload, token) {
    try {
      const { data } = await API.post(
        `organization/register-tenant`,
        payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sentOtp(payload, token) {
    try {
      const { data } = await API.post(
        `organization/send-otp`,
        payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async resendOtp(payload, token) {
    try {
      const { data } = await API.post(
        `organization/send-otp`,
        payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async verifyOtp(payload, token) {
    try {
      const { data } = await API.post(
        `organization/verify-otp`,
        payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

}

export default CompRegisServices;