/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import './DocumentSign.css';
import Navbar from '../../components/Navbar/Navbar';

import { DocumentContext } from '../../store/document';
import { mapAnnotations, base64ToBinary } from '../../utils/helper';
import { navLinks, textChangeApp } from '../../utils/constants';
import { LoaderContext } from '../../store/Loader';
import Stepper from '../../components/ui/Stepper';

import RejectionModal from '../../components/common/RejectionModal';
import CameraModal from '../../components/common/CameraModal';

import zoomInIcon from './../../assets/zoom-in.png';
import zoomOutIcon from './../../assets/zoom-out.png';
import Loader from '../../components/ui/Loader';
import { documentSignSteps } from '../../utils/constants'
import OTPModal from '../../Modals/OtpModal';
import SignatureCreationModal from '../../Modals/SignatureCreationModal';
import { Col, Row, Tab } from 'react-bootstrap';
import SignatureTab from './DocumentTabs/SignatureTab';
import PhotoTab from './DocumentTabs/PhotoTab';
import StampTab from './DocumentTabs/StampTab';
import AddTextTab from './DocumentTabs/AddTextTab';
import DocumentFinalStep from './DocumentTabs/DocumentFinalStep';
import DocumentPageRenderer from './DocumentTabs/DocumentPageRenderer';

let uploadingImageInterval;
const documentSignStepsOrder = [documentSignSteps.SIGNATURE, documentSignSteps.PHOTO, documentSignSteps.STAMP, documentSignSteps.TEXT, documentSignSteps.COMPLETED]

const DocumentGuestSign = () => {
    const { t } = useTranslation('documentsUpdate');
    const { t: ts } = useTranslation('apiMessage');

    const params = useParams();

    const { loaderDispatch } = useContext(LoaderContext);

    const fileRef = useRef(null);
    const textAnnotationsRef = useRef({});
    const sigCanvas = useRef({});
    const stampfileRef = useRef(null);

    const [showOTPModal, setShowOTPModal] = useState(true);
    const [showCreateSignatureModal, setShowCreateSignatureModal] = useState(false);
    const [showRejectionModal, setShowRejectionModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { documentState } = useContext(DocumentContext);
    const [selectedDocument, setSelectedDocument] = useState();
    const [guestUser, setGuestUser] = useState({});
    const [isSigned, setIsSigned] = useState(false);
    const [annotations, setAnnotations] = useState([]);
    const [showDocuments, setShowDocuments] = useState(false);
    const [mySignature, setMySignature] = useState(null);
    const [documentStatus, setDocumentStatus] = useState(false);
    const [documentOverdue, setDocumentOverdue] = useState(false);
    const [recipientPhoneNumber, setRecipientPhoneNumber] = useState('');
    const [rejectedDocument, setRejectedDocument] = useState()
    const [hashData, setHashData] = useState();
    const [isSelectedSignature, setIsSelectedSignature] = useState(false);
    const [isSavingSignature, setIsSavingSignature] = useState(false);
    const [image, setImage] = useState('');
    const [imageFile, setImageFile] = useState([]);
    const [documentSignedStatues, setDocumentSignedStatus] = useState(selectedDocument?.my_status);
    const [PhotoSignedPlaces, setPhotoSignedPlaces] = useState([]);
    const [StampSignedPlaces, setStampSignedPlaces] = useState([]);
    const [isSignedPhoto, setIsSignedPhoto] = useState(false);
    const [isSignedStamp, setIsSignedStamp] = useState(false);
    const [activeStep, setactiveStep] = useState(1)
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    // PHOTO
    const [savePhotoImage, setsavePhotoImage] = useState()
    const [saveStampImage, setsaveStampImage] = useState()
    const [AttachPhotoImage, setAttachPhotoImage] = useState()
    const [AttachStampImage, setAttachStampImage] = useState()
    const [signatureInputValue, setSignatureInputValue] = useState('')
    const [FinishdisableBtn, setFinishdisableBtn] = useState(true)

    const { title, url } = selectedDocument || {};
    const { document: guestDocument, token } = params || {};

    useEffect(() => {
        annotations.filter(annotation => annotation.type === documentSignSteps.TEXT).forEach(annotation => {
            if (!textAnnotationsRef.current[annotation.annId]) {
                textAnnotationsRef.current[annotation.annId] = annotation.value;
            }
        })
    }, [annotations])

    useEffect(() => {
        const availableAnnotations = annotations.map(ann => ann?.type);
        let currentDisableStatus = true
        if (documentStatus) {
            currentDisableStatus = false
        }

        if (availableAnnotations.includes('SIGNATURE')) {
            currentDisableStatus = imageFile ? false : true
        }

        if (availableAnnotations.includes('STAMP')) {
            currentDisableStatus = AttachStampImage ? false : true
        }

        if (availableAnnotations.includes('PHOTO')) {
            currentDisableStatus = AttachPhotoImage ? false : true
        }
        setFinishdisableBtn(currentDisableStatus)
    }, [documentStatus, annotations, imageFile, AttachStampImage, AttachPhotoImage])

    useEffect(() => {
        getSigner();
        getSignature();
        setShowOTPModal(true);
    }, [params?.document])

    useEffect(() => {
        setDocumentSignedStatus(selectedDocument?.my_status);
    }, [selectedDocument])

    const getSigner = async () => {
        try {
            const { data: { user } } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/guest_user/${token}`);
            setGuestUser(user);
            const { data: { recipients } } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/document/guest-document-signers/${params.document}/${user.email}`);
            setRecipientPhoneNumber(recipients[0]?.phoneNumber)
            const { data: { data: { hash } } } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/document/document-otp/${recipients[0]?.id}`);
            setHashData(hash);
        } catch (error) {
            console.log(error)
        }
    }

    const textChangeAppData = textChangeApp(documentState)

    const getDocument = async (payload) => {
        try {
            const { data: documentData } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/document/guest-user-document/${guestDocument}/${token}`, payload);
            setSelectedDocument(documentData);
            const newResult = documentData?.recipients.filter(item => item.email === guestUser?.email)
            const annotationsList = mapAnnotations(newResult);
            setAnnotations(annotationsList);

            const recipient = documentData?.recipients?.find(recipient => recipient.email === guestUser.email);
            if (recipient.overallSigningStatus === 'COMPLETED') {
                setDocumentStatus(true)
            } else if (recipient.overallSigningStatus === 'PENDING' && new Date(recipient.dueDate) < new Date(new Date().setUTCHours(0, 0, 0, 0))) {
                setDocumentStatus(true);
                setDocumentOverdue(true);
            }
            return { status: 'success' }
        } catch (error) {
            toast.error(ts(error?.response?.data?.code || 'SOMETHING_WENT_WRONG'));
            return { status: 'fail' }
        }
    }

    const isRecipientRejected = (recipient) => (
        recipient?.filter(recipient => recipient?.id === selectedDocument?.recipient_id)?.[0]?.isRejected
    )

    const isRejected = rejectedDocument?.recipients?.length > 0 || selectedDocument?.recipients?.length > 0
        ? isRecipientRejected(rejectedDocument?.recipients) || isRecipientRejected(selectedDocument?.recipients)
        : false;

    const zoomInClicked = () => {
        //todo
        console.log('zoomInClicked===>>',);
    };
    const zoomOutClicked = () => {
        // todo
        console.log('zoomOutClicked===>>',);
    };

    const getSignature = async () => {
        const { data: signature } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/get_guest_signature/${token}?type=SIGNATURE`);
        if (signature?.data) {
            setMySignature(signature?.data)
            setImage(signature?.data || '')
            setIsSigned(true)
            setImageFile(signature?.data);
            setIsSavingSignature(false);
        }
    }

    const saveSignature = async (signatureImage) => {
        if (isSelectedSignature) {
            if (signatureImage) {
                setIsSavingSignature(true);
                try {
                    loaderDispatch({ type: 'SET_LOADING', payload: true });
                    const formData = new FormData();
                    formData.append('type', 'SIGNATURE');
                    formData.append("file", signatureImage);
                    const { data: response } = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/update-guest-signature/${token}`, formData);
                    toast.success(ts(response?.code || response?.message || 'Signature Updated'));
                    setIsSavingSignature(false);
                    nextActiveStep()
                    loaderDispatch({ type: 'SET_LOADING', payload: false });
                } catch (error) {
                    toast.error(ts('SIGNATURE_UPLOAD_FAIL'));
                    setIsSavingSignature(false);
                } finally {
                    await getSignature();
                    loaderDispatch({ type: 'SET_LOADING', payload: false });
                }
            } else {
                toast.warn(ts('FILE_NOT_SELECTED'));
            }
        } else {
            saveDrewOrTypedSignature(signatureImage);
        }
    }

    const saveDrewOrTypedSignature = async (signatureImage) => {
        if (signatureImage) {
            setIsSavingSignature(true);
            try {
                loaderDispatch({ type: 'SET_LOADING', payload: true });
                const formData = new FormData();
                formData.append('type', 'SIGNATURE');
                formData.append("file", signatureImage);
                const { data: response } = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/update-guest-signature/${token}`, formData);
                toast.success(ts(response?.code || response?.message));
                setIsSavingSignature(false);
                nextActiveStep()
                loaderDispatch({ type: 'SET_LOADING', payload: false });
            } catch (error) {
                toast.error(ts('SIGNATURE_UPLOAD_FAIL'));
                setIsSavingSignature(false);
            } finally {
                loaderDispatch({ type: 'SET_LOADING', payload: false });
                await getSignature();
            }
        } else {
            toast.warn(ts('FILE_NOT_SELECTED'));
        }
    }

    const handleFileSelection = (e) => {
        if (e.target.files[0]) {
            setIsSelectedSignature(true);
            setImageFile(e.target.files);
            if (uploadingImageInterval) {
                clearTimeout(uploadingImageInterval);
            }
            uploadingImageInterval = setTimeout(() => {
                setImage(URL.createObjectURL(e.target.files[0]));
            }, 100);
            saveSignature(e.target.files[0])
        }
    }

    const handleShowCreateSignatureModal = () => {
        setShowCreateSignatureModal(true);
    }

    const saveDrewSignature = () => {
        setIsSelectedSignature(false);
        setMySignature(sigCanvas.current.toDataURL("image/png"));
        setImage(sigCanvas.current.toDataURL("image/png"));
        setImageFile(base64ToBinary(sigCanvas.current.toDataURL("image/png")));
        saveSignature(base64ToBinary(sigCanvas.current.toDataURL("image/png")));
        setShowCreateSignatureModal(false);
    }

    const makeImage = (e) => {
        e.preventDefault();
        setIsSelectedSignature(false);
        const text = signatureInputValue
        const imageData = updateImage(text);
        setImage(imageData);
        setImageFile(base64ToBinary(imageData));
        setShowCreateSignatureModal(false);
        saveSignature(base64ToBinary(imageData))
    };

    const validateOTP = async (otp) => {
        if (String(otp).length !== 4) {
            return toast.error(t('INVALID_OTP'))
        }
        const { status } = await getDocument({ hash: hashData, otp });
        if (status === 'success') {
            setShowOTPModal(false);
            setShowDocuments(true)
        }
    };

    const updateImage = (text) => {
        const canvas = document.createElement('canvas');
        const canvasText = canvas.getContext('2d');

        canvas.width = 400;
        canvas.height = 200;
        canvas.lineHeight = 200;
        canvasText.fillStyle = 'white';
        canvasText.fillRect(0, 0, canvas.width, canvas.height);

        const gradient = canvasText.createLinearGradient(0, 0, 200, 200);
        gradient.addColorStop(0, 'black');
        canvasText.fillStyle = gradient;

        const fontface = 'Urbanist, Medium, Lato, Roboto, Helvetica, sans-serif';
        canvasText.font = '30px Urbanist, Medium, Lato, Roboto, Helvetica, sans-serif';
        canvasText.textAlign = 'center';

        let fontsize = 100;
        do {
            fontsize--;
            canvasText.font = `${fontsize}px ${fontface}`;
        } while (canvasText.measureText(text).width > canvas.width);

        canvasText.fillText(text, canvas.width / 2, canvas.height / 2);

        const imageData = canvas.toDataURL();
        return imageData;
    };

    const handleRejectedDocument = (document) => {
        setRejectedDocument(document);
        setDocumentSignedStatus('REJECTED');
    }

    const syncTextAnnotationsWithAnnotations = () => {
        const updatedAnnotations = annotations.map((annotation) => {
            if (annotation.type === documentSignSteps.TEXT && textAnnotationsRef.current[annotation.annId]) {
                return {
                    ...annotation,
                    value: textAnnotationsRef.current[annotation.annId]
                }
            }
            return annotation;
        })
        setAnnotations(updatedAnnotations);
    }

    const getNextStepInTargetDirection = (validSteps, direction) => {
        if (direction === 1) {
            for (let i = activeStep; i < 5; i += 1) {
                if (validSteps[i]) {
                    return i + 1;
                }
            }
        } else {
            for (let i = activeStep - 2; i >= 0; i -= 1) {
                if (validSteps[i]) {
                    return i + 1;
                }
            }
        }
    }

    const previousActiveStep = () => {
        const annotationTypes = annotations.map(annotation => annotation.type);
        const stepsValidity = documentSignStepsOrder.map(step => annotationTypes.includes(step) || step === documentSignSteps.COMPLETED);
        const previousStep = getNextStepInTargetDirection(stepsValidity, -1);
        setactiveStep(previousStep);
    }

    const validateTextFields = () => {
        const currentTextFields = textAnnotationsRef.current;
        return Object.values(currentTextFields).every(textFieldValue => textFieldValue !== null && textFieldValue.trim() !== "");
    }

    const getNextStep = () => {
        const annotationTypes = annotations.map(annotation => annotation.type);
        const stepsValidity = documentSignStepsOrder.map(step => annotationTypes.includes(step) || step === documentSignSteps.COMPLETED);
        return getNextStepInTargetDirection(stepsValidity, 1);
    }

    const nextActiveStep = (validationData) => {
        if (activeStep === 1) {
            const unsignedPlaces = annotations?.filter(annotation => annotation?.signingStatus === "NOT_SIGNED" && annotation?.type === "SIGNATURE");
            if (unsignedPlaces.length) {
                // making it go forward based on annotations
                const nextStep = getNextStep();
                setactiveStep(nextStep);
            } else {
                return toast.error('Sign the document')

            }
        } else if (activeStep === 2) {
            if (validationData?.hasValidData || PhotoSignedPlaces.length) {
                // making it go forward based on annotations
                const nextStep = getNextStep();
                setactiveStep(nextStep);
            } else {
                return toast.error('Upload a Photo');
            }
        } else if (activeStep === 3) {
            if (validationData?.hasValidData || (saveStampImage && AttachStampImage)) {
                const nextStep = getNextStep();
                setactiveStep(nextStep);
                return
            } else {
                return toast.error('Upload a Stamp');
            }
        } else if (activeStep === 4) {
            if (validateTextFields()) {
                const nextStep = getNextStep();
                syncTextAnnotationsWithAnnotations();
                setactiveStep(nextStep);
            } else {
                toast.error('Please enter text into all text box fields to go to next step');
            }
        }
    }

    const openCamera = async () => {
        try {
            setIsCameraOpen((prev) => !prev);
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    const handleStampSelection = async (e) => {
        try {
            setsaveStampImage(URL.createObjectURL(e.target.files[0]))

            if (!e?.target?.files[0]) {
                return toast.error(ts('STAMP_NOT_FOUND'));
            }
            const unsignedPlaces = annotations?.filter(annotation => annotation?.signingStatus === "NOT_SIGNED" && annotation?.type === 'STAMP');
            setStampSignedPlaces(unsignedPlaces)
            setIsSignedStamp(unsignedPlaces && unsignedPlaces.length > 0);

            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            formData.append('type', 'STAMP')
            const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/update-guest-signature/${token}`, formData);
            const { data } = response;
            if (response?.data?.data) {
                toast.success(ts(response?.data?.code))
                setAttachStampImage(data?.data);
                nextActiveStep({ hasValidData: !!data?.data })
            }
            formData.delete('type');
            formData.delete('file');
        } catch (error) {
            let errorData = await error.response?.data;
            if (errorData) {
                toast.error(t(errorData?.code));
            } else {
                toast.error(t(error?.message || 'Something Went Wrong'));
            }
        }
    }

    const onClickFinish = async (e) => {
        setIsLoading(true);
        let docSigned = !FinishdisableBtn;
        try {
            const textAnnotations = annotations
                .filter(annotation => annotation.type === documentSignSteps.TEXT)
                .map((annotation) => {
                    if (annotation.type === documentSignSteps.TEXT && textAnnotationsRef.current[annotation.annId]) {
                        return {
                            fieldId: annotation.annId,
                            value: textAnnotationsRef.current[annotation.annId]
                        }
                    }
                    return {
                        fieldId: annotation.annId,
                        value: annotation.value,
                    };
                })
            const payload = {
                token,
                annotations: textAnnotations,
            }
            let response;
            if (textAnnotations.length) {
                response = await axios.put(`/document-recipient/guest-textarea-field/`, payload);
            }
            if (response?.data?.message || textAnnotations.length === 0) {
                if (docSigned) {
                    try {
                        const payload = {
                            documentId: selectedDocument?.id,
                            fieldIDs: annotations.filter(annotation => annotation?.signingStatus === "NOT_SIGNED").map(item => ({ id: item?.annId, type: item?.type }))
                        }
                        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/document/create_guest_signed_document/${token}`, payload);
                        if (await response?.data?.message) {
                            toast.success(ts(response?.data?.code || response?.data?.message));
                            setDocumentSignedStatus('COMPLETED')
                        }
                        setDocumentStatus(true)
                    } catch (error) {
                        toast.error(ts(`${error.response?.data?.code || 'SOMETHING_WENT_WRONG'}`));
                    } finally {
                        setIsLoading(false);
                    }
                }
            }
        } catch (error) {
            toast.error(ts(`${error.response?.data?.code || 'SOMETHING_WENT_WRONG'}`));
        }
        setIsLoading(false);
    };

    const handleTakePhoto = async (dataUri) => {
        setsavePhotoImage(dataUri);
        onClickAttachPhoto(dataUri)
        setIsCameraOpen(false);
    };

    const onClickAttachPhoto = async (dataUri) => {
        try {
            if (!dataUri) {
                return toast.error(ts('PHOTO_NOT_FOUND'));
            }
            const unsignedPlaces = annotations?.filter(annotation => annotation?.signingStatus === "NOT_SIGNED" && annotation?.type === 'IMAGE');
            setPhotoSignedPlaces(unsignedPlaces)
            setIsSignedPhoto(unsignedPlaces && unsignedPlaces.length > 0);
            const blob = await fetch(dataUri).then((res) => res.blob());
            const formData = new FormData();
            formData.append('file', blob, `photo_${new Date().getTime()}.jpg`);
            formData.append('type', 'IMAGE')
            const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/update-guest-signature/${token}`, formData);
            const { data } = response;
            if (response?.data?.data) {
                toast.success(ts(data?.code || 'IMAGE_ADDED'))
                setAttachPhotoImage(data?.data)
                nextActiveStep({ hasValidData: data?.data });
            }
        } catch (error) {
            toast.error(ts(error?.response?.statusText || 'SOMETHING_WENT_WRONG'))
        }
    };

    const handleCameraError = (error) => {
        console.error('Camera error:', error);
    };

    return (
        <div>
            <Navbar pageName={navLinks.documents} guest={true} />
            {/* Document Preview and Signing Page */}
            <Row>
                <Col xl={9}>
                    <div className='document-container'>
                        {!['REJECTED', 'COMPLETED'].includes(documentSignedStatues) &&
                            <>
                                <Stepper
                                    annotations={annotations}
                                    activeStep={activeStep}
                                />
                                <div className='document-sign-previous' style={{ display: activeStep === 1 && 'none' }}>
                                    <button onClick={previousActiveStep} className='nextPreviousbtn'>{t('previous')}</button>
                                </div>
                                <div className='document-sign-next' style={{ display: ((activeStep === 1 && !mySignature) || (activeStep === 2 && !AttachPhotoImage) || (activeStep === 3 && !AttachStampImage) || activeStep === 5) && 'none' }}>
                                    <button className='nextPreviousbtn' disabled={activeStep === 5 && true} onClick={nextActiveStep}>{t('next')}</button>
                                </div>
                            </>}
                        {showDocuments && <div className="section overflow-scroll">
                            {/* Zoom Buttons */}
                            <div className='w-100 d-flex justify-content-center'>
                                <div className='zoominout-container mt-4'>
                                    <span className='recipients recipient-text cursor-pointer' onClick={zoomInClicked}>
                                        <div className='circle'> <img src={zoomInIcon} alt='zoom-in icon' className='me-2' width={11} height={12} /></div>
                                        {t('zIn')}
                                    </span>
                                    <span className='recipients recipient-text cursor-pointer' onClick={zoomOutClicked}>
                                        <div className='circle'><img src={zoomOutIcon} alt='zoom-out icon' className='me-2' width={11} height={12} /></div>
                                        {t('zOt')}
                                    </span>
                                </div>
                            </div>

                            {/* Document */}
                            <DocumentPageRenderer
                                AttachPhotoImage={AttachPhotoImage}
                                isSignedStamp={isSignedStamp}
                                PhotoSignedPlaces={PhotoSignedPlaces}
                                isSignedPhoto={isSignedPhoto}
                                textAnnotationsRef={textAnnotationsRef}
                                annotations={annotations}
                                url={url}
                                isSigned={isSigned}
                                image={image}
                                mySignature={mySignature}
                                isRejected={isRejected}
                                StampSignedPlaces={StampSignedPlaces}
                                AttachStampImage={AttachStampImage}
                                activeStep={activeStep} />
                        </div>}
                    </div>
                </Col>
                <Col xl={3} className='d-flex flex-column' style={{ borderLeft: '1px solid grey', borderTop: '1px solid grey', backgroundColor: '#F8F8F8' }}>
                    {/* Document Details Tab */}
                    <div style={{ borderBottom: '1px solid black', paddingBottom: '0.8rem', paddingTop: '1.5rem' }}>
                        <div className="section">
                            <p className='font-weight-bold m-0' style={{ color: textChangeAppData, fontWeight: 600 }}>{t('flName')}</p>
                            <div className='align-items-center'>
                                <i style={{ paddingRight: '7px', color: 'red' }} className="bi bi-file-earmark-pdf"></i>
                                <span className='pdf-name pl-3' style={{ color: textChangeAppData }}>{title}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid black', marginBottom: '1.5rem', display: !selectedDocument?.owner ? "none" : '' }}>
                        <div className="section m-0">
                            <p className='font-weight-bold m-0' style={{ color: textChangeAppData, fontWeight: 600 }}>{t('senderDetails')}:</p>
                            <div>
                                <label>{t('name')}: </label>
                                <span className='font-weight-bold' style={{ color: textChangeAppData }}>{' '}{selectedDocument?.owner}</span><br />
                            </div>
                        </div>
                    </div>
                    {/* Upload Modals */}
                    {!['REJECTED', 'COMPLETED'].includes(documentSignedStatues) && <div>
                        <Tab.Container defaultActiveKey={activeStep} activeKey={activeStep}>
                            <Tab.Content>
                                <Tab.Pane eventKey={1}>
                                    <SignatureTab
                                        image={image}
                                        mySignature={mySignature}
                                        isSavedSignature={isSavingSignature}
                                        handleFileSelection={handleFileSelection}
                                        handleShowCreateSignatureModal={handleShowCreateSignatureModal}
                                        fileRef={fileRef} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={2}>
                                    <PhotoTab openCamera={openCamera} savePhotoImage={savePhotoImage} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={3}>
                                    <StampTab
                                        isSavingSignature={isSavingSignature}
                                        stampfileRef={stampfileRef}
                                        saveStampImage={saveStampImage}
                                        handleStampSelection={handleStampSelection} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={4}>
                                    <AddTextTab />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>}
                    {/* Finish or Reject Buttons */}
                    <DocumentFinalStep
                        textChangeAppData={textChangeAppData}
                        documentOverdue={documentOverdue}
                        documentStatus={documentStatus}
                        onClickFinish={onClickFinish}
                        setShowRejectionModal={setShowRejectionModal}
                        activeStep={activeStep}
                        rejectedDocument={rejectedDocument}
                        selectedDocument={selectedDocument}
                        documentSignedStatues={documentSignedStatues}
                        FinishdisableBtn={FinishdisableBtn}
                    />
                </Col>
            </Row>
            {/* Modals */}
            {/* Reason for rejection */}
            {showRejectionModal && <RejectionModal
                guestDocument={guestDocument}
                selectedDocument={selectedDocument}
                onReject={handleRejectedDocument}
                setShowRejectionModal={setShowRejectionModal} />}
            {/* Camera open  */}
            {isCameraOpen && <CameraModal
                openCamera={openCamera}
                handleTakePhoto={handleTakePhoto}
                handleCameraError={handleCameraError} />}
            {/* Signature Modal */}
            {showCreateSignatureModal && <SignatureCreationModal
                textChangeAppData={textChangeAppData}
                sigCanvas={sigCanvas}
                setSignatureInputValue={setSignatureInputValue}
                saveDrewSignature={saveDrewSignature}
                signatureInputValue={signatureInputValue}
                setShowCreateSignatureModal={setShowCreateSignatureModal}
                makeImage={makeImage} />}
            {/* OTP Modal */}
            {showOTPModal && <OTPModal
                textChangeAppData={textChangeAppData}
                validateOTP={validateOTP}
                phoneNumber={recipientPhoneNumber} />}
            {/* Loader */}
            {isLoading && <Loader />}
        </div>
    );
}

export default React.memo(DocumentGuestSign);