import React, { useContext, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import AuthServices from '../../services/auth.services';
import { toast } from 'react-toastify';
import { LoaderContext } from '../../store/Loader';
import './ResetPassword.css'
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../LanguageSwitcher';
import translateLanguageImage from '../../assets/Icon material-translate.svg'
import { DocumentContext } from '../../store/document';
import { bgBeforeLogin, textChangeApp } from '../../utils/constants';
import Loader from '../../components/ui/Loader';
import TawqiiaVactorEn from "../../assets/sign_in_vector_tawqiia_en.png"
import TawqiiaVactorAr from "../../assets/sign_in_vector_tawqiia_ar.png"
import TawqiiaLogoEn from "../../assets/Tawqiia_english.png"
import TawqiiaLogoAr from "../../assets/Tawqiia_arabic.png"
import DastkhatVactorEn from "../../assets/dastkat-vactor-img.png"
import DastkhatLogoEnFinalBlue from "../../assets/DastkhatLogoEnFinalBlue.png"
import { generateSignBanner } from '../../utils/helper';

const authServices = new AuthServices();

const ResetPassword = () => {
  let hostname = process.env.REACT_APP_DOMAIN;
  const location = useLocation();
  const orgName = location.pathname.split('/')[1];
  const { getOrgApp, documentState } = useContext(DocumentContext);
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const [isValidPwd, setIsValidPwd] = useState(false);
  const [isPwdMatched, setIsPwdMatched] = useState(false);
  const { loaderDispatch } = useContext(LoaderContext);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('resetPassword');

  const { t: ts } = useTranslation('apiMessage');
  const { i18n: { language } } = useTranslation();
  const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
  const logo = documentState?.orgAppData;
  const dataBg = bgBeforeLogin(documentState)
  const textChangeAppData = textChangeApp(documentState)

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnChange = (name) => (e) => {
    if (name === "password") {
      setIsValidPwd(passwordRegex.test(e.target.value));
      setIsPwdMatched(e.target.value === values.confirmPassword);
    }
    if (name === "confirmPassword") {
      setIsPwdMatched(values.password === e.target.value);
    }
    setValues((prevState) => {
      return { ...prevState, [name]: e.target.value };
    });
  };

  // useEffect(() => { getOrgApp() },
  //   [])

  const handleResetPassword = async () => {
    const { url } = params;
    const { password, confirmPassword } = values;
    if (!password || !confirmPassword) {
      return toast.warn(ts('Please fill in all required fields'));
    }
    if (password !== confirmPassword) {
      return toast.warn(ts('The password did not match the confirm password'));
    }
    try {
      loaderDispatch({ type: 'SET_LOADING', payload: true });
      setIsLoading(true);
      const res = await authServices.resetPassword(
        { password, confirmPassword },
        url
      );
      if (res.status === 'success') {
        toast.success(ts(res?.code || res?.message));
        navigate(`/${orgName}`);
      }
    } catch (error) {
      toast.error(ts(`${error.response?.data?.code}`));
    } finally {
      setValues({
        password: '',
        confirmPassword: '',
      });
      loaderDispatch({ type: 'SET_LOADING', payload: false });
      setIsLoading(false);
    }
  };

  return (
    <div className='login-body' style={{
      backgroundImage: dataBg
    }}>
      <div className='login-page'>
        <div className='form-submitted'>
          <div className='dastkhat-logo px-5 mx-5'>
            <Link to={`/${orgName}`} className={`pointer mt-5 ${!logo?.logo && 'visibility-hidden'}`}>
              <img className="pointer mt-5" src={logo?.logo} alt="#" width={130} height={70} />
            </Link>
          </div>
          <div className='resetpwd-vector-section'>
            <div>
              <div className="resetpwd-vector">
              <img
                  src={generateSignBanner(textChangeAppData, language)}
                  alt="#" />
              </div>
              <div>
              <img className='dasthkath-logo-login' width={100}  
                src={(['ar'].includes(language)&& hostname === 'TAWQIA') ?
                  TawqiiaLogoAr :
                  (['en'].includes(language)&& hostname === 'TAWQIA') ?
                  TawqiiaLogoEn :
                  DastkhatLogoEnFinalBlue}
                  alt="#" />              </div>
            </div>
            <div className='resetpwd-form'>
              <div className='form-here'>
               {hostname === 'TAWQIA' && <div className='d-flex justify-content-end align-items-center'><img className='me-2' width={20} src={translateLanguageImage} alt='' />
                  < LanguageSwitcher />
                </div>}
                <div>
                  <div className='mt-4'>
                    <div className='mb-3'>
                      <p className='mb-1'>{t('newPassword')}<span className='text-danger'>*</span> </p>
                      <div className='d-flex justify-content-center align-items-center form-input-field'>
                        <input
                        placeholder={ts("newPasswordPlaceholder")}
                          onChange={handleOnChange('password')}
                          value={values.password}
                          type={values.showPassword ? 'text' : 'password'}
                        />
                        <span>
                          <i
                            className='bi bi-eye-fill eye-icon m-0 p-0 w-100 mx-2 cursor-pointer'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          ></i>
                        </span>
                      </div>
                      {
                        values.password && <div class={`mt-1 ${isValidPwd ? "valid validation-msg" : "invalid validation-msg"}`}>{`${isValidPwd ? (t("Password_matched_the_requirements")) : (t("PasswordValidation_Instruction_msg"))}`}</div>
                      }
                    </div>
                    <div className='mb-1'>
                      <p className='mb-1'>{t('confirmPassword')}<span className='text-danger'>*</span></p>
                      <div className='d-flex justify-content-center align-items-center form-input-field'>
                        <input
                        placeholder={ts("confirmPasswordPlaceholder")}
                          onChange={handleOnChange('confirmPassword')}
                          value={values.confirmPassword}
                          type={
                            values.showConfirmPassword ? 'text' : 'password'
                          }
                        />
                        <span>
                          <i
                            className='bi bi-eye-fill eye-icon m-0 p-0 w-100 mx-2 cursor-pointer'
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                          ></i>
                        </span>
                      </div>
                      {
                        values.confirmPassword && <div class={`mt-1 ${isPwdMatched ? "valid validation-msg" : "invalid validation-msg"}`}>{`${isPwdMatched ? (t("Password_matched")) : (t("Confirm_Password_should_be_matched_with_new_password"))}`}</div>
                      }
                    </div>
                  </div>
                  <div className='login-forgetpassword'>
                    <div className='login-btn mt-4 mx-0'>
                      <button
                        style={{
                          backgroundColor: textChangeAppData
                        }}

                        onClick={handleResetPassword}
                        className='btnresetpwd'
                      >
                        {t('submitBtn')} - &rarr;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <AuthFooter /> */}
        </div>
      </div>
      {isLoading && (
        <Loader />
      )}
    </div>
  );
};

export default ResetPassword;