/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import Navbar from '../../components/Navbar/Navbar';
import './Settings.css';
import { navLinks, settingsTabs, textChangeApp, nameRegex } from '../../utils/constants';
import keyIcon from './../../assets/key-icon.png';
import userIcon from './../../assets/user-icon.png';
import signIcon from './../../assets/sign-icon.png';
import whiteDownloadIcon from './../../assets/white-upload-icon.png';
import WhiteSignIcon from './../../assets/white-sign-icon.png';
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from '../../store/auth';
import AuthServices from '../../services/auth.services';
import { isUser, base64ToBinary } from '../../utils/helper';
import { Modal as BootstrapModal } from 'bootstrap';
import SignaturePad from "react-signature-canvas";
import { LoaderContext } from '../../store/Loader';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/ui/Loader';

import { DocumentContext } from '../../store/document';

const authServices = new AuthServices();

let uploadingImageInterval;
// let savingSignatureInterval;

const Settings = () => {
    const location = useLocation();
    const orgName = location.pathname.split('/')[1];
    const { t } = useTranslation('settings');
    const { authState, fetchUserProfile, authDispatch } = useContext(AuthContext)
    const { profile } = authState;
    const { loaderDispatch } = useContext(LoaderContext);
    const user = isUser();
    const [activeModalTab, setActiveModalTab] = useState(1);
    const [profileData, setProfileData] = useState({
        firstName: profile?.firstName || user?.firstName || '',
        lastName: profile?.lastName || user?.lastName || '',
        email: profile?.email || user?.email || '',
    });
    const [doneButtonClicked, setDoneButtonClicked] = useState(false);
    // const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");

    const { getOrgApp, documentState } = useContext(DocumentContext);

    const [passwordData, setPasswordData] = useState({
        currentPassword: "",
        password: "",
        confirmPassword: "",
    });

    const { firstName, lastName, email } = profileData;
    const { currentPassword, password, confirmPassword } = passwordData;
    const [doneDisable, setDoneDisable] = useState(false);
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const [isValidPwd, setIsValidPwd] = useState(false);
    const [isPwdMatched, setIsPwdMatched] = useState(false);

    const handleOnProfileChange = name => e => {
        setProfileData(prevState => {
            return { ...prevState, [name]: e.target.value }
        })
    }

    const handleOnPasswordChange = name => e => {
        if (name === "password") {
            setIsValidPwd(passwordRegex.test(e.target.value));
            setIsPwdMatched(e.target.value === confirmPassword);
        }
        if (name === "confirmPassword") {
            setIsPwdMatched(password === e.target.value);
        }
        setPasswordData(prevState => {
            return { ...prevState, [name]: e.target.value }
        })
    }

    const { tab } = useParams();
    const navigate = useNavigate();
    const fileRef = useRef(null);
    const openModalRef = useRef();
    const closeModalRef = useRef();
    const sigCanvas = useRef({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSelectedSignature, setIsSelectedSignature] = useState(false);
    const [isSavingSignature, setIsSavingSignature] = useState(false);
    const [isSavedSignature, setIsSavedSignature] = useState(true);
    const [image, setImage] = useState('');
    const [imageFile, setImageFile] = useState([]);
    const [noError, setNoError] = useState(true);

    const { t: ts } = useTranslation('apiMessage');
    const textChangeAppData = textChangeApp(documentState)

    useEffect(() => {
        // getOrgApp();
        if (Object.values(settingsTabs).indexOf(tab) < 0) {
            navigate(`/${orgName}/settings`);
        }

    }, []);

    useEffect(() => {
        fetchUserProfile();
        getSignature();
    }, [])

    const handleProfileUpdate = async () => {
        loaderDispatch({ type: 'SET_LOADING', payload: true });
        setIsLoading(true);
        try {
            switch (true) {
                case firstName.trim() === '':
                    toast.error(t('FirstName_is_empty'));
                    break;
        
                case lastName.trim() === '':
                    toast.error(t('LastName_is_empty'));
                    break;
        
                case !nameRegex.test(firstName):
                    toast.error(t('Invalid_Input_firstName'));
                    break;
        
                case !nameRegex.test(lastName):
                    toast.error(t('Invalid_Input_lastName'));
                    break;
        
                default:
                    const res = await authServices.updateUserProfile({ firstName, lastName, email });
                    if (res) {
                        toast.success(t('user_Profile_Updated'));
                        fetchUserProfile();
                        localStorage.setItem('user', JSON.stringify({ firstName, lastName, email }));
                    }
                    break;
            }
        } catch (error) {
            toast.error(t(error.response?.data?.code) || error?.response?.data?.message);
        } finally {
            loaderDispatch({ type: 'SET_LOADING', payload: false });
            setIsLoading(false);
        }
    }

    const handlePasswordUpdate = async () => {
        if (!currentPassword || !password || !confirmPassword) {
            return toast.warn(ts('PASSWORD_CONFIRMATION'));
        } else {
            loaderDispatch({ type: 'SET_LOADING', payload: true });
            setIsLoading(true);
            try {
                const res = await authServices.updatePassword({ currentPassword, password, confirmPassword });
                if (res) {
                    toast.success(t('Password_Updated'));
                    authServices.logOut();
                    navigate(`/${orgName}`)
                }
            } catch (error) {
                toast.error(t('Password_update_failed'))
            } finally {
                loaderDispatch({ type: 'SET_LOADING', payload: false });
                setIsLoading(false);
                setPasswordData({
                    currentPassword: "",
                    password: "",
                    confirmPassword: ""
                })
            }
        }

    }
    // currentPassword, password, confirmPassword
    const saveProfile = () => {
        toast.success(("Profile has been saved successfully."), { toastId: 'success' });
    }

    const getSignature = async () => {
        loaderDispatch({ type: 'SET_LOADING', payload: true });
        try {
            setIsLoading(true);
            const response = await authServices.getSignature();
            if (response?.data) {
                authDispatch({ type: 'SIGN_URL', payload: response?.data });
                setImage(response?.data)
            }

            loaderDispatch({ type: 'SET_LOADING', payload: false });
            setIsLoading(false);
        } catch (error) {
            console.log("error --> ", error);
            toast.error(ts('INTERNAL_SERVER_ERROR'));
            loaderDispatch({ type: 'INTERNAL_SERVER_ERROR', payload: false });
            setIsLoading(false);
        }

    }

    const saveSignature = async () => {
        if (isSelectedSignature) {
            if (imageFile.length > 0) {
                setIsSavingSignature(true);
                try {
                    loaderDispatch({ type: 'SET_LOADING', payload: true });
                    setIsLoading(true);
                    const formData = new FormData();
                    formData.append("type", "SIGNATURE");
                    formData.append("file", imageFile[0]);
                    const response = await authServices.updateSignature(formData);
                    getSignature();
                    toast.success(ts(response?.code || response?.message));
                    setIsSavingSignature(false);
                    setIsSavedSignature(true);
                } catch (error) {
                    console.log("error --> ", error);
                    toast.error(ts('SIGNATURE_UPLOAD_FAIL'));
                    setIsSavingSignature(false);
                } finally {
                    loaderDispatch({ type: 'SET_LOADING', payload: false });
                    setIsLoading(false);
                }
            } else {
                toast.warn(ts("File not selected"));
            }
        } else {
            saveDrewOrTypedSignature();
        }
    }

    const saveDrewOrTypedSignature = async () => {
        if (imageFile) {
            setIsSavingSignature(true);
            try {
                loaderDispatch({ type: 'SET_LOADING', payload: true });
                setIsLoading(true);
                const formData = new FormData();
                formData.append("type", "SIGNATURE");
                formData.append("file", imageFile);
                const response = await authServices.updateSignature(formData);
                getSignature();
                toast.success(ts(response?.code || response?.message));
                setIsSavingSignature(false);
                setIsSavedSignature(true);
            } catch (error) {
                console.log("error --> ", error);
                toast.error(ts('SIGNATURE_UPLOAD_FAIL'));
                setIsSavingSignature(false);
            } finally {
                loaderDispatch({ type: 'SET_LOADING', payload: false });
                setIsLoading(false);
            }
        } else {
            toast.warn(ts("File not selected"));
        }
    }

    const handleFileSelection = (e) => {
        setIsLoading(true);
        setIsSelectedSignature(true);
        setIsSavedSignature(false);
        setImageFile(e.target.files);
        if (uploadingImageInterval) {
            clearTimeout(uploadingImageInterval);
        }
        uploadingImageInterval = setTimeout(() => {
            setImage(URL.createObjectURL(e.target.files[0]));
            setIsLoading(false);
        }, 100);
    }

    const showModal = () => {
        const modalEle = openModalRef.current;
        const bsModal = new BootstrapModal(modalEle, {
            backdrop: 'static',
            keyboard: false,
        });
        bsModal.show();
    };

    const clearDrawingCanvas = () => {
        setDoneDisable(false);
        sigCanvas.current.clear();
    }

    const saveDrewSignature = () => {
        setIsSelectedSignature(false);
        // setImage(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
        // setImageFile(base64ToBinary(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")));

        //removed trimming of signature
        setImage(sigCanvas.current.toDataURL("image/png"));
        setImageFile(base64ToBinary(sigCanvas.current.toDataURL("image/png")));
        closeModalRef.current.click();
        setIsSavedSignature(false);
    }

    const handleClose = () => {
        if (doneDisable && !doneButtonClicked) {
            clearDrawingCanvas();
        }
        setDoneButtonClicked(false);
    }

   const handleOnchange = (e) => {
    e.preventDefault();
    setNoError(true);
  };

  const makeImage = (e) => {
    e.preventDefault();
    setIsSelectedSignature(false);
    const text = e.target.words.value;
    const val = text.trim();
    if (!val.length) {
      setNoError(false);
    } else {
      setNoError(true);
      const imageData = updateImage(text);
      setImage(imageData);
      setImageFile(base64ToBinary(imageData));
      setIsSavedSignature(false);
      closeModalRef.current.click();
    }
  };
    const updateImage = (text) => {
        const canvas = document.createElement('canvas');
        const canvasText = canvas.getContext('2d');

        canvas.width = 400;
        canvas.height = 200;
        canvas.lineHeight = 200;
        canvasText.fillStyle = 'white';
        canvasText.fillRect(0, 0, canvas.width, canvas.height);

        const gradient = canvasText.createLinearGradient(0, 0, 200, 200);
        gradient.addColorStop(0, 'black');
        canvasText.fillStyle = gradient;

        const fontface = 'Urbanist, Medium, Lato, Roboto, Helvetica, sans-serif';
        canvasText.font = '30px Urbanist, Medium, Lato, Roboto, Helvetica, sans-serif';
        canvasText.textAlign = 'center';

        let fontsize = 100;
        do {
            fontsize--;
            canvasText.font = `${fontsize}px ${fontface}`;
        } while (canvasText.measureText(text).width > canvas.width);

        canvasText.fillText(text, canvas.width / 2, canvas.height / 2);

        const imageData = canvas.toDataURL();
        return imageData;
    };

    return (<div>
        <div style={{ minHeight: '95vh' }}>
            <Navbar pageName={navLinks.settings} />
            <section className="my-4 settings-tab-heading">
                <div className="container">
                    <h2 className='m-0'>{t('heading')}</h2>
                </div>
            </section>
            <section className="pb-2">
                <div className="container my-documents-navbar d-flex my-2">
                    <Link to={`/${orgName}/settings`} className={`my-documents-nav-link profile-link mx-2 px-4 d-flex align-items-center ${(tab === settingsTabs.PROFILE || !tab) && ' active-my-documents-nav-link'}`}>
                        <img src={userIcon} alt='document icon' width={20} height={20} />
                        <span className='mx-2 py-2'>
                            {t('profile')}
                        </span>
                    </Link>
                    { !authState?.profile?.organization?.is_active_directory ? (
                        <Link to={`/${orgName}/settings/password`} className={`my-documents-nav-link password-link mx-2 px-4 d-flex align-items-center ${tab === settingsTabs.PASSWORD && ' active-my-documents-nav-link'}`}>
                            <img src={keyIcon} alt='document icon' width={20} height={20} />
                            <span className='mx-2 py-2'>
                                {t('password')}
                            </span>
                        </Link>
                    ) : null}
                    <Link to={`/${orgName}/settings/signature`} className={`my-documents-nav-link signature-link mx-2 px-4 d-flex align-items-center ${tab === settingsTabs.SIGNATURE && ' active-my-documents-nav-link'}`}>
                        <img src={signIcon} alt='document icon' width={20} height={20} />
                        <span className='mx-2 py-2'>
                            {t('mySignature')}
                        </span>
                    </Link>
                </div>
            </section>
            <section className='my-2 account-settings'>
                <div className="container settings-form-container p-4 px-5">
                    {(tab === settingsTabs.PROFILE || !tab) && (
                    <div className="profile-form px-4 row">
                        <div className="custom-col-md-6 col-sm-12">
                            <div className="m-2 input-label" style={{color: textChangeAppData}}>
                                {t('fname')}<span className="text-danger">*</span>
                            </div>
                            <input
                                type="text"
                                className="form-control name-input"
                                placeholder={ts('fnamePlaceholder')}
                                value={firstName}
                                onChange={handleOnProfileChange("firstName")}
                            />
                        </div>
                        <div className="custom-col-md-6 col-sm-12 lastName-field">
                            <div className="m-2 input-label" style={{color: textChangeAppData}}>
                                {t('lname')}<span className="text-danger">*</span>
                            </div>
                            <input
                                type="text"
                                className="form-control name-input"
                                placeholder={ts('lnamePlaceholder')}
                                value={lastName}
                                onChange={handleOnProfileChange("lastName")}
                            />
                        </div>
                        <div className="my-3 mt-4 email-field">
                            <div className="m-2 input-label" style={{color: textChangeAppData}}>
                                {t('email')}
                            </div>
                            <input
                                type="email"
                                className="form-control name-input"
                                value={email}
                                onChange={handleOnProfileChange("email")}
                                disabled
                            />
                        </div>
                        <div className="text-center mt-4 mb-2">
                            <button
                                className="btn btn-primary save-btn px-4"
                                style={{background: textChangeAppData}}
                                onClick={handleProfileUpdate}
                            >
                                {t('savebtn')}
                            </button>
                        </div>
                    </div>
                  )}
                    {(tab === settingsTabs.PASSWORD) && (
                    <div className='password-form px-4'>
                        <div className='row'>
                            <div className='col-lg-6 custom-col-md-6 col-sm-12'>
                                <div className='m-2 input-label' style={{color: textChangeAppData}}>
                                    {t('crntpswrd')}<span className='text-danger'>*</span>
                                </div>
                                <input 
                                    type="password" 
                                    placeholder={t('currentPasswordPlaceholder')} 
                                    value={currentPassword} 
                                    onChange={handleOnPasswordChange("currentPassword")} 
                                    className='name-input' 
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='custom-col-md-6 col-sm-12 mt-3 new-password-field'>
                                <div className='m-2 input-label' style={{color: textChangeAppData}}>
                                    {t('nwPswrd')}<span className='text-danger'>*</span>
                                </div>
                                <input 
                                    type="password" 
                                    placeholder={ts('newPasswordPlaceholder')} 
                                    value={password} 
                                    onChange={handleOnPasswordChange("password")} 
                                    className='name-input' 
                                />
                                {
                                    password && <div class={`mt-1 ${isValidPwd ? "valid" : "invalid"}`}>{`${isValidPwd ? (t("Password_matched_the_requirements")) : (t("PasswordValidation_Instruction_msg"))}`}</div>
                                }
                            </div>
                            <div className='custom-col-md-6 col-sm-12 confirm-password-field mt-3'>
                                <div className='m-2 input-label' style={{color: textChangeAppData}}>
                                    {t('cnfmPswrd')}<span className='text-danger'>*</span>
                                </div>
                                <input 
                                    type="password" 
                                    placeholder={ts('confirmPasswordPlaceholder')} 
                                    value={confirmPassword} 
                                    onChange={handleOnPasswordChange("confirmPassword")} 
                                    className='name-input' 
                                />
                                {
                                    confirmPassword && <div class={`mt-1 ${isPwdMatched ? "valid" : "invalid"}`}>{`${isPwdMatched ? (t("Password_matched")) : (t("Confirm_Password_should_be_matched_with_new_password"))}`}</div>
                                }
                            </div>
                        </div>
                        <div className='m-auto text-center mb-3 mt-5'>
                            <button 
                                onClick={handlePasswordUpdate} 
                                className='btn btn-primary m-auto save-btn px-4' 
                                style={{background: textChangeAppData}}
                            >
                                {t('savebtn')}
                            </button>
                        </div>
                    </div>
                    )}
                    {(tab === settingsTabs.SIGNATURE) && (
                    <div className='m-auto ms-5 signature-tab mb-4 mt-3'>
                        {!image && (<div className='d-flex align-items-center'>
                            <h5 className='no-signature bold'>{t('txt')}</h5>
                        </div>)}
                        {image && (<div className='d-flex signed-block align-items-center'>
                            <h4 className='bold'>{t('mySignature')} : </h4>
                            <img src={image} alt='Signature photo' className='my-signature mx-3 p-2' width={160} height={80} />
                            {!isSavedSignature && (<button disabled={isSavingSignature} className='btn btn-success px-4 ms-5 signature-save-btn' onClick={saveSignature}> {!isSavingSignature ? t('SAVE') : t('SAVING') + '...'}</button>)}
                        </div>)}
                        <div className='d-flex align-items-center uploadImage-container mt-4'>
                            <label htmlFor="uploadImage"
                                style={{
                                    backgroundColor: textChangeAppData,
                                    fontSize: '13px',
                                    fontWeight: '600',
                                    height: '4.6vh',
                                    borderRadius: '4px'
                                }}
                                className='uploadImageLabel cursor-pointer px-3 bold-text pointer my-2 d-flex align-items-center justify-content-center'>
                                <input 
                                    name="uploadImage"
                                    type="file"
                                    id="uploadImage"
                                    accept="image/png, image/jpg, image/jpeg"
                                    hidden disabled={isSavingSignature} onChange={handleFileSelection} 
                                    ref={fileRef}
                                />
                                <img src={whiteDownloadIcon} alt='send icon' className='me-2' width={13} height={13} /> 
                                {t('UpSign')}
                            </label>
                            <button 
                                style={{backgroundColor: textChangeAppData}} 
                                className='create-btn btn-primary ms-3 text-white px-3' 
                                onClick={showModal}
                            >
                                <img src={WhiteSignIcon} alt='send icon' className='me-2' width={13} height={13} />
                                {t('CrSign')}
                            </button>
                        </div>
                    </div>)}
                </div>
            </section>

            {/* Create Signature Modal */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={openModalRef}>
                <div className="modal-dialog-centered" role="document">
                    <div className="modal-content modal-dialog models-body px-5 signature-modal">
                        <div className="modal-header signature-modal-header no-border">
                            <div className="container my-documents-navbar d-flex my-2">
                                <div
                                    className={`my-documents-nav-link pointer px-4 d-flex align-items-center`}
                                    style={activeModalTab === 1 ? {borderBottom: `4px solid ${textChangeAppData}`, color: textChangeAppData, fontWeight: 'bold'} : {}}
                                    onClick={() => setActiveModalTab(1)}
                                >
                                    <span className='py-1 draw-sign' style={{color: textChangeAppData}}>
                                        {t('DrawSign')}
                                    </span>
                                </div>
                                <div
                                    className={`my-documents-nav-link pointer mx-2 px-4 d-flex align-items-center`}
                                    style={activeModalTab === 2 ? {borderBottom: `4px solid ${textChangeAppData}`, color: textChangeAppData, fontWeight: 'bold'} : {}}
                                    onClick={() => setActiveModalTab(2)}
                                >
                                    <span className='py-1 type-sign' style={{color: textChangeAppData}}>
                                        {t('TypeSign')}
                                    </span>
                                </div>
                            </div>
                            <div onClick={() => handleClose()}> <button type="button" className="close close-icon" title='Close' data-bs-dismiss="modal" aria-label="Close" ref={closeModalRef}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                        </div>

                        {(activeModalTab === 1) && (<div>
                            <div className="modal-body signature-modal-body">
                                <SignaturePad penColor='green'
                                    canvasProps={{
                                        width: 370,
                                        height: 100,
                                        className: 'signCanvas'
                                    }} ref={sigCanvas}
                                    onEnd={() => setDoneDisable(true)} />
                            </div>
                            <div className="modal-footer signature-modal-footer no-border justify-content-center ">
                                <button className="btn btn-secondary" data-dismiss="modal" onClick={clearDrawingCanvas}> {t('clearBtn')} </button>
                                <button disabled={!doneDisable} type="button" className="btn btn-secondary" data-dismiss="modal" style={{background: textChangeAppData, color: '#fff'}} onClick={saveDrewSignature}>{t('done')}</button>
                            </div>
                        </div>)}

                        {(activeModalTab === 2) && (<div>
                            <form className="the-words" onSubmit={makeImage}>
                                <div className="modal-body">
                                    <h5 className='site-primary-color' style={{color: textChangeAppData}}>{t('ENTER_SIGNATURE')}</h5>
                                    <input
                                        type="text"
                                        name="words"
                                        required
                                        className="form-control signature-input-field py-2"
                                        maxLength="20"
                                        onChange={handleOnchange}
                                    />
                                     <p className='text-danger'>{noError ? "" : "Please enter valid signature"}</p>
                                </div>
                                <div className="modal-footer no-border justify-content-center ">
                                    <button type="submit" className="btn btn-secondary" data-dismiss="modal" style={{background: textChangeAppData, color: '#fff'}} >{t('done')}</button>
                                </div>
                            </form>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
        {isLoading && (
            <Loader />
        )}
    </div>);
};

export default Settings;