import { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Modal, Tabs, Tab, Nav, TabContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SignaturePad from "react-signature-canvas";
import './SignatureCreationModal.css'

const DRAW_SIGNATURE = "draw-singature";
const TYPE_SIGNATURE = "type-singature";

const SignatureCreationModal = (props) => {
    const { textChangeAppData, sigCanvas, setSignatureInputValue, saveDrewSignature, signatureInputValue, setShowCreateSignatureModal, makeImage } = props;
    const { t } = useTranslation('documentsUpdate');

    const [currentTab, setCurrentTab] = useState(DRAW_SIGNATURE);
    const [disableDoneBtn, setDisableDoneBtn] = useState(true);

    useEffect(() => {
        const tabs = document.querySelectorAll('.nav-link');
        tabs.forEach(tabElement => {
            if (tabElement.className.includes('active')) {
                tabElement.style.background = textChangeAppData
                tabElement.style.color = 'white'
            } else {
                tabElement.style.background = '#ccc'
                tabElement.style.color = 'grey'
            }
        })
    }, [currentTab]);

    useEffect(() => {
        sigCanvas.current.clear();
    }, [])

    const handleClearSignature = () => {
        sigCanvas.current.clear();
        setDisableDoneBtn(true)
    }

    const onClose = () => {
        setShowCreateSignatureModal(false)
    }

    return (
        <div className="modal modal-container-top modal-container-top-signature">
            <Modal.Dialog>
                <Tab.Container defaultActiveKey={DRAW_SIGNATURE}>
                    <Modal.Header closeButton onHide={onClose}>
                        <div className='signature-modal-header-content'>
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link onClick={() => setCurrentTab(DRAW_SIGNATURE)} eventKey={DRAW_SIGNATURE}>{t('drawSign')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => setCurrentTab(TYPE_SIGNATURE)} eventKey={TYPE_SIGNATURE}>{t('typeSign')}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="signature-modal-body">
                        <Tab.Content>
                            <Tab.Pane eventKey={DRAW_SIGNATURE}>
                                <SignaturePad
                                    penColor='green'
                                    canvasProps={{
                                        width: 440,
                                        height: 100,
                                        className: 'signatureCanvas'
                                    }}
                                    ref={sigCanvas}
                                    onEnd={() => setDisableDoneBtn(false)}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey={TYPE_SIGNATURE}>
                                <input
                                    type="text"
                                    name="words"
                                    placeholder={t('enteryoursignhere')}
                                    required
                                    className="form-control signature-input-field py-2"
                                    value={signatureInputValue}
                                    onChange={(e) => setSignatureInputValue(e.target.value)}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Modal.Body>
                </Tab.Container>
                <div className='signature-tab-footer'>
                    <Modal.Footer>
                        <Row className="d-flex otp-input-container">
                            <Col style={{ display: currentTab === TYPE_SIGNATURE && 'none' }}>
                                <Button
                                    variant="primary"
                                    className="choose-plan-btn px-4 py-1.3 me-3"
                                    onClick={handleClearSignature}
                                    style={{ backgroundColor: textChangeAppData }}
                                >
                                    {t('clearBtn')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    variant="primary"
                                    className="choose-plan-btn px-4 py-1.3"
                                    onClick={currentTab === DRAW_SIGNATURE ? saveDrewSignature : makeImage}
                                    disabled={currentTab === DRAW_SIGNATURE ? disableDoneBtn : signatureInputValue?.trim() === ""}
                                    style={{ backgroundColor: textChangeAppData }}
                                >
                                    <span>{t('done')}</span>
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </div>
            </Modal.Dialog>
        </div>
    )
}

export default SignatureCreationModal