import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row, Card, CardGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { navLinks, textChangeApp } from '../../utils/constants';
import { DocumentContext } from '../../store/document';
import documentIcon from "./../../assets/document-icon.svg";
import './Templates.css';
import TemplateUploadModal from './TemplateUploadModal';
import { useParams } from 'react-router-dom';
import TemplateUpdateModal from './TemplateUpdateModal';
import DocumentTemplateServices from '../../services/documentTemplateServices';
import TemplateDocumentCreationModal from './TemplateDocumentCreationModal';
import TemplateDeleteModal from '../../Modals/DeleteConfirmationModal';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import pdfIcon from '../../assets/pdf-icon.png'
import TemplateCategories from './TemplateCategories';
import debounce from '../../utils/debounce';
import Pagination from '../../components/common/Pagination';
import Loader from '../../components/ui/Loader';

//Default Templates
import defaultTemplate1 from '../../assets/default_templates/default_template_1.jpg'
import defaultTemplate2 from '../../assets/default_templates/default_template_2.jpg'
import defaultTemplate3 from '../../assets/default_templates/default_template_3.jpg'
import { toast } from 'react-toastify';

const documentTemplateServices = new DocumentTemplateServices();

const TEMPLATE_UPDATE = 'TEMPLATE_UPDATE';
const TEMPLATE_UPLOAD = 'TEMPLATE_UPLOAD';
const DOCUMENT_CREATE = 'DOCUMENT_CREATE';
const TEMPLATE_DELETE = 'TEMPLATE_DELETE';
const TEMPLATE_CATEGORY_UPDATE = 'TEMPLATE_CATEGORY_UPDATE';

const ROW_FORMAT = "ROW_FORMAT"
const GRID_FORMAT = "GRID_FORMAT";

const documentTemplateImages = [defaultTemplate1, defaultTemplate2, defaultTemplate3];

const Templates = () => {
    const { getOrgApp, documentState, fetchTemplateCategories, fetchTemplates } = useContext(DocumentContext);
    const roleData = localStorage.getItem('roleData').split(',') || [];
    const { orgName, tab } = useParams();
    // const templateType = tab === 'templates' ? 'global' : 'organization-templates';
    const templateType = 'organization-templates';

    const [isLoading, setIsLoading] = useState(false);

    const [showModal, setShowModal] = useState('');
    const [selectedTemplateData, setSelectedTemplateData] = useState({});
    const [showFormat, setShowFormat] = useState(ROW_FORMAT);
    const [deleteTemplateId, setDeleteTemplateId] = useState('');
    const [selectedTemplateType, setSelectedTemplateType] = useState({ name: "" });
    const [searchTemplateText, setSearchTemplateText] = useState('');    

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const onPageChange = async (page) => {
        try {
            setIsLoading(true);
            const response = await fetchTemplates(selectedTemplateType.id, searchTemplateText, page);
            if (response.data) {
                setCurrentPage(response.page);
                setTotalCount(response.totalCount)
            }
        } catch (error) {
            // toast(ts('errorInFetchingUser'))
        } finally {
            setIsLoading(false);
        }
    }

    //styling
    const [isFocused, setIsFocused] = useState(false);

    const textColor = textChangeApp(documentState);
    const { t } = useTranslation('templates');
    const { t: td } = useTranslation('documents');

    const handleToggleShowUploadModal = () => {
        if (showModal === TEMPLATE_UPLOAD) {
            setShowModal('');
        } else {
            setShowModal(TEMPLATE_UPLOAD);
        }
    }

    const handleToggleUpdateModal = async () => {
        const response = await fetchTemplates(selectedTemplateType.id, searchTemplateText, currentPage);
        setShowModal(TEMPLATE_UPDATE);
        setCurrentPage(1);
    }

    const handleDeleteTemplate = async () => {
        const response = await documentTemplateServices.deleteTemplate({ documentId: deleteTemplateId }, orgName);
        if (response?.message) {
            setDeleteTemplateId('');
            handleCloseModal();
            toast.success(t('TEMPLATE_DELETED'))
            await fetchTemplates(selectedTemplateType.id, searchTemplateText, currentPage);
        }
    }

    const handleShowDeleteTemplateModal = (templateId) => {
        setDeleteTemplateId(templateId);
        setShowModal(TEMPLATE_DELETE);
    }

    const handleUpdateTemplate = (template) => {
        setShowModal(TEMPLATE_UPDATE);
        setSelectedTemplateData(template);
    }

    const handleCloseModal = () => {
        if (showModal === TEMPLATE_CATEGORY_UPDATE) {
            fetchTemplateCategories();
        }
        setShowModal('');
        setSelectedTemplateData({});
        setDeleteTemplateId('')
    }

    const handleShowCreateDocumentModal = (template) => {
        setSelectedTemplateData(template);
        setShowModal(DOCUMENT_CREATE);
    }

    const handleShowCategoryModal = () => {
        setShowModal(TEMPLATE_CATEGORY_UPDATE);
    }

    useEffect(() => {
        const getTemplates = async () => {
            const response = await fetchTemplates(selectedTemplateType.id, searchTemplateText, currentPage);
            await fetchTemplateCategories();
            if (response) {
                setTotalCount(response?.totalCount);
            }
        };
        getTemplates();
    }, [])

    useEffect(() => {
        fetchTemplates(selectedTemplateType.id, searchTemplateText, currentPage);
    }, [selectedTemplateType.id, searchTemplateText])

    const handleCategoryChange = (currentCategory) => {
        setSelectedTemplateType(currentCategory);
    }

    const UploadTemplateHTML = () => {
        return (
            <Card className='create-template-list template-card cursor-pointer' title='Create New Template'>
                <Card.Body className='template-card-body' onClick={handleToggleShowUploadModal}>
                    <div className='template-creation-div'>
                        <div className='template-list-create-icon' style={{ background: textColor }}>
                            <i class="bi bi-file-earmark-plus" style={{ color: '#fff' }}></i>
                        </div>
                        <div className='create-template-div'>{t("CREATE_TEMPLATE")}</div>
                        {/* {t('UPLOAD_NEW_FILE')} */}
                    </div>
                </Card.Body>
            </Card>
        )
    }

    const updateSearchQuery = debounce((e) => {
        setSearchTemplateText(e.target.value);
    }, [1500])

    return (
        <section>
            <Container className='p-0'>
                <Row>
                    {/* template Header */}
                    <Col className='p-0'>
                        <div className='template-header my-documents-sortbar my-1' style={{ background: textColor }}>
                            <div className='dropwn-down-div'>
                                <DropdownButton className="templates-type-dropdown" id="template-dropdown" title={t(selectedTemplateType.name || "ALL_TEMPlATES")} style={{ background: textColor }}>
                                    <Dropdown.Item eventKey=""><div onClick={() => handleCategoryChange({ name: "" })}>{t("ALL_TEMPlATES")}</div></Dropdown.Item>
                                    {documentState.templateCategories.map(template => <Dropdown.Item eventKey={template.name}><div onClick={() => handleCategoryChange({ name: template.name, id: template.id })}>{t(template.name)}</div></Dropdown.Item>)}
                                </DropdownButton>
                                {roleData?.includes("Admin") && <i onClick={handleShowCategoryModal} className={`bi bi-pencil-square template-categories-modal-button zoom-transistion`}></i>}
                            </div>
                            <div className={`search-div zoom-transistion ${isFocused ? 'scale-transistion' : ''}`}>
                                <div className='search-icon-div'>
                                    <i class="bi bi-search"></i>
                                </div>
                                <input className='search-template' onChange={updateSearchQuery} onBlur={() => setIsFocused(false)} onFocus={() => {setIsFocused(true)}} placeholder={t('SEARCH_TEMPLATE')}/>
                            </div>
                            <div className={`add-icon ${showFormat === GRID_FORMAT || !roleData?.includes("Admin") ? 'invisible' : ''}`} onClick={handleToggleShowUploadModal}>
                                <i class="bi bi-file-earmark-plus"></i>
                            </div>
                            <div className='template-list-type'>
                                <i class="bi bi-grid-3x3-gap-fill pe-3" style={showFormat == GRID_FORMAT ? { color: textColor } : {}} onClick={() => setShowFormat(GRID_FORMAT)}></i>
                                <i class="bi bi-list-task" style={showFormat == ROW_FORMAT ? { color: textColor } : {}} onClick={() => setShowFormat(ROW_FORMAT)}></i>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='template-tab-body'>
                    {showFormat === ROW_FORMAT ? <Row className='mb-5'>
                        {documentState.templates.map((template, index) => {
                            const currentTemplateTitle = template?.title || "";
                            return (
                            <div className='template-row-data'>
                                <div className='template-title-feild cursor-pointer' onClick={() => handleShowCreateDocumentModal(template)}>
                                    <div>
                                        <img src={pdfIcon} width={20} alt="pdf-icon" />
                                    </div>
                                    <div title={currentTemplateTitle}>
                                        {currentTemplateTitle.slice(0, 23)}{currentTemplateTitle.length > 23 ? "..." : ""}
                                    </div>
                                </div>
                                <div className='template-row-aciton-icons'>
                                    <div>
                                        <a href={template?.url}>
                                            <i style={{ color: "green" }} className={`bi bi-download`}></i>
                                        </a>
                                    </div>
                                    <span className={`template-icon-divider ${!template.write_access ? 'd-none' : ''}`}></span>
                                    <div style={template.write_access ? { color: '#0c005c' } : { display: 'none' }} onClick={() => handleUpdateTemplate(template)}>
                                        <i className={`bi bi-pencil-square`}></i>
                                    </div>
                                    <span className={`template-icon-divider ${!template.write_access ? 'd-none' : ''}`}></span>
                                    <div style={template?.write_access ? { color: 'red' } : { display: ' none' }} onClick={() => handleShowDeleteTemplateModal(template.id)}>
                                        <i className={`bi bi-trash3-fill`}></i>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </Row> :
                        <Row className='mb-5'>
                            <Row className='template-col' md={3}>
                                {templateType !== 'global' && roleData?.includes("Admin") && UploadTemplateHTML()}
                                {documentState.templates.map((template, index) => (
                                    <Card className='existing-template-card template-card'>
                                        <Card.Body className='cursor-pointer' onClick={() => handleShowCreateDocumentModal(template)}>
                                            <div className='template-image-div' title={template?.title}>
                                                <img className='template-image' src={template?.thumbnailUrl || documentTemplateImages[index % 3]} alt="template-image" />
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className='template-card-footer'>
                                            <div className='template-title'>
                                                {template?.title?.slice(0,22)}
                                            </div>
                                            <div className='template-action-buttons'>
                                                <div className='template-download-icon'>
                                                    <a href={template?.url}>
                                                        <i style={{ color: "green" }} className="bi bi-download"></i>
                                                    </a>
                                                </div>
                                                <div className='template-edit-icon'>
                                                    <i className={`bi bi-pencil-square ${!template.write_access ? 'd-none' : ''} `} onClick={() => handleUpdateTemplate(template)}></i>
                                                </div>
                                                <div className="template-delete-icon">
                                                    <i className={`bi bi-trash3-fill  ${!template.write_access ? 'd-none' : ''}`} onClick={() => handleShowDeleteTemplateModal(template.id)}></i>
                                                </div>
                                            </div>
                                        </Card.Footer>
                                    </Card>
                                ))}
                            </Row>
                        </Row>}
                </div>
                <Row className='p-5'>
                    <Pagination total={totalCount} onPageChange={onPageChange} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </Row>
            </Container>
            {
                showModal === TEMPLATE_CATEGORY_UPDATE && (
                    <TemplateCategories
                        documentState={documentState}
                        selectedCategory={selectedTemplateType}
                        setSelectedCategory={setSelectedTemplateType}
                        currentTemplateCategories={documentState.templateCategories}
                        handleCloseModal={handleCloseModal}
                    />
                )
            }
            {
                showModal === TEMPLATE_UPLOAD && (
                    <TemplateUploadModal
                        toggleModal={handleToggleShowUploadModal}
                        toggleUpdateModal={handleToggleUpdateModal}
                    />
                )
            }
            {
                showModal === TEMPLATE_UPDATE && documentState?.templates && (
                    <TemplateUpdateModal
                        selectedDocInfo={selectedTemplateData?.id ? selectedTemplateData : documentState.templates[0]}
                        closeUpdateModal={handleCloseModal}
                    />
                )
            }
            {
                showModal === DOCUMENT_CREATE && (
                    <TemplateDocumentCreationModal
                        templateType={templateType}
                        templateData={selectedTemplateData}
                        handleCloseModal={handleCloseModal}
                    />
                )
            }
            {
                showModal === TEMPLATE_DELETE && (
                    <TemplateDeleteModal
                        handleCloseModal={handleCloseModal}
                        handleConfirmation={handleDeleteTemplate}
                        textColor={textColor}
                        confirmationHeading={td("DELETE_TEMPLATE")}
                        confirmationText={td('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TEMPLATE')}
                    />
                )
            }
            {isLoading && <Loader />}
        </section>
    );
};

export default Templates;
