import React, { useContext, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import Navbar from '../../components/Navbar/Navbar';
import './Documents.css';
import { navLinks, statusArabicMaping, statusEnglishMaping, textChangeApp } from '../../utils/constants';
import iconFeatherShare from './../../assets/icon-feather-share.png';
import iconOpenShare from './../../assets/icon-open-document.png';
import templateIcon from './../../assets/template-icon.png'
import Recall from './../../assets/Recalling icon 2-1.svg';
import editIcon from './../../assets/edit-icon.png';
import downloadIcon from './../../assets/download-icon.png';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { documentsTabs } from '../../utils/constants';
import { DocumentContext } from '../../store/document';
import moment from 'moment/moment';
import DocumentServices from '../../services/document.services';
import Spinner from '../../components/ui/Spinner';
import { Modal } from 'bootstrap';
import FileUpload from '../../components/common/FileUpload';
import SignerServices from '../../services/signer.services';
import { SignerContext } from '../../store/signer';
import { LoaderContext } from '../../store/Loader';
import { isUser } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import Pagination from '../../components/common/Pagination';
import DocModal from '../Document/DocModal';
import { Modal as bootstrapModal } from 'bootstrap';
import RequireAuth from '../../RequireAuth/RequireAuth';
import Templates from '../Templates/Templates';

const documentServices = new DocumentServices();
const signerServices = new SignerServices();

const Documents = () => {
  const location = useLocation();
  const { tab } = useParams();

  const orgName = location.pathname.split('/')[1];
  const { getOrgApp, documentState, fetchDocuments, documentDispatch, documentLoading, fetchDocumentDetails, getMyDocumentsByStatus, getSharedWithDocumentsByStatus } =
    useContext(DocumentContext);
  const { signerState, signerDispatch } = useContext(SignerContext);
  const { loaderDispatch } = useContext(LoaderContext);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [recipientsEmails, setRecipientsEmails] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' for ascending, 'desc' for descending
  const [sortColumn, setSortColumn] = useState(''); // Column to sort by, default is 'createdAt'
  const [myDocumentsFilter, setMyDocumentsFilter] = useState({
    status: '',
    day: '',
    page: ''
  });

  const [sharedWithMeDocumentFilter, setSharedWithMeDocumentFilter] = useState({
    status: '',
    day: '',
    page: ''
  });
  const [selectedDocInfo, setSelectedDocInfo] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');
  const [deleteDocId, setDeleteDocId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isHoveredOnLastStatus, setIsHoveredOnLastStatus] = useState(false);
  const textChangeAppData = textChangeApp(documentState)
  const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
  const modalRef = React.useRef();

  const [prevTab, setPrevTab] = useState('');

  const onPageChange = async (page) => {
    if (tab === 'shared-with-me') {
      await getSharedWithDocumentsByStatus({ ...sharedWithMeDocumentFilter, page: page });
    } else {
      await getMyDocumentsByStatus({ ...myDocumentsFilter, page: page });
    }
  }

  const { t } = useTranslation('documents');
  const { t: ts } = useTranslation('apiMessage');

  const { i18n } = useTranslation();

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const navigate = useNavigate();
  const openModalRef = useRef();
  const closeModalRef = useRef();

  const sortedData = documentState?.filteredDocuments?.data?.data?.sort((a, b) => {
    if (sortColumn === 'title') {
      return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
    }
    else if (sortColumn === 'status') {
      return sortOrder === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
    } else if (sortColumn === 'createdAt') {
      return sortOrder === 'asc' ? moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf() : moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf();
    }
    return 0;
  });

  const handleTableSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedDocuments = documentState?.filteredSharedWithMeDocuments?.data?.data?.sort((a, b) => {
    if (sortColumn === 'title') {
      return sortOrder === 'asc' ? a?.document?.title.localeCompare(b?.document?.title) : b?.document?.title.localeCompare(a?.document?.title);
    }
    else if (sortColumn === 'status') {
      return sortOrder === 'asc' ? a?.document?.status.localeCompare(b?.document?.status) : b?.document?.status.localeCompare(a?.document?.status);
    } else if (sortColumn === 'owner') {
      return sortOrder === 'asc' ? a?.document?.owner.localeCompare(b?.document?.owner) : b?.document?.owner.localeCompare(a?.document?.owner);
    } else if (sortColumn === 'createdAt') {
      return sortOrder === 'asc' ? moment(a?.createdAt).valueOf() - moment(b?.createdAt).valueOf() : moment(b?.createdAt).valueOf() - moment(a?.createdAt).valueOf();
    }
    else if (sortColumn === 'dueDate') {
      return sortOrder === 'asc' ? moment(a?.dueDate).valueOf() - moment(b?.dueDate).valueOf() : moment(b?.dueDate).valueOf() - moment(a?.dueDate).valueOf();
    }
    return 0;
  });

  useEffect(() => {
    // getOrgApp();
    if (Object.values(documentsTabs).indexOf(tab) < 0) {
      navigate(`/${orgName}/documents`);
    }

    if (documentState?.filteredDocuments?.status) {
      setMyDocumentsFilter(prevState => {
        return { ...prevState, status: documentState?.filteredDocuments?.status }
      })
    }
    if (documentState?.filteredSharedWithMeDocuments?.status) {
      setSharedWithMeDocumentFilter(prevState => {
        return { ...prevState, status: documentState?.filteredSharedWithMeDocuments?.status }
      })
    }

    let sharedWithMeFilter = Object.values(sharedWithMeDocumentFilter)?.filter(elem => !!elem);
    let documentFilter = Object.values(myDocumentsFilter)?.filter(elem => !!elem);
    if (documentState?.selectedDashboardFilter === '' && !documentState?.filteredDocuments?.status !== '' && !documentState?.filteredSharedWithMeDocuments?.status && sharedWithMeFilter.length < 1 && documentFilter.length < 1) {
      fetchDocuments();
    }

    //removing filter if applied from dashboard
    documentDispatch({ type: 'SET_SELECTED_DASHBOARD_FILTER', payload: '' })

    if (document.getElementsByClassName("modal-backdrop")[0]) {
      document.getElementsByClassName("modal-backdrop")[0].remove();
    }
  }, []);

  //fetch document on tabs change
  useEffect(() => {
    console.log(prevTab, tab)
    if (prevTab !== undefined && prevTab !== '') {
      fetchDocuments();
    }
    if (tab !== undefined) {
      setPrevTab(tab)
    }
  }, [tab])

  const getDocument = async (id) => {
    const document = await documentServices.getDocument(id);
    if (document) {
      documentDispatch({ type: 'SET_SELECTED_DOCUMENT', payload: document });
      if (document?.recipients) {
        signerDispatch({ type: 'SET_SIGNERS', payload: document?.recipients });
      }
    }
  }

  useEffect(() => {
    setMyDocumentsFilter(prevState => {
      return { ...prevState, status: documentState?.filteredDocuments?.status }
    })
  }, [documentState?.filteredDocuments?.status]);

  useEffect(() => {
    if (tab === 'shared-with-me') {
      setSharedWithMeDocumentFilter(prevState => {
        return { ...prevState, status: documentState?.filteredSharedWithMeDocuments?.status }
      })
    }
  }, [documentState?.filteredSharedWithMeDocuments?.status]);

  const deleteDocument = async (id) => {
    try {
      const response = await documentServices.deleteDocument({
        documentId: id,
      });
      if (response.message) {
        documentDispatch({ type: 'DELETE_DOCUMENT', payload: id });
        fetchDocuments();
        toast.success(ts('DOC_DELETED'));
      }
    } catch (error) {
      toast.error(ts('SIGNATURE_DELETE_FAIL'));
    }
  };

  const downloadDocument = (url) => {
    const pdfUrl = url;
    // Create an anchor element
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '';

    // Set the download attribute to force the download
    link.setAttribute('download', '');

    // Programmatically click the link to trigger the download
    link.click();
    toast.success(ts('DOC_DOWNLOADED'), { toastId: 'success2' });
  };

  const handleDocumentFilterOnStatusChange = (e) => {
    // const isParamsInNum = Number(e.target.value);
    setCurrentPage(1)
    if (tab === 'shared-with-me') {
      setIsStatusChanged(!isStatusChanged);
      if (e.target.id === 'status-dropdown') {
        setSharedWithMeDocumentFilter(prevState => {
          return { ...prevState, status: e.target.value }
        });
      } else {
        setSharedWithMeDocumentFilter(prevState => {
          return { ...prevState, day: e.target.value }
        });
      }
    } else {
      setIsStatusChanged(!isStatusChanged);
      if (e.target.id === 'status-dropdown') {
        setMyDocumentsFilter(prevState => {
          return { ...prevState, status: e.target.value }
        });
      } else {
        setMyDocumentsFilter(prevState => {
          return { ...prevState, day: e.target.value }
        });
      }
    }
  };

  useEffect(() => {
    if (tab === 'shared-with-me') {
      let sharedWithMeFilter = Object.values(sharedWithMeDocumentFilter)?.filter(elem => !!elem);
      if (sharedWithMeFilter.length > 0) {
        getSharedWithDocumentsByStatus(sharedWithMeDocumentFilter)
      }
    } else {
      let filter = Object.values(myDocumentsFilter)?.filter(elem => !!elem);
      if (filter.length > 0) {
        getMyDocumentsByStatus(myDocumentsFilter);
      }
    }
  }, [isStatusChanged]);

  const showRecipientsModal = (recipientsEmails) => {
    setRecipientsEmails([]);
    const documentRecipientsEmails = [];
    recipientsEmails.map(
      (email) => email?.email && documentRecipientsEmails.push(email?.email)
    );
    setRecipientsEmails(documentRecipientsEmails);
    const modalEle = openModalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: 'static',
      keyboard: false,
    });
    bsModal.show();
  };

  // const handleViewRecipientClick = (id) => {
  //   navigate(`/documents/${id}/update`);
  // };

  const handleSendMailToAllRecipient = async (id) => {
    try {
      loaderDispatch({ type: 'SET_LOADING', payload: true });
      const document = documentState?.filteredDocuments?.data?.data.find(doc => doc?.id === id);
      const IDs = document?.recipients?.filter(signer => signer?.overallSigningStatus !== 'COMPLETED').map(signer => signer?.id);
      if (IDs?.length < 1) {
        return toast.warn(ts('SIGNER_NOT_FOUND'));
      }
      const res = await signerServices.sendRecipientEmail({ recipientIds: IDs });
      if (res) {
        toast.success(ts(res?.code || res?.message));
        fetchDocuments();
      }
    } catch (error) {
      toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`));
    } finally {
      loaderDispatch({ type: 'SET_LOADING', payload: false });
    }
  }

  const handleViewRecipientClick = async (id) => {
    const document = documentState?.documents?.find(doc => doc?.id === id) || false;
    const isEmailSent = document?.recipients?.[0]?.emailSent ? true : false;
    if (isEmailSent) {
      navigate(`/${orgName}/documents/${id}/view-signer`);
    } else {
      navigate(`/${orgName}/documents/${id}/add`)
      await fetchDocumentDetails(id);
    }
  }

  const handleTitleClick = async (id) => {
    // const document = await getDocument(id);
    navigate(`/${orgName}/documents/${id}/update`);
  }

  useEffect(() => {
    const updatedData = documentState?.filteredDocuments?.data?.data.filter((d) => d?.id === selectedDocInfo?.id)[0];
    setSelectedDocInfo(updatedData)
  }, [documentState?.filteredDocuments])

  const onTitleHandle = async (data) => {
    loaderDispatch({ type: 'SET_LOADING', payload: true });
    if (data.status === 'DRAFT') {
      setSelectedDocInfo(data);
      setSelectedColumn('Title');
    } else {
      await handleTitleClick(data.id);
    }
    loaderDispatch({ type: 'SET_LOADING', payload: false });
  }

  const onSignersHandleClick = async (data) => {
    if (!data) {
      return;
    }
    if (data.status === 'DRAFT') {
      setSelectedDocInfo(data);
      setSelectedColumn(data?.recipients?.length > 0 ? t('viewRespe') : t('ADD_SIGNER'));
    } else {
      navigate(`/${orgName}/documents/${data?.id}/view-signer`)
    }
  }

  const filteredTotalDocuments = documentState?.filteredDocuments?.data?.totalCount;
  const filteredTotalSharedWithMeDocuments = documentState?.filteredSharedWithMeDocuments?.data?.totalCount;

  const handleSharedWithMeDocumentClick = async (recipients = [], documentId) => {
    const { id } = recipients?.find(recipient => recipient.email === isUser().email)
    navigate(`/${orgName}/documents/${documentId}/sign/${id}`)
  }

  const onModalClose = () => {
    setSelectedDocInfo(null)
    setSelectedColumn('');
    signerDispatch({ type: 'SET_SIGNERS', payload: [] });
  }

  const showModal = (doc) => {
    setDeleteDocId(doc)
    const modalEle = modalRef.current;
    const bsModal = new bootstrapModal(modalEle);
    bsModal.show();
  };

  const hideModal = () => {
    const modalEle = modalRef.current;
    const bsModal = bootstrapModal.getInstance(modalEle);
    bsModal.hide();
  };

  const handleRecall = async (documentId) => {
    try {
      await documentServices.recallDocument(documentId);
      fetchDocuments();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <div style={{ minHeight: '94.4vh' }}>
      <Navbar pageName={navLinks.documents} />
      <FileUpload />
      {/* Sub Nav Bar Links */}
      <section className="pt-4 pb-2 f-small-device">
        <div className="container my-documents-navbar d-flex my-2 tabstour">
          <Link
            to={`/${orgName}/documents/tab/shared-with-me`}
            className={`my-documents-nav-link mx-2 px-4 d-flex align-items-center ${tab === documentsTabs.SHARED_WITH_ME &&
              ' active-my-documents-nav-link'
              }`}
          >
            <img
              src={iconFeatherShare}
              alt="document icon"
              width={18}
              height={18}
            />
            <span className="mx-2 py-2 position-relative">
              {t('shared')}
              {documentState?.sharedDocumentAwaitingCount > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {documentState?.sharedDocumentAwaitingCount > 100 ? '100+' : documentState?.sharedDocumentAwaitingCount}
                </span>
              )}
            </span>
          </Link>
          <Link
            to={`/${orgName}/documents`}
            className={`my-documents-nav-link mx-2 px-4 d-flex align-items-center ${(tab === documentsTabs.MY_DOCUMENTS || !tab) &&
              ' active-my-documents-nav-link'
              }`}
          >
            <img
              src={iconOpenShare}
              alt="document icon"
              width={15}
              height={18}
            />
            <span className="mx-2 py-2">{t('myDocs')}</span>
          </Link>
          <Link
            to={`/${orgName}/documents/tab/templates`}
            className={`my-documents-nav-link mx-2 px-4 d-flex align-items-center ${(tab === documentsTabs.TEMPLATES) && ' active-my-documents-nav-link'}`}
          >
            <img
              src={templateIcon}
              alt="template icon"
              width={20}
              height={25}
            />
            <span className="mx-2 py-2">{t('templates')}</span>
          </Link>
        </div>
      </section>

      {tab !== documentsTabs.TEMPLATES && (
        <>
          <section className="my-documents-sortbar-section text-light">
            <div className=" documentsSortingTour container my-documents-sortbar my-1" style={{
              backgroundColor: textChangeAppData
            }}>

              <div className="row">
                {/* <div className='col-sm col-xs'></div> */}
                <div className="col-xs col-sm-6 col-md-5 col-lg-3 py-1 d-flex justify-content-center">
                  <span>{t('Status')}</span>
                  <select
                    value={`${tab === 'shared-with-me' ? sharedWithMeDocumentFilter?.status : myDocumentsFilter?.status}`}
                    onChange={(e) => handleDocumentFilterOnStatusChange(e)}
                    className="mx-2 my-documents-sort-select"
                    id="status-dropdown"
                  >
                    <option value="all">{t('select')}</option>
                    {tab !== documentsTabs.SHARED_WITH_ME && (
                      <option value="DRAFT">{t('draft')}</option>
                    )}
                    <option value="AWAITING">{t('waitfrsgn')}</option>
                    <option value="COMPLETED">{t('completed')}</option>
                    <option value="REJECTED">{t('REJECTED')}</option>
                  </select>
                </div>
                <div className="col-xs col-sm-6 col-md-5 py-1 d-flex justify-content-center">
                  <span>{t('createdon')}</span>
                  <select
                    value={`${tab === 'shared-with-me' ? sharedWithMeDocumentFilter?.day : myDocumentsFilter?.day}`}
                    onChange={(e) => handleDocumentFilterOnStatusChange(e)}
                    className="mx-2 my-documents-sort-select"
                    id="created-dropdown"
                  >
                    <option value="all">{t('select')}</option>
                    <option value="1">{t('1dayago')}</option>
                    <option value="3">{t('3daysago')}</option>
                    <option value="7">{t('1weekago')}</option>
                    <option value="30">{t('1monthago')}</option>
                    <option value="365">{t('1yearago')}</option>
                  </select>
                </div>
              </div>
            </div>
          </section>
        </>)}

      {/* My Documents section - starts here */}
      {(tab === documentsTabs.MY_DOCUMENTS || !tab) && (
        <>
          <div className='table-responsive'>
            <table className="table container my-docs-table borderless w-72 documentsTableTour">
              <thead style={{
                color: textChangeAppData
              }}>
                <tr>
                  <th scope="col" onClick={() => handleSort('title')} className='cursor-pointer'>
                    {t('Title')} {sortColumn === 'title' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                  </th>
                  <th scope="col" onClick={() => handleSort('recipients')}>
                    {t('Signr')}
                  </th>
                  <th scope="col" className='cursor-pointer' onClick={() => handleSort('status')}>
                    {t('Status')} {sortColumn === 'status' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                  </th>
                  <th scope="col" className='cursor-pointer' onClick={() => handleSort('createdAt')}>
                    {t('createdon')} {sortColumn === 'createdAt' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                  </th>
                  <th scope="col">
                    {t('Actions')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {documentLoading ? (
                  <tr>
                    <td colSpan="5"><Spinner /></td>
                  </tr>
                ) : sortedData?.length > 0 ? (
                  sortedData?.map((doc, index) => {
                    const currentDocumentTitle = doc?.title || "";
                    return (
                      <>
                        <tr key={`my-docs${doc?.id}`}>
                          <td className='p-3 text-start'>
                            <div
                              className='cursor-pointer p-2'
                              title={currentDocumentTitle}
                              onClick={() => onTitleHandle(doc)}
                            >
                              {currentDocumentTitle.slice(0, 23)}{currentDocumentTitle.length > 23 ? "..." : ""}
                            </div>
                          </td>
                          <td className='p-4'>
                            <div onClick={() => onSignersHandleClick(doc)} title="Click to view recipients"
                              className='cursor-pointer'
                              style={{
                                color: textChangeAppData,
                              }}>
                              {doc?.recipients?.length > 0 ? t('viewRespe') : t('ADD_SIGNER')}
                            </div>
                          </td>
                          <td className='p-4'>
                            <div className="col dropdown tooltip-drop">
                              <div className='signers-view status-draft' type="button" data-toggle="tooltip" data-html="true" style={{
                                color: doc?.status !== 'REJECTED' ? textChangeAppData : 'rgba(255, 2, 2, 0.831)'
                              }} onMouseOver={() => index === sortedData?.length - 1 ? (isHoveredOnLastStatus !== true && setIsHoveredOnLastStatus(true)) : (isHoveredOnLastStatus !== false && setIsHoveredOnLastStatus(false))}>
                                {i18n.language === 'en' ? statusEnglishMaping[doc?.status] : i18n.language === 'ar' ? statusArabicMaping[doc?.status] : statusEnglishMaping[doc.status]}
                              </div>
                              <div className={`dropdown-content ${isHoveredOnLastStatus ? 'tooltipdrop-content-top' : 'tooltipdrop-content'}`} style={{
                                maxHeight: '200px', overflowY: 'scroll', backgroundColor: textChangeAppData
                              }}>
                                <div className='d-flex justify-content-between p-2'>
                                  <div>{
                                    doc?.recipients?.length > 0 ?
                                      doc?.recipients?.map(recipient => (
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                          <p className='me-3'>{recipient?.email}</p>
                                          {recipient?.overallSigningStatus === 'PENDING' ? <div className='mt-1' style={{ minWidth: '6rem' }}>
                                            <p className='d-flex justify-content-center recipient-status-bg px-2 color-black' >
                                              <span>
                                                <p className='me-2 p-1 recipient-status-pending'><i className="bi bi-exclamation-lg color-black"></i></p>
                                              </span><strong>{t('PENDING')}</strong></p>
                                          </div> : recipient?.overallSigningStatus === 'COMPLETED' ? <div className=''>
                                            <p className='d-flex justify-content-center recipient-status-bg color-black' >
                                              <span>
                                                <p className='me-2 p-1 recipient-status-complete'>&#10004;</p>
                                              </span><strong>{t('completed')}</strong></p>
                                          </div> : recipient?.overallSigningStatus === 'REJECTED' ? <div className='mt-1' style={{ minWidth: '6rem' }}>
                                            <p className='d-flex justify-content-center recipient-status-bg color-black'>
                                              <span>
                                                <p className='me-2 p-1 recipient-status-reject'><i className="bi bi-x-lg color-black"></i></p>
                                              </span><strong>{t('REJECTED')}</strong>
                                            </p>
                                          </div> : ''}
                                        </div>
                                      )) : <p className='mt-1 mb-1'>{t('NoRecipient')}</p>
                                  }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='p-4'>{moment(doc?.createdAt).format('DD-MM-YYYY')}</td>
                          <td className='p-4 docActionsBtnTour'>
                            <div className="col d-flex align-items-center gap-4 justify-content-center">
                              {doc?.status === 'DRAFT' ?
                                <img
                                  onClick={() => onTitleHandle(doc)}
                                  src={editIcon}
                                  alt="document icon"
                                  className="me-0 cursor-pointer"
                                  width={15}
                                  height={15}
                                  title="Click to Edit"
                                /> : <div className="me-3"></div>}
                              <img
                                src={downloadIcon}
                                alt="document icon"
                                onClick={() => downloadDocument(doc?.url)}
                                className="mx-0 cursor-pointer"
                                width={15}
                                height={15}
                                title="Click to Download"
                              />

                              <i
                                onClick={() => showModal(doc?.id)}
                                className={`bi bi-trash-fill mx-0 text-danger cursor-pointer`}
                              ></i>

                              <div
                                className="modal"
                                id="staticBackdrop"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                                ref={modalRef} tabIndex="-1"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered modal-dashboard"
                                  data-bs-backdrop="static"
                                >
                                  <div className="modal-content">
                                    <div className="modal-heading-dash py-3 rounded-2" style={{ backgroundColor: textChangeAppData }}>
                                      <h4
                                        className="modal-titled d-flex justify-content-center mt-1 text-white"
                                        id="staticBackdropLabel"
                                      >
                                        {t('deleteDoc')}
                                      </h4>
                                    </div>
                                    <div className="mt-5">
                                      <label
                                        className="mx-3 text-center"
                                      >
                                        {t('deleteDescription')}
                                      </label>
                                      <div
                                        className="d-flex justify-content-center"
                                      >
                                        <div className="modal-footer mt-5">
                                          <button
                                            type="button"
                                            className="btn btn-secondary deleteBtn"
                                            style={{ backgroundColor: textChangeAppData ? textChangeAppData : defaultPrimaryColorValue }}
                                            data-bs-dismiss="modal"
                                            onClick={hideModal}
                                          >
                                            {t('cancelBtn')}
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-primary deleteBtn"
                                            style={{ backgroundColor: textChangeAppData || defaultPrimaryColorValue }}
                                            onClick={() => deleteDocId ? (hideModal(), deleteDocument(deleteDocId)) : toast.error(ts('SOMETHING_WENT_WRONG'))}
                                          >
                                            {t('deleteBtn')}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {doc?.status === 'DRAFT' ?
                                <button
                                  disabled={!doc?.recipients?.length}
                                  onClick={() => handleSendMailToAllRecipient(doc?.id)}
                                  style={{ backgroundColor: textChangeAppData }}
                                  className={`${doc?.recipients?.length ? 'send-btn' : 'send-btn-disabled'} px-0`}>
                                  <i className="me-0 bi bi-send"></i>
                                  {t('sendBtn')}
                                </button>
                                :
                                // <button
                                //   disabled={(doc?.status === "AWAITING" && moment(Math.max(...doc?.recipients?.map(recipient => new Date(recipient?.dueDate)))) > moment(new Date()))}
                                //   onClick={() => handleSendMailToAllRecipient(doc?.id)}
                                //   style={{ backgroundColor: textChangeAppData }}
                                //   className={`px-3 ${doc?.status !== 'AWAITING' || (doc?.status === "AWAITING" && moment(Math.max(...doc?.recipients?.map(recipient => new Date(recipient?.dueDate)))) > moment(new Date())) ? 'send-btn-disabled' : 'send-btn'}`}>
                                //   <i className="me-2 bi bi-send"></i>
                                //   {t('resSendBtn')}
                                // </button>
                                <button
                                  disabled={!doc?.resendButtonEnabled}
                                  onClick={() => handleSendMailToAllRecipient(doc?.id)}
                                  style={{ backgroundColor: textChangeAppData }}
                                  className={`px-0 ${!doc?.resendButtonEnabled ? 'send-btn-disabled' : 'send-btn'}`}>
                                  <i className="me-0 bi bi-send"></i>
                                  {t('resSendBtn')}
                                </button>
                              }

                              {
                                doc?.status === "AWAITING" ?
                                  <button
                                    onClick={() => { handleRecall(doc?.id) }} style={{
                                      backgroundColor: textChangeAppData
                                    }}
                                    className='send-btn send-btn'
                                  ><img src={Recall} className='recall px-0' />
                                    {t('recall')}
                                  </button> : <div className='send-btn'></div>
                              }
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5"><h5 className='text-center m-5 bold'>{t('noDocs')}</h5></td>
                  </tr>
                )}
              </tbody>
            </table>


          </div>
          <div className='p-4'>
            <Pagination total={filteredTotalDocuments || 0} onPageChange={onPageChange} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </div>
        </>
      )}
      {/* My Documents section - ends here */}

      {/* Shared With Me section - starts here */}
      {tab === documentsTabs.SHARED_WITH_ME && (
        <>
          <div className='table-responsive'>
            <table className="table container borderless my-docs-table w-72 sharedWithMeTableTour">
              <thead style={{
                color: textChangeAppData
              }}>
                <tr>
                  <th scope="col" onClick={() => handleSort('title')} className='cursor-pointer'>
                    {t('Title')} {sortColumn === 'title' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                  </th>
                  <th scope="col" onClick={() => handleSort('recipients')}>
                    {t('Signr')}
                  </th>
                  <th scope="col" className='cursor-pointer' onClick={() => handleSort('status')}>
                    {t('Status')} {sortColumn === 'status' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                  </th>
                  <th scope="col" className='cursor-pointer' onClick={() => handleSort('owner')}>
                    {t('owner')} {sortColumn === 'owner' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                  </th>
                  <th scope="col" className='cursor-pointer' onClick={() => handleSort('createdAt')}>
                    {t('createdon')} {sortColumn === 'createdAt' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                  </th>
                  <th scope="col" className='cursor-pointer' onClick={() => handleSort('dueDate')}>
                    {t('dueDate')} {sortColumn === 'dueDate' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                  </th>
                  <th scope="col">
                    {t('Actions')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {documentLoading ? (
                  <tr>
                    <td colSpan="5"><Spinner /></td>
                  </tr>
                ) : sortedDocuments?.length > 0 ? (
                  sortedDocuments?.map((doc) => {
                    return (
                      <>
                        <tr key={`my-docs${doc?.document?.id}`}>
                          <td className='p-3 text-start'>
                            <div className=' p-2 cursor-pointer' onClick={() => handleSharedWithMeDocumentClick(doc?.all_recipients, doc?.document?.id)}>
                              {doc?.document?.title}
                            </div>
                          </td>
                          <td className='p-4'>
                            <div onClick={() => navigate(`/${orgName}/documents/${doc?.document?.id}/view-signer`)} title="Click to view recipients"
                              className='cursor-pointer'
                              style={{
                                color: textChangeAppData,
                              }}>
                              {t('viewRespe')}
                            </div>
                          </td>
                          <td className='p-4'>
                            <div className={`col ${doc?.document?.status === 'REJECTED' ? 'red-text' : ''}`}>
                              {i18n.language === 'en' ? statusEnglishMaping[doc?.document?.status] : i18n.language === 'ar' ? statusArabicMaping[doc?.document?.status] : statusEnglishMaping[doc.document?.status]}
                            </div>
                          </td>
                          <td className='p-4'>
                            <div className="col text-capitalize">{doc?.document?.owner}</div>
                          </td>
                          <td className='p-4'>
                            <div className="col">{moment(doc?.document?.createdAt).format('DD-MM-YYYY')}</div>
                          </td>
                          <td className='p-4'>
                            <div className="col">{moment(doc?.dueDate).format('DD-MM-YYYY')}</div>
                          </td>
                          <td className='p-4'>
                            <div className="col">
                              {/* {doc?.document?.status === 'COMPLETED' ? (
                                <img
                                  src={editIcon}
                                  alt="document icon"
                                  className="me-2 cursor-default"
                                  width={15}
                                  height={15}
                                  disabled
                                />
                              ) : (
                                <img
                                  src={editIcon}
                                  alt="document icon"
                                  className="me-2 cursor-pointer"
                                  width={15}
                                  height={15}
                                  onClick={() => handleSharedWithMeDocumentClick(doc?.all_recipients, doc?.document?.id)}
                                />
                              )} */}
                              <img
                                src={downloadIcon}
                                alt="document icon"
                                onClick={() => downloadDocument(doc?.document?.url)}
                                className="mx-2 cursor-pointer"
                                width={15}
                                height={15}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7"><h5 className='text-center m-5 bold'>{t('noDocs')}</h5></td>
                  </tr>
                )}
              </tbody>
            </table>


          </div>
          <div className='p-4'>
            <Pagination total={filteredTotalSharedWithMeDocuments || 0} onPageChange={onPageChange} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </div>
        </>
      )}
      {/* Shared With Me section - ends here */}

      {/* Template Section - Startes Here */}
      {tab === documentsTabs.TEMPLATES && (
        <>
          <RequireAuth>
            <Templates />
          </RequireAuth>
        </>
      )}

      {/* Template Section - ends Here*/}

      {/* Recipients Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={openModalRef}
      >
        <div className="modal-dialog-centered" role="document">
          <div className="modal-content modal-dialog models-body">
            <div style={{ padding: "10px 18px" }} className="modal-header">
              <h5 style={{ fontSize: '17px' }} className="modal-title">Document Recipients</h5>
              <button
                type="button"
                className="close close-icon"
                title="Close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeModalRef}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div style={{ marginTop: '-7px' }} className="modal-body">
              {recipientsEmails && recipientsEmails.length > 0 && (
                <ol className="mb-0">
                  {recipientsEmails.map((email) => (
                    <li>{email}</li>
                  ))}
                </ol>
              )}
              {recipientsEmails && recipientsEmails.length === 0 && (
                <h5 className="m-auto my-3 text-danger text-center">
                  No recipients found.
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* new dOC modal */}
      {selectedDocInfo && <DocModal selectedDocInfo={selectedDocInfo} selectedColumn={selectedColumn} onModalClose={onModalClose} />}
    </div>
  );
};

export default Documents;
