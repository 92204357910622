import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "./PaymentHistory.css";
import downloadIcon from '../../../assets/download.svg'
import plusIcon from '../../../assets/Group 371.png'
import visa from "../../../assets/visa.png"
import doownloadIcon from "../../../assets/download.png"
import pdf from "../../../assets/pdf.png"
import { toast } from "react-toastify";
import AddNewPaymentModal from "./AddNewPaymentModal";
import paymenthistoryservices from '../../../services/paymentHistoryServices';
import SubscriptionServices from '../../../services/subscription.services';
import Spinner from "../../../components/ui/Spinner";
import moment from "moment";
import Loader from "../../../components/ui/Loader";
import { SubscriptionContext } from '../../../store/subscription';
import { getDayDifferenceFromCurrent } from '../../../utils/helper';
import useSubscriptions from '../Subscriptions/useSubscription';


const paymenthistoryServices = new paymenthistoryservices();
const subscriptionServices = new SubscriptionServices();


const PaymentHistory = ({ fontStyle, textChangeAppData, selectedColor }) => {
    const { t } = useTranslation('admin');
    const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
    const [Spinnerloading, setSpinnerloading] = useState(false)
    const [Invoices, setInvoices] = useState()
    const [cards, setCards] = useState()
    const [selectedItems, setSelectedItems] = useState([]);
    const [AddPaymentModal, setAddPaymentModal] = useState(false);
    const [SubscribedPLan, setSubscribedPLan] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const {subscriptionState: { activePlan = {} } = {}} = useContext(SubscriptionContext);
    const { getMySubscription, subscriptionLoading } = useSubscriptions();
    const { totalUser, storage, validity  } = activePlan || {};
    const isAllSelected = selectedItems?.length === Invoices?.length;
    const handleSelectAllChange = () => {
        if (selectedItems?.length === Invoices?.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(Invoices?.map((item) => item.id));
        }
    };
    const isItemSelected = (itemId) => selectedItems.includes(itemId);
    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                return prevSelectedItems.filter((id) => id !== itemId);
            } else {
                return [...prevSelectedItems, itemId];
            }
        });
    };
    // {
    //     Invoices?.filter((item) => isItemSelected(item.id))
    //         .map((selectedItem) => (
    //             console.log(selectedItem)
    //         ))
    // }
    const handleDownloadAll = async () => {
        const selecteted = Invoices?.filter((item) => isItemSelected(item.id)).map((item) => item.id);
        const zip = await subscriptionServices?.downloadSelectedPdfs({invoiceIds: selecteted});
        // console.log({zip})
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setSpinnerloading(true);
                setIsLoading(true);
                const [invoicesResponse, cardsResponse, subscribedPlanResponse] = await Promise.all([
                    paymenthistoryServices.getInvoices(),
                    paymenthistoryServices.getCards(),
                    subscriptionServices.getSubscriptions()
                ]);

                setInvoices(invoicesResponse?.data);
                setCards(cardsResponse?.data);
                setSubscribedPLan(subscribedPlanResponse.data);
                setIsLoading(false)

            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("An error occurred while fetching data.");
            } finally {
                setSpinnerloading(false);
            }
        };

        fetchData();
        getMySubscription();
    }, []);
    
    const remainingDays = Math.abs(getDayDifferenceFromCurrent(validity));

    return (
        <>
            {(isLoading || subscriptionLoading) ? (
                <Loader />
            ) : <div>
                <div className="container mt-4 w-100 appearance-whole rounded-4 p-4">
                    <section className="payment-history-border d-flex justify-content-between">
                        <div>
                            <h3 className="mb-0 fw-bold" style={{ color: textChangeAppData || defaultPrimaryColorValue }}>{t(`paymentHistoryTab.${SubscribedPLan?.name}`)} {t('paymentHistoryTab.plan')}</h3>
                            <p>{t('paymentHistoryTab.IncludesInto')} {totalUser} {t('paymentHistoryTab.usersAnd')} {storage} {t('paymentHistoryTab.individualData')}</p>
                        </div>
                        <p className="mb-0 fs-4 fw-bold" style={{ color: textChangeAppData || defaultPrimaryColorValue }}>{remainingDays} {t(`paymentHistoryTab.${remainingDays > 1 ? 'daysRemaining' : 'dayRemaining'}`)}</p>
                    </section>
                    <section className="d-flex justify-content-between align-items-center mt-3">
                        <div>
                            <h3 className="mb-0 fw-bold" style={{ color: textChangeAppData || defaultPrimaryColorValue }}>{t('paymentHistoryTab.PaymentMethods')}</h3>
                            {/* <p>{t('paymentHistoryTab.Changehowtopayforyourplan')}</p> */}
                        </div>
                        <button
                            style={{ backgroundColor: textChangeAppData ? textChangeAppData : defaultPrimaryColorValue }}
                            className='btn text-white px-2 py-1 mb-4 addNewPaymentCardTour btn text-white px-2 py-1 mb-4 d-none' onClick={() => { setAddPaymentModal(true) }}
                            // className='btn text-white px-2 py-1 mb-4 addNewPaymentCardTour btn text-white px-2 py-1 mb-4 d-none' onClick={() => { setAddPaymentModal(true) }}
                        >
                            <img src={plusIcon} alt="Plus Icon" className="plus-icon me-1 mb-1" />
                            {t('paymentHistoryTab.Addanewpayment')}
                        </button>
                    </section>
                    <div className="allCardsTour">
                        {cards?.length > 0 ? cards?.map((card) => {
                        return (<section className="d-flex flex-column flex-sm-row justify-content-between p-4 mb-1 card-section" style={{ backgroundColor: 'rgb(179 205 217)', borderRadius: '12px', height: 'auto' }}>
                            <div className="d-flex flex-row details-card align-items-center" >
                                {/* VISA */}
                                <div className="cardImages">
                                    <img className="d-flex justify-content-center align-items-center res-height" src={visa} />
                                </div>
                                {/* VISA DETAIL */}
                                <div>
                                    <div className="d-flex flex-column gap-3 card-details flex-sm-row">
                                        <h3 className="fontStyle"> {card?.cardName} **** **** **** {card?.last4}</h3>
                                        <div className="d-flex justify-content-end primary-alignmnt ">
                                            <h5 className="primary"  >{t('PRIMARY')}</h5>
                                        </div>
                                    </div>
                                    <div className="font-styles">{t('paymentHistoryTab.Expiry')}  &nbsp;{card?.expiry}</div>
                                </div>

                            </div>
                            <div className="d-flex align-items-center justify-content-end card-actions gap-4 editAndDeleteTour">
                                {/* <div>
                                    <i class="bi bi-trash3-fill d-flex justify-content-center align-items-center" style={{ color: 'red' }}></i></div>
                                <button
                                    style={{ backgroundColor: textChangeAppData ? textChangeAppData : defaultPrimaryColorValue, height: 'auto' }}
                                    className='btn text-white edit-buton'
                                >
                                    {t('paymentHistoryTab.Edit')}
                                </button> */}
                            </div>
                        </section>)
                    }) : ''}</div>
                    

                </div>
                <nav className="container mt-4 w-100 appearance-whole rounded-4 d-flex align-items-center justify-content-between px-4 pt-3 pb-0" style={{ backgroundColor: textChangeAppData ? textChangeAppData : defaultPrimaryColorValue }}>
                    <div className="text-white">
                        <h4 className="mb-0 fw-bold">{t('paymentHistoryTab.BillingHistory')}</h4>
                        <p>{t('paymentHistoryTab.Downloadpreviousplan')}</p>
                    </div>
                    {selectedItems.length > 0 && (
                        <button
                            className='btn text-dark bg-white px-2 py-1 mb-4'
                            onClick={handleDownloadAll}
                        >
                            <img src={downloadIcon} alt="Download Icon" className="download-icon me-2" />
                            {t('paymentHistoryTab.downloadSelected')}
                        </button>
                    )}
                </nav>
                {/* TABLE START HERE */}
                <>
                    <div className='table-responsive mt-2 mb-2'>
                        <table className="table container my-docs-table borderless w-72 ">
                            <thead style={{
                                color: textChangeAppData
                            }}>
                                <tr>
                                    <th scope="col"
                                        // onClick={() => handleSort('title')} 
                                        className='cursor-pointer d-flex ms-2 gap-2 pl-25'>
                                        {/* <div class="form-check">
                                            <input  checked={isAllSelected} onChange={handleSelectAllChange} class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        </div> */}
                                        {t('paymentHistoryTab.Invoice')}                              </th>
                                    <th scope="col"
                                    >
                                        {t('paymentHistoryTab.Billingdate')}                              </th>
                                    <th scope="col" className='cursor-pointer'>
                                        {t('paymentHistoryTab.Amount')}                         </th>
                                    <th scope="col" className='cursor-pointer'>
                                        {t('paymentHistoryTab.plan')}                         </th>
                                    {/* <th scope="col">
                                    Users
                                </th>
                                <th scope="col">
                                    Add-on
                                </th> */}
                                    <th scope="col">

                                    </th>
                                </tr>
                            </thead>
                            <tbody className="billingHistoryTour" >

                                {Spinnerloading ? (
                                    <tr>
                                        <td colSpan="5"><Spinner /></td>
                                    </tr>
                                ) : Invoices?.length > 0 ? (
                                    Invoices?.map((Invoice) => {
                                        return (
                                            <>
                                                <tr >
                                                    <td className='p-3 text-start  d-flex gap-2'>
                                                        {/* <div class="form-check">
                                                            <input class="form-check-input" onChange={() => handleCheckboxChange(Invoice?.id)} checked={isItemSelected(Invoice?.id)} type="checkbox" value="" id="flexCheckDefault" />
                                                        </div> */}
                                                        <img style={{ height: '2rem' }} src={pdf} />
                                                        <div className='cursor-pointer p-2 d-flex justify-content-center pl-25' >
                                                            {Invoice?.name}
                                                        </div>
                                                    </td>
                                                    <td className='p-3 text-start'>
                                                        <div className='cursor-pointer p-2 d-flex justify-content-center' >
                                                            {moment(Invoice?.billingDate).format('MMM DD, YYYY')}
                                                        </div>
                                                    </td>
                                                    <td className='p-3 text-start'>
                                                        <div className='cursor-pointer p-2 d-flex justify-content-center' >
                                                             {Invoice?.amountPaid} <span style={{paddingLeft: '5px'}} className='text-uppercase pl-5'>{Invoice?.currency}</span>
                                                        </div>
                                                    </td>
                                                    <td className='p-3 text-start'>
                                                        <div className='cursor-pointer p-2 d-flex justify-content-center' >
                                                            {Invoice?.planName}
                                                        </div>
                                                    </td>
                                                    <td className='p-3 text-start'>
                                                        <div className='cursor-pointer p-2 d-flex justify-content-center' >
                                                            {!selectedItems?.length ? (
                                                                <a href={Invoice?.pdfURL} onClick={() => toast.success('Download is progress')}>
                                                                    <img href={Invoice?.pdfURL} style={{ height: '1.5rem' }} src={doownloadIcon} />
                                                                </a>
                                                            ) : null}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    )) :

                                    (
                                        <tr>
                                            <td colSpan="5"><h5 className='text-center m-5 bold'>{t('paymentHistoryTab.NoPaymentHistory')}</h5></td>
                                        </tr>
                                    )

                                }
                            </tbody >
                        </table>
                    </div>
                </>
            </div>}
            {AddPaymentModal && <AddNewPaymentModal show={true} handleClose={() => setAddPaymentModal(!AddPaymentModal)} />}
        </>
    )
}

export default PaymentHistory;