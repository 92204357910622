import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './CompanyRegistration.css';
import SubscriptionPlans from '../../components/common/SubscriptionPlans/SubscriptionPlans';
import CompRegisServices from "../../services/comp_register.services";
import LanguageSwitcher from '../../LanguageSwitcher';
import translateLanguageImage from '../../assets/Icon material-translate.svg'
import { DocumentContext } from '../../store/document';
import { LoaderContext } from '../../store/Loader';
import { toast } from 'react-toastify';
import { getSubscriptionPlan, hideEmail } from '../../utils/helper';
import OrgServices from '../../services/org.services';
import { navBgAfterLogin, emailPattern, nameRegex, passwordRegex } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import WhiteTawqiiaLogoEn from "../../assets/Tawqiia_white_english.png"
import WhiteTawqiiaLogoAr from "../../assets/Tawqiia_white_arabic.png"
import WhiteDastkhatLogoEn from "../../assets/Dastkhat_logo_English_white.png"
import OtpInput from 'react-otp-input';
import { domainBasedImages } from "../../utils/helper";
import Loader from '../../components/ui/Loader';
const orgServices = new OrgServices();

const CompanyRegistration = () => {
  const { getOrgApp, documentState } = useContext(DocumentContext);
  const { loaderDispatch,  loaderState: { isLoading: subscriptionLoading = false } } = useContext(LoaderContext);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [logoSrc, setLogoSrc] = useState('');
  const [activeDomain, setActiveDomain]  = useState('')
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    subDomain: '',
    hash: '',
    otp: '',
    planId: '',
    isActiveDirectory: false,
    showPassword: false,
    showConfirmPassword: false,
  });
  const [isValidPwd, setIsValidPwd] = useState(false);
  const [isPwdMatched, setIsPwdMatched] = useState(false);

  const [hasOtp, setHasOtp] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [otpSection, setOtpSection] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [showSubscriptionPlansSection, setShowSubscriptionPlansSection] = useState(false);

  const compRegisServices = new CompRegisServices();

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [organizationId, setOrganizationId] = useState('')
  const { email, firstName, lastName, password, confirmPassword, hash, otp } = values;
  const dataBgNav = navBgAfterLogin(documentState)
  const { t } = useTranslation("registrationCompany");
  const { t: ts } = useTranslation('apiMessage');
  // const { t: ts } = useTranslation("apiMessage");
  const {
    i18n: { language },
  } = useTranslation();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  let hostname = process.env.REACT_APP_DOMAIN;
  useEffect(() => {
    const language = 'en'; 

    const src = domainBasedImages(hostname, language); 
    setActiveDomain(src)
    setLogoSrc(src);
    setShouldDisplay(hostname === 'TAWQIA');
  }, [language]);

  const validateAndToastEmail = email => {
    if (!email) {
      toast.error(t("emptyEmailValidation"));
      return false;
    }
    if (!emailPattern.test(email)) {
      toast.error(t("invalidEmail"));
      return false;
    }

    return true;
  }

  const sendOTPToEmail = async () => {
    const isValidEmail = validateAndToastEmail(email);
    if (!isValidEmail) {
      return;
    }

    try {
      loaderDispatch({ type: 'SET_LOADING', payload: true });
      setIsLoading(true);
      const res = await compRegisServices.sentOtp({ email });
      if (res?.status === "success") {
        if (res?.data?.hash) {
          setValues(prevState => {
            return { ...prevState, hash: res?.data?.hash }
          });
        }
        loaderDispatch({ type: 'SET_LOADING', payload: false });
        setIsLoading(false);
        toast.success(`OTP Sent to ${email}`);
        setOtpSection(true)
      }
    } catch (error) {
      loaderDispatch({ type: 'SET_LOADING', payload: false });
      setIsLoading(false);
      toast.error(error.response?.data?.code || error?.response?.data?.message);
    }
  }

  const resendOtp = async () => {
    loaderDispatch({ type: 'SET_LOADING', payload: true });
    const isValidEmail = validateAndToastEmail(email);
    if (!isValidEmail) {
      return;
    }
    try {
      loaderDispatch({ type: 'SET_LOADING', payload: true });
      setIsLoading(true);
      const res = await compRegisServices.sentOtp({ email });
      if (res?.status === "success") {
        if (res?.data?.hash) {
          setValues(prevState => {
            return { ...prevState, hash: res?.data?.hash }
          });
        }
        loaderDispatch({ type: 'SET_LOADING', payload: false });
        setIsLoading(false);
        toast.success(`OTP Sent to ${email}`);
        setOtpSection(true)
      }
    } catch (error) {
      loaderDispatch({ type: 'SET_LOADING', payload: false });
      setIsLoading(false);
      toast.error(error.response?.data?.code || error?.response?.data?.message);
    }
  }
  const subDomainRegex = /^\S+$/;

  const registerTenant = async () => {

    switch (true) {
      case !firstName:
        toast.error(t("emptyFirstNameValidation"));
        return;
      case !nameRegex.test(firstName.trim()):
        toast.error(t("Invalid_Fname"));
        return;
      case !lastName:
        toast.error(t("emptyLastNameValidation"));
        return;
      case !nameRegex.test(lastName.trim()):
        toast.error(t("Invalid_Lname"));
        return;
      case !password:
        toast.error(t("emptyPasswordValidation"));
        return;
      case !passwordRegex.test(password):
        toast.error(t("Invalid_Password"));
        return;
      case !confirmPassword:
        toast.error(t("emptyConfirmPasswordValidation"));
        return;
      case !passwordRegex.test(confirmPassword):
        toast.error(t("passwordNotMatch"));
        return;
      case !values.companyName.trim():
        toast.error(t("enterCompany"));
        return;
      case !values.subDomain.trim():
        toast.error(t("emptySubDomainValidation"));
        return;
      
      default:
        break;
    }
  if (!subDomainRegex.test(values.subDomain)) {
          toast.error(t("spaceValidationError"));
          return;
        }
    try {
      loaderDispatch({ type: 'SET_LOADING', payload: true });
      setIsLoading(true);
      const res = await compRegisServices.registerCompany(values);
      if (res?.status === "success") {
        toast.success(t('selectPlan'));
        loaderDispatch({ type: 'SET_LOADING', payload: false });
        setIsLoading(false);
        setShowSubscriptionPlansSection(true)
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        loaderDispatch({ type: 'SET_LOADING', payload: false });
        setIsLoading(false);
      }
    } catch (error) {
      loaderDispatch({ type: 'SET_LOADING', payload: false });
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  }

  const getSubscriptionPlan = async () => {
    const data = await orgServices.getSubscriptionPlans();
    setSubscriptionPlans(data?.data);
  };

  useEffect(() => {
    getSubscriptionPlan()
    window.scrollTo(0, 0);
  }, []);

  const handleInput = (name) => async (e) => {
    setValues((prevState) => {
      return { ...prevState, [name]: e.target.value };
    });
  }

  const userDetailsValueHandle = (name) => async (e) => {
    if (name === "password") {
      setIsValidPwd(passwordRegex.test(e.target.value));
      setIsPwdMatched(e.target.value === values.confirmPassword);
    }
    if (name === "confirmPassword") {
      setIsPwdMatched(password === e.target.value);
    }
    setValues((prevState) => {
      return { ...prevState, [name]: name === 'isActiveDirectory' ? e.target.checked : e.target.value };
    });
  }

  const validateOtpHandle = async () => {
    if (!hasOtp) {
      return toast.warn(t('enterOtp'));
    }
    try {
      loaderDispatch({ type: 'SET_LOADING', payload: true });
      setIsLoading(true);
      const res = await compRegisServices.verifyOtp({
        email,
        hash,
        otp: hasOtp
      });
      setValues(prevState => {
        return { ...prevState, email, hash, otp: hasOtp }
      })
      if (res?.status === "success") {
        toast.success(t('otpVerified') || res?.code || res?.message);
        loaderDispatch({ type: 'SET_LOADING', payload: false });
        setIsLoading(false);
        setShowOtpSection(true);
        setIsOtpVerified(true);
      } else {
        loaderDispatch({ type: 'SET_LOADING', payload: false });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(t('otpNotVerified'));
      loaderDispatch({ type: 'SET_LOADING', payload: false });
      setIsLoading(false);
    }
  };

  const clearOTP = () => {
    setHasOtp('');
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      sendOTPToEmail();
    }
  }

  return (
    <div className="company-registration-container">
      <header className='header-normal' style={{ backgroundImage: dataBgNav }}>
        <nav className="navbar navbar-expand-lg company-reg-nav">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <Link className='mx-5 dasthakat-logo' to={'/'}>
              <img 
              // src={language === 'en' ? WhiteTawqiiaLogoEn : WhiteTawqiiaLogoAr}
              src={(['ar'].includes(language)&& hostname === 'TAWQIA') ?
              WhiteTawqiiaLogoAr :
              (['en'].includes(language)&& hostname === 'TAWQIA') ?
              WhiteTawqiiaLogoEn :
                WhiteDastkhatLogoEn}
               alt="Dastkhat logo" height={80} width={80} /></Link>
           { shouldDisplay && <div className='d-flex justify-content-between
             align-items-center mx-5 language-switcher-btn'>
              <img className='me-2' width={20} 
              src={translateLanguageImage} 
              alt='' />
              < LanguageSwitcher />
            </div>}
          </div>
        </nav>
      </header>
      <div className="company-registration-body">
        <div className='container company-registration-form-container mt-5'>
          <div className='d-flex main-container row'>
            {!showOtpSection && !showSubscriptionPlansSection && (<div className='border pt-4'>
              <div className={`${showOtpSection ? 'w-65' : 'w-100'} d-flex company-registration-block mb-5`}>
                <div className='company-registration-form w-50 mt-5'>
                  <div className='mx-4 company-registration-input-field'>
                    <div className="mt-2">
                      <div className='mb-0'>
                        <p className='mb-1 ms-2'>{t("emailId")}<span className='text-danger'>*</span> </p>
                        <div className='d-flex form-input-field bg-white'>
                          <input
                            type='text'
                            placeholder={ts("EmailIdPlaceholder")}
                            value={values.email}
                            name='email'
                            className='ps-2'
                            onChange={handleInput('email')}
                            onKeyDown={handleKeyDown}
                          />
                          <span>
                            <i className='bi bi-at m-0 p-0 w-100 mx-2 cursor-pointer'></i>
                          </span>
                        </div>
                        <span className='d-flex justify-content-end text-primary cursor-pointer mt-3'>
                          <button
                            className='btn choose-plan-btn px-4'
                            onClick={() => sendOTPToEmail()}
                          >
                            <span>{t("sendOtpBtn")}</span><span className='mx-1'>&rarr;</span>
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="my-4">
                      <div className='mb-1'>
                      </div>
                    </div>
                    <div className="my-3">
                      <div className='mb-1'>

                      </div>
                    </div>
                  </div>
                  <div className='w-75 mx-4'>
                    <div className="mt-2">
                      <div className='mb-0'>
                      </div>
                    </div>
                  </div>
                </div>
                {/* className={`${showOtpSection ? 'w-65' : 'w-100'} d-flex  mb-5`} */}
                {hash &&
                  <div className='w-50 company-registration-form mb-2'>
                    <div className="w-50 m-auto text-center otp-box-alignment">
                      <h5 className='mt-2'>
                        {t("enterOtpText")}
                      </h5>
                      <h5 className='my-4 code-sent-text'>{t("sendCodeText")} {hideEmail(email)}</h5>
                    </div>
                    <div className='text-center m-auto d-flex'>
                      <OtpInput
                        value={hasOtp}
                        onChange={setHasOtp}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={'input-box'}
                      />

                    </div>
                    <div className='mt-4 d-flex otp-buttons' style={{ "justifyContent": "space-evenly" }}>
                      <div><button className='btn choose-plan-btn clear-otp-btn px-4' onClick={clearOTP}> {t('Clear')} </button> </div>
                      <button className='btn register-tenant-btn validate-otp-btn px-4' onClick={validateOtpHandle} disabled={hasOtp.length === 4 ? false : true}>
                        <span>{t("validateBtn")} -</span><span className='mx-1'>&rarr;</span>
                      </button>
                      <button className='btn choose-plan-btn resend-otp-btn px-4' onClick={resendOtp}>
                        <span>{t("ResendBtn")}</span>
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>)}
            {showOtpSection &&
              <div className='container py-3 company-registration-form-container border'>
                <div className='row'>
                  <div className='col-xs-12 col-md-6'>
                    <div className="mt-2">
                      <div className='mb-0'>
                        <p className='mb-1 ms-2'>{t("emailId")}<span className='text-danger'>*</span></p>
                        <div className='d-flex form-input-field bg-white'>
                          <input
                            disabled={isOtpVerified}
                            type='text'
                            value={values.email}
                            name='email'
                            className='ps-2'
                            onChange={handleInput('email')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my-4">
                      <div className='mb-1'>
                        <p className='mb-1 ms-2'>{t("fName")}<span className='text-danger'>*</span></p>
                        <div className='d-flex form-input-field bg-white'>
                          <input
                            placeholder={ts('fnamePlaceholder')}
                            value={values.firstName}
                            onChange={userDetailsValueHandle('firstName')}
                            type="text"
                            className='ps-2'
                            name='firstName'
                            disabled={showSubscriptionPlansSection}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my-3">
                      <div className='mb-1'>
                        <p className='mb-1 ms-2'>{t("lName")}<span className='text-danger'>*</span></p>
                        <div className='d-flex form-input-field bg-white'>
                          <input
                            placeholder={ts('lnamePlaceholder')}
                            value={values.lastName}
                            onChange={userDetailsValueHandle('lastName')}
                            type="text"
                            className='ps-2'
                            name='lastName'
                            disabled={showSubscriptionPlansSection}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my-3">
                      <div className='mb-1'>
                        <p className='mb-1 ms-2'>{t("domain")}<span className='text-danger'>*</span></p>
                        <div className='d-flex form-input-field bg-white'>
                          <input
                            placeholder={t("SubdomainPlaceholder")}
                            value={values.subDomain}
                            onChange={userDetailsValueHandle('subDomain')}
                            type="text"
                            className='ps-2'
                            name='companyName'
                            disabled={showSubscriptionPlansSection}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my-3 d-flex bg-white align-items-center ps-2">
                      <input
                        value={values.isActiveDirectory}
                        onChange={userDetailsValueHandle('isActiveDirectory')}
                        type="checkbox"
                        className='mb-3'
                        name='isActiveDirectory'
                        disabled={showSubscriptionPlansSection}
                      />
                      <p className='ps-2'>{t("activeDirectory")}</p>
                    </div>
                  </div>
                  <div className='col-xs-12 col-md-6'>
                    <div className="mt-2">
                      <div className='mb-0'>
                        <p className='mb-1 ms-2'>{t("passwordBtn")}<span className='text-danger'>*</span></p>
                        <div className='d-flex form-input-field bg-white'>
                          <input
                            // type='password'
                            placeholder={ts("password")}
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            name='password'
                            className='ps-2'
                            onChange={userDetailsValueHandle('password')}
                            disabled={showSubscriptionPlansSection}
                          />
                          <span>
                            <i
                              className={`${!values.showPassword ? `bi bi-eye-slash-fill m-0 p-0 w-100 mx-2 cursor-pointer` : `bi bi-eye-fill eye-icon m-0 p-0 w-100 mx-2 cursor-pointer`}`}
                              onClick={() => setValues({ ...values, showPassword: !values.showPassword })}
                              onMouseDown={handleMouseDownPassword}
                            ></i>
                          </span>
                        </div>
                        {
                          values.password && <div class={`mt-1 ${isValidPwd ? "valid" : "invalid"}`}>{`${isValidPwd ? (t("Password_matched_the_requirements")) : (t("PasswordValidation_Instruction_toast"))}`}</div>
                        }
                      </div>
                    </div>
                    <div className="my-4">
                      <div className='mb-1'>
                        <p className='mb-1 ms-2'>{t("confirmPassword")}<span className='text-danger'>*</span></p>
                        <div className='d-flex form-input-field bg-white'>
                          <input
                            placeholder={ts("confirmPasswordPlaceholder")}
                            value={values.confirmPassword}
                            onChange={userDetailsValueHandle('confirmPassword')}
                            // type="password"
                            type={values.showConfirmPassword ? 'text' : 'password'}
                            className='ps-2'
                            name='confirmPassword'
                            disabled={showSubscriptionPlansSection}
                          />
                          <span>
                            <i
                              className={`${!values.showConfirmPassword ? `bi bi-eye-slash-fill m-0 p-0 w-100 mx-2 cursor-pointer` : `bi bi-eye-fill eye-icon m-0 p-0 w-100 mx-2 cursor-pointer`}`}
                              onClick={() => setValues({ ...values, showConfirmPassword: !values.showConfirmPassword })}
                              onMouseDown={handleMouseDownPassword}
                            ></i>
                          </span>
                        </div>
                        {
                          values.confirmPassword && <div class={`mt-1 ${isPwdMatched ? "valid" : "invalid"}`}>{`${isPwdMatched ? (t("Password_matched")) : (t("conFirmPasswordWarn"))}`}</div>
                        }
                      </div>
                    </div>
                    <div className="my-3">
                      <div className='mb-1'>
                        <p className='mb-1 ms-2'>{t("companyName")}<span className='text-danger'>*</span></p>
                        <div className='d-flex form-input-field bg-white'>
                          <input
                            placeholder={t("ComapanyName")}
                            value={values.companyName}
                            onChange={userDetailsValueHandle('companyName')}
                            type="text"
                            className='ps-2'
                            name='companyName'
                            disabled={showSubscriptionPlansSection}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                { !showSubscriptionPlansSection && (
                  <div className='validate-button m-auto py-3'>
                    <button className='btn register-tenant-btn px-4 m-auto' onClick={registerTenant} >
                      <span>{t("nextBtn")} -</span><span className='mx-1'>&rarr;</span>
                    </button>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      </div>
      {showSubscriptionPlansSection && <SubscriptionPlans  subscriptionPlans={subscriptionPlans} orgData={ values } />}
      {(isLoading || subscriptionLoading) && (
        <Loader />
      )}
    </div>
  );
};

export default CompanyRegistration;
