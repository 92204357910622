import { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import axios from "axios";
import { useTranslation } from 'react-i18next';

import DocumentsTable from './DocumentsTable';
import DocumentStatusPieChart from './DocumentStatusPieChart';
import DocumentStatusBarGraph from './DocumentStatusBarGraph';
import MyDocuments from "../../../assets/MyDocuments.png"
import { isAuthenticated } from "../../../utils/helper";

import { getActiveImageBackground } from "../../../utils/helper";

const AdminDashboard = (props) => {
    const [timeDuration, setTimeDuration] = useState(1);
    const [tableStatus, setTableStatus] = useState(0);
    const [barSeries, setBarSeries] = useState([0, 0, 0, 0])

    const { t } = useTranslation('admin');

    const statusMapping = {
        DRAFT: t('DRAFT'),
        AWAITING: t('AWAITING'),
        COMPLETED: t('COMPLETED'),
        REJECTED: t('REJECTED')
    }

    const getStatusSeries = (series) => {
        const newSeries = Object.keys(statusMapping).map((status) => {
            const currentStatusRecordCount = +series.find(responseStatus => responseStatus.status === status)?.count || 0;
            return currentStatusRecordCount;
        });
        return newSeries;
    }

    const handleTimeDurationChange = async (newTimeDuration) => {
        const organization = window.location.href?.split('/')[3] || '';
        const { data: response } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/get-reports?days=${newTimeDuration}`, {
            headers: {
                Authorization: `Bearer ${isAuthenticated()}`,
                'Organization': organization,
            },
        });
        if (response?.data?.countStatus) {
            const newSeries = getStatusSeries(response?.data?.countStatus)
            setTimeDuration(newTimeDuration);
            setBarSeries(newSeries);
        }
    }

    useEffect(() => {
        handleTimeDurationChange(1);
    }, [])

    const handleSetSelectedStatus = (newStatus) => {
        setTableStatus(newStatus);
    }

    console.log("props.textChangeAppData ==>", props.textChangeAppData)

    return (
        <Container>
            <Row className="custom-card">
                <img className="draftimgae myDocumentsImage" style={getActiveImageBackground(props.textChangeAppData)} src={MyDocuments} />
                <div class="text-overlay my-documents-text-overlay">{t('MY_DOCUMENTS')}</div>
                <Col sm={12} md={6} className="documents-border chartTour">
                    <DocumentStatusPieChart
                        getStatusSeries={getStatusSeries}
                    />
                </Col>
                <Col sm={12} md={6} className="barStatusTour">
                    <DocumentStatusBarGraph
                        selectedTimeDuration={+timeDuration}
                        statusMapping={statusMapping}
                        barSeries={barSeries}
                        handleTimeDurationChange={handleTimeDurationChange}
                        handleSetSelectedStatus={handleSetSelectedStatus}
                    />
                </Col>
            </Row>
            {
                tableStatus !== undefined &&  (
                <Row>
                    <DocumentsTable
                        tableStatus={tableStatus}
                        timeDuration={timeDuration}
                        textChangeAppData={props.textChangeAppData}
                    />
                </Row>
                )
            }
        </Container>
    )
}

export default AdminDashboard