import axios from 'axios';
import jwt_decode from "jwt-decode";
import { isAuthenticated } from './utils/helper';
import Auth from './components/Auth/auth';
import debounce from './utils/debounce';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
const UNAUTHORIZED = 401;
const organization = window.location.href?.split('/')[3] || '';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    'Organization': organization,
  },
});

export const setupResponseInterceptor = (navigate) => {
  apiClient.interceptors.response.use(
    (response) => {
      if (localStorage.getItem('jwtToken')) {
        const currentDate = new Date();
        const getMilliseconds = currentDate.getTime();
        const getSeconds = Math.round(getMilliseconds / 1000);
        const decodedToken = jwt_decode(localStorage.getItem('jwtToken'));
        const tokenExpSeconds = decodedToken?.exp;
        if (tokenExpSeconds - getSeconds > 0 && tokenExpSeconds - getSeconds < 1000) {
          refreshToken();
        }
      }
      return response;
    },
    (error) => {
      const { response } = error;
      if (response.status === UNAUTHORIZED && response.data.code === 'TOKENEXPIRED') {
        loginToRedirect(navigate);
      }
      return Promise.reject(error);
    }
  );
}

const refreshToken = debounce(async() => {
  const payload = {
    "refresh_token": JSON.parse(localStorage.getItem('jwtRefreshToken'))
  }
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/refresh_token`,
      payload, {
      headers: { Authorization: `Bearer ${isAuthenticated()}` },
    }
    );
    localStorage.setItem('jwtToken', JSON.stringify(data?.token));
  } catch (error) {
    console.log("Something went wrong");
  }
}, 2000)

const loginToRedirect = debounce((navigate) => {
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('jwtRefreshToken');
  localStorage.removeItem('user');

  if (!window.location.href.includes("login")) {
    const currentPath = window.location.href;
    const urlPath = currentPath.split("/");
    if (urlPath.length >= 3 && !["login", "admin"].includes(urlPath[2])) {
      Auth.setUrl(window.btoa(encodeURIComponent(window.location.href)));
    }
  }

  navigate(`/${localStorage.getItem('orgName')}/login`);
}, 1000)

export default apiClient;
