import axios from 'axios';
import API from '../api';
import { isAuthenticated } from '../utils/helper';

const baseUrl = `${process.env.REACT_APP_SERVER_URL}`;

class DocumentTemplateServices {
  get path() {
    return `/template`;
  }

  async getCategories() {
    try {
      const { data } = await API.get(`/template-category/`, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addCategory(categoryName) {
    try {
      const response = await API.post(`/template-category/`, 
      {
        name: categoryName
      },
      {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateCategory(updatedCategory) {
    try {
      const response = await API.put(`/template-category/${updatedCategory?.id}`, 
      {
        name: updatedCategory.currentName
      },
      {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteCategory(categoryId) {
    try {
      const response = await API.delete(`/template-category/${categoryId}`, 
      {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateTemplateCategory(templateId, categoryId) {
    try {
      const response = await API.put(`template/update-template-category-id/`,
      {
        templateCategoryId: categoryId,
        templateId: templateId
      },
      {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  
  async getTemplates(categoryId, searchTemplateQuery, page) {
    try {
      let APIEndpoint = `/template/get_templates/?page=${page}&&limit=11`
      if (categoryId && typeof categoryId === 'number') {
        APIEndpoint += `&&templateCategoryId=${categoryId}`
      }
      if (searchTemplateQuery && searchTemplateQuery !== '') {
        APIEndpoint += `&&search=${searchTemplateQuery}`
      }
      const { data } = await API.get(APIEndpoint, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getTemplate(id, templateType) {
    try {
      let APIEndpoint = templateType === 'global' ? `/global-template/${id}` : `/template/get_templates/${id}`
      const { data } = await API.get(APIEndpoint, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createTemplate(payload, orgName) {
    try {
      const { data } = await axios.post(`${baseUrl}/template`, payload, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
          Organization: orgName
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteTemplate(payload, organization) {
    try {
      const { documentId } = payload
      const { data } = await API.delete(`/template/delete_template/${documentId}`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
          Organization: organization
        },
        data: payload
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addTemplatePosition(payload) {
    try {
      const { data } = await API.post(`/template-field`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  
  async updateTemplatePositions({ payload, id }) {
    try {
      const { data } = await API.put(`/template-field/update/${id}`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      })
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async removeTemplatePosition(id) {
    try {
      const { data } = await API.delete(`/template-field/delete/${id}`, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      })
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createDocument(id, name, annotations) {
    try {
      const { data } = await API.post(`/template/create_document/${id}`, {
        fieldIDs: annotations,
        documentName: name
      }, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      })
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default DocumentTemplateServices;