import React, { createContext, useReducer } from 'react';

const initialState = {
  isLoading: false,
};

const reducer = (loaderState, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...loaderState, isLoading: action.payload };
    default:
      return loaderState;
  }
};

export const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loaderState, loaderDispatch] = useReducer(reducer, initialState);

  return (
    <LoaderContext.Provider value={{ loaderState, loaderDispatch }}>
      {children}
    </LoaderContext.Provider>
  );
};
