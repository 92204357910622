import { Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import "./Tab.css";

const DocumentFinalStep = (props) => {
    const { textChangeAppData, documentStatus, documentOverdue, onClickFinish, setShowRejectionModal, activeStep, rejectedDocument, selectedDocument, documentSignedStatues, FinishdisableBtn } = props;
    const { t } = useTranslation('documentsUpdate');

    const renderRecipientComments = (recipients, textChangeAppData) => {
        return recipients?.map((recipient) => (
            recipient?.isRejected ? (
                <div className="row py-3 ps-3 shadow recipient-comment mb-3" key={recipient?.email}>
                    <div className="rounded-circle text-center col-2 custom-col-md-4 col-lg-3 user-icon" style={{ backgroundColor: textChangeAppData, width: '4vh', height: '4vh', wordBreak: 'break-all' }}>
                        {recipient?.email?.slice(0, 1).toUpperCase()}
                    </div>
                    <div className='col-10 col-md-8 col-lg-10' style={{ wordBreak: 'break-all' }}>
                        <p className="text-wrap mb-0 font-weight-bold recipient-email">{recipient?.email}</p>
                        <span className="recipient-reason">{recipient?.reasonForRejection}</span>
                    </div>
                </div>
            ) : null
        ));
    }; 

    return (<div style={{ paddingTop: '2rem' }}>
        <Row style={{ display: ['rejected', 'completed'].includes(documentSignedStatues?.toLowerCase()) || documentOverdue ? 'none' : ''}}>
            <Col style={{ display: activeStep !== 5 && 'none' }}>
                <Button disabled={FinishdisableBtn} className='tab-finish-btn' style={{ backgroundColor: textChangeAppData }} onClick={onClickFinish}>
                    {t('submitbtn')}
                </Button>
            </Col>
            <Col>
                <Button className='tab-reject-btn' onClick={() => setShowRejectionModal(true)}>
                    {t('reject')}
                </Button>
            </Col>
        </Row>
        <Row style={{ display: !documentStatus && 'none' }}>
            <h6 className='p-2'>{documentOverdue ? t('DOCUMENT_OVERDUE_STATUS') : t('DOCUMENT_SIGN_STATUS')}</h6>
        </Row>
        {selectedDocument?.my_status === "REJECTED" || rejectedDocument?.status === "REJECTED" ?
            <Row>
                <h6>{t('DOCUMENT_REJECT_STATUS')}</h6>
                <div className='px-2 mt-5'>
                    <h5 className="rejected-heading mb-3">{t('reasonsforRejection')}</h5>
                    {rejectedDocument?.recipients ? renderRecipientComments(rejectedDocument?.recipients, textChangeAppData) :
                        selectedDocument?.recipients ? renderRecipientComments(selectedDocument?.recipients, textChangeAppData) : null}
                </div>
            </Row>
            : null}
    </div>)
}

export default DocumentFinalStep;