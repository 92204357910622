import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from 'react-i18next';

import "./AdminDashboard.css"
import drafttag from "../../../assets/Group 432.png"
import { getActiveImageBackground, isAuthenticated } from "../../../utils/helper";

const DocumentsTable = (props) => {
    const { tableStatus, timeDuration } = props;

    const [documents, setDocuments] = useState([]);
    const [HeadingText, setHeadingText] = useState();

    const { t } = useTranslation('admin');
    const getStatus = () => {
        switch (tableStatus) {
            case 0:
                setHeadingText("Draft");
                return "DRAFT";
            case 1:
                setHeadingText("Awaiting");
                return "AWAITING";
            case 2:
                setHeadingText("Completed");
                return "COMPLETED";
            case 3:
                setHeadingText("Rejected");
                return "REJECTED";
            default:
                setHeadingText("");
                return "";
        }
    };


    const getFormattedDate = (date) => {
        const originalDate = new Date(date);
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const formattedDate = originalDate.toLocaleDateString('en-US', options);

        return formattedDate;
    }

    const handleUpdateDocumentsData = async () => {
        const organization = window.location.href?.split('/')[3] || '';
        const { data: response } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/get-documents?status=${getStatus()}&days=${timeDuration}`, {
            headers: {
                Authorization: `Bearer ${isAuthenticated()}`,
                'Organization': organization,
            },
        });
        if (response?.data) {
            const { data } = response;
            const newDocuments = data.map(document => {
                return {
                    ...document,
                    createdAt: getFormattedDate(document.createdAt),
                }
            })
            setDocuments(newDocuments);
        }
    }

    useEffect(() => {
        handleUpdateDocumentsData();
    }, [tableStatus, timeDuration])

    return (
        <p>
            <div className="documenttabls selectedStatusResultTour">
                <div class="d-flex">
                    <img className="draftimgae" src={drafttag} style={getActiveImageBackground(props.textChangeAppData)} />
                    <div class="text-overlay">{t(`adminDashboard.${HeadingText}`)}</div>
                </div>
                <Table striped bordered hover responsive={true} className="admin-dashboard-documents">
                    <thead>
                        <th>{t(`adminDashboard.documentTitle`)}</th>
                        <th>{t(`adminDashboard.documentOwner`)}</th>
                        <th>{t(`adminDashboard.createdOn`)}</th>
                    </thead>
                    <tbody >
                        {documents?.length > 0 ? documents?.map((document) => (
                            <tr>
                                <td className="d-flex">
                                    <div className="d-flex gap-2">
                                        <i class="bi bi-file-pdf" style={{ color: "red", fontSize: "1.2rem" }}></i>
                                        <div style={{ fontSize: "1rem" }}>{document?.title}</div>
                                    </div>
                                </td>
                                <td style={{ textAlign: 'center' }}>{document?.user?.firstName || "" + " " + document?.user?.lastName || ""}</td>
                                <td style={{ textAlign: 'center' }}>{document?.createdAt}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan={3} style={{ textAlign: "center" }}>
                                    <p className="no-documents-message">{t(`adminDashboard.No${HeadingText}Documents`)}</p>
                                </td>
                            </tr>
                        )}
                        
                    </tbody>
                </Table>
            </div>
        </p>
    )
}

export default DocumentsTable;