import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './OtpModal.css'
import { useState } from "react";

const DeleteConfirmationModal = (props) => {
    const {
        confirmationHeading,
        confirmationText,
        handleConfirmation,
        handleCloseModal,
        textColor
    } = props;
    const [isDeleteClicked, setIsDeleteClicked] = useState(false)
    const { t } = useTranslation('documents');

    const handleDelete = () => {
        setIsDeleteClicked(true)
        handleConfirmation()
    }

    return (<div className="modal show modal-container-top">
        <Modal.Dialog>
            <Modal.Header style={{ background: textColor }}>
                <Modal.Title className="custom-model-header">{confirmationHeading}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 py-5 fs-5">
                {confirmationText}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button className="remove-borders" variant="secondary" onClick={handleCloseModal}>{t('cancelBtn')}</Button>
                <Button className="remove-borders" style={{ background: textColor }} onClick={handleDelete}>
                    {!isDeleteClicked ? t('deleteBtn') : t('deleting...')}
                </Button>
            </Modal.Footer>
        </Modal.Dialog>
    </div>)
}

export default DeleteConfirmationModal