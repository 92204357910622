import React, { useContext } from "react";
import OrgNotFound from "../../assets/orgNotFound.svg";
import "./ErrorPage.css";
import { isAuthenticated, isUser } from "../../utils/helper";
import { Link, useLocation, useParams } from "react-router-dom";

const OrganisationNotFound = () => {
  const organisationName = localStorage.getItem("orgName");
  const location = useLocation();
  const orgName = location.pathname.split('/')[1];

  return (
    <div className=" container mt-5 d-flex justify-content-between">
      <div style={{ marginTop: "13vh" }}>
        <img src={OrgNotFound} alt="error" width={650} />
      </div>

      <div>
        <p className="heading d-flex align-items-center mb-5">
          {/* Company with name "{orgName}" not found. */}
          You dont have access to Dastkhat.
          Please register by clicking below
        </p>
        <Link
          to={'/register-company'}
          className="navbar-brand text-white"
          style={{
            fontSize: "12px",
            backgroundColor: "#206888",
            padding: "1.1rem",
            borderRadius: "17px",
            marginLeft: '14rem'
          }}
          href="#"
        >
          Register Here - &rarr;
        </Link>
      </div>
    </div>
  );
};

export default OrganisationNotFound;
