import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import NotFound from "./pages/NotFound/NotFound";
import Login from "./pages/Login/Login";
import Footer from "./components/common/Footer";
import Templates from "./pages/Templates/Templates";
import Documents from "./pages/Documents/Documents";
import Document from "./pages/Document/Document";
import Settings from "./pages/Settings/Settings";
import DocumentUpdate from "./pages/Document/DocumentUpdate";
import { isAuthenticated } from "./utils/helper";
import DocumentSign from "./pages/Document/DocumentSign";
import { AuthContext } from "./store/auth";
import { LoaderContext } from "./store/Loader";
import SignUp from "./pages/SignUp/SignUp";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import RequireAuth from "./RequireAuth/RequireAuth";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import StaticSigners from "./pages/Signers/StaticSigner";
import UserRegSignup from "./components/UserRegestrationFlow/UserRegSignup";
import Home from "./pages/Home/Home";
import CompanyRegistration from "./pages/CompanyRegistration/CompanyRegistration";
import AdminPanel from "./pages/Admin/AdminPanel";
import OrgServices from "./services/org.services";
import RequireAdminAuth from "./AdminAuth/RequireAdminAuth";
import { DocumentContext } from "./store/document";
// import Spinner from './components/ui/Spinner';
import OrganisationNotFound from "./pages/NotFound/OrganisationNotFound";
import DocumentGuestSign from './pages/Document/DocumentGuestSign';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { GoogleOAuthProvider } from "@react-oauth/google";
import Loader from "./components/ui/Loader";
import { setupResponseInterceptor } from "./api";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { loaderState } = useContext(LoaderContext);
  const [FontAppearence, setFontAppearence] = useState(null);
  const { loaderDispatch } = useContext(LoaderContext);
  const { getOrgApp, documentState } = useContext(DocumentContext);
  // const [resUpdate, setResUpdate] = useState();

  let hostname = process.env.REACT_APP_DOMAIN;
  useEffect(() => {
    let title = 'Dastkhat';
    let description = 'Dastkhat';
    if (hostname === 'TAWQIA') {
      title = 'Tawqia';
      description = 'Tawqia';
    }
    document.title = title;
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute('content', description);
    }
  }, [hostname, location.pathname]);

  const orgServices = new OrgServices();
  const orgName = location.pathname.split("/")[1];
  const subUrl = location.pathname.split("/")[2];
  // const appearenceRes = orgServices.organizationAppearance(orgName);


  // const dataLoad = () => {
  //   loaderDispatch({ type: "SET_LOADING", payload: true });
  //   appearenceRes
  //     .then((result) => {
  //       setFontAppearence(result.font);
  //       // setResUpdate(FontAppearence, documentState?.orgAppData?.primary_color);
  //     })
  //     .catch((error) => {
  //       console.error("Error occurred:", error);
  //       setFontAppearence(null);
  //     })
  //     .finally(() => {
  //       loaderDispatch({ type: "SET_LOADING", payload: false });
  //     });
  // };

  // useEffect(() => {
  //   // dataLoad();
  // }, [resUpdate]);

  useEffect(() => {
    setupResponseInterceptor(navigate);
  }, [navigate]);

  useEffect(() => {
    if (!localStorage.getItem('language') || !localStorage.getItem('i18nextLng')) {
      localStorage.setItem('language', 'en');
      localStorage.setItem('i18nextLng', 'en');
    }
    loaderDispatch({ type: "SET_LOADING", payload: true });
    getOrgApp();
    setFontAppearence(documentState?.orgAppData?.font);
    loaderDispatch({ type: "SET_LOADING", payload: false });
    AOS.init({
      startEvent: 'load',
      disable: 'mobile',
      // once: true
    });
  }, []);


  window.addEventListener('load', function () {
    AOS.refresh();
  });

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <div
          className="App"
          style={{
            fontFamily:
              documentState?.orgAppData?.font !== "" || null ? documentState?.orgAppData?.font : "default",
            minHeight: "95vh",
          }}
        >
          <Routes>
            {documentState?.orgAppData?.font ? (
              <>
                <Route
                  path="/register-company"
                  element={<CompanyRegistration />}
                ></Route>
                <Route path="/:orgName/login" element={<Login />}></Route>
                <Route path="/:orgName" element={<Login />}></Route>
                <Route
                  path="/:orgName/forgot-password"
                  element={<ForgotPassword />}
                ></Route>
                <Route
                  path={`/:orgName/update-user-details/:token`}
                  element={<UserRegSignup />}
                ></Route>
                <Route
                  path="/:orgName/admin"
                  element={
                    <RequireAdminAuth>
                      <AdminPanel />
                    </RequireAdminAuth>
                  }
                ></Route>
                <Route
                  path="/:orgName/admin/subscription"
                  element={
                    <RequireAdminAuth>
                      <AdminPanel tabIndex={3} />
                    </RequireAdminAuth>
                  }
                ></Route>
                <React.Fragment>
                  <Route
                    path="/:orgName/dashboard"
                    element={
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/:orgName/documents"
                    element={
                      <RequireAuth>
                        <Documents />
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/:orgName/documents/tab"
                    element={
                      <RequireAuth>
                        <Documents />
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/:orgName/documents/tab/:tab"
                    element={
                      <RequireAuth>
                        <Documents />
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/:orgName/documents/:document/add"
                    element={
                      <RequireAuth>
                        <Document />
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/:orgName/documents/:document/update"
                    element={
                      <RequireAuth>
                        <DocumentUpdate />
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/:orgName/documents/:document/view-signer"
                    element={
                      <RequireAuth>
                        <StaticSigners />
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/:orgName/documents/:document/sign/:recipientId"
                    element={
                      <RequireAuth>
                        <DocumentSign />
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/:orgName/settings"
                    element={
                      <RequireAuth>
                        <Settings />
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/:orgName/settings/:tab"
                    element={
                      <RequireAuth>
                        <Settings />
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/:orgName/reset-password/:url"
                    element={<ResetPassword />}
                  ></Route>

                  <Route path="*" element={<NotFound />} />
                </React.Fragment>
              </>
            ) : (
              <>
                {orgName || subUrl ? (
                  documentState?.orgAppData?.message === "organization not found" ? <Route path="*" element={<OrganisationNotFound />} /> : <Route path="*" element={<Home />} />
                ) : (
                  <Route path="*" element={<Home />} />
                )}
                <Route path="/" element={<Home />}></Route>
                <Route
                  path="/register-company"
                  element={<CompanyRegistration />}
                ></Route>
              </>
            )}
            <Route
              path="/:orgName/guest-user-document/:document/:token"
              element={<DocumentGuestSign />}
            ></Route>
          </Routes>
          {!documentState?.orgAppData && (
            <Loader />
            )}
        </div>

        <ToastContainer position="top-right" autoClose={2000} />

        <Footer />
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
