import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './SignUp.css';
import logoHere from '../../assets/dastkhat-logo.svg';
import signVector from '../../assets/dasthkath-sign-here-vector.svg';
import { toast } from 'react-toastify';
import { LoaderContext } from '../../store/Loader';
import AuthServices from '../../services/auth.services';
import LanguageSwitcher from '../../LanguageSwitcher';
import translateLanguageImage from '../../assets/Icon material-translate.svg'
import { useTranslation } from 'react-i18next';
import dastakathlogoEnglish from '../../assets/dasthkat-svg-logo.svg'
import dastakathlogoArabic from '../../assets/Final logo Arabic (1).svg'
import signVectorArabic from '../../assets/arabic.png';
// import AuthFooter from '../../components/common/AuthFooter';
import { DocumentContext } from '../../store/document';
import { bgBeforeLogin, textChangeApp } from '../../utils/constants';
import { domainBasedImages } from '../../utils/helper';
const authServices = new AuthServices();

const SignUp = () => {
  const location = useLocation();
  const orgName = location.pathname.split('/')[1];
  const { getOrgApp, documentState } = useContext(DocumentContext);
  const { loaderDispatch } = useContext(LoaderContext);
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
  });
  const [shouldDisplay, setShouldDisplay] = useState(false);

  const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");

  const { t } = useTranslation('register');
  const { t: ts } = useTranslation('apiMessage');
  const { i18n: { language } } = useTranslation();

  const dataBg = bgBeforeLogin(documentState)
  const textChangeAppData = textChangeApp(documentState)

  const navigate = useNavigate();

  // useEffect(() => { getOrgApp() },
  //   [])

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnChange = (name) => (e) => {
    setValues((prevState) => {
      return { ...prevState, [name]: e.target.value };
    });
  };

  const handleSignUp = async () => {
    const { firstName, lastName, email, password, confirmPassword } = values;
    if (!firstName || !email || !password || !confirmPassword) {
      return toast.warn(ts('Please fill in all required fields.'));
    }
    if (password !== confirmPassword) {
      return toast.warn(ts("Password and confirm password don't match."));
    }
    loaderDispatch({ type: 'SET_LOADING', payload: true });
    try {
      const res = await authServices.signUp({
        firstName,
        lastName,
        email,
        password,
      });
      navigate(`/${orgName}`);
      toast.success(ts(res?.code || res?.message));
    } catch (error) {
      toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`));
    } finally {
      loaderDispatch({ type: 'SET_LOADING', payload: false });
      setValues({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
      });
    }
  };
  const logo = documentState?.orgAppData;

  let hostname = process.env.REACT_APP_DOMAIN;
  useEffect(() => {
    setShouldDisplay(hostname === 'TAWQIA');
  }, [hostname, language]);

  return (
    <div className='login-body' style={{
      backgroundImage: dataBg    }}>
      <div className='login-page'>
        <div className='form-submitted'>
          <div className='dastkhat-logo px-5 mx-5'>
            <Link to={`/${orgName}`}>
            <img className="mt-5" src={logo?.logo ? logo?.logo : ''} alt="#" width={130} height={70}/>
            </Link>
          </div>
          <div className='sign-vector-section'>
            <div>
              <div className="sign-vector">
                <img
                  src={language === 'en' ? signVector : signVectorArabic}
                  alt="#" />
              </div>
              <div>
                <img className='dasthkath-logo-login' width={100} src={language === 'en' ? dastakathlogoEnglish : dastakathlogoArabic} alt='' />
              </div>
            </div>
            <div className='login-form'>
              <div className='form-here'>
                <div className='d-flex justify-content-between'>
                  <h3 className="bold-text"><strong>{t('signup')}</strong></h3>
                 {shouldDisplay && <div className='d-flex justify-content-between align-items-center'><img className='me-2' width={20} src={translateLanguageImage} alt='' />
                    <LanguageSwitcher />
                  </div>}
                </div>
                <div className='pt-3'>
                  <div className='mb-3'>
                    <div className='d-flex'>
                      <div className='me-1'>
                        <p className='mb-1'>{t('fname')}</p>
                        <div className='d-flex justify-content-center align-items-center form-input-field'>
                          <input
                            onChange={handleOnChange('firstName')}
                            type='text'
                          />
                        </div>
                      </div>
                      <div className='ms-1'>
                        <p className='mb-1'>{t('lname')}</p>
                        <div className='d-flex justify-content-center align-items-center form-input-field'>
                          <input
                            onChange={handleOnChange('lastName')}
                            type='text'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-1'>
                    <p className='mb-1'>{t('email')}</p>
                    <div className='d-flex justify-content-center align-items-center form-input-field'>
                      <input
                        type='text'
                        name='email'
                        onChange={handleOnChange('email')}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-center align-items-center mt-3'>
                    <div className='me-1'>
                      <p className='mb-1'>{t('pass')}</p>
                      <div className='d-flex form-input-field'>
                        <input
                          name='password'
                          onChange={handleOnChange('password')}
                          value={values.password}
                          type={values.showPassword ? 'text' : 'password'}
                        />
                        <span>
                          <i
                            className='bi bi-eye-fill eye-icon m-0 p-0 w-100 mx-2 cursor-pointer'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div className='ms-1'>
                      <p className='mb-1'>{t('cnfmpass')}</p>
                      <div className='d-flex form-input-field'>
                        <input
                          name='confirmPassword'
                          onChange={handleOnChange('confirmPassword')}
                          value={values.confirmPassword}
                          type={
                            values.showConfirmPassword ? 'text' : 'password'
                          }
                        />
                        <span>
                          <i
                            className='bi bi-eye-fill eye-icon m-0 p-0 w-100 mx-2 cursor-pointer'
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='login-forgetpassword'>
                  <div className='login-btn mt-4 mx-0'>
                    <button onClick={handleSignUp}
                      style={{
                        backgroundColor: textChangeAppData }}
                      className='btnlogin me-4'>
                      {t('signup')} - &rarr;
                    </button>
                  </div>
                </div>
                <hr />
                <div className='text-center'>
                  {t('ftrTxt')}?
                  <Link to={`/${orgName}/login`} className='mx-2' style={{ color: 'blue', textDecoration: 'none' }}>
                    {t('login')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AuthFooter /> */}
    </div>
  );
};

export default SignUp;
