import { Button, Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getActiveImageBackground, isAuthenticated } from "../../utils/helper";
import { useParams } from "react-router-dom";
import { textChangeApp } from "../../utils/constants";
import { useContext, useState } from "react";
import fileIcon from '../../assets/file-icon.svg';
import { DocumentContext } from "../../store/document";
import Loader from "../../components/ui/Loader";
import DocumentTemplateServices from "../../services/documentTemplateServices";

const documentTemplateServices = new DocumentTemplateServices();

const TemplateUploadModal = (props) => {
    const { toggleModal, toggleUpdateModal } = props;

    const { orgName } = useParams();

    const { t: ts } = useTranslation('apiMessage');
    const { t: tp } = useTranslation('templates');

    const { fetchDocuments, documentDispatch, documentState } = useContext(DocumentContext);

    const textChangeAppData = textChangeApp(documentState)

    const [isLoading, setIsLoading] = useState(false);
  

    const onDrop = async (files) => {
        setIsLoading(true);
        if (isAuthenticated()) {
          if (files.length > 0) {
            const file = files[0];
            if (file.type === 'application/pdf') {
              if (file.size <= 10 * 1024 * 1024) {
              try {
                const formData = new FormData();
                formData.append('document', file);
                const response = await documentTemplateServices.createTemplate(formData, orgName);
                if (response.message) {
                  toast.success(ts('FILE_UPLOADED'));
                  toggleUpdateModal();
                }
              } catch (error) {
                toast.error(ts(error?.response?.data?.code || 'SOMETHING_WENT_WRONG'));
              }
            }else {
              toast.error(ts('FILE_TOO_LARGE'));
            }
           } else {
              toast.warn(ts('FILE_NOT_PDF'));
            }
          } else {
            toast.warn(ts('FILE_NOT_SELECTED'));
          }
        } else {
          toast.error(ts('LOGIN_UPLOAD_DOC'));
        }
        setIsLoading(false);
      };

      return isLoading ? (
        <Loader />
      ) : (
        // Modal when not loading
        <Modal show onHide={toggleModal} style={{fontFamily: documentState?.orgAppData?.font || ''}}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontWeight: 'bold', color: textChangeAppData }}>{tp('UPLOAD_NEW_FILE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Dropzone onDrop={onDrop} multiple={false} accept="application/pdf">
              {({ getRootProps, getInputProps }) => (
                <section className='dropzone-section w-100'>
                  <div
                    style={{ border: `3px dashed ${textChangeAppData}` }}
                    {...getRootProps({
                      className: 'd-flex w-100 docUploadTour flex-column align-items-center justify-content-center dropzone',
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className='h-35'>
                      <div className='file-icon d-flex align-items-center justify-content-center'>
                        <img
                          src={fileIcon}
                          style={getActiveImageBackground(textChangeAppData)}
                          alt='File icon'
                          width={17}
                          height={17}
                        />
                      </div>
                    </div>
                    <div className='h-50 pt-3 d-flex flex-column align-items-center justify-content-center'>
                      <span
                        style={{
                          fontWeight: 'bold',
                          color: textChangeAppData
                        }}
                      >
                        {tp('UPLOAD_TEXT')}
                      </span>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              {tp('CLOSE')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
      
  };
  
export default TemplateUploadModal;