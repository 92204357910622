import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import './Document.css';
import { navBgAfterLogin, navLinks, textChangeApp } from '../../utils/constants';
import userPlusIcon from './../../assets/user-plus-icon.png';
import { DocumentContext } from '../../store/document';
import DocumentServices from '../../services/document.services';
import Signer from '../Signers/Signer';
import AddDocumentSigner from '../Signers/AddDocumentSigner';
import { useTranslation } from 'react-i18next';
import { SignerContext } from '../../store/signer';
import Spinner from '../../components/ui/Spinner';

const documentServices = new DocumentServices();

const AddSignersForDoc = ({selectedDocInfo,callNext}) => {
    const [documentTitle, setDocumentTitle] = useState('');
    const [sinnerLoading, setSignerLoading] = useState(false);
    const { getOrgApp, documentDispatch, documentState } = useContext(DocumentContext);
    const { signerDispatch } = useContext(SignerContext);
    const [showAddSignerDiv, setShowAddSignerDiv] = useState(false);
    const [addDisabled, setAddDisabled] = useState(false);
    const { t } = useTranslation('documentsAdd');
    const { t: ts } = useTranslation('apiMessage');
    const params = useParams();
    const textChangeAppData = textChangeApp(documentState)

    const getDocument = async () => {
        if (selectedDocInfo?.recipients?.length) {
            setSignerLoading(true);
            const { data: { document, recipients } } = await documentServices.getDocumentSigner(selectedDocInfo?.id);
            setDocumentTitle(document?.title)
            signerDispatch({ type: 'SET_SIGNERS', payload: recipients });
            setSignerLoading(false);
        } else {
            signerDispatch({ type: 'SET_SIGNERS', payload: selectedDocInfo?.recipients });
        }
    }

    useEffect(() => {
        getDocument();
    }, [params.document])

    const addNewSignerRow = async () => {
        setShowAddSignerDiv(true);
        setAddDisabled(true)
    };

    return (<div>
        {
           sinnerLoading ? <Spinner /> : 
                        <section className='my-2'>
                        <div className="container settings-form-container p-4 px-5">
                            <div className='container border-bottom'>
                                <div className='container border-bottom'>
                                    <h2 style={{
                                        color: textChangeAppData
                                    }} >{t('signr')}</h2>
                                    <p className='site-secondary-color'>{t('txt')}</p>
                                </div>
                            </div>
                            <Signer callNext={callNext} selectedDocInfo={selectedDocInfo}/>
                            {showAddSignerDiv && <AddDocumentSigner setAddDisabled={setAddDisabled} documentId={selectedDocInfo?.id} setShowAddSignerDiv={setShowAddSignerDiv} />}
                            <div className='container m-auto mb-4 mt-5'>
                                <button disabled={addDisabled} className='btn btn-primary m-auto save-btn px-4' onClick={addNewSignerRow} style={{
                                    backgroundColor: textChangeAppData, borderRadius: '10px'
                                }}>
                                    <div className='d-flex align-items-center'>
                                        <img src={userPlusIcon} alt='User plus icon' className='me-1' width={15} height={13} />
                                        <span>{t('addsign')}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </section>
            }
    </div>);
};

export default AddSignersForDoc;
