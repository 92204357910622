import React from 'react';
import { LineWave } from 'react-loader-spinner';
function Spinner() {
  return (
    <div className="d-flex justify-content-center">
      <LineWave
        height="150"
        width="150"
        color="#216988"
        ariaLabel="line-wave"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        firstLineColor=""
        middleLineColor=""
        lastLineColor=""
      />
    </div>
  );
}

export default Spinner;
