import { Navigate, useParams } from 'react-router-dom';

export default function RequireAuth({ children }) {
  const tokenString = localStorage.getItem('jwtToken');
  const { orgName } = useParams();
  
  if (!tokenString && orgName) {
      return <Navigate to={`/${orgName}/login`} />
  }
  return children;
}
