import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import editIcon from "../../../../src/assets/Icon-edit-black.png";

import { getActiveImageBackground } from "../../../utils/helper";
import AuthServices from "../../../services/auth.services";
import { toast } from "react-toastify";

const authServices = new AuthServices();
const ActiveDirectory = (props) => {
    const {
        textChangeAppData,
        defaultPrimaryColorValue,
        adDetails,
        setADDetails,
        getADDetails,
    } = props;

    const [adEdit, setADEdit] = useState(false);
    const [currentADDetails, setCurrentADDetails] = useState(adDetails);

    useEffect(() => {
        setCurrentADDetails(adDetails);
    }, [adDetails])  

    const { t } = useTranslation('admin');
    const { t: ts } = useTranslation('apiMessage');

    const addADDetails = async () => {
        try {
        const response = await AuthServices.addingADConfigDetails(currentADDetails);
        if (response?.data) {
            setADDetails({ ...response.data });
            toast.success(ts(response?.code || 'AD_DETAILS_ADDED_SUCCESSFULLY'))
        }
        } catch (error) {
        toast.error(ts(error?.response?.data?.code || 'FAILED_TO_ADD_AD_DETAILS'));
        }
    }

    const updateADDetails = async () => {
        try {
          const response = await authServices.updatingADConfigDetails(currentADDetails);
          if (response?.data) {
            setADDetails({ ...response.data });
            setADEdit(false);
            toast.success(ts(response?.code || 'AD_DETAILS_UPDATED_SUCCESSFULLY'))
          }
        } catch (error) {
          toast.error(ts(error?.response?.data?.code || 'FAILED_TO_UPDATE_AD_DETAILS'))
        }
    }

    const handleUpdateCurrentADData = (e, field) => {
        const currentFieldValue = e.target.value;
        setCurrentADDetails({ ...currentADDetails, [field]: currentFieldValue});
    }

    return (
        <div className="row ad_tab" style={{ color: textChangeAppData || defaultPrimaryColorValue }}>
          <h3 style={{ color: textChangeAppData || defaultPrimaryColorValue }}>
            {t('AD_HEADING')} 
            {adDetails?.id && !adEdit ? <img className="ms-2 domainConfigEditTour" style={{ cursor: 'pointer', ...getActiveImageBackground(textChangeAppData) }} src={editIcon} alt="edit-icon" onClick={() => setADEdit(!adEdit)} /> : null}
          </h3>
          <p>{t('AD_SUB_HEADING')}</p>
          <div class="col-xl-6 mb-3 mt-3">
            <label for="AD_URL" class="form-label fw-bold">{t('AD_URL')}</label>
            <input type="text" class="form-control" id="AD_URL" placeholder={t('ACTIVE_DIR_URL')} value={currentADDetails?.url} onChange={(e) => handleUpdateCurrentADData(e, "url")} disabled={adDetails?.id && !adEdit} />
          </div>
          <div class="col-xl-6 mb-3 mt-3">
            <label for="UserBase" class="form-label fw-bold">{t('AD_SEARCH_BASE')}</label>
            <input type="text" class="form-control" id="UserBase" placeholder={t('ENTER_SEARCH_BASE')} value={currentADDetails?.searchBase} onChange={(e) => handleUpdateCurrentADData(e, "searchBase")} disabled={adDetails?.id && !adEdit} />
          </div>
          <div className="col-xl-12 btn-actions mt-3">
            {adEdit ? (<>
              <button className="cancel-btn" onClick={() => {
                setADEdit(!adEdit);
                getADDetails();
              }}>{t('cancelBtn')}</button>
              <button style={{ backgroundColor: textChangeAppData || defaultPrimaryColorValue }} onClick={updateADDetails}>{t('Update')}</button>
            </>) : null}
            {!adDetails.id ? <button style={{ backgroundColor: textChangeAppData || defaultPrimaryColorValue }} onClick={addADDetails}>{t('saveBtn')}</button> : null}
          </div>
        </div>
    )
}

export default ActiveDirectory;