import axios from 'axios';
import { isAuthenticated } from '../utils/helper';
// import { isAuthenticated } from "utils/helper";
import API from '../api';

class AuthServices {
  async login(payload) {
    try {
      const { data } = await API.post(`/login`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async signUp(payload) {
    try {
      const { data } = await API.post(`/register`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getUserProfile(token) {
    try {
      const { data } = await API.get(`/profile`, {
        headers: { Authorization: `Bearer ${token || isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateUserProfile(payload) {
    try {
      const { data } = await API.put(`/update-profile`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updatePassword(payload) {
    try {
      const { data } = await API.patch(
        `/update-password`,
        payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateSignature(payload) {
    try {
      const organization = window.location.href?.split('/')[3] || '';
      const { data } = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/update-signature`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated()}`,
            'Organization': organization,
          }
        }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getSignature() {
    try {
      const { data } = await API.get(`/get_signature?type=SIGNATURE`, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async forgotPassword(payload) {
    try {
      const { data } = await API.post(
        `/forgot-password`,
        payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async resetPassword(payload, token) {
    try {
      const { data } = await API.patch(
        `/reset-password/${token}`,
        payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}, ${token}` },
      }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async logOut() {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('jwtRefreshToken');
    localStorage.removeItem('user');
    localStorage.removeItem('roleData')
  }

  async authenticate(token, refreshToken, user) {
    try {
      localStorage.setItem('jwtToken', JSON.stringify(token));
      localStorage.setItem('jwtRefreshToken', JSON.stringify(refreshToken));
      localStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
      return error;
    }
  }

  async resetUserDetails(payload, token) {
    try {
      const { data } = await API.post(
        `/update-user-details/${token}`,
        payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getADConfigDetails() {
    try {
      const { data } = await API.get('/active-directory/get-domain-details', { headers: { Authorization: `Bearer ${isAuthenticated()}` } });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addingADConfigDetails(payload) {
    try {
      const { data } = await API.post('/active-directory/adding-domain-details', payload, { headers: { Authorization: `Bearer ${isAuthenticated()}` } });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updatingADConfigDetails(payload) {
    try {
      const { url, searchBase } = payload;
      const { data } = await API.put(`/active-directory/updating-domain-details/${payload.id}`, { url, searchBase }, { headers: { Authorization: `Bearer ${isAuthenticated()}` } });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async validatingOrganizationHasLDAPAccess() {
    try {
    const { data } = await API.get('/active-directory/organization-has-ldap-access');
    return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default AuthServices;
