import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";

import { DocumentContext } from "../../../store/document";
import RemoveLogoWarningModal from "../Modals/RemoveLogoWarningModal";
import { isAuthenticated } from "../../../utils/helper";
import "../AdminPanel.css"

import adminJsonEnglish from '../../../locales/en/admin.json';
import adminJsonArabic from '../../../locales/ar/admin.json';

const colorOptions = ['#3c486b', '#7c3e66', '#159895', '#206888', '#903749', '#C996CC', '#ACBDC5', '#678983', '#060930', '#4f4557', '#4F200D', '#0C134F'];

const fontMap = {
    "Arial": 'arial',
    "Times New Roman": 'timesNewRoman',
    "Helvetica": 'helvetica',
    "Courier New": 'courierNew',
    "Georgia": 'georgia',
    "Palatino": 'palatino',
    "Tahoma": 'tahoma',
}

const Appearance = () => {
    const { getOrgApp, documentState } = useContext(DocumentContext);
    const { orgName } = useParams();

    const [selectedColor, setSelectedColor] = useState(documentState?.orgAppData?.primary_color ? documentState?.orgAppData?.primary_color : '');
    const [fontStyle, setFontStyle] = useState(documentState?.orgAppData?.font ? documentState?.orgAppData?.font : '');
    const [language, setLanguage] = useState(documentState?.orgAppData?.language ? documentState?.orgAppData?.language : 'English');
    const [selectedImage, setSelectedImage] = useState(null);
    const [showRemoveLogoWarningModal, setShowRemoveLogoWarningModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDeleteLogo, setIsDeleteLogo] = useState(false);  
    const [isSubmitDisabled, setSubmitDisabled] = useState(true);

    const { i18n } = useTranslation();

    const { t } = useTranslation('admin');

    const defaultPrimaryColor = localStorage.getItem("defaultPrimaryColor");
    const defaultSecondaryColor = localStorage.getItem("defaultSecondaryColor");  
    const secondarySelectedColor = documentState?.orgAppData?.secondary_color;

    const defaultColor = '#000';

    const handleColorSelection = (color) => {
        setSelectedColor(color);
    };

    useEffect(() => {
        setSelectedImage(documentState?.orgAppData?.logo);
    }, [documentState]);

    let prevSettings = useRef({ selectedColor, selectedFile, fontStyle, language, isDeleteLogo });
    let currentSetting = { selectedColor, selectedFile, fontStyle, language, isDeleteLogo };
  
    useEffect(() => {
        if (JSON.stringify(prevSettings.current) !== JSON.stringify(currentSetting)) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }, [selectedColor, selectedFile, fontStyle, language, selectedImage, isDeleteLogo ])

    const storedLocalLanguage = localStorage.getItem('language');
    useEffect(() => {
        if (storedLocalLanguage) {
        setLanguage(storedLocalLanguage);
        }
    }, [storedLocalLanguage]);

    const handleFontChange = (font) => {
        setFontStyle(font);
    };

    const changeLanguage = (language) => {
        setLanguage(language);
    };

    const removeLogo = () => {
        setShowRemoveLogoWarningModal(false)
        setSelectedImage(null);
        setIsDeleteLogo(true);
    }

    const handleImageChange = (event) => {
        event.stopPropagation()
        const file = event.target.files[0];
        if (file) {
          const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
          if (allowedTypes.includes(file.type)) {
            setSelectedFile(file);
            setIsDeleteLogo(false);
            setSelectedImage(URL.createObjectURL(file));
          } else {
            toast.error('Only PNG, JPG, and JPEG files are allowed.');
          }
          setSubmitDisabled(false);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        //   if (!selectedFile) {
        //   toast.warn(t("Please_upload_a_logo_before_submitting"));
        //    return;
        // }
        if (selectedFile) {
          formData.append('logo', selectedFile);
          console.log(selectedFile)
        }
        formData.append("primary_color", selectedColor ? selectedColor : defaultPrimaryColor);
        formData.append("secondary_color", secondarySelectedColor ? secondarySelectedColor : defaultSecondaryColor);
        formData.append("font", fontStyle ? fontStyle : 'Arial');
        if (isDeleteLogo) {
         formData.append("remove_logo", true);
         setIsDeleteLogo(false);
        }
        let lang;
        switch (language) {
          case "en":
          case "ar":
            lang = language;
            break;
          default:
            lang = "en";
            break;
        }
    
        formData.append("language", lang);
    
        // loaderDispatch({ type: 'SET_LOADING', payload: true });
        // setIsLoading(true);
        const { data } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/organization/${orgName}/appearance`, formData, {
          headers: {
            Authorization: `Bearer ${isAuthenticated()}`,
            Organization: orgName
          },
        });
        localStorage.setItem('language', language);
        i18n.changeLanguage(language);
        localStorage.setItem('language', lang);
        getOrgApp();
        // loaderDispatch({ type: 'SET_LOADING', payload: false });
        // setIsLoading(false);
        toast.success(t(data?.code));
        //saving the current setting to ref
        prevSettings.current = currentSetting;
        setSubmitDisabled(true);
        // navigate(`/${orgName}/dashboard`);
    }

    const adminTextResolver = (text) => {
        console.log()
        if (language === 'en') {
            return adminJsonEnglish[text]
        } else {
            return adminJsonArabic[text]
        }
    }

    return (
        <>
        <Container className="main-appearance-container appearance-whole">
            <Row>
                <Col>
                    <h5 className="" style={{
                    color: selectedColor ? selectedColor : defaultColor
                    }}><strong>{adminTextResolver('customHeading')}</strong></h5>
                    <p>{adminTextResolver('textBrandingColrs')}</p>
                </Col>
            </Row>
            <Row>
                <Col md={6} lg={5}>
                    <Row className="language-section align-items-center">
                        <Col className="p-0">
                            <h6 className="common-color-st m-0" style={{ color: selectedColor }}>{adminTextResolver('PrimaryColor')}</h6>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Dropdown className="appearance-tab-dropdown" id="appearance-color-dropdown" onSelect={handleColorSelection}>
                                <Dropdown.Toggle>
                                    <div className="color-dropdown-selected-option">
                                        <span className="circle" value={''} style={{ backgroundColor: selectedColor }}></span>
                                        <span>{selectedColor}</span>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {colorOptions.map((color) => (
                                        <Dropdown.Item key={color} eventKey={color} className="color-dropdown-option">
                                            <span className="color" style={{ backgroundColor: color }}></span>
                                            <span>{color}</span>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row className="logo-selection logo-tour mt-3">
                        <Col className="p-0">
                            <h6 className="common-color-st m-0" style={{ color: selectedColor }}>{adminTextResolver('Logo')} </h6>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <input type="file" id="file-input" name="file-input" accept="image/png, image/jpeg" onChange={handleImageChange} />
                            <label className={`rounded-2 ${ language === 'en' ? 'select-file-input' : 'select-file-input-ar'}`} id="file-input-label" for="file-input"> {adminTextResolver("selectFileBtn")}</label>
                        </Col>
                    </Row>
                    <Row className="mt-3 language-section language-tour">
                        <Col className="d-flex align-items-center p-0">
                            <span className="common-color-st" style={{
                                color: selectedColor || defaultColor
                            }}>{adminTextResolver('language')}</span>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Dropdown className="appearance-tab-dropdown" id="language-dropdown" onSelect={changeLanguage}>
                                <Dropdown.Toggle>
                                    {adminTextResolver(language === 'en' ? "english" : "arabic")}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey={"en"}>{adminTextResolver("english")}</Dropdown.Item>
                                    <Dropdown.Item eventKey={"ar"}>{adminTextResolver("arabic")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row className="mt-3 font-selection font-tour">
                        <Col className="d-flex align-items-center p-0">
                            <span className="common-color-st" style={{
                                color: selectedColor || defaultColor
                            }}>{adminTextResolver('font')}</span>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Dropdown className="appearance-tab-dropdown" id="language-dropdown" onSelect={handleFontChange}>
                                <Dropdown.Toggle>
                                    <span className="selected-option">
                                        {adminTextResolver(fontMap[fontStyle])}
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {Object.keys(fontMap).map(font => (
                                        <Dropdown.Item key={font} eventKey={font}>{adminTextResolver(fontMap[font])}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Button
                            style={{
                                backgroundColor: isSubmitDisabled ? ('grey') : (selectedColor ? selectedColor : defaultColor)
                            }}
                            className="submitbtn rounded-3 p-1 mx-5 mt-3"
                            onClick={handleFormSubmit}
                        >
                            {adminTextResolver('submitBtn')}
                        </Button>
                    </Row>
                </Col>
                <Col className="d-none d-md-block">
                    {
                        selectedImage
                        &&
                        <div className="p-2 mt-5" style={{position: 'relative', width: 'fit-content'}}>
                        <div className="remove-icon-container" onClick={() => setShowRemoveLogoWarningModal(true)}>
                            <span className="remove-logo-icon" onClick={() => setShowRemoveLogoWarningModal(true)}>
                                X
                            </span>
                        </div>
                        <div>
                            <img className="logo-image" height={100} src={selectedImage} alt="" width={150} />
                        </div>
                        </div>
                    }
                </Col>
            </Row>
        </Container>
        {showRemoveLogoWarningModal && <RemoveLogoWarningModal setShowRemoveLogoWarningModal={setShowRemoveLogoWarningModal} removeLogo={removeLogo} selectedColor={selectedColor} getLocalSelectedlanguage={adminTextResolver} />}
        </>
    )
}

export default Appearance;