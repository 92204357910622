import React, { createContext,useEffect, useContext, useReducer,useState } from 'react';
import AuthServices from '../services/auth.services';
import { LoaderContext } from './Loader';

const initialState = {
  user: [],
  profile: [],
  signURL: '',
  loadingAuth: false,
};

const reducer = (authState, action) => {
  switch (action.type) {
    case 'LOGIN':
    case 'SIGNUP':
      return { ...authState, user: action.payload };
    case 'LOGOUT':
      return { ...authState, user: null };
    case 'PROFILE':
      return { ...authState, profile: action.payload };
    case 'SIGN_URL':
      return { ...authState, signURL: action.payload };
    case 'SET_AUTH_LOADING':
      return { ...authState, loadingAuth: action.payload };
    default:
      return authState;
  }
};

export const AuthContext = createContext();

const authServices = new AuthServices();

export const AuthProvider = ({ children }) => {
  const { loaderDispatch } = useContext(LoaderContext);
  const [authState, authDispatch] = useReducer(reducer, initialState);
  const [userRole, setUserRole] = useState({
    role:""
  })

  useEffect(() => {
    const storedUserRole = localStorage.getItem('userRole');
    if (storedUserRole) {
      setUserRole(JSON.parse(storedUserRole));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('defaultPrimaryColor', '#206888');
    localStorage.setItem('defaultSecondaryColor', '#ea9e10');
  }, []);

  const fetchUserProfile = async () => {
    loaderDispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await authServices.getUserProfile();
      authDispatch({ type: 'PROFILE', payload: response });
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      loaderDispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  return (
    <AuthContext.Provider value={{ authState, authDispatch, fetchUserProfile, userRole,setUserRole}}>
      {children}
    </AuthContext.Provider>
  );
};
