import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './OtpModal.css'

import OTP from "../components/common/otp/OTP";

const OTPModal = (props) => {
    const { textChangeAppData, validateOTP, phoneNumber } = props;
    const { t } = useTranslation('documentsUpdate');

    return (<div className="modal show modal-container-top">
        <Modal.Dialog>
            <Modal.Header style={{ backgroundColor: textChangeAppData }}>
                <Modal.Title className="custom-model-header">{t('ENTER_OTP_TO_ACCESS_DOCUMENT')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <OTP validateOTP={validateOTP} phoneNumber={phoneNumber} />
            </Modal.Body>
        </Modal.Dialog>
    </div>)
}

export default OTPModal