import { Fragment, useContext, useEffect, useState, useRef, createRef } from 'react';
import short from 'short-uuid';
import Draggable from 'react-draggable';
import ContentLoader from "react-content-loader";
import { toast } from 'react-toastify';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { pdfjsWorker } from 'pdfjs-dist/build/pdf.worker.entry';
import { Rnd } from 'react-rnd';
import { Col, Dropdown, Row } from 'react-bootstrap';

import DocumentServices from '../../services/document.services';
import zoomInIcon from './../../assets/zoom-in.png';
import zoomOutIcon from './../../assets/zoom-out.png';
import { textChangeApp } from '../../utils/constants';
import { DocumentContext } from '../../store/document';
import signatureIcon from "../../assets/icons8-signature-64.png"
import photoIcon from "../../assets/icons8-photo-50.png"
import stampIcon from "../../assets/icons8-stamp-66.png"
import {
    NAME,
    EMAIL_ID,
    COMPANY,
    TITLE,
    TEXT,
    CHECKBOX,
    DROPDOWN,
    SIGNATURE,
    PHOTO,
    STAMP,
    SENDER,
    SIGNER
} from '../../utils/constants';

import '../Document/DocumentUpdate.css';
import DocumentTemplateServices from '../../services/documentTemplateServices';
import AnnotationProperties from './AnnotationProperties';
import './AddTemplatePosition.css'

const documentTemplateServices = new DocumentTemplateServices();

const getAnnotationWidth = () => 150;
const getAnnotationHeight = () => 64;

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const closeIconStyles = {
    container: {
        position: 'relative',
        width: '200px',
        height: '200px',
        backgroundColor: 'lightgray',
        border: '1px solid gray',
    },
    closeIconContainer: {
        position: 'absolute',
        top: '-12px',
        right: '-5px',
        width: '22px',
        height: '22px',
        borderRadius: '50%',
        backgroundColor: 'gray',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
    },
    closeIcon: {
        fontSize: '14px',
        color: 'white',
        cursor: 'pointer',
        position: 'absolute',
    },
};

const AddTemplatePosition = ({ selectedDocInfo, callNext }) => {
    const { documentState, fetchDocuments, documentDispatch } = useContext(DocumentContext);
    const { templateCategories = [] } = documentState;

    const { t } = useTranslation('documentsUpdate');
    const { t: ts } = useTranslation('apiMessage');
    const { t: tp } = useTranslation('templates');

    const { selectedDocument } = documentState;
    let isDragging = false;

    const params = useParams();

    const pdfDocumentRef = useRef(null);
    const refPages = useRef([]);
    const documentRef = useRef();
    const currentAnnotationRef = useRef({});

    const [annotations, setAnnotations] = useState([]);
    const [annotation, setAnnotation] = useState({});
    const [selectedAnnotationForPropertiesDisplay, setSelectedAnnotationForPropertiesDisplay] = useState();
    const [docZoom, setDocZoom] = useState(1);
    const [selectedAnnotationId, setSelectedAnnotationId] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [addAnnotation, setAddAnnotation] = useState(false);
    const [fields, setFields] = useState([]);
    const [templateCategory, setTemplateCategory] = useState('');

    const { data } = selectedDocument || {};
    const { title, url, id: templateId } = data || {};

    const getDocument = async () => {
        const document = await documentTemplateServices.getTemplate(selectedDocInfo?.id);
        if (document) {
            documentDispatch({ type: 'SET_SELECTED_DOCUMENT', payload: document });
            const responseAnnotations = document?.data?.documentTemplateFields || [];
            const formattedAnnotations = responseAnnotations.map(annotation => ({
                ...annotation,
                annId: annotation.id,
                x: Number(annotation?.positionX),
                y: Number(annotation?.positionY),
            }));
            setAnnotations(formattedAnnotations);
        }
    };

    const textChangeAppData = textChangeApp(documentState);

    useEffect(() => {
        if (selectedDocInfo?.id) {
            getDocument();
        }
    }, []);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    }, []);

    const setDummyDimensions = (payload) => {
        const data = payload;
        switch (payload.type) {
            case EMAIL_ID:
            case COMPANY:
            case TITLE:
            case NAME:
            case TEXT:
                data.height = 60
                data.width = 100
                break;
            case CHECKBOX:
                data.height = 40
                data.width = 40;
                break;
            case DROPDOWN:
                data.height = 40
                data.width = 100
                break;
            default:
        }
    }

    useEffect(() => {
        const updateDocument = async () => {
            try {
                const { fieldName, fulfiller } = currentAnnotationRef.current;
                const payload = {
                    type: fieldName,
                    page: selectedAnnotation[0]?.page,
                    positionX: selectedAnnotation[0]?.x,
                    positionY: selectedAnnotation[0]?.y,
                    height: selectedAnnotation[0].height || 20,
                    width: selectedAnnotation[0].width || 20,
                    documentTemplateId: selectedDocInfo?.id,
                    fulfiller,
                    is_required: false, //fulfiller === SENDER,
                }
                setDummyDimensions(payload);
                const response = await documentTemplateServices.addTemplatePosition(payload);

                if (response.message) {
                    toast.success(ts(response?.code || response?.message));
                    setAddAnnotation(false);
                    const responseAnnotation = response?.data;
                    const updatedData = annotations.map(obj => {
                        if (obj.annId === annotation.annId) {
                            const addedAnnotation = {
                                ...responseAnnotation,
                                x: Number(responseAnnotation?.positionX),
                                y: Number(responseAnnotation?.positionY),
                                height: responseAnnotation.height,
                                width: responseAnnotation.width,
                                page: Number(responseAnnotation?.page),
                                type: fieldName,
                                annId: responseAnnotation?.id,
                                fulfiller: responseAnnotation.fulfiller
                            };
                            setSelectedAnnotationForPropertiesDisplay(addedAnnotation);
                            return addedAnnotation;
                        }
                        return obj;
                    });
                    setAnnotation({});
                    setAnnotations(updatedData);
                }
            } catch (error) {
                toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`) || error.response?.data?.code);
            } finally {
                //scroll back to page where the annotation is added
                goToPageOnRender();
            }
        }
        const selectedAnnotation = annotations && annotations.length > 0 && annotations.filter(annotation => annotation.annId === selectedAnnotationId);
        selectedAnnotation && selectedAnnotation.length > 0 && addAnnotation && updateDocument();
    }, [selectedAnnotationId, annotations, fetchDocuments, templateId]);

    //Scroll back to last added annotation site
    const goToPageOnRender = () => {
        if (annotations.length) {
            const lastAnnotationIndex = annotations.length - 1;
            const annotationsHtml = document.querySelector(`.annotation_${lastAnnotationIndex}`);
            if (annotationsHtml) {
                annotationsHtml.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
            }
        }
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        refPages.current = new Array(nextNumPages).fill().map((_, index) => refPages.current[index] || createRef());
        setNumPages(nextNumPages);
    }

    const handleAnnotationClick = (e, index) => {
        if (isDragging || e?.target?.className === 'annotation-text' || e?.target?.className === 'recipient-email' || e?.target?.className !== `react-pdf__Page__canvas` || currentAnnotationRef.current.fieldName === undefined) {
            setAddAnnotation(false);
            return null;
        } else {
            const localRef = refPages.current[index];
            const { clientX, clientY } = e;
            const { x, y, height, width } = localRef.getBoundingClientRect();
            const pageX = clientX - x;
            const pageY = clientY - y;
            const nextId = annotations.length + 'a';
            const overFlowWidth = width - pageX;
            const overFlowHeight = height - pageY;
            const { fieldName, fulfiller } = currentAnnotationRef.current;
            const annotation = {
                x: overFlowWidth < getAnnotationWidth() ? pageX - (getAnnotationWidth() - overFlowWidth) : pageX,
                y: overFlowHeight < getAnnotationHeight() ? pageY - (getAnnotationHeight() - overFlowHeight) : pageY,
                page: index,
                type: fieldName,
                annId: nextId,
                fulfiller: fulfiller,
                is_required: false, //fulfiller === SENDER
            };
            if ([EMAIL_ID, COMPANY, TITLE, NAME, TEXT, CHECKBOX, DROPDOWN].includes(fieldName)) {
                setDummyDimensions(annotation);
                annotation.x = overFlowWidth < annotation.width ? pageX - (annotation.width - overFlowWidth) : pageX;
                annotation.y = overFlowHeight < annotation.height ? pageY - (annotation.height - overFlowHeight) : pageY;
            }
            setAddAnnotation(true);
            setAnnotation(annotation);
            setSelectedAnnotationForPropertiesDisplay(annotation);
            setAnnotations([...annotations, annotation]);
            setSelectedAnnotationId(nextId);
        }
    };

    const getAnnotationElement = (element) => {
        let currentElement = element;
        for (let i = 0; i < 4; i++) {
            if (currentElement.className.includes('react-draggable')) {
                return currentElement;
            }
            currentElement = currentElement.parentElement;
        }
        return undefined;
    }

    const handleDrag = (e) => {
        isDragging = true;
    };

    const checkForDimensionChange = (oldAnnotation, updatedAnnotation) => {
        return oldAnnotation.x != updatedAnnotation.x || oldAnnotation.y != updatedAnnotation.y || oldAnnotation.height != updatedAnnotation.height || oldAnnotation.width != updatedAnnotation.width;
    }

    const onDragStop = async (e, draggableData, annotationId, index) => {
        setTimeout(() => (isDragging = false), 0);
        if (e.target.className === 'close-button' || e.target.className.includes('close-button')) {
            try {
                const response = await documentTemplateServices.removeTemplatePosition(annotationId);
                if (response.message) {
                    const res = annotations.filter(annotation => annotation.annId !== annotationId);
                    setAnnotations(res);

                    toast.success(ts(response?.code || response?.message));
                }
            } catch (error) {
                toast.error(ts(error?.response?.data?.code || 'SOMETHING_WENT_WRONG'))
                console.log('Something went wrong.');
            }
        } else {
            let newArr = [...annotations]
            let targetTextElement = e.target;
            let originalAnnotation = {};
            const newArray = newArr.map((annotation, i) => {
                if (annotation.annId === annotationId) {
                    originalAnnotation = { ...annotation }
                    const newAnnotationData = { ...annotation, x: draggableData.x, y: draggableData.y };
                    if ([EMAIL_ID, COMPANY, NAME, TITLE, TEXT, CHECKBOX, DROPDOWN].includes(annotation.type)) {
                        targetTextElement = getAnnotationElement(e.target);
                        if (targetTextElement) {
                            newAnnotationData.height = targetTextElement.clientHeight;
                            newAnnotationData.width = targetTextElement.clientWidth;
                        }
                    }
                    return newAnnotationData;
                } else {
                    return annotation;
                }
            });
            try {
                const cAnn = newArray?.filter(v => v.annId === annotationId)?.[0];
                const payload = { ...cAnn, positionX: draggableData.x, positionY: draggableData.y }
                if (targetTextElement) {
                    payload.height = cAnn.height;
                    payload.width = cAnn.width;
                }
                if (checkForDimensionChange(originalAnnotation, cAnn)) {
                    const response = await documentTemplateServices.updateTemplatePositions({ payload, id: annotationId });
                    if (response.message) {
                        toast.success(ts(response?.code || response?.message));
                        setAnnotations(newArray);
                        setSelectedAnnotationId(annotationId);
                    }
                }
                setSelectedAnnotationForPropertiesDisplay(cAnn);
            } catch (error) {
                // Revert back to original Position if something goes wrong
                setAnnotations(newArr);

                toast.error(ts(error?.response?.data?.code || 'SOMETHING_WENT_WRONG'))
                console.log('Something went wrong.');
            }
        }
    };

    const handleUpdateAnnotation = async (updatedAnnotation) => {
        const formattedAnnotation = { ...updatedAnnotation, positionX: updatedAnnotation.x, positionY: updatedAnnotation.y };
        const response = await documentTemplateServices.updateTemplatePositions({ payload: formattedAnnotation, id: updatedAnnotation.annId });
        let newArray = [...annotations]
        if (response.message) {
            toast.success(ts(response?.code || response?.message));
            newArray = newArray.map((currentAnnotation) => {
                if (currentAnnotation.annId === updatedAnnotation.annId) {
                    return {
                        ...updatedAnnotation,
                    }
                }
                return currentAnnotation;
            })
            setSelectedAnnotationForPropertiesDisplay(formattedAnnotation);
            setAnnotations(newArray);
        }
    }

    const renderSignature = (annotation, index) => {
        return (
            <Draggable
                disabled={false}
                onStop={(e, draggableData) => onDragStop(e, draggableData, annotation.annId, index)}
                defaultPosition={{ x: annotation.x, y: annotation.y }}
                bounds="parent"
                onDrag={handleDrag}
                key={annotation.annId}
            >
                {renderAnnotationTemplate(annotation, textChangeAppData, SIGNATURE)}
            </Draggable>
        );
    };

    const renderImage = (annotation, index) => {
        return (
            <Draggable
                disabled={false}
                onStop={(e, draggableData) => onDragStop(e, draggableData, annotation.annId, index)}
                defaultPosition={{ x: annotation.x, y: annotation.y }}
                bounds="parent"
                onDrag={handleDrag}
                key={annotation.annId}
            >
                {renderAnnotationTemplate(annotation, textChangeAppData, PHOTO)}
            </Draggable>
        );
    };

    const renderTextarea = (annotation, index, type) => {
        return (
            <Rnd
                size={{ width: `${annotation.width}px`, height: `${annotation.height}px` }}
                position={{ x: annotation.x, y: annotation.y }}
                onDragStop={(e, d) => { onDragStop(e, d, annotation.annId, index) }}
                onResizeStop={(e, direction, ref, delta, position) => { onDragStop(e, position, annotation.annId, index) }}
                minHeight={23}
                minWidth={20}
                key={annotation.annId}
                bounds="parent"
            >
                <div
                    className={`annotation_${index}`}
                    style={{ height: '100%', width: '100%', borderStyle: "solid", borderColor: textChangeAppData }}
                >
                    <div
                        style={{ height: '100%', width: '100%', backgroundColor: '#808080ba' }}
                        className='resizableTextarea d-flex align-items-center justify-content-center'
                    >
                        {tp(type)}
                    </div>
                    <div
                        className="close-button close-button-container"
                        style={closeIconStyles.closeIconContainer}
                    >
                        <div className="close-button" style={closeIconStyles.closeIcon}>
                            &times;
                        </div>
                    </div>
                </div>
            </Rnd>
        );
    };

    const renderStamp = (annotation, index) => {
        return (
            <Draggable
                disabled={false}
                onStop={(e, draggableData) => onDragStop(e, draggableData, annotation.annId, index)}
                defaultPosition={{ x: annotation.x, y: annotation.y }}
                bounds="parent"
                onDrag={handleDrag}
                key={annotation.annId}
            >
                {renderAnnotationTemplate(annotation, textChangeAppData, STAMP, index)}
            </Draggable>
        );
    };

    const renderCheckbox = (annotation, index) => {
        return (
            <Rnd
                size={{ width: `${annotation.width}px`, height: `${annotation.height}px` }}
                position={{ x: annotation.x, y: annotation.y }}
                onDragStop={(e, d) => { onDragStop(e, d, annotation.annId, index) }}
                onResizeStop={(e, direction, ref, delta, position) => { onDragStop(e, position, annotation.annId, index) }}
                key={annotation.annId}
                bounds="parent"
            >
                <div
                    className={`annotation_${index}`}
                    style={{ height: '100%', width: '100%', borderStyle: "solid", borderColor: textChangeAppData }}
                >
                    <div
                        style={{ height: '100%', width: '100%', backgroundColor: '#808080ba' }}
                        className='resizableTextarea d-flex align-items-center justify-content-center'
                    >
                        <input type='checkbox' className='w-100 h-100' />
                    </div>
                    <div
                        className="close-button close-button-container"
                        style={closeIconStyles.closeIconContainer}
                    >
                        <div className="close-button" style={closeIconStyles.closeIcon}>
                            &times;
                        </div>
                    </div>
                </div>
            </Rnd>
        )
    }

    const renderDropdown = (annotation, index) => {
        return (
            <Rnd
                size={{ width: `${annotation.width}px`, height: `${annotation.height}px` }}
                position={{ x: annotation.x, y: annotation.y }}
                onDragStop={(e, d) => { onDragStop(e, d, annotation.annId, index) }}
                onResizeStop={(e, direction, ref, delta, position) => { onDragStop(e, position, annotation.annId, index) }}
                key={annotation.annId}
                bounds="parent"
            >
                <div
                    className={`annotation_${index}`}
                    style={{ height: '100%', width: '100%', borderStyle: "solid", borderColor: textChangeAppData }}
                >
                    <div
                        style={{ height: '100%', width: '100%', backgroundColor: '#808080ba' }}
                        className='resizableTextarea d-flex align-items-center justify-content-center'
                    >
                        <p className='m-0'>{tp('DROPDOWN')}</p>
                    </div>
                    <div
                        className="close-button close-button-container"
                        style={closeIconStyles.closeIconContainer}
                    >
                        <div className="close-button" style={closeIconStyles.closeIcon}>
                            &times;
                        </div>
                    </div>
                </div>
            </Rnd>
        );
    }

    const renderAnnotationTemplate = (annotation, colorCode, type, index) => (
        <div
            className={`annotation_${index}`}
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
                maxWidth: `${getAnnotationWidth()}rem`,
                backgroundColor: 'rgb(255 255 255)',
                opacity: 0.8,
                cursor: 'move',
            }}
        >
            <div className="annotation-text d-flex flex-column align-items-center">
                {type === SIGNATURE && (
                    <p className='StampPhotoSignIconsdoc justify-content-center gap-2' style={{ width: '8rem', height: '2.7rem' }}>
                        <img src={signatureIcon} alt='' style={{ height: '1.5rem' }} />
                        <div className='' style={{ color: 'black', fontSize: '14px' }}>{t('SIGNATURE_LABEL')}</div>
                    </p>
                )}
                {type === PHOTO && (
                    <p className='StampPhotoSignIconsdoc flex-column'>
                        <img className='mt-1' src={photoIcon} alt='' style={{ height: '1.7rem' }} />
                        <div className='' style={{ color: 'black', fontSize: '14px' }}>{t('PHOTO_LABEL')}</div>
                    </p>
                )}
                {type === STAMP && (
                    <p className='StampPhotoSignIconsdoc flex-column'>
                        <img className='mt-1' src={stampIcon} alt='' style={{ height: '1.7rem' }} />
                        <div className='' style={{ color: 'black', fontSize: '14px' }}>{t('STAMP_LABEL')}</div>
                    </p>
                )}
                {<div className="recipient-email">
                    {Boolean(annotation.recipientEmail) ? annotation.recipientEmail : '< >'}
                </div>}
            </div>
            <div
                className="close-button close-button-container"
                style={closeIconStyles.closeIconContainer}
            // onClick={(e) => deleteAnnotation(annotation.annId)}
            >
                <div className="close-button" style={closeIconStyles.closeIcon}>
                    &times;
                </div>
            </div>
        </div>
    );

    const renderAnnotations = (pageIndex) => {
        return annotations && annotations.map((annotation, index) => {
            if (Number(annotation.page) === pageIndex) {
                switch (annotation.type) {
                    case SIGNATURE:
                        return renderSignature(annotation, index);
                    case PHOTO:
                        return renderImage(annotation, index);
                    case TEXT:
                    case EMAIL_ID:
                    case NAME:
                    case COMPANY:
                    case TITLE:
                        return renderTextarea(annotation, annotation.annId, annotation.type);
                    case CHECKBOX:
                        return renderCheckbox(annotation, index);
                    case STAMP:
                        return renderStamp(annotation, index);
                    case DROPDOWN:
                        return renderDropdown(annotation, index);
                    default:
                        return null
                }
            }
            return null;
        });
    };

    const dynamicCursor = (selectedAnnotation) => {
        let height = 100;
        let width = 100;

        switch (selectedAnnotation.fieldName) {
            case SIGNATURE:
            case TEXT:
                height = 60;
                break;
            case CHECKBOX:
                height = 40;
                width = 80;
                break;
            case PHOTO:
            case STAMP:
                break;
            case EMAIL_ID:
            case NAME:
            case COMPANY:
            case TITLE:
                height = 60;
                width = 120;
                break;
            case DROPDOWN:
                height = 40;
                width = 120;
                break;
            default:
                return 'pointer'
        }
        return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}"><rect x="0" y="0" width="22rem" height="100" fill="rgb(0 93 135)" opacity="0.4" /><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="14" fill="white">${t(selectedAnnotation.label)}</text></svg>'), auto`;
    }

    const handleFieldSelect = (selectedField) => {
        documentRef.current.style.cursor = dynamicCursor(selectedField)
        currentAnnotationRef.current = selectedField;
    }

    useEffect(() => {
        setFields([
            {
                fieldName: NAME,
                label: 'NAME_LABEL',
                fulfiller: SENDER,
                icon: <i class="bi bi-cursor-text"></i>
            },
            {
                fieldName: EMAIL_ID,
                label: 'EMAIL_LABEL',
                fulfiller: SENDER,
                icon: <i class="bi bi-envelope-at"></i>
            },
            {
                fieldName: CHECKBOX,
                label: 'CHECKBOX_LABEL',
                fulfiller: SENDER,
                icon: <i class="bi bi-check2-square"></i>
            },
            {
                fieldName: TEXT,
                label: 'TEXTAREA_LABEL',
                fulfiller: SENDER,
                icon: <i class="bi bi-fonts"></i>
            },
            {
                fieldName: DROPDOWN,
                label: 'DROPDOWN_LABEL',
                fulfiller: SENDER,
                icon: <i class="bi bi-menu-down"></i>
            },
            // {
            //     fieldName: SIGNATURE,
            //     label: 'SIGNATURE_LABEL',
            //     fulfiller: SIGNER,
            //     icon: <i class="bi bi-pen"></i>
            // },
            // {
            //     fieldName: PHOTO,
            //     label: 'PHOTO_LABEL',
            //     fulfiller: SIGNER,
            //     icon: <i class="bi bi-file-image"></i>
            // },
            // {
            //     fieldName: STAMP,
            //     label: 'STAMP_LABEL',
            //     fulfiller: SIGNER,
            //     icon: <i class="bi bi-cc-circle-fill"></i>
            // },
            // {
            //     fieldName: TEXT,
            //     label: 'TEXTAREA_LABEL',
            //     fulfiller: SIGNER,
            //     icon: <i class="bi bi-fonts"></i>
            // },
        ]);
    }, []);

    const zoomInClicked = () => {
        docZoom <= 6 && setDocZoom(docZoom + 1);
    };

    const zoomOutClicked = () => {
        docZoom > 1 && setDocZoom(docZoom - 1);
    };

    const renderPlaceholder = (props) => {
        const length = 30;
        const lines = Array.from({ length }, (_, index) => index);
        return (
            <div className='row'>
                <div className='col-sm-12' style={{ height: '600px' }}>
                    <ContentLoader
                        speed={1}
                        viewBox="0 0 auto auto"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        style={{ width: '100%', height: '100%' }}
                        {...props}
                    >
                        <rect x="10%" y="5" rx="3" ry="3" width="80%" height="6" />
                        {
                            lines.map((item, index) => {
                                return (
                                    <rect key={index} x="0" y={(15 * (index + 1)) + (6 * (index + 1))} rx="3" ry="3" width="100%" height="6" />
                                )
                            })
                        }
                        <rect x="10%" y={(15 * (10 + 1)) + (6 * (10 + 1))} rx="3" ry="3" width="80%" height="6" />
                    </ContentLoader>
                </div>
            </div>
        )
    }

    const handleCategorySelect = async (selectedCategoryId) => {
        const response = await documentTemplateServices.updateTemplateCategory(templateId, selectedCategoryId);
        const { data } = response;
        if (data.code === 'TEMPLATE_CATEGORY_ID_UPDATED_SUCCESSFULLY') {
            const selectedCategory = templateCategories.find(category => category.id === Number(selectedCategoryId));
            setTemplateCategory(selectedCategory?.name || '')
            toast.success(ts(data?.code || data?.message));
        }
    }

    return (
        <div>
            <div>
                <div className="">
                    <div style={{ width: '100%' }} className="row">
                        <Col sm={2}>
                            <Row className="flex-column mt-3">
                                {
                                    fields.map((field, index) => (
                                        <div className="m-0 p-0" key={field.fieldName + field.fulfiller}>
                                            {index === 0 && <p style={{ fontWeight: '600', color: textChangeAppData }}>{tp('SENDER_FIELDS')}</p>}
                                            {index === 5 && <><hr className='my-3' /><p style={{ margin: '0px', fontWeight: '600', color: textChangeAppData }}>RECIPIENT FIELDS</p></>}
                                            <Col className='selection-feilds p-1' style={{ border: `1px solid ${textChangeAppData}` }} onClick={() => handleFieldSelect(field)}>
                                                <div style={{ color: textChangeAppData, fontSize: '20px' }}>{field?.icon || ''}</div>
                                                <span className='field-name' style={{ fontWeight: '600', color: textChangeAppData }}>{tp(field.fieldName)}</span>
                                            </Col>
                                        </div>
                                    ))
                                }
                            </Row>
                            <div>
                                <p style={{ fontWeight: '600', color: textChangeAppData }} className='mt-3 mb-2'>{tp('TEMPLATE_CATEGORY')}</p>
                                <Dropdown onSelect={handleCategorySelect}>
                                    <Dropdown.Toggle style={{ backgroundColor: textChangeAppData }} id="dropdown-basic">
                                        {templateCategory || tp('SELECT_CATEGORY')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {
                                            templateCategories.map(category => (
                                                <Dropdown.Item eventKey={category.id}>{category.name}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <div className="col-sm-7" style={{ height: "650px", overflowY: 'auto' }}>
                            <div className="section">
                                <div className='zoominout-container'>
                                    {docZoom < 6 && (<span className='recipients recipient-text cursor-pointer' onClick={zoomInClicked}>
                                        <div className='circle'> <img src={zoomInIcon} alt='zoom-in icon' className='me-2' width={11} height={12} /> </div>
                                        {t('zIn')}
                                    </span>)}
                                    {docZoom > 1 && (<span className='recipients recipient-text cursor-pointer' onClick={zoomOutClicked}>
                                        <div className='circle'><img src={zoomOutIcon} alt='zoom-out icon' className='me-2' width={11} height={12} /></div>
                                        {t('zOt')}
                                    </span>)}
                                </div>
                                <div ref={documentRef} style={{ cursor: dynamicCursor(currentAnnotationRef.current) }}>
                                    <Document
                                        inputRef={pdfDocumentRef}
                                        options={options}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        renderMode="canvas"
                                        file={url}
                                        renderAnnotationLayer={false}
                                        className={`document-update doc-zoom-${docZoom}-stage`}
                                        style={{ zoom: '2' }}
                                        loading={renderPlaceholder}
                                    >
                                        {Array.from({ length: numPages }, (_, index) => (
                                            <Fragment key={short.generate().toString()}>
                                                <div
                                                    key={short.generate().toString()}
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                    className={`mx-auto w-fit page-${index}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleAnnotationClick(e, index)
                                                    }}
                                                >
                                                    <Page
                                                        inputRef={(pa) => (refPages.current[index] = pa)}
                                                        style={{ margin: 'auto' }}
                                                        key={`page_${index + 1}`}
                                                        pageNumber={index + 1}
                                                        renderAnnotationLayer={false}
                                                        renderTextLayer={false}
                                                        loading={renderPlaceholder}
                                                        onLoadSuccess={(e) => { }}
                                                        onRenderError={() => console.log('error occurred')}
                                                    >
                                                    </Page>
                                                    {renderAnnotations(index)}
                                                </div>
                                            </Fragment>
                                        ))}
                                    </Document>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 pdf-preview">
                            {selectedAnnotationForPropertiesDisplay ? (
                                <>
                                    <h4 style={{color: textChangeAppData}}>{tp('SELECTED_FIELD_PROPERTIES')}</h4>
                                    <AnnotationProperties
                                        annotation={selectedAnnotationForPropertiesDisplay}
                                        handleUpdateAnnotation={handleUpdateAnnotation}
                                        themeColor={textChangeAppData}
                                    />
                                </>
                            ) : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AddTemplatePosition;
