import { useContext } from 'react';
import inactiveSmallCircle from '../../assets/small_white_circle.png';
import inactiveBigCircle from '../../assets/big_white_circle.png';
import finishBlack from '../../assets/finish_black.png';
import imageBlack from '../../assets/image_black.png';
import stampBlack from '../../assets/stamp_black.png';
import textBoxBlack from '../../assets/stepper-black-text.png';
import documentIconBlack from '../../assets/document_icon_black.png';

import activeBackgroundCircle from '../../assets/blue_circle.png';
import activeDocumentSign from '../../assets/document_icon_white.png';
import activeImageWhite from '../../assets/image_white.png';
import activeStampWhite from '../../assets/stamp_white.png';
import textBoxWhite from '../../assets/stepper-white-text.png';
import activeFinishWhite from '../../assets/finish_white.png';

import { DocumentContext } from "../../store/document";
import { getActiveImageBackground } from '../../utils/helper';
import { useTranslation } from 'react-i18next';

import '../ui/styles/Stepper.css'


const Stepper = (props) => {
    const { t } = useTranslation('settings');

    const { documentState } = useContext(DocumentContext);

    const annotationTypes = props.annotations.map(annotation => annotation.type);
    const primaryColor = documentState?.orgAppData?.primary_color?.toLowerCase();

    const ProcessBarColor = { border: `2px Solid ${primaryColor}` };
    const inActiveProgessBarColor = { border: '1px solid #000' };

    const generateInactiveIcon = (image) => {
        return (
            <div className='image_icons'>
                {/* Big Circle */}
                <div>
                    <img src={inactiveBigCircle} alt='big-background' />
                </div>

                {/* Small Circle */}
                <div className='inactive-small'>
                    <img src={inactiveSmallCircle} alt='small-background' />
                </div>

                {/* Icon */}
                <div className='inactive-icon'>
                    <img src={image} alt='icon' width={25} height={25} />
                </div>
            </div>
        );
    };

    const generateactiveIcon = (image) => {
        return (
            <div className='image_icons'>
                {/* Big Circle */}
                <div className='active-big'>
                    <img style={getActiveImageBackground(primaryColor)} src={activeBackgroundCircle} alt='big-background' width={80} />
                </div>

                {/* Icon */}
                <div className='active-icon'>
                    <img src={image} alt='icon' width={27} height={27} />
                </div>
            </div>
        );
    };

    return (
        <div className='stepper-main'>
            {annotationTypes.includes('SIGNATURE') ? <>
                <div className='icon-div'>
                    <div>
                        {props.activeStep === 1 ? generateactiveIcon(activeDocumentSign) : generateInactiveIcon(documentIconBlack)}
                    </div>
                    <div className='stepper-subtitle'>
                        {t('DOCUMENT_SIGN')}
                    </div>
                </div>
                <div className='stepper-line' style={props.activeStep >= 2 ? ProcessBarColor : inActiveProgessBarColor}></div>
            </> : null}
            {annotationTypes.includes('IMAGE') ? <>
                <div className='icon-div'>
                    <div>
                        {props.activeStep === 2 ? generateactiveIcon(activeImageWhite) : generateInactiveIcon(imageBlack)}
                    </div>
                    <div className='stepper-subtitle'>
                        {t('UPLOAD_PHOTO')}
                    </div>
                </div>
                <div className='stepper-line' style={props.activeStep >= 3 ? ProcessBarColor : inActiveProgessBarColor}></div>
            </> : null}
            {annotationTypes.includes('STAMP') ? <>
                <div className='icon-div'>
                    <div>
                        {props.activeStep === 3 ? generateactiveIcon(activeStampWhite) : generateInactiveIcon(stampBlack)}
                    </div>
                    <div className='stepper-subtitle'>
                        {t('UPLOAD_STAMP')}
                    </div>
                </div>
                <div className='stepper-line' style={props.activeStep >= 4 ? ProcessBarColor : inActiveProgessBarColor}></div>
            </> : null}
            {annotationTypes.includes('TEXTAREA') ? <>
                <div className='icon-div'>
                    <div>
                        {props.activeStep === 4 ? generateactiveIcon(textBoxWhite) : generateInactiveIcon(textBoxBlack)}
                    </div>
                    <div className='stepper-subtitle'>
                        {t('INPUT_TEXT')}
                    </div>
                </div>
                <div className='stepper-line' style={props.activeStep >= 4 ? ProcessBarColor : inActiveProgessBarColor}></div>
            </> : null}
            <div className='icon-div'>
                <div>
                    {props.activeStep === 5 ? generateactiveIcon(activeFinishWhite) : generateInactiveIcon(finishBlack)}
                </div>
                <div className='stepper-subtitle'>
                    {t('FINISH')}
                </div>
            </div>
        </div>
    )
}

export default Stepper;