import Cookies from 'universal-cookie';

const cookies = new Cookies();
class Auth {
    static setUrl(newUrl) {
        cookies.set('dastKhatRedirect', newUrl, { path: '/' });
    }

    static getNewUrl() {
        return cookies.get('dastKhatRedirect');
    }

    static async deleteNewUrl() {
        await cookies.remove('dastKhatRedirect', { path: '/' });
    }
}

export default Auth;
