import { Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import AddRemoveOptions from "./AddRemoveOptions";
import { DROPDOWN } from '../../utils/constants';
import debounce from "../../utils/debounce";

const AnnotationProperties = (props) => {
    const { annotation, handleUpdateAnnotation, themeColor } = props;

    const { t } = useTranslation('templates');

    const updateAnnotationProperties = debounce((updatedAnnotation) => {
        handleUpdateAnnotation(updatedAnnotation);
    }, 500)

    return (
        <Row>
            <Col md={12}>
                <p className="mb-1" style={{ fontSize: '1.1rem' }}>{t('FULFILLER')}: {t(annotation.fulfiller)}</p>
            </Col>
            <Col md={12}>
                <Form>
                    <Form.Check
                        style={{ fontSize: '1.1rem' }}
                        inline
                        label={t('REQUIRED')}
                        name="group1"
                        type="checkbox"
                        checked={annotation.is_required}
                        id="inline-checkbox-1"
                        onClick={e => updateAnnotationProperties({ ...annotation, is_required: e.target.checked })}
                    />
                </Form>
            </Col>
            {annotation.type === DROPDOWN && (
                <Col md={12}>
                    <AddRemoveOptions
                        annotationData={annotation}
                        handleUpdateAnnotation={handleUpdateAnnotation}
                        themeColor={themeColor}
                    />
                </Col>
            )}
        </Row>
    )
}

export default AnnotationProperties;