import { Card, Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Tab.css";
import camera from './../../../assets/camera.png';
import photoimg from "./../../../assets/display-pic.png";

const PhotoTab = (props) => {
    const { openCamera, savePhotoImage } = props
    const { t } = useTranslation('documentsUpdate');
    const { t: tp } = useTranslation('settings');

    return (<>
        <Card>
            <Card.Title style={{ backgroundColor: '#bcbeff' }}>
                {tp('UPLOAD_PHOTO')}:
            </Card.Title>
            <Card.Body style={{ backgroundColor: '#c4b3f7', fontWeight: '600', color: '#8f1657' }}>
                <Row className="d-flex justify-content-between gap-1">
                    <Col sm={5} className="photo-card-body">
                        <Button className="tab-button camera-tab-button" onClick={openCamera}>
                            <img src={camera} width={16} height={16} alt='create Signature Icon' />
                            {t('camera')}
                        </Button>
                    </Col>
                    <Col sm={6} className="photo-card-body">
                        <img src={savePhotoImage ? savePhotoImage : photoimg} alt='photo' className='my-uploaded-signature mx-3 p-2' width={160} height={150} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </>)
}

export default PhotoTab