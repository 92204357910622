import React, { Fragment, useEffect, useState, useRef, createRef } from 'react';
import short from 'short-uuid';
import Draggable from 'react-draggable';
import ContentLoader from "react-content-loader";
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { pdfjsWorker } from 'pdfjs-dist/build/pdf.worker.entry';

import signatureIcon from "./../../../assets/icons8-signature-64.png";
import photoIcon from "./../../../assets/icons8-photo-50.png";
import stampIcon from "./../../../assets/icons8-stamp-66.png"


const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const getAnnotationWidth = () => 11;

const DocumentPageRenderer = (props) => {
    const { textAnnotationsRef, isRejected, url, image, mySignature, annotations, isSigned, isSignedPhoto, PhotoSignedPlaces, AttachPhotoImage, isSignedStamp, StampSignedPlaces, AttachStampImage, activeStep } = props;
    const { t } = useTranslation('documentsUpdate');

    let isDragging = false;

    const refPages = useRef([]);
    const pdfDocumentRef = useRef(null);

    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    }, []);

    const renderAnnotations = (pageIndex) => {
        return annotations && annotations.map((annotation, index) => {
            if (annotation?.page === pageIndex && annotation?.signingStatus === "NOT_SIGNED") {
                if (annotation?.type === 'SIGNATURE') {
                    return (
                        <Draggable disabled={true} defaultPosition={{ x: annotation.x, y: annotation.y }} bounds="parent" key={index}>
                            <div
                                className={`annotation_${index}`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    maxWidth: `${getAnnotationWidth()}rem`,
                                    backgroundColor: !isRejected ? isSigned ? 'transparent' : '#fff' : null,
                                    opacity: .8,
                                    cursor: 'context-menu',
                                    border: 'none',
                                    userSelect: 'none',
                                    paddingRight: '3px'
                                }}
                            >
                                {
                                    !isRejected ? annotations?.filter(annotation => annotation.type === 'SIGNATURE' && annotation?.signingStatus === "NOT_SIGNED").length && image && mySignature ? (
                                        <img className="recipient-sign-img" src={mySignature} alt='Recipient_Signature' />
                                    ) :
                                        (
                                            <div className="annotation-text d-flex flex-column align-items-center">
                                                <p
                                                    className='StampPhotoSignIconsdoc justify-content-center gap-2'
                                                    style={{ width: '8rem', height: '2.7rem' }}
                                                >
                                                    <img src={signatureIcon} alt='' style={{ height: '1.5rem' }} />
                                                    <div className='' style={{ color: 'black', fontSize: "14px" }}>{t('SIGNATURE_LABEL')}</div>
                                                </p>
                                                <div className='recipient-email'>{Boolean(annotation.recipientEmail) ? annotation.recipientEmail : '< >'}</div>
                                            </div>
                                        ) : null
                                }
                            </div>
                        </Draggable>
                    );
                } else if (annotation?.type === 'IMAGE') {
                    return (
                        <Draggable disabled={true} defaultPosition={{ x: annotation.x, y: annotation.y }} bounds="parent" key={index}>
                            <div
                                className={`annotation_${index}`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    maxWidth: `${getAnnotationWidth()}rem`,
                                    backgroundColor: !isRejected ? isSignedPhoto ? 'transparent' : '#fff' : null,
                                    opacity: .8,
                                    cursor: 'context-menu',
                                    border: 'none',
                                }}
                            >
                                {
                                    !isRejected ? isSignedPhoto ? (
                                        <img className="recipient-stamp-img" src={PhotoSignedPlaces?.some(item => item?.annId === annotation?.annId) && AttachPhotoImage} alt='Recipient_Photo' />
                                    ) :
                                        (
                                            <div className="annotation-text d-flex flex-column align-items-center">
                                                <p className='StampPhotoSignIconsdoc flex-column'>
                                                    <img className='mt-1' src={photoIcon} alt='' style={{ height: '1.7rem' }} />
                                                    <div className='' style={{ color: 'black', fontSize: "14px" }}>{t('PHOTO_LABEL')}</div>
                                                </p>
                                                <div className='recipient-email'>{Boolean(annotation.recipientEmail) ? annotation.recipientEmail : '< >'}</div>
                                            </div>
                                        ) : null
                                }
                            </div>
                        </Draggable>
                    );
                } else if (annotation?.type === 'STAMP') {
                    return (
                        <Draggable disabled={true} defaultPosition={{ x: annotation.x, y: annotation.y }} bounds="parent" key={index}>
                            <div
                                className={`annotation_${index}`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    maxWidth: `${getAnnotationWidth()}rem`,
                                    backgroundColor: !isRejected ? isSigned ? 'transparent' : '#fff' : null,
                                    opacity: .8,
                                    cursor: 'context-menu',
                                    border: 'none',
                                    userSelect: 'none',
                                    paddingRight: '3px'
                                }}
                            >
                                {
                                    !isRejected ? isSignedStamp ? (
                                        <img className="recipient-stamp-img" src={StampSignedPlaces?.some(item => item?.annId === annotation?.annId) && AttachStampImage} alt='Recipient_Stamp' />
                                    ) :
                                        (
                                            <div className="annotation-text d-flex flex-column align-items-center">
                                                <p className='StampPhotoSignIconsdoc flex-column'>
                                                    <img className='mt-1' src={stampIcon} alt='' style={{ height: '1.7rem' }} />
                                                    <div className='' style={{ color: 'black', fontSize: "14px" }}>{t('STAMP_LABEL')}</div>
                                                </p>
                                                <div className="recipient-email">
                                                    {Boolean(annotation.recipientEmail) ? annotation.recipientEmail : '< >'}
                                                </div>
                                            </div>
                                        ) : null
                                }
                            </div>
                        </Draggable>
                    );
                } else if (annotation?.type === 'TEXTAREA') {
                    return (
                        <Draggable disabled={true} defaultPosition={{ x: annotation.x, y: annotation.y }} bounds="parent" key={index}>
                            <div
                                className={`annotation_${index} pdf-textarea`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    backgroundColor: !isRejected ? isSigned ? 'transparent' : '#fff' : null,
                                    opacity: .8,
                                    cursor: 'context-menu',
                                    border: 'none',
                                    userSelect: 'none',
                                    paddingRight: '3px'
                                }}
                            >
                                <textarea
                                    style={{
                                        height: `${annotation.height}px`,
                                        width: `${annotation.width}px`,
                                        resize: 'none',
                                        backgroundColor: "#80808042",
                                        border: "#0000ffa8 solid",
                                    }}
                                    placeholder={activeStep === 4 ? t('TEXTBOX_PLACEHOLDER') : ''}
                                    defaultValue={annotation.value}
                                    disabled={activeStep !== 4}
                                    onChange={(e) => handleTextFill(e, annotation)}
                                />
                            </div>
                        </Draggable>
                    )
                }
            }
            return null;
        });
    };

    const handleTextFill = (e, selectedAnnotation) => {
        const element = e.target;
        if (element.scrollHeight > 30 && element.clientHeight < element.scrollHeight) {
            element.value = textAnnotationsRef.current[selectedAnnotation.annId] || '';
        } else {
            const currentValue = element.value;
            textAnnotationsRef.current[selectedAnnotation.annId] = currentValue;
        }
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        refPages.current = new Array(nextNumPages).fill().map((_, index) => refPages.current[index] || createRef());
        setNumPages(nextNumPages);
    }

    const renderPlaceholder = (props) => {
        const length = 30;
        const lines = Array.from({ length }, (_, index) => index);
        return (
            <div className='row'>
                <div className='col-sm-12' style={{ height: '600px' }}>
                    <ContentLoader
                        speed={1}
                        viewBox="0 0 auto auto"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        style={{ width: '100%', height: '100%' }}
                        {...props}
                    >
                        <rect x="10%" y="5" rx="3" ry="3" width="80%" height="6" />
                        {
                            lines.map((item, index) => {
                                return (
                                    <rect x="0" y={(15 * (index + 1)) + (6 * (index + 1))} rx="3" ry="3" width="100%" height="6" />
                                )
                            })
                        }
                        <rect x="10%" y={(15 * (10 + 1)) + (6 * (10 + 1))} rx="3" ry="3" width="80%" height="6" />
                    </ContentLoader>
                </div>
            </div>
        )
    }

    const handleAnnotationClick = (e, index) => {
        if (isDragging || e?.target?.className === 'annotation-text' || e?.target?.className === 'recipient-email' || e?.target?.className !== 'react-pdf__Page__canvas') {
            return null;
        }
    };

    return (<>
        <Document
            inputRef={pdfDocumentRef}
            options={options}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode="canvas"
            file={url}
            renderAnnotationLayer={false}
            // className="document-sign"
            loading={renderPlaceholder}
        >
            {Array.from({ length: numPages }, (_, index) => (
                <Fragment key={short.generate().toString()}>
                    <div
                        key={short.generate().toString()}
                        style={{ position: 'relative' }}
                        className={`mx-auto w-fit page-${index}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAnnotationClick(e, index)
                        }}
                    >
                        <Page
                            inputRef={(pa) => (refPages.current[index] = pa)}
                            className="mt-5"
                            style={{ margin: 'auto' }}
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            loading={renderPlaceholder}
                            onRenderError={() => console.log('error occurred')}
                        >
                        </Page>
                        {renderAnnotations(index)}
                    </div>
                </Fragment>
            ))}
        </Document>
    </>)
}

export default DocumentPageRenderer;