/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import documentIcon from "./../../assets/document-icon.svg";
import usersIcon from "./../../assets/users-icon.svg";
import completedIcon from "./../../assets/completed-icon.svg";
import pendingIcon from "./../../assets/pending-icon.svg";
import Navbar from "./../../components/Navbar/Navbar";
import "./Dashboard.css";
import { navLinks, textChangeApp } from "../../utils/constants";
import DocumentServices from "../../services/document.services";
import { DocumentContext } from "../../store/document";
import { useEffect } from "react";
import { LoaderContext } from "../../store/Loader";
import FileUpload from "../../components/common/FileUpload";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import TawqiiaVactorEn from "../../assets/sign_in_vector_tawqiia_en.jpg"
import TawqiiaVactorAr from "../../assets/sign_in_vector_tawqiia_ar.jpg"
import DastkhatVactorEn from "../../assets/dastkat-vactor-img.png"
import { domainBasedImages, generateSignBanner } from "../../utils/helper";
import { Modal } from 'bootstrap';
import Loader from "../../components/ui/Loader";

const bootstrap = require("bootstrap");

const documentServices = new DocumentServices();
const Dashboard = () => {
  const modalRef = React.useRef();
  const [isLoading, setIsLoading] = useState(false);


  const hideModal = () => {
    const modalEle = modalRef.current;
    const bsModal = bootstrap.Modal.getInstance(modalEle);
    bsModal.hide();
    if (document.getElementsByClassName("modal-backdrop")[0]) {
      document.getElementsByClassName("modal-backdrop")[0].remove();
    }
    if (document.body.style.getPropertyValue("overflow") === "hidden") {
      document.body.style.removeProperty('overflow');
    }
  };
  const location = useLocation();
  const orgName = location.pathname.split("/")[1];
  const {
    getOrgApp,
    documentState,
    documentDispatch,
    getMyDocumentsByStatus,
    getSharedWithDocumentsByStatus,
  } = useContext(DocumentContext);
  const { loaderDispatch } = useContext(LoaderContext);
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");
  const { t: ts } = useTranslation("apiMessage");
  const {
    i18n: { language },
  } = useTranslation();
  const textChangeAppData = textChangeApp(documentState)

  const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [logoSrc, setLogoSrc] = useState('');
  const [activeDomain, setActiveDomain] = useState('');

  let hostname = process.env.REACT_APP_DOMAIN;
  useEffect(() => {
    const language = 'en';
    const src = domainBasedImages(hostname, language);
    setActiveDomain(src)
    setLogoSrc(src);
    setShouldDisplay(hostname === 'TAWQIA');
  }, [language]);


  async function getDocumentCount() {
    try {
      loaderDispatch({ type: "SET_LOADING", payload: true });
      const documentCount = await documentServices.getDocumentStatusCount();
      if (documentCount) {
        documentDispatch({
          type: "SET_DOCUMENT_COUNT",
          payload: documentCount?.countByStatus,
        });
      }
    } catch (error) {
    } finally {
      loaderDispatch({ type: "SET_LOADING", payload: false });
    }
  }

  useEffect(() => {
    getOrgApp();
    const getDocumentCountInital = async () => {
      await getDocumentCount();
      const documentCount = await documentServices.getDocumentStatusCount();
      //Open Document Info Modal
      if (documentCount?.countByStatus?.pending_by_me > 0) {
        const modalEle = modalRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: 'static',
          keyboard: false,
        });
        bsModal.show();
      }
    }
    getDocumentCountInital();
  }, []);

  const myDocument = {
    dashboard_status_counts: {
      default: `/${orgName}/dashboard`,
      sharedWithMe: `/${orgName}/documents/tab/shared-with-me`,
      myDocuments: `/${orgName}/documents`,
    },
  };

  const handleDocumentsCountStatus = async (status, isShared = false) => {
    setIsLoading(true);
    // loaderDispatch({ type: 'SET_LOADING', payload: true });
    documentDispatch({ type: 'SET_SELECTED_DASHBOARD_FILTER', payload: {status, isMyDocumentsFilter: !isShared} })
    let documentsGetter = isShared ? getSharedWithDocumentsByStatus : getMyDocumentsByStatus;
    let tabPath = myDocument.dashboard_status_counts.default;
    await getDocumentCount({ status });
    if (isShared) {
      await documentsGetter({ status });
      tabPath = myDocument.dashboard_status_counts.sharedWithMe;
    } else {
      await documentsGetter({ status });
      tabPath = myDocument.dashboard_status_counts.myDocuments;
    }
    navigate(tabPath);
    // loaderDispatch({ type: 'SET_LOADING', payload: false });
    setIsLoading(false);
  };

  return (
    <>
      <div className="dashboard-container">
        <Navbar pageName={navLinks.dashboard} />
        <FileUpload getDocumentCount={getDocumentCount} />
        <section className="dashboard-section">
          <div className="container d-flex flex-column flex-lg-row align-items-center justify-content-around">
            <img
              src={generateSignBanner(textChangeAppData, language)}
              alt="Sign here image"
              className="dashboard-image"
            />
            <div
              className="w-65 documents-counts-info-container d-flex flex-column align-items-center justify-content-center countsectionTour"
            >
              <div className=" flex-column flex-md-row flex-lg-row  justify-content-between w-70 individual-document-height gap-4">
                <div
                  onClick={() => {
                    handleDocumentsCountStatus('DRAFT');
                  }}
                  className="individual-document-counts-info my-2 px-4 py-2 mt-4 position-relative cursor-pointer"
                >
                  <span className="position-absolute top-0 start-100 translate-middle badge document-counts-badge">
                    <img src={documentIcon} alt="document icon" width={10} />
                  </span>
                  <div className="individual-document-type draft-content">{t("Draft")}</div>
                  <div
                    className="individual-document-count me-3"
                    style={{
                      color:
                        textChangeAppData
                    }}
                  >
                    {documentState?.documentCountObj?.draft}
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row flex-lg-row  justify-content-between w-70 individual-document-height gap-4 w-100">
                  <div
                    onClick={() => {
                      handleDocumentsCountStatus('AWAITING');
                    }}
                    className="individual-document-counts-info my-3 px-4 py-2 w-50 me-5 position-relative cursor-pointer"
                  >
                    <span className="position-absolute top-0 start-100 translate-middle badge document-counts-badge">
                      <img src={usersIcon} alt="document icon" width={18} />
                    </span>
                    <div className="individual-document-type">{t("awtd")}</div>
                    <div
                      className="individual-document-count me-3"
                      style={{
                        color:
                          textChangeAppData
                      }}
                    >
                      {documentState?.documentCountObj?.awaiting}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      handleDocumentsCountStatus('AWAITING', true);
                    }}
                    className="individual-document-counts-info my-3 px-4 py-2 w-50 position-relative cursor-pointer"
                  >
                    <span className="position-absolute top-0 start-100 translate-middle badge document-counts-badge">
                      <img src={pendingIcon} alt="document icon" width={18} />
                    </span>
                    <div className="individual-document-type">
                      {t("pndnsign")}
                    </div>
                    <div
                      className="individual-document-count me-3 "
                      style={{
                        color:
                          textChangeAppData
                      }}
                    >
                      {documentState?.documentCountObj?.pending_by_me}
                    </div>
                  </div>
                </div>

              </div>
              <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-between w-70 individual-document-height gap-4">
                <div
                  onClick={() => {
                    handleDocumentsCountStatus('COMPLETED');
                  }}
                  className="individual-document-counts-info my-3 px-4 py-2 w-50 me-5 position-relative cursor-pointer"
                >
                  <span className="position-absolute top-0 start-100 translate-middle badge document-counts-badge">
                    <img src={completedIcon} alt="document icon" width={18} />
                  </span>
                  <div className="individual-document-type">{t("Comp")}</div>
                  <div
                    className="individual-document-count me-3"
                    style={{
                      color:
                        textChangeAppData
                    }}
                  >
                    {documentState?.documentCountObj?.completed}
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleDocumentsCountStatus('COMPLETED', true);
                  }}
                  className="individual-document-counts-info my-3 px-4 py-2 w-50 position-relative cursor-pointer"
                >
                  <span className="position-absolute top-0 start-100 translate-middle badge document-counts-badge">
                    <img src={completedIcon} alt="document icon" width={18} />
                  </span>
                  <div className="individual-document-type">{t("CptByme")}</div>
                  <div
                    className="individual-document-count me-3 "
                    style={{
                      color:
                        textChangeAppData
                    }}
                  >
                    {documentState?.documentCountObj?.completed_by_me}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row flex-lg-row  justify-content-between w-70 individual-document-height gap-4">
                {/* <div
                  onClick={() => {
                    handleDocumentsCountStatus('COMPLETED', true);
                  }}
                  className="individual-document-counts-info my-3 px-4 py-2 w-50 position-relative cursor-pointer"
                >
                  <span className="position-absolute top-0 start-100 translate-middle badge document-counts-badge">
                    <img src={completedIcon} alt="document icon" width={18} />
                  </span>
                  <div className="individual-document-type">{t("CptByme")}</div>
                  <div
                    className="individual-document-count me-3 "
                    style={{
                      color:
                        textChangeAppData
                    }}
                  >
                    {documentState?.documentCountObj?.completed_by_me}
                  </div>
                </div> */}

                {/* Reject count start*/}
                <div
                  onClick={() => {
                    handleDocumentsCountStatus('REJECTED');
                  }}
                  className="individual-document-counts-info my-3 px-4 me-5 py-2 w-50 position-relative cursor-pointer"
                >
                  <span className="position-absolute top-0 start-100 translate-middle badge document-counts-badge">
                    {/* <img src={completedIcon} alt="document icon" width={18} /> */}
                    <i class="bi bi-x-octagon" style={{fontSize: '15px'}}></i>
                  </span>
                  <div className="individual-document-type">{t("Rejected")}</div>
                  <div
                    className="individual-document-count me-3 "
                    style={{
                      color:
                        textChangeAppData
                    }}
                  >
                    {documentState?.documentCountObj?.rejected
                    }
                  </div>
                </div>
                {/* Reject count end */}
                {/* Reject by me start */}
                <div
                  onClick={() => {
                    handleDocumentsCountStatus('REJECTED', true);
                  }}
                  className="individual-document-counts-info my-3 px-4 py-2 w-50 position-relative cursor-pointer"
                >
                  <span className="position-absolute top-0 start-100 translate-middle badge document-counts-badge">
                    {/* <img src={completedIcon} alt="document icon" width={18} /> */}
                    <i class="bi bi-x-octagon" style={{fontSize: '15px'}}></i>
                  </span>
                  <div className="individual-document-type">{t("RejectedByMe")}</div>
                  <div
                    className="individual-document-count me-3 "
                    style={{
                      color:
                        textChangeAppData
                    }}
                  >
                    {documentState?.documentCountObj?.rejected_by_me
                    }
                  </div>
                </div>
                {/* Reject by me end */}

              </div>
            </div>
          </div>
        </section>

        {/* Shared Documents modal */}
        <div
          className="modal"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          ref={modalRef} tabIndex="-1"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dashboard"
            data-bs-backdrop="static"
          >
            <div className="modal-content">
              <div className="modal-heading-dash py-3 rounded" style={{ backgroundColor: textChangeAppData }}>
                <h4
                  className="modal-titled shared-docs-title d-flex justify-content-center mt-2 text-white"
                  id="staticBackdropLabel"
                >
                  {t('Shareddocuments')}
                </h4>
              </div>
              <div className="mt-5">
                <label
                  className="mx-3 text-center text-color"
                >
                  {t('textMessage')}
                </label>
                <div
                  className="d-flex justify-content-center"
                >
                  <div className="modal-footer mt-5 mb-4">
                    <button
                      type="button"
                      className="btn btn-secondary close-btn"
                      data-bs-dismiss="modal"
                      onClick={hideModal}
                    >
                      {t('close')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary share-docs-btn"
                      style={{ backgroundColor: textChangeAppData ? textChangeAppData : defaultPrimaryColorValue }}
                      onClick={() => {
                        hideModal();
                        navigate(`/${orgName}/documents/tab/shared-with-me`);
                      }}
                    >
                      {t('goToSharedDocs')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    </>
  );
};

export default Dashboard;
