
import React, { useContext, useEffect, useState } from 'react'
import { subscriptionArabic, subscriptionEnglish, textChangeApp } from '../../../utils/constants'
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';

//subscription icons
import freeIcon from '../../../assets/free_icon_1.png'
import basicIcon from '../../../assets/basic_icon_1.png'
import enterpriseIcon from '../../../assets/enterprise_icon_1.png'
import premiumIcon from '../../../assets/premium_icon_1.png'

import freeIconActive from '../../../assets/free_icon-white.png'
import basicIconActive from '../../../assets/basic_icon-white.png'
import enterpriseIconActive from '../../../assets/enterprise_icon_white.png'
import premiumIconActive from '../../../assets/premium_icon_white.png'
import { DocumentContext } from '../../../store/document';
import { getActiveImageBackground } from '../../../utils/helper';

function SubscriptionPlan({ plan, handleGuestCheckout, handleUpgrade, isUpgrade, modalRef }) {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const orgName = location.pathname.split("/")[1];
  const { i18n } = useTranslation();
  const { t } = useTranslation('landingPage');
  const [hovered, setisHovered] = useState(false);
  const [currentIcon, setCurrentIcon] = useState(premiumIcon);

  const { documentState } = useContext(DocumentContext)
  const textColor = textChangeApp(documentState)

  const handlePlanSelect = () => {
    // let user = isUser();
    if (!orgName) {
      navigate('/register-company')
      return;
    }
    if (isUpgrade) {
      handleUpgrade(plan?.id);
    } else {
      handleGuestCheckout(plan?.id);
    }
  }

  useEffect(() => {
    switch (plan.name) {
      case 'Trial':
        setCurrentIcon(hovered ? freeIconActive : freeIcon);
        return;
      case 'Basic':
        setCurrentIcon(hovered ? basicIconActive : basicIcon);
        return;
      case 'Enterprise':
        setCurrentIcon(hovered ? enterpriseIconActive : enterpriseIcon);
        return;
      default:
        setCurrentIcon(hovered ? premiumIconActive : premiumIcon);
        return;
    }
  }, [plan, hovered])

  console.log(getActiveImageBackground(textColor))

  return (
    <>
      <div className='custom-col-md-6 col-lg-3 p-2' onMouseEnter={() => setisHovered(true)} onMouseLeave={() => setisHovered(false)}>
        <div className="princing-item mt-0" style={{ background: hovered ? textColor || "#1d607e" : '' }}>
          <div>
            <h4 className="basic-and-enterprise-plan plan-title" style={{ color: !hovered ? textColor : '' }}>
              <img src={currentIcon} alt='plan-icon' width={35} style={{ marginLeft: '-1.5rem', paddingRight: '0.4rem', marginBottom: '0.3rem', filter: !hovered ? getActiveImageBackground(textColor)?.filter : '' }} />
              {i18n.language === 'en' ? subscriptionEnglish[plan?.name] : i18n.language === 'ar' ? subscriptionArabic[plan?.name] : subscriptionEnglish[plan?.name]}

              {/* {plan?.metadata?.product_name} */}
            </h4>
          </div>
          <div className="pricing-divider">
            <h4 className="my-0 display-6 font-weight-normal mb-3 mt-4" style={{ color: !hovered ? textColor : '' }}>${plan?.amount}</h4>
          </div>
          <div className='plan-details mb-3 position-relative px-3' style={{ color: !hovered ? textColor : '' }}>
            <ul className="list-unstyled mb-4 position-relative">
              <li className='d-flex align-items-center justify-content-center  m-auto my-3'>
                <div className='text-align-left w-65'>
                  {/* <i className="bi bi-arrow-right-circle-fill me-2"></i>  */}
                  <span>{t('Duration')} </span>
                </div>
                <div className='flex-1 text-align-left'>1 {t(plan?.interval)}</div>
              </li>
              <li className='d-flex align-items-center justify-content-center  m-auto my-3'>
                <div className='text-align-left w-65'>
                  {/* <i className="bi bi-arrow-right-circle-fill me-2"></i>  */}
                  <span>{t('NoOfUsers')} </span>
                </div>
                <div className='flex-1 text-align-left'>{plan?.userCount || ''}</div>
              </li>
              <li className='d-flex align-items-center justify-content-center  m-auto my-3'>
                <div className='text-align-left w-65'>
                  {/* <i className="bi bi-arrow-right-circle-fill me-2"></i>  */}
                  <span>{t('Storage')}</span>
                </div>
                <div className='flex-1 text-align-left'>{plan?.storage || ''}</div>
              </li>
            </ul>
          </div>
          <button className="btn choose-plan-btn mb-4" style={{ backgroundColor: !hovered ? textColor : '' }} onClick={() => handlePlanSelect(plan?.id)}>
            <span style={{ color: hovered ? textColor : '' }}> {t('ChoosePlan')} - </span><span style={{ color: hovered ? textColor : '' }} className='mx-1'>&rarr;</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default SubscriptionPlan