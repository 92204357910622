import { useContext, useEffect } from "react";
import { Navigate, useParams } from 'react-router-dom';

import NotFound from "../pages/NotFound/NotFound";
import { AuthContext } from "../store/auth";

export default function RequireAdminAuth({ children }) {
  const {fetchUserProfile} = useContext(AuthContext)
  const { orgName } = useParams();
  
  useEffect(() => {
    window.onload = () => {
      fetchUserProfile();
    }
  }, []);
  
  const jwtToken = localStorage.getItem('jwtToken');
  if (!jwtToken && orgName) {
    return <Navigate to={`/${orgName}/login`} />
  }
  
  const tokenString = localStorage.getItem('roleData');
  if (!tokenString?.includes("Admin")) {
    return <NotFound/>
  }
  return children;
}
