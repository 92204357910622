import React, { createContext, useReducer } from 'react';

const initialState = {
  signers: [],
};

const reducer = (signerState, action) => {
  switch (action.type) {
    case 'SET_SIGNERS':
      return { ...signerState, signers: action.payload };
    case 'ADD_SIGNER':
      return { ...signerState, signers: [...signerState.signers, action.payload] };
    case 'DELETE_SIGNER':
      return {
        ...signerState,
        signers: signerState.signers.filter((doc) => doc.id !== action.payload),
      };
    case 'UPDATE_SIGNERS':
      return { ...signerState, signers: [...signerState.signers.map((doc) => {
        if (doc.id === action.payload.id) {
          return action.payload.signer
        }
        return doc
      })]}
    default:
      return signerState;
  }
};

export const SignerContext = createContext();

export const SignerProvider = ({ children }) => {
  const [signerState, signerDispatch] = useReducer(reducer, initialState);

  return (
    <SignerContext.Provider value={{ signerState, signerDispatch }}>
      {children}
    </SignerContext.Provider>
  );
};
