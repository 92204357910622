import React, { useContext, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./AdminPanel.css";
import { useState } from "react";
import { toast } from "react-toastify";
import { textChangeApp } from "../../utils/constants";
import { useTranslation } from 'react-i18next';

import { getActiveImageBackground } from "../../utils/helper";
import { DocumentContext } from "../../store/document";
import { useNavigate, useLocation } from 'react-router-dom';
import { LoaderContext } from "../../store/Loader";
import { AuthContext } from "../../store/auth";
import AuthServices from "../../services/auth.services";
import userIcon from "../../../src/assets/Icon feather-users.png";
import appearanceIcon from "../../../src/assets/Image 24.png";
import subscriptionIcon from "../../../src/assets/Image 23.png";
import ActiveDirectoryIcon from "../../../src/assets/ActiveDirectoryIcon.png";
import paymentHistoryIcon from "../../assets/Icon material-history.png"
import SubscriptionsTab from "./Subscriptions/Subscriptions";
import PaymentHistoryTab from "./PaymentHistory/PaymentHistory";
import Loader from "../../components/ui/Loader";
import Pagination from "../../components/common/Pagination";
import AdminDashboard from "./AdminDashboard/AdminDashboard";
import Users from "./Users/Users";
import adminDashboardControlIcon from '../../assets/admin_dashboard.png'
import Appearance from "./Appearance/Appearance";
import ActiveDirectory from "./ActiveDirectory/ActiveDirectory";
import { Tab, Tabs } from "react-bootstrap";

const ADMIN_DASHBOARD = "ADMIN_DASHBOARD";
const APPEARANCE = "APPEARANCE";
const USERS = "USERS";
const ACTIVE_DIRECTORY = "ACTIVE_DIRECTORY";
const PAYMENT_HISTORY = "PAYMENT_HISTORY";
const SUBSCRIPTIONS = "SUBSCRIPTIONS";

const AdminPanel = (props) => {
  const { tabIndex } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const orgName = location.pathname.split('/')[1];
  const tabName = location?.state?.tabName;
  const { documentState } = useContext(DocumentContext);
  const { authState, fetchUserProfile } = useContext(AuthContext);
  const [currentTab, setCurrentTab] = useState(APPEARANCE)
  const [adDetails, setADDetails] = useState({ url: '', searchBase: '' });
  const [fontStyle, setFontStyle] = useState(documentState?.orgAppData?.font ? documentState?.orgAppData?.font : '');
  const [language, setLanguage] = useState(documentState?.orgAppData?.language ? documentState?.orgAppData?.language : 'English');
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState(documentState?.orgAppData?.primary_color ? documentState?.orgAppData?.primary_color : '');
  const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
  const { loaderDispatch } = useContext(LoaderContext);

  useEffect(() => {
    if (tabIndex) {
      navigate(`/${orgName}/admin`, { state: { tabName: "subscription" } })
    }
  }, [tabIndex, navigate, orgName])

  useEffect(() => {
    if (tabName === "subscription") {
      window.history.replaceState({}, document.title, window.location.pathname);
      setCurrentTab(SUBSCRIPTIONS);
    }
  }, [tabName])


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (currentTab === ACTIVE_DIRECTORY) {
      getADDetails();
    }
  }, [currentTab])

  useEffect(() => {
    // getOrgApp();
    // get lastest user Profile data on Reload
    fetchUserProfile();
  }, [])

  const textChangeAppData = textChangeApp(documentState)

  const authServices = new AuthServices();

  const { t } = useTranslation('admin');
  const { t: ts } = useTranslation('apiMessage');

  const getADDetails = async () => {
    try {
      const { data } = await authServices.getADConfigDetails();
      if (data && data.id && data.url) {
        setADDetails({ ...data });
      } else {
        setADDetails({ url: '', searchBase: '' });
      }
    } catch (error) {
      toast.error(ts(error?.response?.data?.code || 'Failed_TO_FETCH_AD_DETAILS'))
    }
  }

  const storedLocalLanguage = localStorage.getItem('language');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, [storedLocalLanguage]);

  const handleCurrentTabChange = (tab) => {
    setCurrentTab(tab);
  }

  const getActiveModalTabIndex = () => {
    switch (currentTab) {
      case ADMIN_DASHBOARD:
            return 0;
        case APPEARANCE:
            return 1;
        case USERS:
            return 2;
        case ACTIVE_DIRECTORY:
            return 3;
        case SUBSCRIPTIONS:
            return 4;
        case PAYMENT_HISTORY:
            return 5;
        default:
            return 1;
    }
  }

    const getTabTitle = (currentTab) => {
        switch (currentTab) {
            case ADMIN_DASHBOARD:
                return (
                    <>
                        <img src={adminDashboardControlIcon} alt="Appearance Icon" className="appearance-icon me-1" />
                        <span className="py-1 tab fonts-adjust">{t('adminDashboard.heading')}</span>
                    </>
                );
            case APPEARANCE:
                return (
                    <>
                        <img src={appearanceIcon} alt="Appearance Icon" className="appearance-icon me-1" />
                        <span className="py-1 tab fonts-adjust">{t('appearanceTab')}</span>
                    </>
                );
            case USERS:
                return (
                    <>
                        <img src={userIcon} alt="User Icon" className="user-icon me-1" />
                        <span className="py-1 tab fonts-adjust">{t('usersTab')}</span>
                    </>
                );
            case ACTIVE_DIRECTORY:
                return (
                    <>
                        <img src={ActiveDirectoryIcon} alt="User Icon" className="user-icon me-1" />
                        <span className="py-1 tab fonts-adjust">{t('activeDirectory')}</span>
                    </>
                );
            case SUBSCRIPTIONS:
                return (
                    <>
                        <img src={subscriptionIcon} alt="Subscriptions Icon" className="subscriptions-icon me-1" />
                        <span className="py-1 tab fonts-adjust">{t('subscriptionsTab')}</span>
                    </>
                );
            case PAYMENT_HISTORY:
                return (
                    <>
                        <img src={paymentHistoryIcon} alt="payment history Icon" className="payment-history-icon me-1" />
                        <span className="py-1 tab fonts-adjust">{t('paymentHistoryTab.heading')}</span>
                    </>
                );
            default:
                return 1;
        }
    }

  return (
    <div>
        <Navbar activeModalTab = {getActiveModalTabIndex()}/>
        <Tabs
            defaultActiveKey={currentTab}
            activeKey={currentTab}
            id="admin-panel-container"
            className="admin-panel-tabs-container container adminPanel-line d-flex mt-5 mb-2"
            onSelect={(selectedTab) => handleCurrentTabChange(selectedTab)}
        >
            <Tab eventKey={APPEARANCE} title={getTabTitle(APPEARANCE)} className="appearanceTabTourClick">
                <Appearance/>
            </Tab>
            <Tab eventKey={USERS} title={getTabTitle(USERS)}>
                <Users/>
            </Tab>
            <Tab eventKey={ACTIVE_DIRECTORY} title={getTabTitle(ACTIVE_DIRECTORY)}>
                <ActiveDirectory
                    textChangeAppData={textChangeAppData}
                    defaultPrimaryColorValue={defaultPrimaryColorValue}
                    adDetails={adDetails}
                    setADDetails={setADDetails}
                    getADDetails={getADDetails}
                />
            </Tab>
            {/* <Tab eventKey={SUBSCRIPTIONS} title={getTabTitle(SUBSCRIPTIONS)}>
                <SubscriptionsTab fontStyle={fontStyle} textChangeAppData={textChangeAppData} selectedColor={selectedColor} />
            </Tab> */}
            {/* <Tab eventKey={PAYMENT_HISTORY} title={getTabTitle(PAYMENT_HISTORY)}>
                <PaymentHistoryTab fontStyle={fontStyle} textChangeAppData={textChangeAppData} selectedColor={selectedColor} />
            </Tab> */}
            <Tab eventKey={ADMIN_DASHBOARD} title={getTabTitle(ADMIN_DASHBOARD)}>
                <AdminDashboard textChangeAppData={textChangeAppData.toLowerCase()}/>
            </Tab>
        </Tabs>
        {isLoading && (
            <Loader />
        )}
    </div>
  )
};


export default AdminPanel;
