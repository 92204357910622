/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useParams, useLocation } from "react-router-dom";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Navbar from '../../components/Navbar/Navbar';
import DocumentServices from '../../services/document.services';
import uploadStampPhoto from '../../services/uploadStampSignPhotoservices';
import './DocumentSign.css';
import zoomInIcon from './../../assets/zoom-in.png';
import zoomOutIcon from './../../assets/zoom-out.png';
import { DocumentContext } from '../../store/document';
import { AuthContext } from '../../store/auth';
import AuthServices from '../../services/auth.services';
import { mapAnnotations, isUser, base64ToBinary, isAuthenticated } from '../../utils/helper';
import { navLinks, textChangeApp } from '../../utils/constants';
import { LoaderContext } from '../../store/Loader';
import SignerServices from '../../services/signer.services';
import RejectionModal from '../../components/common/RejectionModal';
import CameraModal from '../../components/common/CameraModal';
import { documentSignSteps } from '../../utils/constants'
import Stepper from '../../components/ui/Stepper';
import Loader from '../../components/ui/Loader';
import { Col, Row, Tab } from 'react-bootstrap';
import DocumentPageRenderer from './DocumentTabs/DocumentPageRenderer';
import SignatureTab from './DocumentTabs/SignatureTab';
import PhotoTab from './DocumentTabs/PhotoTab';
import StampTab from './DocumentTabs/StampTab';
import AddTextTab from './DocumentTabs/AddTextTab';
import DocumentFinalStep from './DocumentTabs/DocumentFinalStep';
import OTPModal from '../../Modals/OtpModal';
import SignatureCreationModal from '../../Modals/SignatureCreationModal';

const documentServices = new DocumentServices();
const authServices = new AuthServices();
const signerServices = new SignerServices();
const uploadStamporPhoto = new uploadStampPhoto();
let uploadingImageInterval;

const documentSignStepsOrder = [documentSignSteps.SIGNATURE, documentSignSteps.PHOTO, documentSignSteps.STAMP, documentSignSteps.TEXT, documentSignSteps.COMPLETED]

const DocumentSign = () => {
    const location = useLocation();
    const params = useParams();
    const orgName = location.pathname.split('/')[1];

    const fileRef = useRef(null);
    const stampfileRef = useRef(null);
    const sigCanvas = useRef({});
    const textAnnotationsRef = useRef({});


    const { t } = useTranslation('documentsUpdate');
    const { t: ts } = useTranslation('apiMessage');

    const { documentState, documentDispatch, fetchDocuments } = useContext(DocumentContext);
    const { authDispatch } = useContext(AuthContext)
    const { loaderDispatch } = useContext(LoaderContext);

    const user = isUser();

    const { selectedDocument } = documentState;
    const { title, url } = selectedDocument || {};

    const [showCreateSignatureModal, setShowCreateSignatureModal] = useState(false);
    const [showRejectionModal, setShowRejectionModal] = useState(false);
    const [showOTPModal, setShowOTPModal] = useState(true);
    const [showDocuments, setShowDocuments] = useState(false);
    const [isSigned, setIsSigned] = useState(false);
    const [isSignedPhoto, setIsSignedPhoto] = useState(false);
    const [isSignedStamp, setIsSignedStamp] = useState(false);
    const [PhotoSignedPlaces, setPhotoSignedPlaces] = useState([]);
    const [StampSignedPlaces, setStampSignedPlaces] = useState([]);
    const [annotations, setAnnotations] = useState([]);
    const [recipientPhoneNumber, setRecipientPhoneNumber] = useState('');
    const [hashData, setHashData] = useState('');
    const [mySignature, setMySignature] = useState(null);
    const [documentStatus, setDocumentStatus] = useState(false);
    const [documentOverdue, setDocumentOverdue] = useState(false);
    const [rejectedDocument, setRejectedDocument] = useState()
    const [isSelectedSignature, setIsSelectedSignature] = useState(false);
    const [isSavingSignature, setIsSavingSignature] = useState(false);
    const [otpCode, setOtpCode] = useState();
    const [image, setImage] = useState('');
    const [imageFile, setImageFile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [savePhotoImage, setsavePhotoImage] = useState()
    const [saveStampImage, setsaveStampImage] = useState()
    const [AttachPhotoImage, setAttachPhotoImage] = useState()
    const [AttachStampImage, setAttachStampImage] = useState()
    const [FinishdisableBtn, setFinishdisableBtn] = useState(true)
    const [activeStep, setactiveStep] = useState(1)
    const [signatureInputValue, setSignatureInputValue] = useState('')
    const [isCameraOpen, setIsCameraOpen] = useState(false);

    const textChangeAppData = textChangeApp(documentState)

    useEffect(() => {
        const availableAnnotations = annotations.map(ann => ann?.type);
        let currentDisableStatus = true
        if (documentStatus) {
            currentDisableStatus = false
        }

        if (availableAnnotations.includes('SIGNATURE')) {
            currentDisableStatus = imageFile ? false : true
        }

        if (availableAnnotations.includes('STAMP')) {
            currentDisableStatus = AttachStampImage ? false : true
        }

        if (availableAnnotations.includes('PHOTO')) {
            currentDisableStatus = AttachPhotoImage ? false : true
        }
        setFinishdisableBtn(currentDisableStatus)
    }, [documentStatus, annotations, imageFile, AttachStampImage, AttachPhotoImage])

    useEffect(() => {
        annotations.filter(annotation => annotation.type === documentSignSteps.TEXT).forEach(annotation => {
            if (!textAnnotationsRef.current[annotation.annId]) {
                textAnnotationsRef.current[annotation.annId] = annotation.value;
            }
        })
    }, [annotations])

    useEffect(() => {
        getSignature();
        getSigner();
        sendOTPAccessDocument();
        setShowOTPModal(true);
    }, [params?.document])

    useEffect(() => {
        const newResult = selectedDocument && selectedDocument?.recipients && selectedDocument?.recipients.filter(item => item.email === isUser()?.email)
        const annotationsList = mapAnnotations(newResult);
        setAnnotations(annotationsList);
    }, [selectedDocument])

    const handleShowCreateSignatureModal = () => {
        setShowCreateSignatureModal(true);
    }

    const validateOTP = async (otp) => {
        setOtpCode(otp);
        if (String(otp).length !== 4) {
            return toast.error(t('INVALID_OTP'))
        }
        const { status } = await getDocument(hashData, otp);
        if (status === 'success') {
            setShowOTPModal(false);
            getSignature();
            setShowDocuments(true)
        }
    };

    const getDocument = async (hash, otp) => {
        try {
            const document = await documentServices.getDocument(params?.document, { hash: hashData, otp });
            if (document) {
                documentDispatch({ type: 'SET_SELECTED_DOCUMENT', payload: document });
            }
            const user = isUser()
            const recipient = document?.recipients?.find(recipient => recipient.email === user.email);
            if (recipient.overallSigningStatus === 'COMPLETED') {
                setDocumentStatus(true)
            } else if (recipient.overallSigningStatus === 'PENDING' && new Date(recipient.dueDate) < new Date(new Date().setUTCHours(0, 0, 0, 0))) {
                setDocumentStatus(true);
                setDocumentOverdue(true);
            }
            return { status: 'success' }
        } catch (error) {
            toast.error(ts(`${error?.response?.data?.code}` || 'SOMETHING_WENT_WRONG'))
            return { status: 'fail' }
        }
    }

    const sendOTPAccessDocument = async () => {
        const { data: { hash } } = await documentServices.getDocumentOTP(params.recipientId)
        setHashData(hash);
    }

    const getSigner = async () => {
        try {
            const res = await signerServices.getSigner(params?.recipientId);
            setRecipientPhoneNumber(res?.phoneNumber)
        } catch (error) {
            console.log(error)
        }
    }

    const isRecipientRejected = (recipient) => (
        recipient?.filter(recipient => recipient?.email === user?.email)?.[0]?.isRejected
    )

    const isRejected = rejectedDocument?.recipients?.length > 0 || selectedDocument?.recipients?.length > 0
        ? isRecipientRejected(rejectedDocument?.recipients) || isRecipientRejected(selectedDocument?.recipients)
        : false;

    const onClickFileSignature = () => {
        const unsignedPlaces = annotations?.filter(annotation => annotation?.signingStatus === "NOT_SIGNED" && annotation?.type === "SIGNATURE");
        setIsSigned(unsignedPlaces && unsignedPlaces.length > 0);
    };

    const onClickAttachPhoto = async (dataUri) => {
        try {
            if (!dataUri) {
                return toast.error(ts('PHOTO_NOT_FOUND'));
            }
            const unsignedPlaces = annotations?.filter(annotation => annotation?.signingStatus === "NOT_SIGNED" && annotation?.type === 'IMAGE');
            setPhotoSignedPlaces(unsignedPlaces)
            setIsSignedPhoto(unsignedPlaces && unsignedPlaces.length > 0);
            const blob = await fetch(dataUri).then((res) => res.blob());
            const formData = new FormData();
            formData.append('file', blob, `photo_${new Date().getTime()}.jpg`);
            formData.append('type', 'IMAGE')
            const response = await uploadStamporPhoto.uploadPhotoImage(formData);
            if (response?.data) {
                toast.success(ts(response?.code || 'IMAGE_ADDED'))
                setAttachPhotoImage(response?.data)
                nextActiveStep({ hasValidData: response?.data });
            }
        } catch (error) {
            toast.error(ts(error?.response?.code || "SOMETHING_WENT_WRONG"))
        }
    };

    const zoomInClicked = () => {
        //todo
        console.log('zoomInClicked===>>',);
    };

    const zoomOutClicked = () => {
        // todo
        console.log('zoomOutClicked===>>',);
    };

    const onClickFinish = async (e) => {
        setIsLoading(true);
        let docSigned = !FinishdisableBtn;
        try {
            const textAnnotations = annotations
                .filter(annotation => annotation.type === documentSignSteps.TEXT)
                .map((annotation) => {
                    if (annotation.type === documentSignSteps.TEXT && textAnnotationsRef.current[annotation.annId]) {
                        return {
                            ...annotation,
                            fieldId: annotation.annId,
                            value: textAnnotationsRef.current[annotation.annId]
                        }
                    }
                    return {
                        ...annotation,
                        fieldId: annotation.annId,
                    };
                })
            const payload = {
                annotations: textAnnotations,
            }

            let response;
            if (textAnnotations.length) {
                response = await axios.put(
                    `/document-recipient/textarea-field`,
                    payload,
                    {
                        headers: {
                            organization: orgName,
                            Authorization: `Bearer ${isAuthenticated()}`
                        }
                    },
                );
            }

            if (response?.data?.message || textAnnotations.length === 0) {
                if (docSigned) {
                    try {
                        const payload = {
                            documentId: selectedDocument?.id,
                            fieldIDs: annotations.filter(annotation => annotation?.signingStatus === "NOT_SIGNED").map(item => ({ id: item?.annId, type: item?.type }))
                        }
                        const response = await documentServices.createSignedDocument(payload);
                        if (response.message) {
                            toast.success(ts(response?.code || response?.message));
                            try {
                                const response = await documentServices.getDocumentDetails(params?.document, { hash: hashData, otp: otpCode });
                                documentDispatch({ type: 'SET_SELECTED_DOCUMENT', payload: response });
                                fetchDocuments();
                            } catch (error) {
                                console.error('Error fetching documents:', error);
                                toast.error(ts(`${error?.response?.data?.code || 'SOMETHING_WENT_WRONG'}`));
                            }
                        }
                        setDocumentStatus(true)
                    } catch (error) {
                        toast.error(ts(`${error?.response?.data?.code || 'SOMETHING_WENT_WRONG'}`));
                    } finally {
                        setIsLoading(false);
                    }
                }
            }
        } catch (error) {
            toast.error(ts(`${error.response?.data?.code || 'SOMETHING_WENT_WRONG'}`));
        }

        setIsLoading(false);
    };

    const getSignature = async () => {
        const response = await authServices.getSignature();
        if (response?.data) {
            setImage(response?.data);
            setImageFile(response?.data);
            setMySignature(response?.data);
            const unsignedPlaces = annotations?.filter(annotation => annotation?.signingStatus === "NOT_SIGNED" && annotation?.type === "SIGNATURE");
            setIsSigned(unsignedPlaces?.length > 0);
            authDispatch({ type: 'SIGN_URL', payload: response?.data });
            onClickFileSignature();
        }
    }

    const saveSignature = async (signatureImage) => {
        if (isSelectedSignature) {
            if (signatureImage) {
                setIsSavingSignature(true);
                try {
                    loaderDispatch({ type: 'SET_LOADING', payload: true });
                    const formData = new FormData();
                    formData.append("type", "SIGNATURE");
                    formData.append("file", signatureImage);
                    const response = await authServices.updateSignature(formData);
                    getSignature();
                    toast.success(ts(response?.code || response?.message));
                    setIsSavingSignature(false);
                    nextActiveStep();
                } catch (error) {
                    toast.error(ts('SIGNATURE_UPLOAD_FAIL'));
                    setIsSavingSignature(false);
                } finally {
                    loaderDispatch({ type: 'SET_LOADING', payload: false });
                }
            } else {
                toast.warn(ts('FILE_NOT_SELECTED'));
            }
        } else {
            saveDrewOrTypedSignature(signatureImage);
        }
    }

    const saveDrewOrTypedSignature = async (signatureImage) => {
        if (imageFile) {
            setIsSavingSignature(true);
            try {
                loaderDispatch({ type: 'SET_LOADING', payload: true });
                const formData = new FormData();
                formData.append("type", "SIGNATURE");
                formData.append("file", signatureImage);
                const response = await authServices.updateSignature(formData);
                toast.success(ts(response?.code || response?.message));
                setIsSavingSignature(false);
                getSignature();
                loaderDispatch({ type: 'SET_LOADING', payload: false });
                nextActiveStep()
            } catch (error) {
                toast.error(ts('SIGNATURE_UPLOAD_FAIL'));
                setIsSavingSignature(false);
            } finally {
                loaderDispatch({ type: 'SET_LOADING', payload: false });
            }
        } else {
            toast.warn(ts('FILE_NOT_SELECTED'));
        }
    }

    const handleFileSelection = (e) => {
        setIsSelectedSignature(true);
        if (uploadingImageInterval) {
            clearTimeout(uploadingImageInterval);
        }
        uploadingImageInterval = setTimeout(() => {
            setImage(URL.createObjectURL(e.target.files[0]));
        }, 100);
        onClickFileSignature()
        setImageFile(e.target.files);
        saveSignature(e.target.files[0]);
    }

    const handleStampSelection = async (e) => {
        try {
            setsaveStampImage(URL.createObjectURL(e.target.files[0]))

            if (!e?.target?.files[0]) {
                return toast.error(ts('STAMP_NOT_FOUND'));
            }
            const unsignedPlaces = annotations?.filter(annotation => annotation?.signingStatus === "NOT_SIGNED" && annotation?.type === 'STAMP');
            setStampSignedPlaces(unsignedPlaces)
            setIsSignedStamp(unsignedPlaces && unsignedPlaces.length > 0);

            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            formData.append('type', 'STAMP')
            const response = await uploadStamporPhoto.uploadPhotoImage(formData);
            if (response?.data) {
                toast.success("Stamp Uploaded Successfully")
                setAttachStampImage(response?.data);
                nextActiveStep({ hasValidData: !!response?.data })
            }
        } catch (error) {
            console.error('Upload Stamp error ' + error)
        }
    }

    const saveDrewSignature = () => {
        const canvasImage = sigCanvas.current.toDataURL("image/png")
        setIsSelectedSignature(false);
        setMySignature(canvasImage);
        setImage(canvasImage);
        setImageFile(base64ToBinary(canvasImage));
        setShowCreateSignatureModal(false);
        onClickFileSignature();
        saveSignature(base64ToBinary(canvasImage));
    }

    const makeImage = (e) => {
        e.preventDefault();
        setIsSelectedSignature(false);
        const text = signatureInputValue;
        const imageData = updateImage(text);
        setMySignature(imageData);
        setImage(imageData);
        setImageFile(base64ToBinary(imageData));
        onClickFileSignature();
        setShowCreateSignatureModal(false);
        saveSignature(base64ToBinary(imageData));
    };

    const updateImage = (text) => {
        const canvas = document.createElement('canvas');
        const canvasText = canvas.getContext('2d');

        canvas.width = 400;
        canvas.height = 200;
        canvas.lineHeight = 200;
        canvasText.fillStyle = 'white';
        canvasText.fillRect(0, 0, canvas.width, canvas.height);

        const gradient = canvasText.createLinearGradient(0, 0, 200, 200);
        gradient.addColorStop(0, 'black');
        canvasText.fillStyle = gradient;

        const fontface = 'Urbanist, Medium, Lato, Roboto, Helvetica, sans-serif';
        canvasText.font = '30px Urbanist, Medium, Lato, Roboto, Helvetica, sans-serif';
        canvasText.textAlign = 'center';

        let fontsize = 100;
        do {
            fontsize--;
            canvasText.font = `${fontsize}px ${fontface}`;
        } while (canvasText.measureText(text).width > canvas.width);

        canvasText.fillText(text, canvas.width / 2, canvas.height / 2);

        const imageData = canvas.toDataURL();
        return imageData;
    };

    const handleRejectedDocument = async (document) => {
        setRejectedDocument(document);
        try {
            const response = await documentServices.getDocumentDetails(params?.document, { hash: hashData, otp: otpCode });
            documentDispatch({ type: 'SET_SELECTED_DOCUMENT', payload: response });
            fetchDocuments();
        } catch (error) {
            console.error('Error fetching documents:', error);
            toast.error(ts(`${error?.response?.data?.code || 'SOMETHING_WENT_WRONG'}`));
        }
    }

    const openCamera = async () => {
        try {
            setIsCameraOpen((prev) => !prev);
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };
    
    const handleTakePhoto = async (dataUri) => {
        setsavePhotoImage(dataUri);
        setIsCameraOpen(false);
        onClickAttachPhoto(dataUri)
    };

    const handleCameraError = (error) => {
        console.error('Camera error:', error);
    };

    const syncTextAnnotationsWithAnnotations = () => {
        const updatedAnnotations = annotations.map((annotation) => {
            if (annotation.type === documentSignSteps.TEXT && textAnnotationsRef.current[annotation.annId]) {
                return {
                    ...annotation,
                    value: textAnnotationsRef.current[annotation.annId]
                }
            }
            return annotation;
        })
        setAnnotations(updatedAnnotations);
    }

    const getNextStepInTargetDirection = (validSteps, direction) => {
        if (direction === 1) {
            for (let i = activeStep; i < 5; i += 1) {
                if (validSteps[i]) {
                    return i + 1;
                }
            }
        } else {
            for (let i = activeStep - 2; i >= 0; i -= 1) {
                if (validSteps[i]) {
                    return i + 1;
                }
            }
        }
    }

    const getNextStep = () => {
        const annotationTypes = annotations.map(annotation => annotation.type);
        const stepsValidity = documentSignStepsOrder.map(step => annotationTypes.includes(step) || step === documentSignSteps.COMPLETED);
        return getNextStepInTargetDirection(stepsValidity, 1);
    }

    const nextActiveStep = (validationData) => {
        if (activeStep === 1) {
            const unsignedPlaces = annotations?.filter(annotation => annotation?.signingStatus === "NOT_SIGNED" && annotation?.type === "SIGNATURE");
            if (unsignedPlaces.length) {
                // making it go forward based on annotations
                const nextStep = getNextStep();
                setactiveStep(nextStep);
            } else {
                return toast.error('Sign the document')

            }
        } else if (activeStep === 2) {
            if (validationData?.hasValidData || PhotoSignedPlaces.length) {
                // making it go forward based on annotations
                const nextStep = getNextStep();
                setactiveStep(nextStep);
            } else {
                return toast.error('Upload a Photo');
            }
        } else if (activeStep === 3) {
            if (validationData?.hasValidData || (saveStampImage && AttachStampImage)) {
                const nextStep = getNextStep();
                setactiveStep(nextStep);
                return
            } else {
                return toast.error('Upload a Stamp');
            }
        } else if (activeStep === 4) {
            if (validateTextFields()) {
                const nextStep = getNextStep();
                setactiveStep(nextStep);
                syncTextAnnotationsWithAnnotations();
            } else {
                toast.error('Please enter text into all text box fields to go to next step');
            }
        }
    }

    const previousActiveStep = () => {
        const annotationTypes = annotations.map(annotation => annotation.type);
        const stepsValidity = documentSignStepsOrder.map(step => annotationTypes.includes(step) || step === documentSignSteps.COMPLETED);
        const previousStep = getNextStepInTargetDirection(stepsValidity, -1);
        setactiveStep(previousStep);
    }

    const validateTextFields = () => {
        const currentTextFields = textAnnotationsRef.current;
        return Object.values(currentTextFields).every(textFieldValue => textFieldValue !== null && textFieldValue.trim() !== "");
    }

    return (
        <div>
            <Navbar pageName={navLinks.documents} />
            {/* Document Preview and Signing Page */}
            <Row>
                <Col xl={9}>
                    <div className='document-container'>
                        {!['REJECTED', 'COMPLETED'].includes(selectedDocument?.my_status) &&
                            <>
                                <Stepper
                                    annotations={annotations}
                                    activeStep={activeStep}
                                />
                                <div className='document-sign-previous' style={{ display: activeStep === 1 && 'none' }}>
                                    <button onClick={previousActiveStep} className='nextPreviousbtn'>{t('previous')}</button>
                                </div>
                                <div className='document-sign-next' style={{ display: ((activeStep === 1 && !mySignature) || (activeStep === 2 && !AttachPhotoImage) || (activeStep === 3 && !AttachStampImage) || activeStep === 5) && 'none' }}>
                                    <button className='nextPreviousbtn' disabled={activeStep === 5 && true} onClick={nextActiveStep}>{t('next')}</button>
                                </div>
                            </>}
                        {showDocuments && <div className="section overflow-scroll">
                            {/* Zoom Buttons */}
                            <div className='w-100 d-flex justify-content-center'>
                                <div className='zoominout-container mt-4'>
                                    <span className='recipients recipient-text cursor-pointer' onClick={zoomInClicked}>
                                        <div className='circle'> <img src={zoomInIcon} alt='zoom-in icon' className='me-2' width={11} height={12} /></div>
                                        {t('zIn')}
                                    </span>
                                    <span className='recipients recipient-text cursor-pointer' onClick={zoomOutClicked}>
                                        <div className='circle'><img src={zoomOutIcon} alt='zoom-out icon' className='me-2' width={11} height={12} /></div>
                                        {t('zOt')}
                                    </span>
                                </div>
                            </div>
                            {/* Document */}
                            <DocumentPageRenderer
                                AttachPhotoImage={AttachPhotoImage}
                                isSignedStamp={isSignedStamp}
                                PhotoSignedPlaces={PhotoSignedPlaces}
                                isSignedPhoto={isSignedPhoto}
                                textAnnotationsRef={textAnnotationsRef}
                                annotations={annotations}
                                url={url}
                                isSigned={isSigned}
                                image={image}
                                mySignature={mySignature}
                                isRejected={isRejected}
                                StampSignedPlaces={StampSignedPlaces}
                                AttachStampImage={AttachStampImage}
                                activeStep={activeStep} />
                        </div>}
                    </div>
                </Col>
                <Col xl={3} className='d-flex flex-column' style={{ borderLeft: '1px solid grey', borderTop: '1px solid grey', backgroundColor: '#F8F8F8' }}>
                    {/* Document Details Tab */}
                    <div style={{ borderBottom: '1px solid black', paddingBottom: '0.8rem', paddingTop: '1.5rem' }}>
                        <div className="section">
                            <p className='font-weight-bold m-0' style={{ color: textChangeAppData, fontWeight: 600 }}>{t('flName')}</p>
                            <div className='align-items-center'>
                                <i style={{ paddingRight: '7px', color: 'red' }} className="bi bi-file-earmark-pdf"></i>
                                <span className='pdf-name pl-3' style={{ color: textChangeAppData }}>{title}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid black', marginBottom: '1.5rem' }}>
                        <div className="section m-0">
                            <p className='font-weight-bold m-0' style={{ color: textChangeAppData, fontWeight: 600 }}>{t('senderDetails')}:</p>
                            <div>
                                {selectedDocument?.owner?.name && <>
                                    <label>{t('name')}: </label>
                                    <span className='font-weight-bold' style={{ color: textChangeAppData }}>{' '}{selectedDocument?.owner?.name}</span><br />
                                </>}
                                {selectedDocument?.owner?.email && <>
                                    <label>{t('email')}: </label>
                                    <span className='font-weight-bold  pl-1' style={{ color: textChangeAppData }}>{' '}{selectedDocument?.owner?.email}</span><br />
                                </>}
                                {selectedDocument?.owner?.orgnizationName && <>
                                    <label>{t('organization')}: </label>
                                    <span className='font-weight-bold  pl-1' style={{ color: textChangeAppData }}>{' '}{selectedDocument?.owner?.orgnizationName}</span><br />
                                </>}
                            </div>
                        </div>
                    </div>
                    {/* Upload Modals */}
                    {!['REJECTED', 'COMPLETED'].includes(selectedDocument?.my_status) && !documentOverdue && <div>
                        <Tab.Container defaultActiveKey={activeStep} activeKey={activeStep}>
                            <Tab.Content>
                                <Tab.Pane eventKey={1}>
                                    <SignatureTab
                                        image={image}
                                        mySignature={mySignature}
                                        isSavedSignature={isSavingSignature}
                                        handleFileSelection={handleFileSelection}
                                        handleShowCreateSignatureModal={handleShowCreateSignatureModal}
                                        fileRef={fileRef} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={2}>
                                    <PhotoTab openCamera={openCamera} savePhotoImage={savePhotoImage} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={3}>
                                    <StampTab
                                        isSavingSignature={isSavingSignature}
                                        stampfileRef={stampfileRef}
                                        saveStampImage={saveStampImage}
                                        handleStampSelection={handleStampSelection} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={4}>
                                    <AddTextTab />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>}
                    {/* Finish or Reject Buttons */}
                    <DocumentFinalStep
                        textChangeAppData={textChangeAppData}
                        documentOverdue={documentOverdue}
                        documentStatus={documentStatus}
                        onClickFinish={onClickFinish}
                        setShowRejectionModal={setShowRejectionModal}
                        activeStep={activeStep}
                        rejectedDocument={rejectedDocument}
                        selectedDocument={selectedDocument}
                        documentSignedStatues={selectedDocument?.my_status}
                        FinishdisableBtn={FinishdisableBtn}
                    />
                </Col>
            </Row>
            {/* Modals */}
            {/* Reason for rejection */}
            {showRejectionModal && <RejectionModal
                // guestDocument={guestDocument}
                selectedDocument={selectedDocument}
                onReject={handleRejectedDocument}
                setShowRejectionModal={setShowRejectionModal} />}
            {/* Camera open  */}
            {isCameraOpen && <CameraModal
                openCamera={openCamera}
                handleTakePhoto={handleTakePhoto}
                handleCameraError={handleCameraError} />}
            {/* Signature Modal */}
            {showCreateSignatureModal && <SignatureCreationModal
                textChangeAppData={textChangeAppData}
                sigCanvas={sigCanvas}
                setSignatureInputValue={setSignatureInputValue}
                saveDrewSignature={saveDrewSignature}
                signatureInputValue={signatureInputValue}
                setShowCreateSignatureModal={setShowCreateSignatureModal}
                makeImage={makeImage} />}
            {/* OTP Modal */}
            {showOTPModal && <OTPModal
                textChangeAppData={textChangeAppData}
                validateOTP={validateOTP}
                phoneNumber={recipientPhoneNumber} />}
            {/* Loader */}
            {isLoading && <Loader />}
        </div >
    );
}

export default DocumentSign;
