import React, { useContext, useState, useEffect } from "react";
import { textChangeApp } from "../../../utils/constants";
import { useTranslation } from 'react-i18next';
import { DocumentContext } from "../../../store/document";
import { LoaderContext } from "../../../store/Loader";
import { toast } from "react-toastify";
import { isUser } from "../../../utils/helper";
import { Modal as bootstrapModal } from "bootstrap";
import { Button, Col, Row } from "react-bootstrap";

import OrgServices from "../../../services/org.services";
import Pagination from "../../../components/common/Pagination";
import Loader from "../../../components/ui/Loader";
import AddUserModal from "../Modals/AddUserModal";

const Users = () => {
    const { documentState } = useContext(DocumentContext);
    const textChangeAppData = textChangeApp(documentState);
    const { loaderDispatch } = useContext(LoaderContext);

    const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");

    const orgServices = new OrgServices();

    const modalRef = React.useRef();

    const { t } = useTranslation('admin');
    const { t: ts } = useTranslation('apiMessage');

    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalUserCount, setTotalUserCount] = useState(10);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedUserInfo, setSelectedUserInfo] = useState(null);

    useEffect(() => {
        getUsers();
    }, [])


    const showModal = (user) => {
        setSelectedUserInfo(user);
        const modalEle = modalRef.current;
        const bsModal = new bootstrapModal(modalEle);
        bsModal.show();
    };

    const hideModal = () => {
        setSelectedUserInfo(null);
        const modalEle = modalRef.current;
        const bsModal = bootstrapModal.getInstance(modalEle);
        bsModal.hide();
    };

    const deleteEmail = async (index, email, isAdmin) => {
        if (isAdmin) {
            toast.warn(t("Cannot_delete_admin"));
        } else {
            try {
                loaderDispatch({ type: 'SET_LOADING', payload: true });
                setIsLoading(true);
                await orgServices.deleteAdmin({
                    email: email
                });
                loaderDispatch({ type: 'SET_LOADING', payload: false });
                setIsLoading(false);
                await getUsers()
                toast.success(ts('adminDeleted'));
            } catch (error) {
                loaderDispatch({ type: 'SET_LOADING', payload: false });
                setIsLoading(false);
                console.log({ error });
            }
        }
    }

    async function getUsers(pageNumber, pageSize) {
        try {
            const userData = await orgServices.getusers(pageNumber, pageSize);
            if (userData.data) {
                setUsers(userData.data);
                setCurrentPage(userData.page);
                setTotalUserCount(userData.totalCount)
            }
        } catch (error) {
            toast(ts('errorInFetchingUser'))
        }
    }

    const makeUserAdminHandler = async (id, email, user) => {
        const roles = user?.roles || [];
        const isAdmin = roles.find(role => role?.name === 'Admin');
        try {
            loaderDispatch({ type: 'SET_LOADING', payload: true });
            setIsLoading(true);
            if (isAdmin) {
                const res = await orgServices.changeAdminRoleToUser({
                    email: email
                });
                if (res.status === 'success') {
                    toast.success(ts('adminRoleChangedToUser'));
                    loaderDispatch({ type: 'SET_LOADING', payload: false });
                    setIsLoading(false);
                    getUsers();
                }
            } else {
                const res = await orgServices.makeAdmin({
                    email: email
                });
                if (res.status === 'success') {
                    toast.success(ts('adminAdded'));
                    loaderDispatch({ type: 'SET_LOADING', payload: false });
                    setIsLoading(false);
                    getUsers();
                }
            }
        } catch (error) {
            loaderDispatch({ type: 'SET_LOADING', payload: false });
            setIsLoading(false);
            console.log({ error });
        }
    }

    const onPageChange = async (page) => {
        try {
            setIsLoading(true);
            const userData = await orgServices.getusers(page);
            if (userData.data) {
                setUsers(userData.data);
                setCurrentPage(userData.page);
                setTotalUserCount(userData.totalCount)
            }
        } catch (error) {
            toast(ts('errorInFetchingUser'))
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <div>
                <div className="rounded-3 p-3 container table-navbar mt-3" style={{
                    backgroundColor: textChangeAppData
                }}>
                    {/* <!-- User Modal start --> */}
                    <Row>
                        <Col className="d-flex justify-content-end custom-col-md-6 text-end header-nav" >
                            <Button
                                style={{backgroundColor: textChangeAppData}}
                                onClick={() => setShowAddUserModal(true)}
                                className="rounded-3 me-3 border border-2"
                            >
                                <span className="p-1 adduserTour">{t('createUserBtn')}</span>
                            </Button>
                        </Col>
                    </Row>
                    {showAddUserModal && (
                        <AddUserModal
                            textChangeAppData={textChangeAppData}
                            setIsLoading={setIsLoading}
                            setShowAddUserModal={setShowAddUserModal}
                            setCurrentPage={setCurrentPage}
                            getUsers={getUsers}
                        />
                    )}
                    {/* <!-- User Modal End --> */}
                </div>

                {windowWidth > 500 ? <div class="rounded-2 table-responsive container px-0">
                    <table class=" w-100 table table-bordered table-hover">
                        <thead class="thead-dark">
                            <tr class="">
                                <th scope="col">{t('email')}</th>
                                <th scope="col">{t('action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((user) => {
                                const { roles } = user;
                                const currentUser = isUser()?.email === user?.email;
                                const isAdmin = roles.some(role => role.name === 'Admin');
                                return (
                                    <tr class="" key={user?.id}>
                                        <td> {user?.email}</td>
                                        <td className="d-flex align-items-center justify-content-center deleteAndMakeUserAdminTour">
                                            {currentUser ? (
                                                <i className="bi bi-trash-fill me-2" style={{ color: "#808080 !important" }}></i>
                                            ) : (
                                                <i onClick={() => showModal({ id: user?.id, email: user?.email, isAdmin: isAdmin })}
                                                    className="bi bi-trash-fill text-danger cursor-pointer me-2"
                                                    width={13} height={15}></i>
                                            )}
                                            <button style={{ background: currentUser ? "gray" : textChangeAppData, color: "white", border: "none" }}
                                                 value={isAdmin} onClick={() => !currentUser && makeUserAdminHandler(user?.id, user?.email, user)}
                                                className="rounded-3"> <span className="admin-btn-responsive-bg">
                                                    {!isAdmin ? t('makeAdminBtn') : t('makeUserBtn')} </span> </button></td>
                                    </tr>
                                )
                            }
                            )}
                        </tbody>
                    </table>
                </div> : <div class="rounded-2 table-bordered table-responsive">
                    <table class=" w-100 table  table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="">{t('email')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((user) => {
                                const { roles } = user;
                                const isAdmin = roles.some(role => role.name === 'Admin');
                                const currentUser = isUser()?.email === user?.email;
                                return (
                                    <tr class="" key={user?.id}>
                                        <td> {user?.email}</td>
                                        <td className="d-flex align-items-center justify-content-center deleteAndMakeUserAdminTour">
                                            {currentUser ? (
                                                <i className="bi bi-trash-fill me-2" style={{ color: "#808080 !important" }}></i>
                                            ) : (
                                                <i onClick={() => showModal({ id: user?.id, email: user?.email, isAdmin: isAdmin })}
                                                    className="bi bi-trash-fill text-danger cursor-pointer me-2"
                                                    width={13} height={15}></i>
                                            )}
                                            <button style={{ background: currentUser ? "gray" : textChangeAppData, color: "white", border: "none" }}
                                                 value={isAdmin} onClick={() => !currentUser && makeUserAdminHandler(user?.id, user?.email, user)}
                                                className="rounded-3"> <span className="admin-btn-responsive-bg">
                                                    {!isAdmin ? t('makeAdminBtn') : t('makeUserBtn')} </span> </button></td>
                                    </tr>
                                )
                            }
                            )}
                        </tbody>
                    </table>
                </div>}
                <div className="p-5">
                    <Pagination total={totalUserCount} onPageChange={onPageChange} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
            </div>
            <div
                className="modal"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                ref={modalRef} tabIndex="-1"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-dashboard"
                    data-bs-backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-heading-dash py-3 rounded-2" style={{ backgroundColor: textChangeAppData }}>
                            <h4
                                className="modal-titled d-flex justify-content-center mt-1 text-white"
                                id="staticBackdropLabel"
                            >
                                {t('deleteUser')}
                            </h4>
                        </div>
                        <div className="mt-5">
                            <label
                                className="mx-3 text-center"
                            >
                                {t('deleteDescription')}
                            </label>
                            <div
                                className="d-flex justify-content-center"
                            >
                                <div className="modal-footer mt-5 w-75 justify-content-center">
                                    <button
                                        type="button"
                                        style={{ backgroundColor: textChangeAppData ? textChangeAppData : defaultPrimaryColorValue }}
                                        className="btn btn-primary w-25 cancel-btn"
                                        data-bs-dismiss="modal"
                                        onClick={hideModal}
                                    >
                                        {t('cancelBtn')}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary w-25 delete-btn"
                                        style={{ backgroundColor: textChangeAppData ? textChangeAppData : defaultPrimaryColorValue }}
                                        onClick={() => {
                                            hideModal();
                                            deleteEmail(selectedUserInfo?.id, selectedUserInfo?.email, selectedUserInfo?.isAdmin);
                                        }}
                                    >
                                        {t('deleteBtn')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && (
                <Loader />
            )}
        </div>
    )
}

export default Users