import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./store/auth";
import { DocumentProvider } from "./store/document";
import { SignerProvider } from "./store/signer";
import { LoaderProvider } from "./store/Loader";
import { I18nextProvider } from 'react-i18next';
import { SubscriptionProvider } from './store/subscription';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CompanyRegistration from "./pages/CompanyRegistration/CompanyRegistration";


i18n.use(LanguageDetector).init({
  resources: {
    en: {
      login: require('./locales/en/login'),
      dashboard: require('./locales/en/dashboard'),
      templates: require('./locales/en/templates.json'),
      documents: require('./locales/en/documents.json'),
      documentsAdd: require('./locales/en/documentsAdd.json'),
      documentsUpdate: require('./locales/en/documentsUpdate.json'),
      forgot_password: require('./locales/en/forgot_password.json'),
      register: require('./locales/en/register.json'),
      settings: require('./locales/en/settings.json'),
      apiMessage: require('./locales/en/apiMessages.json'),
      resetPassword : require('./locales/en/ResetPassword.json') ,
      pagination : require('./locales/en/pagination.json') ,
      admin: require('./locales/en/admin.json') ,
      landingPage: require('./locales/en/landingPage.json'),
      registrationCompany: require('./locales/en/registrationCompany.json')

      // Import other component-specific translations for English here
    },
    ar: {
      login: require('./locales/ar/login'),
      dashboard: require('./locales/ar/dashboard'),
      templates: require('./locales/ar/templates.json'),
      documents: require('./locales/ar/documents.json'),
      documentsAdd: require('./locales/ar/documentsAdd.json'),
      documentsUpdate: require('./locales/ar/documentsUpdate.json'),
      forgot_password: require('./locales/ar/forgot_password.json'),
      register: require('./locales/ar/register.json'),
      settings: require('./locales/ar/settings.json'),
      apiMessage: require('./locales/ar/apiMessages.json'),
      resetPassword : require('./locales/ar/ResetPassword.json'),
      pagination : require('./locales/ar/pagination.json') ,
      admin : require('./locales/ar/admin.json') ,
      landingPage: require('./locales/ar/landingPage.json'),
      registrationCompany: require('./locales/ar/registrationCompany.json')

      // Import other component-specific translations for Arabic here
    },
  },
  fallbackLng: 'ar',
  interpolation: {
    escapeValue: false,
  },
});
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LoaderProvider>
        <AuthProvider>
          <DocumentProvider>
            <SignerProvider>
              <SubscriptionProvider>
                <I18nextProvider i18n={i18n}>
                 <Elements stripe={stripePromise}>
                   <App />
                 </Elements>
               </I18nextProvider>
             </SubscriptionProvider>
            </SignerProvider>
          </DocumentProvider>
        </AuthProvider>
      </LoaderProvider>
    </BrowserRouter>
  </React.StrictMode>
);
