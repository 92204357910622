// import ReactPaginate from 'react-paginate';
import React, { useState, useEffect, useContext } from 'react'
import './Pagination.css';
import { useTranslation } from 'react-i18next';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { textChangeApp } from '../../utils/constants';
import { DocumentContext } from '../../store/document';

const Pagination = ({ total, onPageChange, setCurrentPage, currentPage }) => {
  // const [currentPage, setCurrentPage] = useState(1);
  const data = Array.from({ length: total });
  const { t } = useTranslation('pagination');
  const { documentState } = useContext(DocumentContext);
  const textChangeAppData = textChangeApp(documentState)

  useEffect(() => {
    // Check if the element exists
    const activeButton = document.querySelector('.active-pagination-button');
    
    if (activeButton && activeButton?.children.length) {
      // Apply styles to the active pagination button
      activeButton.children[0].style.color = 'white';
      activeButton.children[0].style.background = textChangeAppData;
    }
  }, [total, onPageChange, setCurrentPage, currentPage]);

  // Define the number of items per page
  const itemsPerPage = 10;
  const pageCount = Math.ceil(data.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
    onPageChange(selectedPage)
  };

  // Calculate the start and end index of the current page
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's data
  const currentData = data.slice(startIndex, endIndex);

  const isNextButtonDisabled = currentPage === pageCount - 1;
  const isPrevButtonDisabled = currentPage === 0;

  return (
    <div>
      {/* Display the data for the current page */}
      {/* {currentData.map((item) => (
        <div key={item}>{item}</div>
      ))} */}
      {/*Pagination start */}
      {/* Render the pagination component */}

     <ResponsivePagination
        maxWidth={10}
        current={currentPage}
        total={pageCount}
        onPageChange={handlePageChange}
        previousLabel={t('PREV')}
        nextLabel={t('NEXT')}
        activeItemClassName={'active-pagination-button'}
      />
      {/*Pagination End */}
    </div>
  );
};

export default Pagination;
