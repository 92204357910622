import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { textChangeApp } from '../../utils/constants';
import debounce from '../../utils/debounce';
import DocumentTemplateServices from '../../services/documentTemplateServices';
import DeleteConfirmationModal from '../../Modals/DeleteConfirmationModal'

const documentTemplateServices = new DocumentTemplateServices(); 

const TemplateCategories = (props) => {
    const { documentState, currentTemplateCategories, handleCloseModal, selectedCategory, setSelectedCategory } = props;
    const [newCategory, setNewCategory] = useState('');
    const [templateCategories, setTemplateCategories] = useState([]);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [deleteCategory, setDeleteCategory] = useState();

    const { t } = useTranslation('templates');

    const handleAddCategory = async () => {
        if (newCategory.trim() !== '') {
            const response = await documentTemplateServices.addCategory(newCategory);
            const { data } = response;
            if (data.code === "TEMPLATE_CATEGORY_CREATED_SUCCESSFULLY") {
                const { data: categoryData } = data;
                const { id, name } = categoryData;
                setTemplateCategories([ ...templateCategories, { id, name, currentName: name }]);
                setNewCategory('');
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        }
    };

    useEffect(() => {
        const templateCategoryOptions = currentTemplateCategories.map(templateCategory => ({ ...templateCategory, currentName: templateCategory.name, edit: false }));
        setTemplateCategories(templateCategoryOptions)
    }, [currentTemplateCategories])

    const updateTemplateCategory = debounce((e, editCategory) => {
        const currentValue = e.target.value;
        const newTemplateCategories = templateCategories.map(templateCategory => templateCategory?.id === editCategory?.id ? ({ ...templateCategory, currentName: currentValue }) : templateCategory)
        setTemplateCategories(newTemplateCategories);
    }, 1500)

    const cancelCategoryUpdate = (editCategory) => {
        const newTemplateCategories = templateCategories.map(templateCategory => templateCategory?.id === editCategory?.id ? ({ id: editCategory.id, ...templateCategory, currentName: templateCategory.name, edit: false }) : templateCategory)
        setTemplateCategories(newTemplateCategories);
    }

    const confirmCategoryUpdate = async (editCategory) => {
        const response = await documentTemplateServices.updateCategory(editCategory);
        const { data } = response;
        if (data.code === "TEMPLATE_CATEGORY_UPDATED_SUCCESSFULLY") {
            const { data: categoryData } = data;
            if (editCategory.id === selectedCategory.id) {
                setSelectedCategory({ ...selectedCategory, name: categoryData.name });
            }
            const newTemplateCategories = templateCategories.map(templateCategory => templateCategory?.id === editCategory?.id ? ({ id: editCategory.id, name: categoryData.name, currentName: categoryData.name, edit: false }) : templateCategory)
            setTemplateCategories(newTemplateCategories);
            toast.success(data?.message);
        } else {
            toast.error(data.message);
        }
    }

    const handleRemoveCategoryClick = (clickedCategory) => {
        setDeleteCategory(clickedCategory);
        setShowDeleteConfirmationModal(true);
    }

    const handleRemoveCategory = async () => {
        const response = await documentTemplateServices.deleteCategory(deleteCategory.id);
        setShowDeleteConfirmationModal(false);
        const { data } = response;
        if (data.code === "TEMPLATE_CATEGORY_DELETED_SUCCESSFULLY") {
            if (deleteCategory.id === selectedCategory.id) {
                setSelectedCategory({ name: "" });
            }
            const newTemplateCategories = templateCategories.filter(templateCategory => templateCategory?.id !== deleteCategory?.id)
            setTemplateCategories(newTemplateCategories);
            toast.success(data?.message);
        } else {
            toast.error(data.message);
        }
    };

    const handleCategoryEdit = (editCategory) => {
        const newTemplateCategories = templateCategories.map(category => category?.id === editCategory?.id ? { ...category, edit: true } : category);
        setTemplateCategories(newTemplateCategories);
    }

    const handleCloseDeleteConfirmationModal = () => {
        setDeleteCategory();
        setShowDeleteConfirmationModal(false);
    }

    const textChangeAppData = textChangeApp(documentState)

    return showDeleteConfirmationModal ? (
                <DeleteConfirmationModal
                    handleCloseModal={handleCloseDeleteConfirmationModal}
                    handleConfirmation={handleRemoveCategory}
                    textColor={textChangeAppData}
                    confirmationHeading={t("DELETE_CATEGORY")}
                    confirmationText={t('DELETE_CATEGORY_CONFIRMATION')}
                />
            ) : (
                <Modal show onHide={handleCloseModal} className='template-document-creation-modal'>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontWeight: 'bold', color: textChangeAppData }}>{t('UPDATE_TEMPLATE_CATEGORIES')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3 justify-content-between">
                            <Col sm={7} className='p-0'>
                                <Form.Group controlId="newCategory">
                                    <Form.Control
                                        type="text"
                                        value={newCategory}
                                        onChange={(e) => setNewCategory(e.target.value)}
                                        placeholder={t('NEW_CATEGORY')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='p-0 d-flex justify-content-end'>
                                <Button style={{ backgroundColor: textChangeAppData }} onClick={handleAddCategory}>
                                    {t('ADD_CATEGORY')}
                                </Button>
                            </Col>
                        </Row>
                        <Row>

                        {templateCategories.map((category, index) => (
                            <Row key={index} className="mb-2" style={{ backgroundColor: 'lightgray', padding: '5px', borderRadius: '5px' }}>
                                {category.edit?
                                    (
                                        <>
                                            <Col sm={10}>
                                                <Form.Control type='text' onChange={(e) => updateTemplateCategory(e, category)} defaultValue={category.currentName} />
                                            </Col>
                                            <Col sm={2} className="d-flex align-items-center">
                                                <svg onClick={() => confirmCategoryUpdate(category)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy-fill me-2" style={{ color: 'green' }} viewBox="0 0 16 16">
                                                    <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z"/>
                                                    <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z"/>
                                                </svg>
                                                <svg onClick={() => cancelCategoryUpdate(category)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square-fill" style={{ color: 'red' }} viewBox="0 0 16 16">
                                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708"/>
                                                </svg>
                                            </Col>
                                        </>
                                    ) : (
                                        <>
                                            <Col sm={10}>
                                                <Form.Control plaintext readOnly={category.name} value={category.name} />
                                            </Col>
                                            <Col sm={2} className="d-flex align-items-center">
                                                <i class="bi bi-pen-fill me-2" onClick={() => handleCategoryEdit(category)}></i>
                                                <i className="bi bi-trash" onClick={() => handleRemoveCategoryClick(category)} style={{ cursor: 'pointer', color: 'red' }}></i>
                                            </Col>
                                        </>
                                    )
                                }   
                            </Row>
                        ))}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                        {t('CLOSE')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
};

export default TemplateCategories;
