import { Fragment, useContext, useEffect, useState, useRef, createRef } from 'react';
import short from 'short-uuid';
import Draggable from 'react-draggable';
import ContentLoader from "react-content-loader";
import { toast } from 'react-toastify';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { pdfjsWorker } from 'pdfjs-dist/build/pdf.worker.entry';
import { Rnd } from 'react-rnd';
import { Dropdown } from 'react-bootstrap';

import './DocumentUpdate.css';
import DocumentServices from '../../services/document.services';
import zoomInIcon from './../../assets/zoom-in.png';
import zoomOutIcon from './../../assets/zoom-out.png';
import reAssignIcon from './../../assets/re-assign-icon.png'
import { recipientSignatureColor, textChangeApp } from '../../utils/constants';
import { mapAnnotations } from '../../utils/helper';
import { DocumentContext } from '../../store/document';
import signatureIcon from "../../assets/icons8-signature-64.png"
import photoIcon from "../../assets/icons8-photo-50.png"
import stampIcon from "../../assets/icons8-stamp-66.png"
import textBoxIcon from "../../assets/TextBox.png"
import warning_icon from '../../assets/warning-triangle.png'
import greenTick from '../../assets/green-tick.png'
import DocumentTemplateServices from '../../services/documentTemplateServices';

const documentServices = new DocumentServices();
const documentTemplateServices = new DocumentTemplateServices();

const getAnnotationWidth = () => 150;
const getAnnotationHeight = () => 64;

const SIGNATURE = "SIGNATURE"
const STAMP = "STAMP"
const PHOTO = "IMAGE"
const TEXT = "TEXTAREA"

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const closeIconStyles = {
    container: {
        position: 'relative',
        width: '200px',
        height: '200px',
        backgroundColor: 'lightgray',
        border: '1px solid gray',
    },
    closeIconContainer: {
        position: 'absolute',
        top: '-12px',
        right: '-5px',
        width: '22px',
        height: '22px',
        borderRadius: '50%',
        backgroundColor: 'gray',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
    },
    closeIcon: {
        fontSize: '14px',
        color: 'white',
        cursor: 'pointer',
        position: 'absolute',
    },
};

const AddSignPosition = ({ selectedDocInfo, callNext }) => {
    const { documentState, fetchDocuments, documentDispatch } = useContext(DocumentContext);

    const { t } = useTranslation('documentsUpdate');
    const { t: ts } = useTranslation('apiMessage');

    const { selectedDocument } = documentState;
    let isDragging = false;

    const params = useParams();

    const pdfDocumentRef = useRef(null);
    const refPages = useRef([]);
    const documentRef = useRef();
    const currentAnnotation = useRef();

    const [signers, setSigners] = useState([])
    const [annotations, setAnnotations] = useState([]);
    const [annotation, setAnnotation] = useState({});
    const [docZoom, setDocZoom] = useState(1);
    const [selectedAnnotationId, setSelectedAnnotationId] = useState(null);
    const [selectedRecipient, setSelectedRecipient] = useState();
    const [allRecipient, setAllRecipient] = useState([]);
    const [numPages, setNumPages] = useState(null);
    const [addAnnotation, setAddAnnotation] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [StampPhotoSign, setStampPhotoSign] = useState(true);
    const [reAssignAnnotation, setReAssignAnnotation] = useState('');

    const { title, url, id } = selectedDocument || {};

    const getDocument = async () => {
        const document = await documentServices.getDocument(selectedDocInfo?.id);
        if (document) {
            setSigners(document?.recipients);
            documentDispatch({ type: 'SET_SELECTED_DOCUMENT', payload: document });
            let arr = [];
            document?.recipients?.forEach((recipient, index) => {
                arr.push({
                    recipient,
                    color: recipientSignatureColor[index] ? recipientSignatureColor[index] : '#a6a49f',
                })
            });
            setAllRecipient(arr);
            setAnnotations(mapAnnotations(document?.recipients) || []);
            setSelectedRecipient(document?.recipients?.[0])
            setStampPhotoSign(false);
        }
    };

    const textChangeAppData = textChangeApp(documentState);

    useEffect(() => {
        getDocument();
        if (selectedDocInfo?.documentTemplateId) getTemplateData();
    }, [params?.document]);

    const getTemplateData = async () => {
        const document = await documentTemplateServices.getTemplate(selectedDocInfo?.documentTemplateId);
        if (document) {
            const responseAnnotations = document.documentTemplateFields.filter(annotation => annotation.fulfiller === 'SIGNER') || [];
            const formattedAnnotations = responseAnnotations.map(annotation => ({
                annId: annotation.id,
                height: null,
                width: null,
                page: 0,
                recipientEmail: '',
                recipientId: '',
                signingStatus: "NOT_SIGNED",
                type: annotation?.type,
                x: Number(annotation?.positionX),
                y: Number(annotation?.positionY),
                isTemplateAnnotation: true,
            }))
            setAnnotations([...annotations, ...formattedAnnotations]);
        }
    };

    useEffect(() => {
        callNext({ status: 'DRAFT', recipients: annotations, signers });
    }, [annotations])

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    }, []);

    useEffect(() => {
        const updateDocument = async () => {
            try {
                let type = currentAnnotation.current;
                const payload = {
                    type: type,
                    page: selectedAnnotation[0]?.page,
                    positionX: selectedAnnotation[0]?.x,
                    positionY: selectedAnnotation[0]?.y,
                    height: selectedAnnotation[0].height || 20,
                    width: selectedAnnotation[0].width || 20,
                    recipientId: selectedRecipient?.id,
                }
                const response = await documentServices.addRecipientPositions(payload);
                if (response.message) {
                    toast.success(ts(response?.code || response?.message));
                    setAddAnnotation(false);
                    const responseAnnotation = response?.data;
                    const updatedData = annotations.map(obj => {
                        if (obj.annId === annotation.annId) {
                            return {
                                x: Number(responseAnnotation?.positionX),
                                y: Number(responseAnnotation?.positionY),
                                height: responseAnnotation.height,
                                width: responseAnnotation.width,
                                page: Number(responseAnnotation?.page),
                                type: type,
                                annId: responseAnnotation?.id,
                                recipientEmail: obj?.recipientEmail,
                                recipientId: responseAnnotation?.recipientId,
                                signingStatus: responseAnnotation?.signingStatus,
                            };
                        }
                        return obj;
                    });
                    setAnnotation({});
                    setAnnotations(updatedData);
                }
            } catch (error) {
                toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`) || error.response?.data?.code);
            } finally {
                //scroll back to page where the annotation is added
                goToPageOnRender();
            }
        }
        const selectedAnnotation = annotations && annotations.length > 0 && annotations.filter(annotation => annotation.annId === selectedAnnotationId);
        selectedAnnotation && selectedAnnotation.length > 0 && addAnnotation && updateDocument();
    }, [selectedAnnotationId, annotations, fetchDocuments, id, selectedRecipient?.id]);

    //Scroll back to last added annotation site
    const goToPageOnRender = () => {
        if (annotations.length) {
            const lastAnnotationIndex = annotations.length - 1;
            const annotationsHtml = document.querySelector(`.annotation_${lastAnnotationIndex}`);
            if (annotationsHtml) {
                annotationsHtml.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
            }
        }
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        refPages.current = new Array(nextNumPages).fill().map((_, index) => refPages.current[index] || createRef());
        setNumPages(nextNumPages);
    }

    const handleAnnotationClick = (e, index) => {
        if (isDragging || e?.target?.className === 'annotation-text' || e?.target?.className === 'recipient-email' || e?.target?.className !== `react-pdf__Page__canvas` || currentAnnotation.current === undefined) {
            setAddAnnotation(false);
            return null;
        } else {
            const localRef = refPages.current[index];
            const { clientX, clientY } = e;
            const { x, y, height, width } = localRef.getBoundingClientRect();
            const pageX = clientX - x;
            const pageY = clientY - y;
            const nextId = annotations.length + 1;
            const overFlowWidth = width - pageX;
            const overFlowHeight = height - pageY;
            let type = currentAnnotation.current;
            const annotation = {
                x: overFlowWidth < getAnnotationWidth() ? pageX - (getAnnotationWidth() - overFlowWidth) : pageX,
                y: overFlowHeight < getAnnotationHeight() ? pageY - (getAnnotationHeight() - overFlowHeight) : pageY,
                page: index,
                type: type,
                annId: nextId,
                recipientId: selectedRecipient?.id,
                recipientEmail: selectedRecipient?.email,
            };
            if (type === TEXT) {
                annotation.x = overFlowWidth < 100 ? pageX - (100 - overFlowWidth) : pageX;
                annotation.y = overFlowHeight < 60 ? pageY - (60 - overFlowHeight) : pageY;
                annotation.height = 60;
                annotation.width = 100;
            }
            setAddAnnotation(true);
            setAnnotation(annotation);
            setAnnotations([...annotations, annotation]);
            setSelectedAnnotationId(nextId);
        }
    };

    const getAnnotationElement = (element) => {
        let currentElement = element;
        for (let i = 0; i < 4; i++) {
            if (currentElement.className.includes('react-draggable')) {
                return currentElement;
            }
            currentElement = currentElement.parentElement;
        }
        return undefined;
    }

    const handleDrag = (e) => {
        isDragging = true;
    };

    const onDragStop = async (e, draggableData, annotationId, index) => {
        setTimeout(() => (isDragging = false), 0);
        if (e.target.className === 're-assign-button' || e.target.className.includes('re-assign-button')) {
            setReAssignAnnotation(annotationId)
        } else if (e.target.className === 'close-button' || e.target.className.includes('close-button')) {
            try {
                setReAssignAnnotation('');
                const response = await documentServices.removeRecipientPositions(annotationId);
                if (response.message) {
                    const updatedDoc = { ...selectedDocument }
                    updatedDoc?.recipients.forEach(recipient => {
                        const data = recipient?.fields.filter(annotation => {
                            return annotation.id !== annotationId
                        })
                        recipient.fields = [...data]
                        return recipient

                    })
                    const res = annotations.filter(annotation => annotation.annId !== annotationId);
                    setAnnotations(res);
                    documentDispatch({ type: 'SET_SELECTED_DOCUMENT', payload: updatedDoc });
                    setAnnotations(res);

                    toast.success(ts(response?.code || response?.message));
                }
            } catch (error) {
                toast.error(ts(error?.response?.data?.code || 'SOMETHING_WENT_WRONG'))
                console.log('Something went wrong.');
            }
        } else {
            setReAssignAnnotation('');
            let newArr = [...annotations]
            let targetTextElement = e.target;
            const newArray = newArr.map((annotation, i) => {
                if (annotation.annId === annotationId) {
                    const newAnnotationData = { ...annotation, x: draggableData.x, y: draggableData.y };
                    if (annotation.type === TEXT) {
                        targetTextElement = getAnnotationElement(e.target);
                        if (targetTextElement) {
                            newAnnotationData.height = targetTextElement.clientHeight;
                            newAnnotationData.width = targetTextElement.clientWidth;
                        }
                    }
                    return newAnnotationData;
                } else {
                    return annotation;
                }
            });
            try {
                const cAnn = annotations?.filter(v => v.annId === annotationId)?.[0];
                const payload = { page: cAnn.page, positionX: draggableData.x, positionY: draggableData.y }
                if (targetTextElement) {
                    payload.height = targetTextElement.clientHeight;
                    payload.width = targetTextElement.clientWidth;
                }
                const response = await documentServices.updateRecipientPositions({ payload, id: annotationId });
                if (response.message) {
                    toast.success(ts(response?.code || response?.message));
                    setAnnotations(newArray);
                    setSelectedAnnotationId(annotationId);
                }
            } catch (error) {
                // Revert back to original Position if something goes wrong
                setAnnotations(newArr);

                toast.error(ts(error?.response?.data?.code || 'SOMETHING_WENT_WRONG'))
                console.log('Something went wrong.');
            }
        }
    };

    const renderSignature = (annotation, index) => {
        const recipient = allRecipient?.find(item => item.recipient.id === annotation.recipientId);
        const colorCode = recipient?.color || '#a6a49f';
        const isDraggableDisabled = annotation.emailSent;

        return (
            <Draggable
                disabled={isDraggableDisabled || annotation?.isTemplateAnnotation}
                onStop={(e, draggableData) => onDragStop(e, draggableData, annotation.annId, index)}
                defaultPosition={{ x: annotation.x, y: annotation.y }}
                bounds="parent"
                onDrag={handleDrag}
                key={index}
            >
                {renderAnnotationTemplate(annotation, colorCode, SIGNATURE)}
            </Draggable>
        );
    };

    const renderImage = (annotation, index) => {
        const recipient = allRecipient?.find(item => item.recipient.id === annotation.recipientId);
        const colorCode = recipient?.color || '#a6a49f';
        const isDraggableDisabled = annotation.emailSent;

        return (
            <Draggable
                disabled={isDraggableDisabled || annotation?.isTemplateAnnotation}
                onStop={(e, draggableData) => onDragStop(e, draggableData, annotation.annId, index)}
                defaultPosition={{ x: annotation.x, y: annotation.y }}
                bounds="parent"
                onDrag={handleDrag}
                key={index}
            >
                {renderAnnotationTemplate(annotation, colorCode, PHOTO)}
            </Draggable>
        );
    };

    const renderTextarea = (annotation, index) => {
        const recipient = allRecipient?.find(item => item.recipient.id === annotation.recipientId);
        const colorCode = recipient?.color || '#a6a49f';
        const isDraggableDisabled = annotation.emailSent;
        return (
            <Rnd
                size={{ width: `${annotation.width}px`, height: `${annotation.height}px` }}
                position={{ x: annotation.x, y: annotation.y }}
                onDragStop={(e, d) => { onDragStop(e, d, annotation.annId, index) }}
                onResizeStop={(e, direction, ref, delta, position) => { onDragStop(e, position, annotation.annId, index) }}
                minHeight={23}
                minWidth={20}
                bounds="parent"
            >
                <div
                    className={`annotation_${index}`}
                    style={{ height: '100%', width: '100%', borderStyle: "solid", borderColor: "blue" }}
                >
                    <div
                        style={{ height: '100%', width: '100%', backgroundColor: '#808080ba' }}
                        className='resizableTextarea'
                    >
                    </div>
                    {/* {!annotation.emailSent && (
                        <div
                            className="re-assign-button re-assign-button-container"
                            style={{ ...closeIconStyles.closeIconContainer, right: '20px' }}
                        >
                            <div className="re-assign-button" style={closeIconStyles.closeIcon}>
                                <img className='re-assign-button' style={{ filter: 'invert(1)' }} src={reAssignIcon} width={20} alt={'re-assign'} />
                            </div>
                        </div>
                    )} */}
                    {!annotation.emailSent && (
                        <div
                            className="close-button close-button-container"
                            style={closeIconStyles.closeIconContainer}
                        >
                            <div className="close-button" style={closeIconStyles.closeIcon}>
                                &times;
                            </div>
                        </div>
                    )}
                </div>
            </Rnd>
        );
    };

    const renderStamp = (annotation, index) => {
        const recipient = allRecipient?.find(item => item.recipient.id === annotation.recipientId);
        const colorCode = recipient?.color || '#a6a49f';
        const isDraggableDisabled = annotation.emailSent;

        return (
            <Draggable
                disabled={isDraggableDisabled || annotation?.isTemplateAnnotation}
                onStop={(e, draggableData) => onDragStop(e, draggableData, annotation.annId, index)}
                defaultPosition={{ x: annotation.x, y: annotation.y }}
                bounds="parent"
                onDrag={handleDrag}
                key={index}
            >
                {renderAnnotationTemplate(annotation, colorCode, STAMP, index)}
            </Draggable>
        );
    };

    const renderAnnotationTemplate = (annotation, colorCode, type, index) => (
        <div
            className={`annotation_${index}`}
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
                maxWidth: `${getAnnotationWidth()}rem`,
                backgroundColor: 'rgb(255 255 255)',
                opacity: 0.8,
                cursor: 'move',
            }}
        >
            <div className="annotation-text d-flex flex-column align-items-center">
                {type === SIGNATURE && (
                    <p className='StampPhotoSignIconsdoc justify-content-center gap-2' style={{ width: '8rem', height: '2.7rem' }}>
                        <img src={signatureIcon} alt='' style={{ height: '1.5rem' }} />
                        <div className='' style={{ color: 'black', fontSize: '14px' }}>{t('SIGNATURE_LABEL')}</div>
                    </p>
                )}
                {type === PHOTO && (
                    <p className='StampPhotoSignIconsdoc flex-column'>
                        <img className='mt-1' src={photoIcon} alt='' style={{ height: '1.7rem' }} />
                        <div className='' style={{ color: 'black', fontSize: '14px' }}>{t('PHOTO_LABEL')}</div>
                    </p>
                )}
                {/* {type === TEXT && (
                    <div style={{ height: '100%', width: '100%', borderStyle: 'solid', borderColor: 'blue' }}>
                        <div style={{ height: '100%', width: '100%', backgroundColor: '#808080ba' }} className='resizableTextarea'></div>
                    </div>
                )} */}
                {type === STAMP && (
                    <p className='StampPhotoSignIconsdoc flex-column'>
                        <img className='mt-1' src={stampIcon} alt='' style={{ height: '1.7rem' }} />
                        <div className='' style={{ color: 'black', fontSize: '14px' }}>{t('STAMP_LABEL')}</div>
                    </p>
                )}
                {<div className="recipient-email">
                    {Boolean(annotation.recipientEmail) ? annotation.recipientEmail : '< >'}
                </div>}
            </div>
            {/* {!annotation.emailSent && (
                <div
                    className="re-assign-button re-assign-button-container"
                    style={{ ...closeIconStyles.closeIconContainer, right: '20px' }}
                >
                    <div className="re-assign-button" style={closeIconStyles.closeIcon}>
                        <img className='re-assign-button' style={{ filter: 'invert(1)' }} src={reAssignIcon} width={20} alt={'re-assign'} />
                    </div>
                </div>
            )} */}
            {!annotation.emailSent && (
                <div
                    className="close-button close-button-container"
                    style={closeIconStyles.closeIconContainer}
                // onClick={(e) => deleteAnnotation(annotation.annId)}
                >
                    <div className="close-button" style={closeIconStyles.closeIcon}>
                        &times;
                    </div>
                </div>
            )}
        </div>
    );

    const renderAnnotations = (pageIndex) => {
        return annotations && annotations.map((annotation, index) => {
            if (annotation.page === pageIndex && annotation?.signingStatus === "NOT_SIGNED" && selectedRecipient) {
                switch (annotation.type) {
                    case SIGNATURE:
                        return renderSignature(annotation, index);
                    case PHOTO:
                        return renderImage(annotation, index);
                    case TEXT:
                        return renderTextarea(annotation, index);
                    case STAMP:
                        return renderStamp(annotation, index);
                    default:
                        return null
                }
            }
            return null;
        });
    };

    const onClickRecipient = (recipient, index) => {
        setSelectedRecipient(recipient);
        setStampPhotoSign(false)
        setSelectedIndex(index);
    };

    const dynamicCursor = (selectedAnnotation) => {
        switch (selectedAnnotation) {
            case SIGNATURE:
                return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="60"><rect x="0" y="0" width="100" height="100" fill="rgb(0 93 135)" opacity="0.4" /><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="15" fill="white">${t('SIGNATURE_LABEL')}</text></svg>'), auto`;
            case PHOTO:
                return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect x="5" y="0" width="100" height="100" fill="rgb(0 93 135)" opacity="0.4" /><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="14" fill="white">${t('PHOTO_LABEL')}</text></svg>'), auto`;
            case STAMP:
                return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect x="0" y="0" width="22rem" height="100" fill="rgb(0 93 135)" opacity="0.4" /><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="14" fill="white">${t('STAMP_LABEL')}</text></svg>'), auto`;
            case TEXT:
                return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="60"><rect x="0" y="0" width="22rem" height="100" fill="rgb(0 93 135)" opacity="0.4" /><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="14" fill="white">${t('TEXTAREA_LABEL')}</text></svg>'), auto`;
            default:
                return 'pointer'
        }
    }

    const onClickRecipientsignature = () => {
        documentRef.current.style.cursor = dynamicCursor(SIGNATURE)
        currentAnnotation.current = SIGNATURE;
    }

    const onClickRecipientPhoto = () => {
        documentRef.current.style.cursor = dynamicCursor(PHOTO)
        currentAnnotation.current = PHOTO;
    }

    const onClickRecipientStamp = () => {
        documentRef.current.style.cursor = dynamicCursor(STAMP)
        currentAnnotation.current = STAMP;
    }

    const onClickRecipientText = () => {
        documentRef.current.style.cursor = dynamicCursor(TEXT)
        currentAnnotation.current = TEXT;
    }

    const zoomInClicked = () => {
        docZoom <= 6 && setDocZoom(docZoom + 1);
    };

    const zoomOutClicked = () => {
        docZoom > 1 && setDocZoom(docZoom - 1);
    };

    const renderPlaceholder = (props) => {
        const length = 30;
        const lines = Array.from({ length }, (_, index) => index);
        return (
            <div className='row'>
                <div className='col-sm-12' style={{ height: '600px' }}>
                    <ContentLoader
                        speed={1}
                        // width={'auto'}
                        // height={'auto'}
                        viewBox="0 0 auto auto"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        style={{ width: '100%', height: '100%' }}
                        {...props}
                    >
                        <rect x="10%" y="5" rx="3" ry="3" width="80%" height="6" />
                        {
                            lines.map((item, index) => {
                                return (
                                    <rect x="0" y={(15 * (index + 1)) + (6 * (index + 1))} rx="3" ry="3" width="100%" height="6" />
                                )
                            })
                        }
                        <rect x="10%" y={(15 * (10 + 1)) + (6 * (10 + 1))} rx="3" ry="3" width="80%" height="6" />
                    </ContentLoader>
                </div>
            </div>
        )
    }

    const assignReceiptanttoAnnotation = async (signer) => {
        const payload = { "newRecipientId": signer?.id, "documentId": selectedDocInfo?.id, recipientFeildId: reAssignAnnotation };
        const response = await documentServices.updateAnnotationRecipient(payload);
        if (response?.message) {
            const annotations_new = annotations.map(ann => {
                if (ann.annId === reAssignAnnotation) {
                    return {
                        ...ann,
                        recipientEmail: signer?.email,
                        recipientId: signer?.id,
                    }
                } else {
                    return ann
                }
            });
            setAnnotations([...annotations_new]);
            //reset add template annotation
            setReAssignAnnotation('');
            toast.success(t('ANNOTATION_RECIPIENT_UPDATED'))
        }
    }

    const recepitantSelectionDropdown = () => {
        const reAssignAnnotationData = annotations.find(ann => ann.annId === reAssignAnnotation);
        return (
            <Dropdown>
                <Dropdown.Toggle style={{ background: textChangeAppData, width: '18rem' }} id="dropdown-basic">
                    Select Recipient for {reAssignAnnotationData?.type}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{overflowY: 'scroll', maxHeight:'15rem'}}>
                    {signers?.map((signer, i) => <Dropdown.Item disabled={reAssignAnnotationData?.recipientId === signer?.id} onClick={() => assignReceiptanttoAnnotation(signer)} eventKey={`${signer?.email}-${signer?.id}`}>
                        <div className='d-grid' style={{ gridTemplateColumns: '1fr 6fr', gap: '0.5rem' }}>
                            <span className={`${reAssignAnnotationData?.recipientId !== signer?.id && 'invisible'}`}><img src={greenTick} alt='selected' width={15} /></span>
                            <span>{signer?.email}</span>
                        </div>
                    </Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    return (
        <div>
            <div>
                <div className="">
                    <div style={{ width: '100%' }} className="row">
                        <div className="col-sm-8" style={{ height: "500px", overflowY: 'auto' }}>
                            <div className="section">
                                <div className='zoominout-container'>
                                    {docZoom < 6 && (<span className='recipients recipient-text cursor-pointer' onClick={zoomInClicked}>
                                        <div className='circle'> <img src={zoomInIcon} alt='zoom-in icon' className='me-2' width={11} height={12} /> </div>
                                        {t('zIn')}
                                    </span>)}
                                    {docZoom > 1 && (<span className='recipients recipient-text cursor-pointer' onClick={zoomOutClicked}>
                                        <div className='circle'><img src={zoomOutIcon} alt='zoom-out icon' className='me-2' width={11} height={12} /></div>
                                        {t('zOt')}
                                    </span>)}
                                </div>
                                <div ref={documentRef} style={{ cursor: dynamicCursor(currentAnnotation) }}>
                                    <Document
                                        inputRef={pdfDocumentRef}
                                        options={options}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        renderMode="canvas"
                                        file={url}
                                        renderAnnotationLayer={false}
                                        className={`document-update doc-zoom-${docZoom}-stage`}
                                        style={{ zoom: '2' }}
                                        loading={renderPlaceholder}
                                    >
                                        {Array.from({ length: numPages }, (_, index) => (
                                            <Fragment key={short.generate().toString()}>
                                                <div
                                                    key={short.generate().toString()}
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                    className={`mx-auto w-fit page-${index}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleAnnotationClick(e, index)
                                                    }}
                                                >
                                                    <Page
                                                        inputRef={(pa) => (refPages.current[index] = pa)}
                                                        className={`${selectedDocument && selectedDocument?.status !== 'DRAFT' ? 'emailSent' : ''}`}
                                                        style={{ margin: 'auto' }}
                                                        key={`page_${index + 1}`}
                                                        pageNumber={index + 1}
                                                        renderAnnotationLayer={false}
                                                        renderTextLayer={false}
                                                        loading={renderPlaceholder}
                                                        onLoadSuccess={(e) => { }}
                                                        onRenderError={() => console.log('error occurred')}
                                                    >
                                                    </Page>
                                                    {renderAnnotations(index)}
                                                </div>
                                            </Fragment>
                                        ))}
                                    </Document>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 pdf-preview">
                            <div className='pdf-details'>
                                {/* Pdf details */}
                                <div>
                                    <div className="section">
                                        <p className='font-weight-bold' style={{
                                            color: textChangeAppData
                                        }}>{t('flName')}</p>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <i style={{ paddingRight: '7px', color: 'red' }} className="bi bi-file-earmark-pdf"></i>
                                            <span className='pdf-name pl-3' style={{
                                                color: textChangeAppData
                                            }}>{title}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* Assign Recipient to Annotations */}
                                {/* {reAssignAnnotation !== '' && <div className="section">
                                    <div className='py-2' style={{ fontWeight: '600', color: textChangeAppData }}>Select Recipitant for the selected Annotation:</div>
                                    {recepitantSelectionDropdown()}
                                </div>} */}
                                {/* Signers Emails */}
                                <div className="section">
                                    <p className='signers-title' style={{
                                        color: textChangeAppData
                                    }}>{t('sgnr')} </p>
                                    <div style={{ height: "7rem", overflowY: 'auto' }}>
                                        {
                                            signers && signers.length > 0 ? (
                                                signers.map((recipient, index) => (
                                                    <p
                                                        key={index}
                                                        style={{
                                                            background: index === selectedIndex ? recipientSignatureColor[index] : recipientSignatureColor[index],
                                                            border: index === selectedIndex ? 'dashed 3px #000' : undefined,
                                                            boxShadow: index === selectedIndex ? '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)' : '',
                                                            overflowWrap: 'anywhere'
                                                        }}
                                                        className='signers-mail'
                                                        onClick={() => onClickRecipient(recipient, index)}
                                                    >
                                                        {Boolean(recipient.email) ? recipient.email : 'Signers Not Found'}
                                                    </p>
                                                ))
                                            ) : (
                                                <div>
                                                    <p className=''>{t('SIGNER_NOT_FOUND')}</p>
                                                    {/* <button className='btn btn-primary btn-sm  save-btn px-4 d-flex mt-5 fs-6' style={{ background: textChangeAppData }} onClick={() => { window.location.href = `/${orgName}/documents/${id}/add` }}>{t('ADD_SIGNER')}</button> */}
                                                </div>

                                            )
                                        }
                                    </div>
                                </div>
                                <hr></hr>
                                {/* Annotations */}
                                <div>
                                    <div className='d-flex col justify-content-evenly align-items-center gap-1rem' style={{ height: 'auto' }}>
                                        <p
                                            onClick={onClickRecipientsignature} id='sign' className='StampPhotoSignIcons'
                                            disabled={StampPhotoSign}>
                                            <img style={{ height: '2rem' }} src={signatureIcon} alt='' />
                                            <div className='mt-2 fw-bolder' style={{ color: 'black', letterSpacing: '1.3px', fontSize: '14px' }}>{t('SIGNATURE_LABEL').toLocaleLowerCase()}</div>
                                        </p>
                                        <p onClick={onClickRecipientPhoto} id='photo' className='StampPhotoSignIcons'
                                            disabled={StampPhotoSign}>
                                            <img style={{ height: '2rem' }} src={photoIcon} alt='' />
                                            <div style={{ color: 'black', letterSpacing: '1.3px', fontSize: '14px' }} className='mt-2 fw-bolder word-break: break-word'>{t('PHOTO_LABEL').toLocaleLowerCase()}</div>
                                        </p>
                                        <p onClick={onClickRecipientStamp} id='stamp' className='StampPhotoSignIcons'
                                            disabled={StampPhotoSign}>
                                            <img style={{ height: '2rem' }} src={stampIcon} alt='' />
                                            <div className='mt-2 fw-bolder' style={{ color: 'black', letterSpacing: '1.3px', fontSize: '14px' }}>{t('STAMP_LABEL').toLocaleLowerCase()}</div>
                                        </p>
                                        <p onClick={onClickRecipientText} id='stamp' className='StampPhotoSignIcons'
                                            disabled={StampPhotoSign}>
                                            <img style={{ height: '2rem' }} src={textBoxIcon} alt='' />
                                            <div className='mt-2 fw-bolder' style={{ color: 'black', letterSpacing: '1.3px', fontSize: '14px' }}>{t('TEXTAREA_LABEL').toLocaleLowerCase()}</div>
                                        </p>
                                    </div>
                                </div>
                                {/* Signature Warning */}
                                <div style={signers.map(signer => signer.email).every(signerEmail => [...new Set(annotations.filter(ann => ann.type === SIGNATURE).map(ann => ann?.recipientEmail))].includes(signerEmail)) ? { display: 'none' } : {}}>
                                    <p style={{ color: '#d6ac02' }} className='d-flex justify-content-center align-items-center'> <img style={{ height: '1.05rem' }} src={warning_icon} /> &nbsp;  &nbsp; {t('Signature_is_Mandatory')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSignPosition;
