import { useTranslation } from 'react-i18next';

const AddTextTab = () => {
    const { t } = useTranslation('documentsUpdate');
    
    return (<>
        <div>
            <h5 className='mb-0' style={{ fontWeight: '600', color: '#8f1657' }}>
                {t('TEXTBOX_INSTRUCTION')}:
            </h5>
            <p>{t('TEXTBOX_INSTRUCTION_TEXT')}</p>
        </div>
    </>)
}

export default AddTextTab;