import axios from 'axios';
import { isAuthenticated } from '../utils/helper';
import API from '../api';

class PaymentHistoryServices {
    async getInvoices() {
try {
    const { data } = await API.get(`subscription/payment-history?limit=10&status=paid&starting_after=null&ending_before=null`, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` }
    })
    return data;
} catch (error) {
    return error;
}
    }
    async getCards() {
try {
    const { data } = await API.get(`subscription/card`, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` }
    })
    return data;
} catch (error) {
    return error;
}
    }

    async downselectedInvoices(payload){
        try {
            const { data } = await API.post(`subscription/download-invoices`,payload, {
                headers: { Authorization: `Bearer ${isAuthenticated()}` }
            })
            return data
        } catch (error) {
            return error
        }
    }
}
export default PaymentHistoryServices