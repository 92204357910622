import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { emailPattern } from "../../../utils/constants";
import OrgServices from "../../../services/org.services";
import { toast } from "react-toastify";

const orgServices = new OrgServices();

const AddUserModal = (props) => {
    const [email, setEmailId] = useState();
    
    const {
        textChangeAppData,
        setIsLoading,
        setShowAddUserModal,
        setCurrentPage,
        getUsers,
    } = props;

    const { t } = useTranslation('admin');
    const { t: ts } = useTranslation('apiMessage');

    const handleEmailChange = (e) => {
        setEmailId(e.target.value);
    }

    const addEmail = async () => {
        if (email.trim() === "") {
            toast.warn(ts('warnEmptyEmailField'));
            return;
        }
        if (emailPattern.test(email)) {
            setEmailId("");
            try {
                setShowAddUserModal(false);
                setIsLoading(true);
                const res = await orgServices.registerUser({
                    email: email
                });
                if (res?.status === "success") {
                    toast.success(t(res?.code || res?.message));
                    setIsLoading(false);
                    setCurrentPage(1);
                    await getUsers(1);
                }

            } catch (error) {
                toast.error(t(error.response?.data?.code) || error?.response?.data?.message,);
            } finally {
                setShowAddUserModal(false);
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
            toast.warn(ts('warnInvalidEmailEntered'));
            setEmailId("");
        }
    }


    return (
        <Modal
            id="addUserModal"
            show={true}
            onHide={() => setShowAddUserModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            background="static"
        >
            <Modal.Header className="py-2 d-flex align-items-center justify-content-center" style={{ backgroundColor: textChangeAppData, borderRadius: '4px' }}>
                <h3
                    className="modal-titled d-flex justify-content-center mt-2"
                    style={{ color: "white" }}
                    id="staticBackdropLabel"
                >
                    {t('addUserHeadingModal')}
                </h3>
            </Modal.Header>
            <Modal.Body className="p-0">
                <Row className="mt-4 mb-4 justify-content-center">
                    <Col sm={6} md={8} lg={8} className="d-flex flex-column">
                    <label
                        className="mb-2 ms-1"
                        style={{ color: "#114b65" }}
                    >
                        {t('email')}<span className="text-danger">*</span>
                    </label>
                    <input
                        className="rounded-3 p-1"
                        type="email"
                        placeholder={ts("EmailIdPlaceholder")}
                        value={email}
                        onChange={handleEmailChange}
                    />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className="w-100 align-items-center justify-content-center">
                        <Button
                            onClick={() => setShowAddUserModal(false)}
                            className="me-4"
                            style={{ backgroundColor: textChangeAppData, color: 'white', width: "6vw", minWidth: '4rem' }}
                        >
                            {t('cancelBtn')}
                        </Button>
                        <Button
                            onClick={addEmail}
                            style={{ backgroundColor: textChangeAppData, color: 'white', width: "6vw", minWidth: '4rem' }}>
                            {t('saveBtn')}
                        </Button>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default AddUserModal;