import { Fragment, useContext, useEffect, useState, useRef, createRef } from 'react';
import short from 'short-uuid';
import Draggable from 'react-draggable';
import ContentLoader from "react-content-loader";
import { toast } from 'react-toastify';
import { Document, Page, pdfjs } from 'react-pdf';
import { Link, useParams, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { pdfjsWorker } from 'pdfjs-dist/build/pdf.worker.entry';
import Navbar from '../../components/Navbar/Navbar';
import DocumentServices from '../../services/document.services';
import './DocumentUpdate.css';
import zoomInIcon from './../../assets/zoom-in.png';
import zoomOutIcon from './../../assets/zoom-out.png';
import { recipientSignatureColor, textChangeApp } from '../../utils/constants';
import { mapAnnotations } from '../../utils/helper';
import { DocumentContext } from '../../store/document';
import { SignerContext } from '../../store/signer';
import signatureIcon from "../../assets/icons8-signature-64.png"
import photoIcon from "../../assets/icons8-photo-50.png"
import stampIcon from "../../assets/icons8-stamp-66.png"
import { navLinks } from '../../utils/constants';
import bgImagePinkNav from "../../assets/bgm2-7c3e66.png";
import bgImage from "../../assets/navbar-bg.png";
import bgImageVilotNav from "../../assets/bgm2-3C486B.png";
import bgImageGreenNav from "../../assets/bgm2-159895.png";
const documentServices = new DocumentServices();

const getAnnotationWidth= () => 11;
const getAnnotationHeight= () => 64;

const DocumentUpdate = () => {
    const location = useLocation();
    const orgName = location.pathname.split('/')[1];
    const defaultPrimaryColorValue = localStorage.getItem("defaultPrimaryColor");
    const { getOrgApp, documentState, fetchDocuments, documentDispatch } = useContext(DocumentContext);
    const { selectedDocument } = documentState;
    const { title, url, id, recipients } = selectedDocument || {};
    const { signerState } = useContext(SignerContext);
    // const { signers } = signerState;
    // const annotationsList = recipients && recipients.length > 0 ? mapAnnotations(recipients) : [];
    // const [annotationsList, setAnnotationList] = useState([])
    let isDragging = false;
    const pdfDocumentRef = useRef(null);
    const [signers, setSigners] = useState([])
    const [annotations, setAnnotations] = useState([]);
    const [annotation, setAnnotation] = useState({});
    const [docZoom, setDocZoom] = useState(1);
    const [selectedAnnotationId, setSelectedAnnotationId] = useState(null);
    const [selectedRecipient, setSelectedRecipient] = useState();
    const [selectedDocInfo, setSelectedDocInfo] = useState({});
    const [allRecipient, setAllRecipient] = useState([]);
    const [numPages, setNumPages] = useState(null);
    const [pageHeight, setPageHeight] = useState(0);
    const [addAnnotation, setAddAnnotation] = useState(false);
    const refPages = useRef([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { t } = useTranslation('documentsUpdate');
    const { t: ts } = useTranslation('apiMessage');

    const params = useParams();

    const getDocument = async () => {
        const document = await documentServices.getDocument(params?.document);
        if (document) {
            setSelectedDocInfo(document);
            setSigners(document?.recipients);
            documentDispatch({ type: 'SET_SELECTED_DOCUMENT', payload: document });
            let arr = [];
            document?.recipients?.forEach((recipient, index) => {
                arr.push({
                    recipient,
                    color: recipientSignatureColor[index] ? recipientSignatureColor[index] : '#a6a49f',
                })
            });
            setAllRecipient(arr);
            setAnnotations(mapAnnotations(document?.recipients) || []);
            setSelectedRecipient(document?.recipients?.[0])
        }
    };

  const textChangeAppData = textChangeApp(documentState)

    useEffect(() => {
        // fetchDocuments();
        getDocument();
    }, [params?.document]);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    });

    useEffect(() => {
        const updateDocument = async () => {
            if (selectedDocInfo.status !== 'DRAFT') {
                return;
            };
            try {
                const payload = {
                    page: selectedAnnotation[0]?.page,
                    positionX: selectedAnnotation[0]?.x,
                    positionY: selectedAnnotation[0]?.y,
                    recipientId: selectedRecipient?.id,
                }
                const response = await documentServices.addRecipientPositions(payload);
                if (response.message) {
                    toast.success(ts(response?.code || response?.message));
                    setAddAnnotation(false);
                    const responseAnnotation = response?.data;
                    const updatedData = annotations.map(obj => {
                        if (obj.annId === annotation.annId) {
                            return {
                                x: Number(responseAnnotation?.positionX),
                                y: Number(responseAnnotation?.positionY),
                                page: Number(responseAnnotation?.page),
                                annId: responseAnnotation?.id,
                                recipientEmail: obj?.recipientEmail,
                                recipientId: responseAnnotation?.recipientId,
                                signingStatus: responseAnnotation?.signingStatus,
                            };
                        }
                        return obj;
                    });
                    setAnnotations(updatedData);
                    setAnnotation({});
                }
            } catch (error) {
                toast.error(ts(`${error.response?.data?.code || error?.response?.data?.message}`) || error.response?.data?.code);
            }
        }
        const selectedAnnotation = annotations && annotations.length > 0 && annotations.filter(annotation => annotation.annId === selectedAnnotationId);
        selectedAnnotation && selectedAnnotation.length > 0 && addAnnotation && updateDocument();
    }, [selectedAnnotationId, annotations, fetchDocuments, id, selectedRecipient?.id]);

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        refPages.current = new Array(nextNumPages).fill().map((_, index) => refPages.current[index] || createRef());
        setNumPages(nextNumPages);
    }

    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/',
    };

    const handleAnnotationClick = (e, index) => {
        if (isDragging || e?.target?.className === 'annotation-text' || e?.target?.className === 'recipient-email' || e?.target?.className !== 'react-pdf__Page__canvas') {
            setAddAnnotation(false);
            return null;
        } else {
            const localRef = refPages.current[index];
            const { clientX, clientY } = e;
            const { x, y, height, width } = localRef.getBoundingClientRect();
            const pageX = clientX - x;
            const pageY = clientY - y;
            const nextId = annotations.length + 1;
            const overFlowWidth = width - pageX;
            const overFlowHeight = height - pageY;
            const annotation = {
                x: overFlowWidth < getAnnotationWidth() ? pageX - (getAnnotationWidth() - overFlowWidth) : pageX,
                y: overFlowHeight < getAnnotationHeight() ? pageY - (getAnnotationHeight() - overFlowHeight) : pageY,
                page: index,
                annId: nextId,
                recipientId: selectedRecipient?.id,
                recipientEmail: selectedRecipient?.email,
            };
            setAddAnnotation(true);
            setAnnotation(annotation);
            setAnnotations([...annotations, annotation]);
            setSelectedAnnotationId(nextId);
        }
    };

    const handleDrag = (e) => {
        isDragging = true;
    };

    const onDragStop = async (e, draggableData, annotationId, index) => {
        setTimeout(() => (isDragging = false), 0);
        if (e.target.className === 'close-button') {
            try {
                const response = await documentServices.removeRecipientPositions(annotationId);
                if (response.message) {
                    const res = annotations.filter(annotation => annotation.annId !== annotationId);
                    setAnnotations(res);
                    toast.success(ts(response?.code || response?.message));
                    setAnnotations(res);
                }
            } catch (error) {
                toast.error(ts(error?.response?.data?.code || 'SOMETHING_WENT_WRONG'))
                console.log('Something went wrong.');
            }
        } else {
            let newArr = [...annotations]
            const newArray = newArr.map((annotation, i) => {
                if (annotation.annId === annotationId) {
                    return { ...annotation, x: draggableData.x, y: draggableData.y };
                } else {
                    return annotation;
                }
            });
            try {
                const cAnn = annotations?.filter(v => v.annId === annotationId)?.[0];
                const payload = { page: cAnn.page, positionX: draggableData.x, positionY: draggableData.y }
                const response = await documentServices.updateRecipientPositions({ payload, id: annotationId });
                if (response.message) {
                    toast.success(ts(response?.code || response?.message));
                    setAnnotations(newArray);
                    setSelectedAnnotationId(annotationId);
                }
            } catch (error) {
                // Revert back to original Position if something goes wrong
                setAnnotations(newArr);
                
                toast.error(ts(error?.response?.data?.code || 'SOMETHING_WENT_WRONG'))
                console.log('Something went wrong.');
            }
        }
    };
    const closeIconStyles = {
        container: {
            position: 'relative',
            width: '200px',
            height: '200px',
            backgroundColor: 'lightgray',
            border: '1px solid gray',
        },
        closeIconContainer: {
            position: 'absolute',
            top: '-15px',
            right: '-15px',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: 'gray',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
        },
        closeIcon: {
            fontSize: '14px',
            color: 'white',
            cursor: 'pointer',
            position: 'absolute',
            top: '30%',
            left: '30%',
        },
    };

    const renderAnnotations = (pageIndex) => {
        return annotations && annotations.map((annotation, index) => {
            if (annotation.page === pageIndex && annotation?.signingStatus === "NOT_SIGNED") {
                if (annotation.type === 'SIGNATURE') {
                    const recipient = allRecipient?.filter(item => item.recipient.id === annotation.recipientId);
                    const colorCode = recipient?.[0]?.color || '#a6a49f';
                    const isDraggableDisabled = annotation.emailSent;
                    return (
                        <Draggable
                            disabled={isDraggableDisabled}
                            onStop={(e, draggableData) =>
                                onDragStop(e, draggableData, annotation.annId, index)
                            }
                            defaultPosition={{ x: annotation.x, y: annotation.y }}
                            bounds="parent"
                            onDrag={handleDrag}
                            key={index}
                        >
                            <div
                                className={`annotation_${index}`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    maxWidth: `${getAnnotationWidth()}rem`,
                                    // height: `${getAnnotationHeight()}px`,
                                    // backgroundColor: colorCode,
                                    backgroundColor: 'rgb(255 255 255)',
                                    opacity: 0.8,
                                    cursor: 'move',
                                }}
                            >
                                <div className="annotation-text d-flex flex-column align-items-center">
                                    <p
                                        className='StampPhotoSignIconsdoc justify-content-center gap-2'
                                        style={{ width: '8rem', height: '2.7rem' }}
                                    >
                                        <img src={signatureIcon} alt='' style={{ height: '1.5rem' }} />
                                        <div className='' style={{ color: 'black', fontSize: "14px" }}>Signature</div>
                                    </p>
                                    <div className="recipient-email">
                                        {Boolean(annotation.recipientEmail) ? annotation.recipientEmail : '< >'}
                                    </div>
                                </div>
                                {!annotation.emailSent && (
                                    <div
                                        className="close-button close-button-container"
                                        style={closeIconStyles.closeIconContainer}
                                    >
                                        <div className="close-button" style={closeIconStyles.closeIcon}>
                                            &times;
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Draggable>
                    );
                } else if (annotation.type === 'IMAGE') {
                    const recipient = allRecipient?.filter(item => item.recipient.id === annotation.recipientId);
                    const colorCode = recipient?.[0]?.color || '#a6a49f';
                    const isDraggableDisabled = annotation.emailSent;
                    return (
                        <Draggable
                            disabled={isDraggableDisabled}
                            onStop={(e, draggableData) =>
                                onDragStop(e, draggableData, annotation.annId, index)
                            }
                            defaultPosition={{ x: annotation.x, y: annotation.y }}
                            bounds="parent"
                            onDrag={handleDrag}
                            key={index}
                        >
                            <div
                                className={`annotation_${index}`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    maxWidth: `${getAnnotationWidth()}rem`,
                                    // height: `${getAnnotationHeight()}px`,
                                    backgroundColor: 'rgb(255 255 255)',
                                    opacity: 0.8,
                                    cursor: 'move',
                                }}
                            >
                                <div className="annotation-text d-flex flex-column align-items-center">
                                    <p
                                        className='StampPhotoSignIconsdoc flex-column'
                                    // style={{backgroundColor:colorCode}}
                                    >
                                        <img className='mt-1' src={photoIcon} alt='' style={{ height: '1.7rem' }} />
                                        <div className='' style={{ color: 'black', fontSize: "14px" }}>Photo</div>
                                    </p>
                                    <div className="recipient-email">
                                        {Boolean(annotation.recipientEmail) ? annotation.recipientEmail : '< >'}
                                    </div>
                                </div>
                                {!annotation.emailSent && (
                                    <div
                                        className="close-button close-button-container"
                                        style={closeIconStyles.closeIconContainer}
                                    >
                                        <div className="close-button" style={closeIconStyles.closeIcon}>
                                            &times;
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Draggable>
                    );
                } else {
                    const recipient = allRecipient?.filter(item => item.recipient.id === annotation.recipientId);
                    const colorCode = recipient?.[0]?.color || '#a6a49f';
                    const isDraggableDisabled = annotation.emailSent;
                    return (
                        <Draggable
                            disabled={isDraggableDisabled}
                            onStop={(e, draggableData) =>
                                onDragStop(e, draggableData, annotation.annId, index)
                            }
                            defaultPosition={{ x: annotation.x, y: annotation.y }}
                            bounds="parent"
                            onDrag={handleDrag}
                            key={index}
                        >
                            <div
                                className={`annotation_${index}`}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    maxWidth: `${getAnnotationWidth()}rem`,
                                    // height: `${getAnnotationHeight()}px`,
                                    backgroundColor: 'rgb(255 255 255)',
                                    opacity: 0.8,
                                    cursor: 'move',
                                }}
                            >
                                <div className="annotation-text d-flex flex-column align-items-center">
                                    <p
                                        className='StampPhotoSignIconsdoc flex-column'
                                    // style={{backgroundColor:colorCode}}
                                    >
                                        <img className='mt-1' src={stampIcon} alt='' style={{ height: '1.7rem' }} />
                                        <div className='' style={{ color: 'black', fontSize: "14px" }}>Stamp</div>
                                    </p>
                                    <div className="recipient-email">
                                        {Boolean(annotation.recipientEmail) ? annotation.recipientEmail : '< >'}
                                    </div>
                                </div>
                                {!annotation.emailSent && (
                                    <div
                                        className="close-button close-button-container"
                                        style={closeIconStyles.closeIconContainer}
                                    >
                                        <div className="close-button" style={closeIconStyles.closeIcon}>
                                            &times;
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Draggable>
                    );
                }
            }
            return null;
        });
    };

    const onClickRecipient = (recipient, index) => {
        setSelectedRecipient(recipient);
        setSelectedIndex(index);
    };
    const zoomInClicked = () => {
        docZoom <= 6 && setDocZoom(docZoom + 1);
    };
    const zoomOutClicked = () => {
        docZoom > 1 && setDocZoom(docZoom - 1);
    };

    const renderPlaceholder = (props) => {
        const length = 30;
        const lines = Array.from({ length }, (_, index) => index);
        return (
            <div className='row'>
                <div className='col-sm-12' style={{ height: '600px' }}>
                    <ContentLoader
                        speed={1}
                        // width={'auto'}
                        // height={'auto'}
                        viewBox="0 0 auto auto"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        style={{ width: '100%', height: '100%' }}
                        {...props}
                    >
                        <rect x="10%" y="5" rx="3" ry="3" width="80%" height="6" />
                        {
                            lines.map((item, index) => {
                                return (
                                    <rect x="0" y={(15 * (index + 1)) + (6 * (index + 1))} rx="3" ry="3" width="100%" height="6" />
                                )
                            })
                        }
                        <rect x="10%" y={(15 * (10 + 1)) + (6 * (10 + 1))} rx="3" ry="3" width="80%" height="6" />
                    </ContentLoader>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Navbar pageName={navLinks.documents} />
            <div>
                <div className="">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="section overflow-scroll">
                                <div className='d-flex justify-content-center'>
                                <div className='zoominout-container'>
                                    {docZoom < 6 && (<span className='recipients recipient-text cursor-pointer' onClick={zoomInClicked}>
                                        <div className='circle'> <img src={zoomInIcon} alt='zoom-in icon' className='me-2' width={11} height={12} /> </div>
                                        {t('zIn')}
                                    </span>)}
                                    {docZoom > 1 && (<span className='recipients recipient-text cursor-pointer' onClick={zoomOutClicked}>
                                        <div className='circle'><img src={zoomOutIcon} alt='zoom-out icon' className='me-2' width={11} height={12} /></div>
                                        {t('zOt')}
                                    </span>)}
                                </div>
                                </div>
                                <Document
                                    inputRef={pdfDocumentRef}
                                    options={options}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    renderMode="canvas"
                                    file={url}
                                    renderAnnotationLayer={false}
                                    className={`document-update doc-zoom-${docZoom}-stage`}
                                    style={{ zoom: '1.5' }}
                                    loading={renderPlaceholder}
                                >
                                    {Array.from({ length: numPages }, (_, index) => (
                                        <Fragment key={short.generate().toString()}>
                                            <div
                                                key={short.generate().toString()}
                                                style={{
                                                    position: 'relative',
                                                }}
                                                className={`mx-auto w-fit page-${index}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleAnnotationClick(e, index)
                                                }}
                                            >
                                                <Page
                                                    inputRef={(pa) => (refPages.current[index] = pa)}
                                                    className={`mt-5 ${selectedDocument && selectedDocument?.status !== 'DRAFT' ? 'emailSent' : ''}`}
                                                    style={{ margin: 'auto' }}
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}
                                                    renderAnnotationLayer={false}
                                                    renderTextLayer={false}
                                                    loading={renderPlaceholder}
                                                    onLoadSuccess={(e) => {
                                                        if (e.height) setPageHeight(e.height);
                                                    }}
                                                    onRenderError={() => console.log('error occurred')}
                                                >
                                                </Page>
                                                {renderAnnotations(index)}
                                            </div>
                                        </Fragment>
                                    ))}
                                </Document>
                            </div>
                        </div>
                        <div className="col-sm-3 pdf-preview">
                            <div className='pdf-details'>
                                <div>
                                    <div className="section">
                                        <p className='font-weight-bold' style={{ color: textChangeAppData }}>{t('flName')}</p>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <i style={{ paddingRight: '7px', color: 'red' }} className="bi bi-file-earmark-pdf"></i>
                                            <span className='pdf-name pl-3' style={{
                                                color: textChangeAppData }}>{title}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <p className='signers-title' style={{
                                        color: textChangeAppData }}>{t('sgnr')} </p>
                                    {
                                        signers && signers.length > 0 ? (
                                            signers.map((recipient, index) => (
                                                <p
                                                    key={index}
                                                    style={{
                                                        background: index === selectedIndex ? recipientSignatureColor[index] : recipientSignatureColor[index],
                                                        border: index === selectedIndex ? 'dashed 3px #000' : undefined,
                                                        boxShadow: index === selectedIndex ? '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)' : '',
                                                    }}
                                                    className='signers-mail Textwrap'
                                                    onClick={() => onClickRecipient(recipient, index)}
                                                >
                                                    {Boolean(recipient.email) ? recipient.email : 'Signers Not Found'}
                                                </p>
                                            ))
                                        ) : (
                                            <div>
                                                <p className=''>{t('SIGNER_NOT_FOUND')}</p>
                                         {/* <button className='btn btn-primary btn-sm  save-btn px-4 d-flex mt-5 fs-6' style={{ background: textChangeAppData }} onClick={() => { window.location.href = `/${orgName}/documents/${id}/add` }}>{t('ADD_SIGNER')}</button> */}
                                            </div>

                                        )
                                    }
                                    <Link
                                        to={`/${orgName}/documents`}
                                        className={'finish-button-container'}
                                    >
                                        <button style={{
                                            backgroundColor: textChangeAppData }} className='btn btn-primary btn-sm  save-btn px-4 d-flex mt-3 fs-6'>
                                            <div className='d-flex align-items-center'>
                                                <span style={{ fontSize: '13px' }}>{t('CLOSE_BTN')}</span>
                                            </div>
                                        </button>
                                    </Link>
                                </div>
                                {
                                    selectedDocument?.status === "REJECTED" ? 
                                        <div className='border-top pt-4 mt-3'>
                                            <h5 className="rejected-heading mb-3 ms-3">{t('reasonsforRejection')}</h5>
                                            <div className='comment-section px-2'>
                                                {
                                                    selectedDocument?.recipients?.map((recipient) => ( recipient?.isRejected ? 
                                                        <div className='py-3 px-3 shadow d-flex align-items-start gap-2 mb-4 recipient-comment'>
                                                            <div className='rounded-circle text-center px-2 py-1 recipient-name' style={{backgroundColor: textChangeAppData}}>
                                                                {recipient?.email.slice(0, 1).toUpperCase()}
                                                            </div>
                                                            <div>
                                                                <p className='mb-0 font-weight-bold recipient-email Textwrap'>{recipient?.email}</p>
                                                                <span className='Textwrap recipient-reason'>{recipient?.reasonForRejection}</span>
                                                            </div>
                                                        </div> : null
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocumentUpdate;