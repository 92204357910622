import API from '../api';
import { isAuthenticated } from '../utils/helper';

class uploadStampSignPhotoServices {
  async uploadPhotoImage(payload) {
    try {
      const { data } = await API.patch('/update-signature', payload, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
          'Content-Type': ['multipart/form-data', 'application/JSON'],
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPhotoImage() {
    try {
      const { data } = await API.get('/usermedia/', {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteImage(id) {
    try {
      const { data } = await API.delete(`/usermedia/${id}`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}



export default uploadStampSignPhotoServices