import API from '../api';
import { isAuthenticated } from '../utils/helper';
const baseUrl = `${process.env.REACT_APP_SERVER_URL}`;


class OrgServices {
  get path() {
    return `/admin`;
  }

  async addApperance(subDomain, payload) {
    try {
      const { data } = await API.put(`/organization/${subDomain}/appearance`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async changeAdminRoleToUser(payload) {
    try {
      const { data } = await API.post(`admin/change-admin-role-user`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async registerUser(payload, token) {
    try {
      const { data } = await API.post(
        `admin/register-user`,
        payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async organizationAppearance(subDomain, payload) {
    try {
      const { data } = await API.get(`/organization/${subDomain}/appearance`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getSubscriptionPlans() {
    try {
      const { data } = await API.get(`/payment/plans`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async makeAdmin(payload) {
    try {
      const { data } = await API.post(`admin/make-user-admin`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteAdmin(payload) {
    try {
      const { data } = await API.post(`admin/delete-user`, payload, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getusers(page=1, pageSize=10) {
    try {
      const { data } = await API.get(`admin/users?page=${page}&&limit=${pageSize}`, {
        headers: { Authorization: `Bearer ${isAuthenticated()}` },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

}

export default OrgServices;