import { tourContent } from "../../utils/constants";
import { useTranslation } from "react-i18next";

export const dashboardtourConfigAdmin = [
  {
    selector: '.chartTour',
    content: () =>
      tourContent({
        title: 'Documents Chart',
        description: 'Documents status chart.',
      }),
  },
  {
    selector: '.barStatusTour',
    content: () =>
      tourContent({
        title: 'Documents Status',
        description: 'Documents status in bars form.',
      }),
  },
  {
    selector: '.selectedStatusResultTour',
    content: () =>
      tourContent({
        title: 'Selected Status Result',
        description: 'Here you can see selected status results.',
      }),
  },
 
  {
    selector: '.appearanceTabTourClick',
    content: () =>
      tourContent({
        title: 'Appearance',
        description: 'Click here to know more about appearance features.',
      }),
  },
]

export const tourConfigAdmin = [
  {
    selector: '.p-color',
    content: () =>
      tourContent({
        title: 'Primary color selector',
        description: 'Customise the primary colour.',
      }),
  },
  {
    selector: '.logo-tour',
    content: () =>
      tourContent({
        title: 'Logo',
        description: 'Upload the company logo here.',
      }),
  },
  // {
  //   selector: '.language-tour',
  //   content: () =>
  //   tourContent({
  //       title: 'Language',
  //       description: 'You can change the language here.',
  //     }),
  // },
  
  {
    selector: '.font-tour',
    content: () =>
      tourContent({
        title: 'Font',
        description: 'You can change the font here.',
      }),
  },
  {
    selector: '.userTab-tour',
    content: () =>
      tourContent({
        title: 'User Tab',
        description: 'Click here to know more about user feature you can add more user here.',
      }),
  }
]

export const ApperenceTawqiatourConfigAdmin = [
  {
    selector: '.p-color',
    content: () =>
      tourContent({
        title: 'Primary color selector',
        description: 'Customise the primary colour.',
      }),
  },
  {
    selector: '.logo-tour',
    content: () =>
      tourContent({
        title: 'Logo',
        description: 'Upload the company logo here.',
      }),
  },
  {
    selector: '.language-tour',
    content: () =>
    tourContent({
        title: 'Language',
        description: 'You can change the language here.',
      }),
  },
  
  {
    selector: '.font-tour',
    content: () =>
      tourContent({
        title: 'Font',
        description: 'You can change the font here.',
      }),
  },
  {
    selector: '.userTab-tour',
    content: () =>
      tourContent({
        title: 'User Tab',
        description: 'You can add users here.',
      }),
  }
]

export const userTourConfigAdmin = [
  {
    selector: '.adduserTour',
    content: () =>
      tourContent({
        title: 'Add user',
        description: 'You can add user in organisation here.',
      }),
  },
  {
    selector: '.deleteAndMakeUserAdminTour',
    content: () =>
      tourContent({
        title: 'User Delete make user as admin',
        description: 'You can delete and make user as admin here.',
      }),
  },
  {
    selector: '.subscriptionTabTour',
    content: () =>
      tourContent({
        title: 'Subscription',
        description: 'Click here to know more about subscription plans.',
      }),
  },
]
export const activeTourConfigAdmin = [
  {
    selector: '.domainConfigEditTour',
    content: () =>
      tourContent({
        title: 'Domain Configuration',
        description: 'You can edit your domain configurations here.',
      }),
  },
]
export const subscriptionHistoryTabConfigAdmin = [
  {
    selector: '.allCardsTour',
    content: () =>
      tourContent({
        title: 'All Card',
        description: 'You can check your all the cards here.',
      }),
  },
  {
    selector: '.billingHistoryTour',
    content: () =>
      tourContent({
        title: 'Billing History',
        description: 'You can see your past billing history here.',
      }),
  },
   // {
  //   selector: '.editAndDeleteTour',
  //   content: () =>
  //     tourContent({
  //       title: 'Actions',
  //       description: 'You can edit & delete your card from here.',
  //     }),
  // },
]

const TourStepContent = ({title, description}) => {
  const { t } = useTranslation('dashboard');
  return tourContent({
    title: t(title),
    description: t(description),
  });
};

export const dashboardConfig = [
  {
    selector: '.docUploadTour',
    content: () => <TourStepContent title={'D_N_D_OR_UPLOAD_FILE'} description={'UPLOAD_DOCS_HERE'} />
  },
  {
    selector: '.countsectionTour',
    content: () => <TourStepContent title={'Status'} description={'DOC_STATUS'} />
  },
]
export const documentsConfig = [
  {
    selector: '.docUploadTour',
    content: () =>
      tourContent({
        title: 'Drag and drop or upload file',
        description: 'You can upload your documents here.',
      }),
  },
  {
    selector: '.tabstour',
    content: () =>
      tourContent({
        title: 'Documents tabs',
        description: 'My documents and shared with me tabs here.',
      }),
  },
  {
    selector: '.documentsSortingTour',
    content: () =>
      tourContent({
        title: 'Documents sorting',
        description: 'You can sort your documents with the help of this two filters options.',
      }),
  },
  {
    selector: '.documentsTableTour',
    content: () =>
      tourContent({
        title: 'Documents',
        description: 'You can check your documents full details here.',
      }),
  },
  {
    selector: '.docActionsBtnTour',
    content: () =>
      tourContent({
        title: 'Actions',
        description: 'You can edit, download, recall and delete the documents, Also send your documnt to signers.',
      }),
  },
]
export const sharedWithMeTabConfig = [
  {
    selector: '.sharedWithMeTableTour',
    content: () =>
      tourContent({
        title: 'Shared documents ',
        description: `Documents share with you by other's. You can check title, signers, status, owner created date, due date & download your documents`,
      }),
  },
]
export const documentsSignConfig = [
  {
    selector: '.documentTour',
    content: () =>
      tourContent({
        title: 'Document',
        description: 'You can see your signature position, stamp position and photo position on document.',
      }),
  },
  {
    selector: '.signatureTabTour',
    content: () =>
      tourContent({
        title: 'Signature',
        description: 'You can upload your signature and create also.',
      }),
  },
  {
    selector: '.stepperTour',
    content: () =>
      tourContent({
        title: 'Steps',
        description: 'Once you complete this steppes you can finish this process using finish button.',
      }),
  },
  {
    selector: '.rejectTour',
    content: () =>
      tourContent({
        title: 'Reject Document',
        description: 'You can reject the document with reason using this button.',
      }),
  },
]
export const subscriptionTabConfigAdmin = [
  {
    selector: '.subscriptionPlanTab',
    content: () =>
      tourContent({
        title: 'Usage Details',
        description: 'You can see your usage details here.',
      }),
  },
  {
    selector: '.addMoreTour',
    content: () =>
      tourContent({
        title: 'Storage addon',
        description: 'You can add more storage from here.',
      }),
  },
  {
    selector: '.cancelAndChangeBtnTour',
    content: () =>
      tourContent({
        title: 'Cancel and change the plan',
        description: 'You can cancel and change your plan from here.',
      }),
  },
  {
    selector: '.addOns',
    content: () =>
    tourContent({
      title: `Add On's`,
      description: `Here you can see your add on's`,
    }),
  },
  {
    selector: '.paymentTabTour',
    content: () =>
      tourContent({
        title: 'Payment History',
        description: 'Click here to know more about payement history.',
      }),
  },
  // {
  //   selector: '.autoDebitTour',
  //   content: () =>
  //     tourContent({
  //       title: 'Auto debit',
  //       description: 'You can use this option for enable auto debit option.',
  //     }),
  // },
]


